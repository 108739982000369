import { Image, ResponsiveImageType } from "react-datocms";
import { ImageRecord } from "../../graphql/dato/__generated__/dato-graphql.generated";
import ImageCaption from "./ImageCaption";
import ImageCopyright from "./ImageCopyright";

const ImageElement: React.FC<{ imageRecord: ImageRecord }> = ({
  imageRecord,
}) => {
  if (!imageRecord.image) {
    return null;
  }

  const image = imageRecord.image.responsiveImage as ResponsiveImageType;
  const imageCaption = imageRecord.caption || image?.title;

  return (
    <div className="flex flex-col items-center">
      <Image
        data={image}
        layout="intrinsic"
        objectFit="contain"
        className="max-h-[320px]"
      />
      <ImageCaption caption={imageCaption} />
      <ImageCopyright fileField={imageRecord.image} />
    </div>
  );
};

export default ImageElement;
