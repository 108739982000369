import {
  IonButtons,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonPage,
  IonIcon,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

const SuccessAccountDeletionModal: React.FC<{
  onDismiss: () => void;
}> = ({ onDismiss }) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="px-5 pb-5 h-full">
          <div className="text-center bg-[#EAEBF0] h-full p-5">
            <h3 className="text-[1.5rem] text-[#414254] font-bold py-10">
              {t('deleteAccountConfirmation.modals.accountDeletionSuccess.title')}
            </h3>
            <p className=" text-[0.875]text-[#414254]">
              {t('deleteAccountConfirmation.modals.accountDeletionSuccess.text')}
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SuccessAccountDeletionModal;
