import { useCurrentPosition } from "@capacitor-community/geolocation-react";
import { useIonViewDidEnter } from '@ionic/react';

const useGeolocationPermissions = () => {
  const { getPosition } = useCurrentPosition();

  // Ask for geolocation permissions if not given by triggering a geolocation request
  useIonViewDidEnter(() => {
    getPosition();
  });

  return null;
};

export default useGeolocationPermissions;
