import { useCallback, useEffect, useState } from 'react';
import { uniqBy } from 'lodash-es';

import { useLocale } from '../contexts/LocaleContext';
import {
  useExperienceLevelsQuery,
} from '../graphql/dato/__generated__/dato-graphql.generated';
import { ExperienceLevel } from '../interfaces/Interfaces';

const useExperienceLevels = (isVisible: boolean) => {
  const { queryLocale, locale } = useLocale();

  const [experienceLevelsInQueryLocale, setExperienceLevelsInQueryLocale] = useState<{ [key: string]: ExperienceLevel[]; }>({});
  const [isAllExperienceLevelsReceivedInQueryLocale, setIsAllExperienceLevelsReceivedInQueryLocale] = useState<{ [key: string]: boolean; }>({});

  // Query for experienceLevels when rending the component
  const experienceLevelsPageSize = 100;
  const [experienceLevelsPageNumber, setExperienceLevelsPageNumber] = useState(0);

  const experienceLevelsQueryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      first: experienceLevelsPageSize,
      skip: experienceLevelsPageNumber * experienceLevelsPageSize,
    };
  }, [queryLocale, experienceLevelsPageNumber]);

  const [experienceLevelsResult] = useExperienceLevelsQuery({
    variables: experienceLevelsQueryVariables(),
    pause: !isVisible
  });

  const { data: experienceLevelsData } = experienceLevelsResult;

  // Set experienceLevels when data is available
  useEffect(() => {
    if (experienceLevelsData?.experienceLevels) {
      // Add experienceLevels to the list
      const returnedExperienceLevels = experienceLevelsData?.experienceLevels as ExperienceLevel[];
      const newExperienceLevelsInQueryLocale = uniqBy(
        [...(experienceLevelsInQueryLocale?.[locale] || []), ...returnedExperienceLevels],
        (experienceLevel) => experienceLevel.id
      );
      setExperienceLevelsInQueryLocale({
        ...experienceLevelsInQueryLocale,
        [locale]: newExperienceLevelsInQueryLocale,
      });

      // Fetch more experienceLevels if available
      if (returnedExperienceLevels.length === experienceLevelsPageSize) {
        // By setting the page size another GraphQL query for the next page gets executed
        setExperienceLevelsPageNumber(experienceLevelsPageNumber + 1);
      } else {
        setIsAllExperienceLevelsReceivedInQueryLocale({
          ...isAllExperienceLevelsReceivedInQueryLocale,
          [locale]: true,
        });
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [experienceLevelsData]
  );

  return {
    experienceLevelsInQueryLocale,
    isAllExperienceLevelsReceivedInQueryLocale,
  }
};

export default useExperienceLevels;
