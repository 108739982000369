import { IonButton, IonContent, useIonRouter, useIonViewDidEnter } from '@ionic/react';
import { useLocation } from 'react-router';
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import clsx from 'clsx';

import AppLayout from "../layouts/AppLayout";
import toursSlideBackground from "../assets/onboarding/tours-slide.png";
import interactiveSlideBackground from "../assets/onboarding/interactive-slide.png";
import exploreSlideBackground from "../assets/onboarding/explore-slide.png";
import onDemandSlideBackground from "../assets/onboarding/on-demand-slide.png";
import logo from "../assets/onboarding/logo.svg";
import useOnboardingStore from '../stores/useOnboardingStore';
import "./OnboardingPage.scss";
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';

const screensData = [
  {
    imageBlockHeader: ['onboarding.toursScreen.imageBlock.header.partOne', 'onboarding.toursScreen.imageBlock.header.partTwo'],
    backgroundImage: toursSlideBackground,
    backgroundColorClass: 'bg-[#414254]',
    header: 'onboarding.toursScreen.header',
    text: 'onboarding.toursScreen.text',
    showLogo: true,
  },
  {
    backgroundImage: interactiveSlideBackground,
    backgroundColorClass: 'bg-[#A4D5CD]',
    header: 'onboarding.interactiveScreen.header',
    text: 'onboarding.interactiveScreen.text',
  },
  {
    backgroundImage: exploreSlideBackground,
    backgroundColorClass: 'bg-[#AAAAAA]',
    header: 'onboarding.exploreScreen.header',
    text: 'onboarding.exploreScreen.text',
  },
  {
    backgroundImage: onDemandSlideBackground,
    backgroundColorClass: 'bg-[#E38873]',
    header: 'onboarding.onDemandScreen.header',
    text: 'onboarding.onDemandScreen.text',
  },
];

const OnboardingPage: React.FC = () => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const location = useLocation();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const setIsOnboardingOver = useOnboardingStore((state) => state.setIsOnboardingOver);

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_ONBOARDING);
    }
  });

  const completeOnboarding = async (isSkip?: boolean, slideNumber?: number) => {
    if (mixpanelEnabled) {
      mixpanel.track(
      isSkip ?
        MixpanelEvents.ONBOARDING_SKIP :
        MixpanelEvents.ONBOARDING_COMPLETE,
          isSkip ? { slideNumber } : {}
      );
    }

    const params = new URLSearchParams(location.search);
    const returnTo = params.get('returnTo');
    await setIsOnboardingOver(true);
    router.push(returnTo ? decodeURIComponent(returnTo) : '/', 'none', 'pop');
  };

  return (
    <AppLayout>
      <IonContent>
        <Swiper
          modules={[Pagination]}
          slidesPerView={1}
          pagination={{ horizontalClass: 'onboarding-pagination' }}
          className="onboarding-swiper"
        >
          {screensData.map((screenData, index) =>
            <SwiperSlide
              key={screenData.header}
              className={clsx(screenData.backgroundColorClass, "min-h-screen bg-[#414254] flex flex-col")}
            >
              <div
                className="w-full min-h-[300px] relative flex-1 bg-no-repeat bg-cover bg-center rounded-b-[70px]"
                style={{ backgroundImage: `url(${screenData.backgroundImage})` }}
              >
                {screenData.imageBlockHeader && <div className="text-center pt-[60px] mb-[80px]">
                  {screenData.imageBlockHeader.map((headerPart) =>
                    <div key={headerPart}>
                      <span className="text-[1.125rem] font-semibold text-[#414254] text-center bg-[#A4D5CD] px-3 py-0.2">
                        {t(headerPart)}
                      </span>
                      <br/>
                    </div>
                  )}
                </div>}

                {screenData.showLogo && <img
                  src={logo}
                  alt="guidable-logo"
                  className="w-[67px] h-[67px] absolute bottom-[13px] left-1/2 translate-x-[-50%]"
                />}
              </div>

              <div>
                <div className="text-white text-center px-1">
                  <h2 className="text-[1.125rem] font-semibold pt-4 pb-1">{t(screenData.header)}</h2>
                  <p className="text-[1rem] font-medium max-w-[500px] mx-auto">{t(screenData.text)}</p>
                </div>
                <div className="text-center pt-[50px]">
                  {index === screensData?.length - 1 ?
                    <IonButton
                      fill="clear"
                      color="light"
                      className="text-[1rem] capitalize font-semibold underline mb-[20px]"
                      onClick={() => completeOnboarding()}
                    >{t("onboarding.buttons.complete")}</IonButton> :
                    <IonButton
                      fill="clear"
                      color="light"
                      className="text-[0.875rem] capitalize font-light mb-[20px]"
                      onClick={() => completeOnboarding(true, index + 1)}
                    >{t("onboarding.buttons.skip")}</IonButton>
                  }
                </div>
              </div>
            </SwiperSlide>
          )}
        </Swiper>
      </IonContent>
    </AppLayout>
  );
};

export default OnboardingPage;
