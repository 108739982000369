import { IonButton, IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { Capacitor } from '@capacitor/core';
import { useEffect } from 'react';

import {
  SocialProvider,
  useSocialSignInMutation
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useToast from '../../hooks/useToast';
import googleIcon from '../../assets/auth/icons/google.svg';
import useError from '../../hooks/useError';
import { checkReturnedRegistrationData } from '../../helpers/auth-helpers';
import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';
import { useAuth } from '../../contexts/AuthContext';

const GoogleSingInButton: React.FC<{ source: 'login' | 'sign-up' }> = ({ source }) => {
  const { presentToast } = useToast();
  const { t } = useTranslation();
  const { setCurrentUserWithRelatedData } = useAuth();
  const { handleBackendError } = useError();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [socialSignInMutation] = useSocialSignInMutation();

  const signInWithGoogle = async () => {
    if (mixpanelEnabled) {
      mixpanel.track(
        source === 'login' ? MixpanelEvents.LOG_IN_VIA_GOOGLE : MixpanelEvents.SIGN_UP_VIA_GOOGLE
      );
    }

    let resourceUrlResponse: any;
    try {
      resourceUrlResponse = await GoogleAuth.signIn();
    } catch (e) {
      presentToast('socialAuth.google.error', 'danger');
    }

    if (!resourceUrlResponse?.authentication?.access_token && !resourceUrlResponse?.authentication?.accessToken) {
      presentToast('socialAuth.google.error', 'danger');
      return;
    }

    await handleBackendError(async () => {
      const { data, errors } = await socialSignInMutation({
        variables: {
          input: {
            socialProvider: SocialProvider.Google,
            accessToken: resourceUrlResponse?.authentication?.access_token || resourceUrlResponse?.authentication?.accessToken,
          }
        }
      });

      if (errors) return errors;

      const me = data?.auth?.socialSignIn;

      if (resourceUrlResponse?.email && me) {
        checkReturnedRegistrationData({ email: resourceUrlResponse?.email } as any, me);
      }

      if (mixpanelEnabled && me) {
        mixpanel.track(
          source === 'login' ? MixpanelEvents.LOG_IN_SUCCESS : MixpanelEvents.SIGN_UP_SUCCESS,
          {
            sentEmail: resourceUrlResponse?.email,
            returnedEmail: me.email
          }
        );
      }

      setCurrentUserWithRelatedData(me);
    });
  };

  useEffect(() => {
    GoogleAuth.initialize(Capacitor.isNativePlatform() ? {} : {
      clientId: process.env.REACT_APP_GOOGLE_AUTH_WEB_APP_ID,
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });
  }, []);

  return (
    <IonButton
      onClick={signInWithGoogle}
      className="normal-case text-[#344054] text-[1rem]"
      color="light"
      size="large"
    >
      <IonIcon icon={googleIcon} slot="start" className="mr-2"/>
      {t('socialAuth.google.buttonText')}
    </IonButton>
  );
};

export default GoogleSingInButton;
