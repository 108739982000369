import { Directory, Filesystem } from '@capacitor/filesystem';

const useFileSystem = () => {
  const blobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

  const receiveFile = async (url: string) => {
    const res = await fetch(url);
    const blob = await res.blob();
    return blobToBase64(blob) as Promise<string>;
  };

  const writeFile = async (url: string, path: string) => {
    try {
      const data = await receiveFile(url);

      const { uri } = await Filesystem.writeFile({
        path,
        data,
        directory: Directory.Data,
        recursive: true,
      });

      return uri;
    } catch (e) {
      console.log('File downloading error: ', e);
      return null;
    }
  };

  const deleteFile = async (path: string) => {
    try {
      await Filesystem.deleteFile({
        path,
        directory: Directory.Data,
      });
    } catch (e) {
      console.log('File deletion error: ', e);
    }
  };

  const getUri = async (path: string) => {
    try {
      const { uri } = await Filesystem.getUri({
        path,
        directory: Directory.Data,
      });
      return uri;
    } catch (e) {
      console.log('Getting file URI error: ', e);
      return null;
    }
  };

  return {
    writeFile,
    deleteFile,
    getUri,
  };
};

export default useFileSystem;
