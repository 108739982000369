import { IonButton, IonContent, useIonModal, useIonRouter, useIonViewWillLeave } from '@ionic/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';

import AppInput from '../components/form/AppInput';
import { getBackendValidationErrors } from '../helpers/error-helpers';
import { BackendValidationError } from '../interfaces/Interfaces';
import { useLocale } from '../contexts/LocaleContext';
import AppLayout from '../layouts/AppLayout';
import useToast from '../hooks/useToast';
import SuccessAccountDeletionModal from '../components/modals/SuccessAccountDeletionModal';
import {
  useDeleteMeConfirmMutation,
  useResendDeleteMeVerificationEmailMutation
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import FloatingBackButton from '../components/buttons/FloatingBackButton';
import useError from '../hooks/useError';
import { isIosVersion } from '../helpers/device-helpers';
import { useAuth } from '../contexts/AuthContext';

interface DeleteAccountConfirmationForm {
  verificationCode: string;
}

const DeleteAccountConfirmationPage: React.FC = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { presentToast } = useToast();
  const { setCurrentUserWithRelatedData } = useAuth();
  const { handleBackendError } = useError();

  const [deleteMeConfirmMutation] = useDeleteMeConfirmMutation();
  const [resendDeleteMeVerificationEmailMutation] = useResendDeleteMeVerificationEmailMutation();

  const [present, dismiss] = useIonModal(SuccessAccountDeletionModal, {
    onDismiss: () => dismiss(),
  });

  const [backendErrors, setBackendErrors] = useState<BackendValidationError | null | undefined>(null);
  const { register, reset, handleSubmit, formState: { errors } } = useForm<DeleteAccountConfirmationForm>();

  const deleteAccountConfirm = async (input: DeleteAccountConfirmationForm) => {
    try {
      const { data } = await deleteMeConfirmMutation({ variables: { input }});
      const deleteMeConfirm = data?.user?.deleteMeConfirm;

      if (deleteMeConfirm) {
        setCurrentUserWithRelatedData(null);
        reset();

        present({
          // set animated false for ios 17,because animation breaks the popup
          // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
          animated: !isIosVersion(17),
          onWillDismiss: () => {
            router.push(generatePath("/:locale(de|en|fr|es)/user-profile", { locale }));
          }
        });
      }
    } catch (e: any) {
      const backendErrors = getBackendValidationErrors(e);
      setBackendErrors(backendErrors);
    }
  };

  const resendDeleteMeVerificationEmail = async () => {
    await handleBackendError(async () => {
      const { data, errors } = await resendDeleteMeVerificationEmailMutation();

      if (errors) return errors;

      const resendDeleteMeVerificationEmail = data?.user?.resendDeleteMeVerificationEmail;
      if (resendDeleteMeVerificationEmail) {
        presentToast('deleteAccount.toasts.resendVerificationCode.success');
      }
    });
  };

  useIonViewWillLeave(() => {
    reset();
  });

  return (
    <AppLayout>
      <IonContent style={{
        '--padding-top': 'var(--ion-safe-area-top)'
      }}>
        <div className="relative mx-auto min-h-full max-w-xl p-5 pt-[65px] bg-[#EAEBF0] text-center">
          <FloatingBackButton style={{ left: "6px" }}/>

          <h3 className="text-[1.5rem] text-[#414254] font-bold pb-5">{t('deleteAccountConfirmation.pageTitle')}</h3>

          <form onSubmit={handleSubmit(deleteAccountConfirm)} className="flex flex-col">
            <AppInput
              placeholder="deleteAccountConfirmation.form.verificationCode.placeholder"
              name="verificationCode"
              register={register}
              validators={{ required: t('deleteAccountConfirmation.form.verificationCode.errors.required') }}
              frontendErrors={errors}
              backendErrors={backendErrors}
            />

            <IonButton
              type="submit" size="large" shape="round" className="capitalize font-medium text-[1rem] mt-[80px]"
            >{t('deleteAccountConfirmation.buttons.deleteAccount')}</IonButton>
          </form>

          <IonButton
            fill='clear'
            className="capitalize"
            onClick={resendDeleteMeVerificationEmail}
          >{t('deleteAccountConfirmation.buttons.resendVerificationCode')}</IonButton>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default DeleteAccountConfirmationPage;
