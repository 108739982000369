import { memo, useCallback, useEffect, useState } from 'react';
import {
  IonLabel,
} from '@ionic/react';
import { map, uniq } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { Story, Tour } from '../interfaces/Interfaces';
import useAuthStore from '../stores/useAuthStore';
import {
  TourRatingStatistics,
  useGetToursRatingStatisticsLazyQuery,
  useGetUserToursAudioStatisticsLazyQuery,
  UserTourAudioStatistics
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import {
  useToursByTourStopIdsQuery,
  useToursStopsByStoryIdsQuery
} from '../graphql/dato/__generated__/dato-graphql.generated';
import useError from '../hooks/useError';
import { useLocale } from '../contexts/LocaleContext';
import TourCardSlider from './sliders/TourCardSlider';

const ToursRelatedToStories: React.FC<{
  stories: Story[];
  labelTranslationPath: string;
}> = ({ stories, labelTranslationPath }) => {
  const { t } = useTranslation();
  const { queryLocale } = useLocale();
  const { handleBackendError } = useError();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [tourStopIds, setTourStopIds] = useState<string[]>([]);
  const [tours, setTours] = useState<Tour[]>([]);
  const [toursRatingStatistics, setToursRatingStatistics] = useState<TourRatingStatistics[]>([]);
  const [userToursAudioStatistics, setUserToursAudioStatistics] = useState<UserTourAudioStatistics[]>([]);

  const [getToursRatingStatisticsQuery] = useGetToursRatingStatisticsLazyQuery();
  const [getUserToursAudioStatisticsQuery] = useGetUserToursAudioStatisticsLazyQuery();

  const tourStopsQueryVariables = useCallback(() => ({
    locale: queryLocale,
    storyIds: map(stories, ({ id }) => id),
  }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stories]
  );

  const [tourStopsResult] = useToursStopsByStoryIdsQuery({
    variables: tourStopsQueryVariables(),
    pause: !stories?.length,
  });

  const toursQueryVariables = useCallback(() =>({
    locale: queryLocale,
    tourStopIds,
  }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tourStopIds]
  );

  const [toursResult] = useToursByTourStopIdsQuery({
    variables: toursQueryVariables(),
    pause: !tourStopIds?.length,
  });

  useEffect(() => {
    const tourStops = tourStopsResult?.data?.tourStops;
    if (tourStops?.length) {
      setTourStopIds(uniq(map(tourStops, ({id}) => id)));
    }
  }, [tourStopsResult]);

  useEffect(() => {
    const tours = toursResult?.data?.tours;
    if (tours?.length) {
      setTours(tours as Tour[]);
    }
  }, [toursResult]);

  useEffect(() => {
    const getToursRatingStatistics = async () => {
      if (tours?.length) {
        await handleBackendError(async () => {
          const { data, error } = await getToursRatingStatisticsQuery({
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                datoTourIds: map(tours, ({ id }) => id),
              }
            }
          });

          if (error) return error;

          const statistics = data?.tourReview?.getToursRatingStatistics;
          if (statistics) {
            setToursRatingStatistics(statistics);
          }
        });
      }
    };

    getToursRatingStatistics();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tours]
  );

  useEffect(() => {
    const getUserToursAudioStatistics = async () => {
      if (isAuthenticated && tours?.length) {
        await handleBackendError(async () => {
          const { data, error } = await getUserToursAudioStatisticsQuery({
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                datoTourIds: map(tours, ({ id }) => id),
              }
            }
          });

          if (error) return error;

          const statistics = data?.userTour?.getUserToursAudioStatistics;
          if (statistics) {
            setUserToursAudioStatistics(statistics);
          }
        });
      }
    };

    getUserToursAudioStatistics();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, tours]
  );

  useEffect(() => {
    setTourStopIds([]);
    setTours([]);
    setToursRatingStatistics([]);
    setUserToursAudioStatistics([]);
  }, [queryLocale]);

  if (!tours?.length) {
   return null;
  }

  return (
    <div>
      <IonLabel className="block text-[1rem] text-[#535E69] font-medium py-4">
        {t(labelTranslationPath)}
      </IonLabel>

      <div className="mx-[-4px]">
        <TourCardSlider
          tours={tours}
          toursRatingStatistics={toursRatingStatistics}
          userToursAudioStatistics={userToursAudioStatistics}
        />
      </div>
    </div>
  );
};

export default memo(ToursRelatedToStories);
