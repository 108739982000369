import { IonButton, IonContent, useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';

import AppLayout from '../layouts/AppLayout';
import { useLocale } from '../contexts/LocaleContext';
import useRoutes from '../hooks/useRoutes';
import { useDeleteMeMutation } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import FloatingBackButton from '../components/buttons/FloatingBackButton';
import useError from '../hooks/useError';

const DeleteAccountPage: React.FC = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { localePath } = useRoutes();
  const { handleBackendError } = useError();

  const [deleteMeMutation] = useDeleteMeMutation();

  const deleteMe = async () => {
    await handleBackendError(async () => {
      const { data, errors } = await deleteMeMutation();

      if (errors) return errors;

      const deleteMe = data?.user?.deleteMe;
      if (deleteMe) {
        router.push(generatePath("/:locale(de|en|fr|es)/user-profile/delete-account/confirmation", { locale }));
      }
    });
  };

  return (
    <AppLayout>
      <IonContent style={{
        '--padding-top': 'var(--ion-safe-area-top)'
      }}>
        <div className="relative mx-auto min-h-full max-w-xl p-5 pt-[55px] bg-[#EAEBF0] text-center">
          <FloatingBackButton style={{ left: "6px" }}/>

          <h3 className="text-[1.25rem] text-[#414254] font-bold pt-20 pb-5">{t('deleteAccount.pageTitle')}</h3>
          <p className="text-[0.875rem] text-[#414254] pb-20">{t('deleteAccount.message')}</p>

          <div className="flex flex-col pb-10">
            <IonButton
              fill="outline"
              className="normal-case font-normal"
              style={{ '--background': '#ffffff' }}
              onClick={deleteMe}
            >{t('deleteAccount.buttons.deleteAccount')}</IonButton>
            <IonButton
              size="large"
              className="normal-case font-normal text-[1rem]"
              routerLink={localePath('user-profile')}
            >{t('deleteAccount.buttons.keepAccount')}</IonButton>
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default DeleteAccountPage;
