import { setContext } from '@apollo/client/link/context';
import { App as CapacitorApp } from "@capacitor/app";
import { Device } from '@capacitor/device';
import { isPlatform } from '@ionic/react';

const appVersionMiddleware = setContext(async (req, { headers }) => {
  const deviceInfo = await Device.getInfo();
  const appPlatform = deviceInfo.platform;
  let appVersion;

  const isRunningInBrowser = isPlatform('mobileweb') || deviceInfo.platform === 'web';

  if (!isRunningInBrowser) {
    const appInfo = await CapacitorApp.getInfo();
    appVersion = appInfo?.version;
  }

  return {
    headers: {
      ...headers,
      ...appVersion ? { 'app-version': appVersion } : {},
      'app-platform': isRunningInBrowser ? 'web' : appPlatform,
    },
  };
});

export default appVersionMiddleware;
