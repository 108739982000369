import { DeepPartial } from 'react-hook-form';
import { useEffect } from 'react';

import TourMapWithMarkers from '../../components/map/TourMapWithMarkers';
import useGeolocationPermissions from '../../hooks/useGeolocationPermissions';
import { Tour } from '../../interfaces/Interfaces';
import { UserTour, UserTourAudioStatistics } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';

const TourMapTab: React.FC<{
  tour: Tour,
  userTour?: DeepPartial<UserTour> | null;
  isVisible: boolean;
  userTourAudioStatistics?: UserTourAudioStatistics | null;
}> = ({ tour, userTour, isVisible, userTourAudioStatistics }) => {
  // Request geolocation permissions if not granted yet
  //  by triggering a Geolocation request
  useGeolocationPermissions();

  const { mixpanel, mixpanelEnabled } = useMixpanel();

  useEffect(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_TOUR_MAP_TAB, {
        tourId: tour?.id,
        tourTitle: tour?.title,
      });
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  return (
    <div className="h-full w-full min-h-[400px]">
      <TourMapWithMarkers
        tour={tour}
        userTour={userTour}
        isVisible={isVisible}
        userTourAudioStatistics={userTourAudioStatistics}
      />
    </div>
  );
};

export default TourMapTab;
