import { ApolloLink } from '@apollo/client';

const timezoneMiddleware = new ApolloLink((operation, forward) => {
  const timezone = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;

  // add the recent-activity custom header to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      timezone
    }
  }));

  return forward(operation);
});

export default timezoneMiddleware;
