import { IonButton, useIonViewWillLeave } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { map, trim } from 'lodash-es';
import * as moment from 'moment';

import useAuthStore from '../../stores/useAuthStore';
import AppInput from '../../components/form/AppInput';
import { getBackendValidationErrors } from '../../helpers/error-helpers';
import { BackendValidationError } from '../../interfaces/Interfaces';
import useToast from '../../hooks/useToast';
import { useUpdateMeMutation } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import AppSelect from '../../components/form/AppSelect';
import { useGendersQuery } from '../../graphql/dato/__generated__/dato-graphql.generated';
import { useLocale } from '../../contexts/LocaleContext';
import AppDateInput from '../../components/form/AppDateInput';

interface UpdateProfileForm {
  profile: {
    firstName: string;
    lastName: string;
    birthday: Date | null;
    datoGenderId: string;
  }
}

const UpdateProfile: React.FC = () => {
  const user = useAuthStore(state => state.me);
  const setMe = useAuthStore(state => state.setMe);
  const { t } = useTranslation();
  const { presentToast } = useToast();
  const { queryLocale } = useLocale();

  const [updateMeMutation] = useUpdateMeMutation();
  const [gendersResult] = useGendersQuery({ variables: { locale: queryLocale }});

  const [gendersOptions, setGenderOptions] = useState<Array<{ value: string; text: string }>>([]);

  const [backendErrors, setBackendErrors] = useState<BackendValidationError | null | undefined>(null);
  const { register, reset, setValue, handleSubmit, formState: { errors } } = useForm<UpdateProfileForm>();

  const updateProfile = async (input: UpdateProfileForm) => {
    try {
      const inputWithTrimmedData = {
        profile: {
          ...input.profile,
          firstName: trim(input.profile.firstName),
          lastName: trim(input.profile.lastName),
        }
      }

      const { data } = await updateMeMutation({ variables: {
        input: inputWithTrimmedData,
      }});
      const me = data?.user?.updateMe;

      if (me) {
        setMe(me);
        presentToast('profile.updateProfile.toasts.updateProfile.success');
      }
    } catch (e: any) {
      const backendErrors = getBackendValidationErrors(e);
      setBackendErrors(backendErrors);
    }
  };

  const setForm = () => {
    reset({
      profile: {
        firstName: user?.profile?.firstName || '',
        lastName: user?.profile?.lastName || '',
        birthday: user?.profile?.birthday ?
          moment(user?.profile?.birthday).format('YYYY-MM-DD') as unknown as Date :
          null,
        datoGenderId: user?.profile?.datoGenderId || '',
      }
    });
  };

  useEffect(() => {
    setForm();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, gendersOptions]
  );

  useEffect(() => {
    setGenderOptions(map(gendersResult?.data?.genders, (gender) => ({
      value: gender.id,
      text: gender.title as string,
    })));
  }, [gendersResult]);

  useIonViewWillLeave(() => {
    setForm();
  });

  return (
    <div className="flex flex-col mb-[30px]">
      <form onSubmit={handleSubmit(updateProfile)} className="flex flex-col">
        <AppInput
          placeholder="profile.updateProfile.form.firstName.placeholder"
          name="profile.firstName"
          register={register}
          validators={{ required: t('profile.updateProfile.form.firstName.errors.required') }}
          frontendErrors={errors}
          backendErrors={backendErrors}
          trimSpacesExceptLastOne={true}
        />
        <AppInput
          placeholder="profile.updateProfile.form.lastName.placeholder"
          name="profile.lastName"
          register={register}
          validators={{ required: t('profile.updateProfile.form.lastName.errors.required') }}
          frontendErrors={errors}
          backendErrors={backendErrors}
          trimSpacesExceptLastOne={true}
        />
        <AppDateInput
          placeholder="profile.updateProfile.form.birthday.placeholder"
          name="profile.birthday"
          register={register}
          setValue={setValue}
          frontendErrors={errors}
          backendErrors={backendErrors}
        />
        <AppSelect
          placeholder="profile.updateProfile.form.gender.placeholder"
          name="profile.datoGenderId"
          options={gendersOptions}
          register={register}
          frontendErrors={errors}
          backendErrors={backendErrors}
        />

        <IonButton
          type="submit" size="large" shape="round" className="capitalize font-medium text-[1rem] mt-[20px]"
        >{t('profile.updateProfile.buttons.save')}</IonButton>
      </form>
    </div>
  );
};

export default UpdateProfile;
