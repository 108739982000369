import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonLabel,
  IonModal,
  IonTitle,
  IonToggle,
  IonToolbar
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { countBy, includes, map, reduce } from 'lodash-es';

import { Category, ExperienceLevel, Story } from '../../interfaces/Interfaces';
import { checkIfStorySelectedByCategory, checkIfStorySelectedByExperienceLevel } from '../../helpers/story-helpers';
import RecordsByCategoriesFilterSection from './RecordsByCategoriesFilterSection';
import { isIosVersion } from '../../helpers/device-helpers';
import useAnalyticsStore from '../../stores/useAnalyticsStore';
import filterIcon from '../../assets/map/filter.svg';

const RecordsByExperienceLevelsFilterSection: React.FC<{
  sectionTitle: string;
  experienceLevels?: ExperienceLevel[];
  selectedExperienceLevelIds: string[];
  storiesCountByExperienceLevels: { [key: string]: number };
  updateSelected: (id: string, isChecked: boolean) => void;
}> = ({
  sectionTitle,
  experienceLevels,
  selectedExperienceLevelIds,
  storiesCountByExperienceLevels,
  updateSelected,
}) => {
  const { t } = useTranslation();

  return (
    <div className="m-2 border-b border-[#d5d6dc]">
      <h2 className="pl-4 py-5 text-[#232437] text-[1.25rem] font-semibold">{t(sectionTitle)}</h2>

      <IonItemGroup className="pb-2">
        {experienceLevels?.map((experienceLevel) => {
          const storyCountByItem = storiesCountByExperienceLevels[experienceLevel.id];

          // hide experience levels without stories
          if (!storyCountByItem) return null;

          return (
            <IonItem key={experienceLevel.id} lines="none" className="mb-6">
              <IonCheckbox
                slot="end"
                mode="md"
                color="dark"
                value={experienceLevel.id}
                onClick={(e: any) => {
                  updateSelected(experienceLevel.id, e?.target?.checked);
                }}
                checked={selectedExperienceLevelIds?.includes(experienceLevel.id)}
              />
              <div>
                <div className="text-[#232437] font-medium">{experienceLevel.title}</div>
                <div className="text-[#687582] text-[0.875rem]">{experienceLevel?.description}</div>
              </div>
            </IonItem>
          );
        })}
      </IonItemGroup>
    </div>
  )
};

const StoryMapFilter: React.FC<{
  stories: Story[];
  categories?: Category[];
  previouslySelectedCategoryIds: string[];
  setSelectedCategories: (categories: string[]) => void;
  experienceLevels?: ExperienceLevel[];
  previouslySelectedExperienceLevelIds: string[];
  setSelectedExperienceLevels: (categories: string[]) => void;
  previouslySelectedIsIncludingViewedStories: boolean;
  setSelectedIsIncludingViewedStories: (isIncludingViewedStories: boolean) => void;
}> = ({
  stories,
  categories,
  previouslySelectedCategoryIds,
  setSelectedCategories,
  experienceLevels,
  previouslySelectedExperienceLevelIds,
  setSelectedExperienceLevels,
  previouslySelectedIsIncludingViewedStories,
  setSelectedIsIncludingViewedStories
}) => {
  const { t } = useTranslation();

  const [currentlySelectedCategoryIds, setCurrentlySelectedCategoryIds] = useState<string[]>([]);
  const [currentlySelectedExperienceLevelIds, setCurrentlySelectedExperienceLevelIds] = useState<string[]>([]);
  const [currentlySelectedIsIncludingViewedStories, setCurrentlySelectedIsIncludingViewedStories] = useState<boolean>();
  const [storyCountByCategories, setStoryCountByCategories] = useState<{ [key: string]: number }>({});
  const [storyCountByExperienceLevels, setStoryCountByExperienceLevels] = useState<{ [key: string]: number }>({});
  const [totalStoryCount, setTotalStoryCount] = useState<number>(0);

  const viewedStoryIds = useAnalyticsStore((state) => state.viewedStoryIds);

  const modal = useRef<HTMLIonModalElement>(null);

  useEffect(() => {
    setCurrentlySelectedCategoryIds(previouslySelectedCategoryIds);
    setCurrentlySelectedExperienceLevelIds(previouslySelectedExperienceLevelIds);
    setCurrentlySelectedIsIncludingViewedStories(previouslySelectedIsIncludingViewedStories);
  }, [previouslySelectedCategoryIds, previouslySelectedExperienceLevelIds, previouslySelectedIsIncludingViewedStories]);

  useEffect(() => {
    const storiesByCategoriesCount: { [key: string]: number } = reduce(categories, (acc, category) => {
      const storiesByCategory = stories.filter((story: Story) => {
        const storyCategoryIds = map(story?.categories, category => category.id);
        return includes(storyCategoryIds, category.id) &&
          (!!currentlySelectedIsIncludingViewedStories || (!viewedStoryIds.includes(story.id)));
      });

      return {
        ...acc,
        [category.id]: storiesByCategory.length,
      }
    }, {});

    setStoryCountByCategories(storiesByCategoriesCount);

    // sort categories by the count of attached stories
    categories?.sort((a, b) => storiesByCategoriesCount[b.id] - storiesByCategoriesCount[a.id]);
  }, [stories, categories, currentlySelectedIsIncludingViewedStories, viewedStoryIds]);

  useEffect(() => {
    const storiesByExperienceLevelsCount: { [key: string]: number } = reduce(experienceLevels, (acc, experienceLevel) => {
      const storiesByExperienceLevel = stories.filter((story: Story) => {
        const storyExperienceLevelIds = map(story?.experienceLevels, experienceLevel => experienceLevel.id);
        return includes(storyExperienceLevelIds, experienceLevel.id) &&
          (!!currentlySelectedIsIncludingViewedStories || (!viewedStoryIds.includes(story.id)))
      });

      return {
        ...acc,
        [experienceLevel.id]: storiesByExperienceLevel.length,
      }
    }, {});

    setStoryCountByExperienceLevels(storiesByExperienceLevelsCount);
  }, [stories, experienceLevels, currentlySelectedIsIncludingViewedStories, viewedStoryIds]);

  useEffect(() => {
    const selectedStoriesCount = countBy(stories, story =>
      checkIfStorySelectedByCategory(story, currentlySelectedCategoryIds, categories) &&
      checkIfStorySelectedByExperienceLevel(story, currentlySelectedExperienceLevelIds, experienceLevels) &&
      (!!currentlySelectedIsIncludingViewedStories || (!viewedStoryIds.includes(story.id)))
    );

    setTotalStoryCount(selectedStoriesCount?.true || 0);
  }, [
    currentlySelectedCategoryIds, currentlySelectedExperienceLevelIds, currentlySelectedIsIncludingViewedStories,
    stories, categories, experienceLevels, viewedStoryIds
  ]);

  const updateSelectedCategoryIds = (categoryId: string, checked: boolean) => {
    checked ?
      setCurrentlySelectedCategoryIds([...currentlySelectedCategoryIds, categoryId]) :
      setCurrentlySelectedCategoryIds(currentlySelectedCategoryIds.filter((id) => id !== categoryId));
  };

  const updateAllSelectedCategoryIds = (type: 'clearAll' | 'selectAll' = 'clearAll') => {
    const newSelectedCategoryIds = type === 'clearAll' ? [] : (categories?.map(category => category.id) || []);
    setCurrentlySelectedCategoryIds(newSelectedCategoryIds)
  };

  const updateSelectedExperienceLevelIds = (experienceLevelId: string, checked: boolean) => {
    checked ?
      setCurrentlySelectedExperienceLevelIds([...currentlySelectedExperienceLevelIds, experienceLevelId]) :
      setCurrentlySelectedExperienceLevelIds(currentlySelectedExperienceLevelIds.filter((id) => id !== experienceLevelId));
  };

  const updateAllSelectedExperienceLevelIds = (type: 'clearAll' | 'selectAll' = 'clearAll') => {
    const newSelectedExperienceLevelIds = type === 'clearAll' ?
      [] :
      (experienceLevels?.map(experienceLevel => experienceLevel.id) || []);
    setCurrentlySelectedExperienceLevelIds(newSelectedExperienceLevelIds)
  };

  const updateIsIncludingViewedStories = (e: any) => {
    setCurrentlySelectedIsIncludingViewedStories(e.target.checked);
  };

  const resetAllFilters = () => {
    updateAllSelectedCategoryIds();
    updateAllSelectedExperienceLevelIds();
    setCurrentlySelectedIsIncludingViewedStories(false);
  };

  const saveResults = () => {
    setSelectedCategories(currentlySelectedCategoryIds);
    setSelectedExperienceLevels(currentlySelectedExperienceLevelIds);
    setSelectedIsIncludingViewedStories(!!currentlySelectedIsIncludingViewedStories);
    modal?.current?.dismiss();
  };

  const closeFilter = () => {
    setCurrentlySelectedCategoryIds(previouslySelectedCategoryIds);
    setCurrentlySelectedExperienceLevelIds(previouslySelectedExperienceLevelIds);
    setCurrentlySelectedIsIncludingViewedStories(previouslySelectedIsIncludingViewedStories);
    modal?.current?.dismiss();
  };

  return (
    <>
      <IonButton
        id="open-modal"
        fill="clear"
        slot="icon-only"
        shape="round"
        style={{
          '--padding-start': '5px',
          '--padding-end': '5px',
          '--overflow': 'visible'
        }}
      >
        <IonIcon
          src={filterIcon}
          className="text-[2rem] text-[#687582]"
        />
        {(previouslySelectedCategoryIds?.length + previouslySelectedExperienceLevelIds?.length) !== ((categories?.length || 0) + (experienceLevels?.length || 0)) && <div
          className="absolute top-[-6px] right-[-3px] w-[16px] h-[16px] leading-[16px] text-[0.5rem] text-white text-center tracking-normal rounded-full bg-[#3b383d]"
        >{(previouslySelectedCategoryIds?.length || 0) + (previouslySelectedExperienceLevelIds?.length || 0) }</div>}
      </IonButton>

      {/*set animated false for ios 17,because animation breaks the popup*/}
      {/*on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)*/}
      <IonModal ref={modal} animated={!isIosVersion(17)} trigger="open-modal">
        <IonHeader className="ion-no-border">
          <IonToolbar color="light">
            <IonButtons slot="start">
              <IonButton onClick={closeFilter}>
                <IonIcon icon={chevronBackOutline} />
              </IonButton>
            </IonButtons>
            <IonButtons slot="end" className="mr-1">
              <IonButton
                fill="clear" className="normal-case text-[#EC6040] tracking-normal"
                onClick={resetAllFilters}
              ><span className="font-semibold">{t("storyMap.filter.buttons.reset")}</span></IonButton>
            </IonButtons>
            <IonTitle className="text-[1rem] text-center font-semibold text-[#000]">
              {t("storyMap.filter.header")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding relative">
          <RecordsByExperienceLevelsFilterSection
            sectionTitle="storyMap.filter.experienceLevelsSection.title"
            experienceLevels={experienceLevels}
            selectedExperienceLevelIds={currentlySelectedExperienceLevelIds}
            storiesCountByExperienceLevels={storyCountByExperienceLevels}
            updateSelected={updateSelectedExperienceLevelIds}
          />

          <RecordsByCategoriesFilterSection
            sectionTitle="storyMap.filter.categoriesSection.title"
            categories={categories}
            selectedCategoryIds={currentlySelectedCategoryIds}
            recordsCountByCategories={storyCountByCategories}
            updateSelected={updateSelectedCategoryIds}
            updateAllSelected={updateAllSelectedCategoryIds}
          />

          <IonItem
            lines="none"
            className="p-2 border-t border-[#d5d6dc]"
            style={{
              '--inner-padding-end': 0,
              '--background': 'none'
            }}
          >
            <IonLabel><span className="text-[#232437] font-medium">
              {t('storyMap.filter.viewedStoriesSection.label')}
            </span></IonLabel>
            <IonToggle
              className="mx-3 px-0"
              checked={currentlySelectedIsIncludingViewedStories}
              onIonChange={updateIsIncludingViewedStories}
            />
          </IonItem>

          <div className="h-[100px]"/>

          <IonButton
            expand="block"
            size="large"
            className="fixed bottom-0 z-10 left-0 right-0 mx-10 mt-5 mb-10 normal-case"
            onClick={saveResults}
          >
            <span
              className="font-semibold text-[1rem]"
            >{t("storyMap.filter.buttons.applyFilter", { number: totalStoryCount })}</span>
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
};

export default memo(StoryMapFilter);
