import clsx from 'clsx';

const SectionHeader: React.FC<{
  title: string;
  className?: string;
}> = ({ title, className }) => {
  return (
    <h3 className={clsx("font-bold text-[1.25rem] text-[#232437]", className)}>{title}</h3>
  );
};

export default SectionHeader;
