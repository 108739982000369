import { useTranslation } from 'react-i18next';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';

import exploreIcon from '../../assets/home/explore.svg';

const OpenExploreModeSection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center bg-[#fff] px-5 py-10">
      <IonIcon icon={exploreIcon} className="text-[5rem]" />
      <h3 className="text-[1.125rem] font-bold text-[#232437] mb-1">{t('home.openExploreMode.title')}</h3>
      <div className="text-[0.875rem] text-[#535E69] max-w-[275px] mx-auto mb-5">
        {t('home.openExploreMode.text.partOne')}
        <span className="text-[#3E86E6] font-semibold">&nbsp;{t('home.openExploreMode.text.partTwo')}&nbsp;</span>
        {t('home.openExploreMode.text.partThree')}
      </div>
      <IonButton shape="round" className="font-medium normal-case h-[45px]" routerLink="stories">
        <span className="font-semibold">{t('home.openExploreMode.buttons.turnOn')}</span>
        <IonIcon slot="end" icon={arrowForwardOutline} />
      </IonButton>
    </div>
  );
};

export default OpenExploreModeSection;
