import { IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { ErrorMessage } from '@hookform/error-message';
import { head } from 'lodash-es';
import clsx from 'clsx';

const AppSelect: React.FC<{
  register: any;
  placeholder: string;
  name: string;
  options: Array<{ value: any; text: string }>;
  disabled?: boolean;
  validators?: any;
  frontendErrors?: any;
  backendErrors?: any;
  inputClassNames?: string;
  labelClassNames?: string;
}> = ({
  register,
  placeholder,
  name,
  options,
  disabled = false,
  validators,
  frontendErrors,
  backendErrors,
  inputClassNames = 'rounded-[8px]',
  labelClassNames = 'text-gray-500'
}) => {
  const { t } = useTranslation();

  return (
    <div className="mb-3 flex flex-col w-full">
      <div className="relative">
        <IonSelect
          className={clsx(
            "border w-full pb-2 pt-4 px-4 font-normal focus:outline-none peer bg-white min-h-[50px]",
            inputClassNames
          )}
          id={name}
          placeholder={t(placeholder)}
          disabled={disabled}
          cancelText={t('select.buttons.cancel')}
          okText={t('select.buttons.confirm')}
          {...register(name, validators || {})}
        >
          {options?.map((option) =>
            <IonSelectOption key={option.value} value={option.value}>{option.text}</IonSelectOption>
          )}
        </IonSelect>
        <label
          htmlFor={name}
          className={clsx(
            "absolute text-sm duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4",
            labelClassNames
          )}
        >{t(placeholder)}</label>
      </div>

      <ErrorMessage
        errors={frontendErrors}
        name={name}
        render={({ message }) => (
          <p className="text-[0.7rem] text-red-500 text-left ml-4 mt-[2px]">
            {message}
          </p>
        )}
      />

      { backendErrors?.[name] && <p
        className="text-[0.7rem] text-red-500 text-left ml-4 mt-[2px]"
      >{head(backendErrors?.[name])}</p> }
    </div>
  );
};

export default AppSelect;
