import { useCallback, useEffect, useState } from 'react';
import { uniqBy } from 'lodash-es';

import { useLocale } from '../contexts/LocaleContext';
import {
  useCategoriesQuery,
} from '../graphql/dato/__generated__/dato-graphql.generated';
import { Category } from '../interfaces/Interfaces';

const useCategories = (isVisible: boolean, isAvailableForDynamicTourCreation?: boolean) => {
  const { queryLocale, locale } = useLocale();

  const [categoriesInQueryLocale, setCategoriesInQueryLocale] = useState<{ [key: string]: Category[]; }>({});
  const [isAllCategoriesReceivedInQueryLocale, setIsAllCategoriesReceivedInQueryLocale] = useState<{ [key: string]: boolean; }>({});

  // Query for categories when rending the component
  const categoriesPageSize = 100;
  const [categoriesPageNumber, setCategoriesPageNumber] = useState(0);

  const categoriesQueryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      first: categoriesPageSize,
      skip: categoriesPageNumber * categoriesPageSize,
      isAvailableForDynamicTourCreation,
    };
  }, [queryLocale, categoriesPageNumber, isAvailableForDynamicTourCreation]);

  const [categoriesResult] = useCategoriesQuery({
    variables: categoriesQueryVariables(),
    pause: !isVisible,
  });

  const { data: categoriesData } = categoriesResult;

  // Set categories when data is available
  useEffect(() => {
    if (categoriesData?.categories) {
      // Add categories to the list
      const returnedCategories = categoriesData?.categories as Category[];
      const newCategoriesInQueryLocale = uniqBy(
        [...(categoriesInQueryLocale?.[locale] || []), ...returnedCategories],
        (category) => category.id
      );
      setCategoriesInQueryLocale({
        ...categoriesInQueryLocale,
        [locale]: newCategoriesInQueryLocale,
      });

      // Fetch more categories if available
      if (returnedCategories.length === categoriesPageSize) {
        // By setting the page size another GraphQL query for the next page gets executed
        setCategoriesPageNumber(categoriesPageNumber + 1);
      } else {
        setIsAllCategoriesReceivedInQueryLocale({
          ...isAllCategoriesReceivedInQueryLocale,
          [locale]: true,
        });
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [categoriesData]
  );

  return {
    categoriesInQueryLocale,
    isAllCategoriesReceivedInQueryLocale,
  }
};

export default useCategories;
