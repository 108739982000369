import { IonButton, IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { mapOutline } from 'ionicons/icons';

import useRoutes from '../../hooks/useRoutes';
import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';

const FloatingToursMapButton: React.FC = () => {
  const { t } = useTranslation();
  const { localePath } = useRoutes();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  return (
    <IonButton
      className="fixed bottom-1 left-1/2 -translate-x-1/2 normal-case text-white text-[0.875rem] font-semibold h-[52px] min-w-[118px]"
      shape="round"
      style={{
        '--background': '#232437',
        '--box-shadow': '0px 6px 11px -3px rgba(0, 0, 0, 0.25)'
      }}
      routerLink={localePath(`tours/map`, undefined, true)}
      routerDirection="forward"
      onClick={() => {
        if (mixpanelEnabled) {
          mixpanel.track(MixpanelEvents.LIST_OF_TOURS_EXPLORE_MAP_BUTTON);
        }
      }}
    >
      <IonIcon icon={mapOutline} slot="start" className="mr-2"/>
      {t('tours.toursMapButton')}
    </IonButton>
  );
};

export default FloatingToursMapButton;
