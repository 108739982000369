import { intersection, map } from 'lodash-es';

import { Category, Tour, TourStop } from '../interfaces/Interfaces';

const getPreviousTourStop = (tourStop: TourStop): TourStop | null | undefined => {
  const tourStops = tourStop?.tour?.tourStops;
  if (!tourStops?.length) return null;

  const tourStopIndex = tourStops.indexOf(tourStop);
  const previousTourStop = tourStops[tourStopIndex - 1];
  // avoid navigation to preview tour stop
  return previousTourStop?.isPreviewTourStop ? null : previousTourStop;
};

const getNextTourStop = (tourStop: TourStop): TourStop | null | undefined => {
  const tourStops = tourStop?.tour?.tourStops;
  if (!tourStops?.length) return null;

  const tourStopIndex = tourStops.indexOf(tourStop);
  return tourStops[tourStopIndex + 1];
};

const checkIfTourSelectedByCategory = (
  tour: Tour,
  selectedCategoriesInLocale: string[],
  categoriesInLocale?: Category[],
) => {
  // if all categories are selected in the filter mark the story as selected even if any category isn't attached to it
  if (selectedCategoriesInLocale?.length === categoriesInLocale?.length) {
    return true;
  }

  const storyCategoryIds = map(tour?.categories, category => category.id);
  return !!intersection(storyCategoryIds, selectedCategoriesInLocale)?.length;
};

const isFreeTour = (tour: Tour) => {
  return tour.currentTourPrice === 0 || !tour.regularTourPrice;
};

const isAccessibleTour = (tour: Tour, accessibleTourIds: string[]) => {
  return isFreeTour(tour) || accessibleTourIds?.includes(tour.id);
};

export { getPreviousTourStop, getNextTourStop, checkIfTourSelectedByCategory, isFreeTour, isAccessibleTour };
