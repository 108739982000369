import { IonButton, IonIcon } from '@ionic/react';
import { playCircle } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';
import useStoryPlayer from '../../hooks/useStoryPlayer';
import { Tour } from '../../interfaces/Interfaces';

const PlayTourPreviewButton: React.FC<{ tour: Tour }> = ({ tour }) => {
  const { navigateToStory } = useStoryPlayer();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { t } = useTranslation();

  const previewTourStop = tour.tourStops?.find((tourStop) => tourStop.isPreviewTourStop);
  const story = previewTourStop?.stories?.[0];

  return (
    <>
      {!!story && <div className="flex">
        <IonButton
          className="normal-case font-semibold tracking-normal h-[25px]"
          shape="round"
          style={{ '--padding-start': '3px' }}
          onClick={() => {
            if (mixpanelEnabled) {
              mixpanel.track(MixpanelEvents.START_PREVIEW_TOUR_STOP, {
                tourId: tour.id,
                tourTitle: tour.title,
                tourStopId: previewTourStop?.id,
                tourStopTitle: previewTourStop?.title,
                storyId: story?.id,
                storyTitle: story?.title,
              });
            }

            if (story) {
              navigateToStory({ story });
            } else {
              console.log("Tour stop doesn't have any stories to play");
            }
          }}
        >
          <IonIcon icon={playCircle} className="mr-4"/>
          {t('tourPage.buttons.preview')}
        </IonButton>
      </div>}
    </>
  );
};

export default PlayTourPreviewButton;
