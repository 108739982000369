import {
  createContext,
  useContext,
} from 'react';

import { useIonRouter } from '@ionic/react';
import { useLocation } from 'react-router';
import useAuthStore from '../stores/useAuthStore';
import useGroupSharingStore from '../stores/useGroupSharingStore';
import useStoryExplorationStore from '../stores/useStoryExplorationStore';
import { User } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';

const useValue = () => {
  const router = useIonRouter();
  const location = useLocation();

  const setMe = useAuthStore(state => state.setMe);
  const setMyGroupSharing = useGroupSharingStore(state => state.setMyGroupSharing);
  const setIsExploreModeEnabled = useStoryExplorationStore((state) => state.setIsExploreModeEnabled);

  const setCurrentUserWithRelatedData = (me: User | null | undefined, skipNavigation: boolean = false) => {
    if (me) {
      const { groupSharing, ...userData } = me;
      setMe(userData);
      setMyGroupSharing(groupSharing, true);

      // navigate to home screen or returnTo page only if navigationUrl for the GroupSharing doesn't exist
      // otherwise the user is redirected to the GroupSharing navigationUrl
      if (!me?.groupSharing?.navigationUrl && !skipNavigation) {
        const params = new URLSearchParams(location.search);
        const returnTo = params.get('returnTo');
        router.push(returnTo ? decodeURIComponent(returnTo) : '/', 'none', 'pop');
      }
    } else {
      setMe(null);
      setMyGroupSharing(null);
      setIsExploreModeEnabled(false);
    }
  };

  return {
    setCurrentUserWithRelatedData,
  };
};

const AuthContext = createContext({} as ReturnType<typeof useValue>);

const AuthProvider: React.FC = ({ children }) => {
  return (
    <AuthContext.Provider value={useValue()}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  return useContext(AuthContext);
};

export { AuthProvider, useAuth };
