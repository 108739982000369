import { IonCard, IonCardHeader, IonIcon } from '@ionic/react';
import { checkmarkCircle, heart, playCircle } from 'ionicons/icons';
import { Image } from 'react-datocms';
import { useTranslation } from 'react-i18next';

import { Story } from "../../interfaces/Interfaces";
import CategoryTags from "../category-tags/CategoryTags";
import { isPremiumStory } from '../../helpers/story-helpers';

const StoryCard: React.FC<{
  story: Story;
  isViewed?: boolean;
  storyLikesAmount: number;
  navigateToStory: ({ story }: { story: Story }) => void;
}> = ({ story, isViewed, storyLikesAmount, navigateToStory }) => {
  const { t } = useTranslation();

  const creatorName = story.creatorProfile?.creatorName;
  const creatorProfileImage = (story.creatorProfile as any)?.roundProfileImage?.responsiveImage;

  return (
    <IonCard
      className="m-1 p-4 cursor-pointer rounded-[20px] bg-white shadow-[0px_5px_12px_0px_rgba(0,0,0,0.06)]"
      onClick={() => {
        navigateToStory({ story });
      }}
    >
      <IonCardHeader className="flex flex-col min-h-[7.6rem] p-0">
        <div className="text-[1rem] leading-[1.3rem] text-[#202839] font-semibold line-clamp-2 mb-2.5 min-h-[42px]">
          {story.title}
        </div>

        <div className="flex items-center mb-2.5 min-h-[20px]">
          {creatorProfileImage && (
            <div className="mr-2 h-[20px] w-[20px] shrink-0">
              <Image
                data={creatorProfileImage}
                className="rounded-full shadow"
                pictureClassName="rounded-full"
              />
            </div>
          )}
          <div className="text-[0.8125rem] text-[#535E69] font-medium line-clamp-2">{creatorName}</div>
        </div>

        {/* set min-height because the badges are cut off in ios 17 if use line-clamp-2 */}
        <CategoryTags
          categories={story.categories}
          wrapperClassName="line-clamp-2 mt-0.5 min-h-[54px]"
        />

        <div className="h-[28px] pt-1">{isPremiumStory(story) && <span
          className="text-[#976808] text-[0.875rem] font-bold py-1 px-2 rounded-[5px] bg-[#9768084a]"
        >{t('storyCard.premium')}</span>}</div>

        <div className="grow"/>

        <div className="flex justify-between items-center pt-1">
          <div className="flex items-center gap-4">
            <div className="flex items-center">
              <IonIcon icon={heart} slot="end" className="text-[#5A5A5A] w-[13px] h-[13px] mr-0.5"/>
              <div className="text-[#5A5A5A] text-[0.8125rem] font-semibold">
                {storyLikesAmount || 0}
              </div>
            </div>

            {isViewed && <div className="flex items-center">
              <IonIcon icon={checkmarkCircle} className="text-[#8ED283] w-[20px] h-[20px] mr-1"/>
              <div className="text-[#8ED283] text-[0.8125rem] font-medium">{t('storyCard.played')}</div>
            </div>}
          </div>

          <IonIcon icon={playCircle} className="text-[#E16A4F] w-[40px] h-[40px] mr-1"/>
        </div>
      </IonCardHeader>
    </IonCard>
  );
};

export default StoryCard;
