import { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import {
  IonButton,
  IonContent,
  IonItem,
  IonList,
  IonRadio,
  IonRadioGroup,
  useIonRouter,
  useIonViewDidEnter
} from '@ionic/react';
import { useLocation } from 'react-router';
import { GlassfyOffering, GlassfySku } from 'capacitor-plugin-glassfy';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import AppLayout from '../layouts/AppLayout';
import useIonVisible from '../hooks/useIonVisible';
import useInAppPurchase from '../hooks/useInAppPurchase';
import useAuthStore from '../stores/useAuthStore';
import { useTransaction } from '../contexts/TransactionContext';
import coverImage from '../assets/premium-access-purchasing/cover.png';
import './PremiumAccessPurchasingPage.scss';
import useRoutes from '../hooks/useRoutes';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';

const PremiumAccessPurchasingPage: React.FC = () => {
  const router = useIonRouter();
  const location = useLocation();
  const { isVisible } = useIonVisible();
  const { initGlassfy, getOffering, purchaseSku, setCustomSubscriber } = useInAppPurchase();
  const { hasPremiumAccess, activeTransactionsLoading } = useTransaction();
  const { t } = useTranslation();
  const { currentPath, loginPath } = useRoutes();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const user = useAuthStore(state => state.me);
  const isUserInitialised = useAuthStore(state => state.isUserInitialised);

  const [offering, setOffering] = useState<GlassfyOffering | null>();
  const [isLoaderShown, setIsLoaderShown] = useState<boolean>(false);
  const [selectedSku, setSelectedSku] = useState<GlassfySku | null>(null);

  useEffect(() => {
    const initializeGlassfy = async () => {
      const isGlassfyInitialized = await initGlassfy();

      if (isGlassfyInitialized) {
        const selectedOffering = await getOffering('premium_non_renewing');
        setOffering(selectedOffering);
      }
    };

    if (isVisible) initializeGlassfy();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVisible]
  );

  useEffect(() => {
    if (hasPremiumAccess) {
      setIsLoaderShown(false);

      if (isVisible) {
        const params = new URLSearchParams(location.search);
        const returnTo = params.get('returnTo');
        router.push(returnTo ? decodeURIComponent(returnTo) : '/', 'none', 'pop');
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasPremiumAccess, isVisible]
  );

  const purchase = async () => {
    if (!user?.id) {
      router.push(loginPath(currentPath()));
      return;
    }

    if (selectedSku) {
      if (mixpanelEnabled) {
        mixpanel.track(MixpanelEvents.BUY_PREMIUM_ACCESS);
      }

      setIsLoaderShown(true);
      const isCustomSubscriberSet = await setCustomSubscriber(user.id);
      if (isCustomSubscriberSet) {
        const isPurchasing = await purchaseSku(selectedSku);
        if (!isPurchasing) {
          setIsLoaderShown(false);
        }
      } else {
        setIsLoaderShown(false);
      }
    }
  };

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_PREMIUM_ACCESS_PURCHASING);
    }
  });

  if (!Capacitor.isNativePlatform()) {
    return (
      <AppLayout>
        <IonContent>
          <div className="relative mx-auto min-h-full max-w-xl bg-[#FAFAFA] p-5 text-center">
            {t('premiumAccessPurchasing.webError')}
          </div>
        </IonContent>
      </AppLayout>
    )
  }

  return (
    <AppLayout isLoaderShown={isLoaderShown}>
      <IonContent>
        <div
          className="relative mx-auto min-h-full max-w-xl bg-white"
          style={{
            paddingTop: "calc(var(--ion-safe-area-top, 7px) + 7px)",
          }}
        >
          <img src={coverImage} alt=""/>

          <div
            className="absolute top-[-15px] left-[50%] translate-x-[-50%] text-white text-[1.125rem] font-bold bg-[#EC765A] px-[14px] py-[22px] rounded-b-[10px]"
          >{t('premiumAccessPurchasing.label')}</div>
          
          <h2 className="text-[1.125rem] text-[#232437] text-center font-bold px-5 py-5">
            {t('premiumAccessPurchasing.title')}
          </h2>

          <IonList className="pl-8 pr-4 bg-white">
            {['offline', 'groupSharing', 'access'].map((advantage) => <IonItem
              key={advantage}
              lines="none"
              style={{ '--padding-start': 0, '--inner-padding-end': 0, '--background': '#ffffff', '--min-height': '20px' }}
            >
              <div className="flex items-center mb-1.5">
                <div className="border-l-2 border-primary my-[6px] mr-4 min-h-[20px]"/>
                <div
                  className="text-[#868B90] text-[0.75rem]"
                  dangerouslySetInnerHTML={{ __html: t(`premiumAccessPurchasing.advantages.${advantage}`) as string}}
                />
              </div>
            </IonItem>)}
          </IonList>

          <div className="px-5 py-5">
            <div className="text-[1rem] text-[#535E69] font-bold mb-3">{t('premiumAccessPurchasing.form.label')}</div>

            <IonList className="bg-white">
              <IonRadioGroup>
                {offering?.skus.map((sku) => <IonItem
                  key={sku?.product?.identifier}
                  lines="none"
                  className="mb-2"
                  style={{
                    '--background': '#ffffff',
                    '--border-color': '#D5D6DC',
                    '--border-width': '1px',
                    '--border-radius': '12px',
                  }}
                >
                  <IonRadio
                    slot="start"
                    mode="ios"
                    color="dark"
                    className="item-premium-access-radio"
                    disabled={isLoaderShown}
                    onClick={() => setSelectedSku(sku)}
                  />
                  <div className="flex w-full justify-between items-center text-[1rem] font-semibold">
                    <div className={clsx(
                      'text-[1rem] font-semibold mr-2',
                      sku?.product?.identifier === selectedSku?.product?.identifier ? 'text-primary' : 'text-[#535E69]'
                    )}>{sku?.product?.title}</div>
                    <div className="text-[#535E69]">{sku?.product?.price}{sku?.product?.currencyCode}</div>
                  </div>
                </IonItem>)}
              </IonRadioGroup>
            </IonList>

            <IonButton
              className="pointer-events-auto normal-case tracking-normal font-semibold min-h-[56px] w-full text-[1rem]"
              shape="round"
              style={{
                '--background': '#ec765a',
                '--padding-start': '32px',
                '--padding-end': '32px',
                '--box-shadow': 'none'
              }}
              disabled={!isUserInitialised || activeTransactionsLoading || !selectedSku || isLoaderShown}
              onClick={() => purchase()}
            >{t('premiumAccessPurchasing.form.buttons.pay')}</IonButton>
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default PremiumAccessPurchasingPage;
