import { useEffect } from 'react';
import { useIonRouter } from '@ionic/react';
import { inRange, isBoolean } from 'lodash-es';
import * as moment from 'moment';
import { usePrevious } from 'react-use';

import { useGroupSharingChangedSubscription } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { useMediaPlayer } from '../contexts/MediaPlayerContext';
import useGroupSharingStore from '../stores/useGroupSharingStore';
import useAuthStore from '../stores/useAuthStore';

const useGroupSharing = () => {
  const router = useIonRouter();
  const currentMediaPlayerValues = useMediaPlayer();
  const previousNavigationUrl = usePrevious(null);

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const { data: groupSharingChangedData } = useGroupSharingChangedSubscription({
    // subscribe when user is authenticated
    skip: !isAuthenticated,
  });

  const currentRoute = router.routeInfo.pathname + router.routeInfo.search;

  const myGroupSharing = useGroupSharingStore((state) => state.myGroupSharing);
  const setMyGroupSharing = useGroupSharingStore((state) => state.setMyGroupSharing);

  const navigateTo = (route: string | undefined | null, isGroupSharingStart: boolean = false) => {
    // delete isGroupSharingStart query param from the routes
    const updatedRoute = route?.replace('&isGroupSharingStart=true', '');
    const updatedCurrentRoute = currentRoute?.replace('&isGroupSharingStart=true', '');

    if (updatedRoute && (updatedRoute !== updatedCurrentRoute || isGroupSharingStart)) {
      // add isGroupSharingStart to url determine that the group sharing is updated not from subscription
      router.push(isGroupSharingStart ? `${updatedRoute}&isGroupSharingStart=true` : updatedRoute, "none", "replace");
    }
  };

  const togglePlayPause = (isPlaying: boolean | undefined | null) => {
    if (isBoolean(isPlaying) && currentMediaPlayerValues.isPlaying !== isPlaying) {
      currentMediaPlayerValues.togglePlayPause(false);
    }
  };

  const seekTo = (startAudioTime: Date | undefined | null) => {
    if (startAudioTime) {
      const currentAudioTime = moment().diff(moment(startAudioTime), 'seconds');

      if (currentMediaPlayerValues.currentAudioTime !== currentAudioTime) {
        // update only if current time is different +/- 1s
        const changed = !inRange(currentMediaPlayerValues?.currentAudioTime, currentAudioTime - 1, currentAudioTime + 1);
        if (changed) {
          currentMediaPlayerValues.seekTo(currentAudioTime, false);
        }
      }
    }
  };

  const setPlaybackRate = (playbackRate: number | undefined | null) => {
    if (playbackRate && currentMediaPlayerValues.playbackRate !== playbackRate) {
      currentMediaPlayerValues.setPlaybackRate(playbackRate);
    }
  };

  // navigate when user receives group sharing not from subscription (for example when attaching to group sharing)
  useEffect(() => {
    if (myGroupSharing?.isGroupSharingStart && myGroupSharing?.navigationUrl !== previousNavigationUrl) {
      navigateTo(myGroupSharing?.navigationUrl, true);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myGroupSharing],
  );

  // update media player values if user receives group sharing from subscription
  useEffect(() => {
    if (groupSharingChangedData) {
      const {
        navigationUrl,
        isPlaying,
        startAudioTime,
        playbackRate,
      } = groupSharingChangedData?.groupSharingChanged;

      navigateTo(navigationUrl);
      togglePlayPause(isPlaying);
      seekTo(startAudioTime);
      setPlaybackRate(playbackRate);

      setMyGroupSharing(groupSharingChangedData?.groupSharingChanged);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupSharingChangedData]
  );
};

export default useGroupSharing;
