import { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useIonRouter } from "@ionic/react";
import { isEmpty } from "lodash-es";
import { Image, StructuredText } from "react-datocms";

import { CreatorProfile, Tour } from "../../interfaces/Interfaces";
import Slider from "../../components/sliders/Slider";
import BlockRenderer from "../../components/players/BlockRenderer";
import useRoutes from "../../hooks/useRoutes";
import CategoryTags from "../../components/category-tags/CategoryTags";
import SegmentHeader from "../../components/SegmentHeader";
import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';

const TourPreviewContent: React.FC<{ tour: Tour }> = ({ tour }) => {
  const { tourPreviewContent } = tour;

  if (!tourPreviewContent) {
    return null;
  }

  return (
    <div className="prose prose-slate mt-3 prose-p:mt-0 prose-p:mb-1 prose-p:leading-6">
      <StructuredText
        data={tourPreviewContent as any}
        renderBlock={BlockRenderer}
      />
    </div>
  );
};

const TourCreatorProfile: React.FC<{ tour: Tour }> = ({ tour }) => {
  const creatorProfile = tour.creatorProfile as CreatorProfile;

  const router = useIonRouter();
  const { creatorProfilePath } = useRoutes();

  if (!creatorProfile) {
    return null;
  }

  return (
    <div
      className="-mx-4 mt-3 flex cursor-pointer items-center justify-start border-t border-b border-primary/50 bg-primary/10 px-4 py-1"
      onClick={() => {
        // Navigate to creator profile
        router.push(creatorProfilePath(creatorProfile));
      }}
    >
      <div className="mr-3 aspect-square max-h-[72px] rounded-full">
        {creatorProfile.roundProfileImage?.responsiveImage && (
          <Image
            data={creatorProfile.roundProfileImage.responsiveImage}
            pictureClassName="rounded-full"
          />
        )}
      </div>
      <div className="mb-2.5 mt-1.5">
        <div className="text-lg font-bold leading-[1.5rem] text-slate-800">
          {creatorProfile.creatorName}
        </div>
        <div className="text-sm text-slate-700">{creatorProfile.slogan}</div>
        <CategoryTags
          categories={creatorProfile.categories}
          wrapperClassName="mt-1"
        />
      </div>
    </div>
  );
};

const TourImpressions: React.FC<{ tour: Tour }> = ({ tour }) => {
  const { t } = useTranslation();
  const { tourImpressions: impressions } = tour;

  if (!impressions || isEmpty(impressions)) {
    return null;
  }

  return (
    <div className="mt-4">
      <SegmentHeader title={t("tourPage.tourImpressions")} />
      <Slider
        sliderProps={{
          autoHeight: true,
          // Don't swipe on photosphere viewer and image comparer
          noSwipingSelector: ".psv-container, sl-image-comparer",
          slidesPerView: 1.45,
          className: "w-full pb-1.5",
          spaceBetween: 10,
        }}
        slideProps={{
          className:
            "cursor-grab active:cursor-grabbing bg-primary rounded-md shadow-md pb-2 overflow-hidden",
        }}
      >
        {impressions.map((record, index) => (
          <div key={`tour-impressions-${index}`}>
            <BlockRenderer record={record} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

const TourOverviewTab: React.FC<{ tour: Tour }> = ({ tour }) => {
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  useEffect(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_TOUR_OVERVIEW_TAB, {
        tourId: tour?.id,
        tourTitle: tour?.title,
      });
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <div className="relative w-full flex-col px-4 pb-16 pt-2">
        <TourPreviewContent tour={tour} />
        <TourCreatorProfile tour={tour} />
        <TourImpressions tour={tour} />
      </div>
    </>
  );
};

export default TourOverviewTab;
