import { useEffect, useRef, useMemo, useState, memo } from 'react';
import { useIonViewDidEnter } from '@ionic/react';
import Map, { MapRef, NavigationControl } from 'react-map-gl';
import { lineString } from '@turf/helpers';
import bbox from '@turf/bbox';
import { useDebouncedEffect } from '@react-hookz/web';
import { Position } from 'geojson';

import { Tour } from '../../interfaces/Interfaces';
import GeolocateControl from './GeolocateControl';
import { setMapLanguage } from '../../helpers/map-helpers';
import { useLocale } from '../../contexts/LocaleContext';

const navControlStyle = {
  right: 10,
  bottom: 10,
};

const TourMap: React.FC<{ tour: Tour, isVisible: boolean } & { children?: React.ReactNode }> = ({
  tour,
  children,
  isVisible
}) => {
  const mapRef = useRef<MapRef>(null);
  const { locale } = useLocale();

  const initialViewState = useMemo(() => {
    return {
      latitude: tour?.tourStops?.[0]?.location?.latitude,
      longitude: tour?.tourStops?.[0]?.location?.longitude,
      zoom: 15.6,
    };
  }, [tour]);

  // fit all tour stops to the map
  useDebouncedEffect(() => {
    const tourStopCoordinates = tour?.tourStops?.reduce((acc: Position[], tourStop) => {
      const coordinates = tourStop?.location?.longitude && tourStop?.location?.latitude && [tourStop?.location?.longitude, tourStop?.location?.latitude];
      return coordinates ? [...acc, coordinates] : acc;
    }, []);
    const features = lineString(tourStopCoordinates || []);
    const [minLng, minLat, maxLng, maxLat] = bbox(features);

    mapRef?.current?.fitBounds(
      [[minLng, minLat], [maxLng, maxLat]],
      { padding: 40, duration: 1000 }
    );
  }, [tour, mapRef], 10);

  const [viewState, setViewState] = useState(initialViewState);

  const resizeMap = () => {
    if (isVisible) {
      mapRef.current?.resize();
    }
  };

  // Set a different map center if the tour changes
  useEffect(() => {
    if (isVisible) {
      setViewState(initialViewState);
    }
  }, [isVisible, initialViewState]);

  useIonViewDidEnter(() => {
    resizeMap();
  }, [mapRef]);

  return (
    <Map
      ref={mapRef}
      {...viewState}
      onMove={(evt) => setViewState(evt.viewState)}
      attributionControl={false}
      reuseMaps={true}
      dragRotate={false}
      mapStyle="mapbox://styles/thomas-guidable/ckwju2mpv96fa14mplcpx79ld"
      onLoad={(e) => {
        setMapLanguage(e, locale);
      }}
    >
      {isVisible && <GeolocateControl mapRef={mapRef}/>}

      {isVisible && <NavigationControl style={navControlStyle} showCompass={false}/>}

      {children}
    </Map>
  );
};

export default memo(TourMap);
