import { IonFab, IonFabButton, IonIcon, NavContext } from "@ionic/react";
import { arrowBackSharp } from 'ionicons/icons';
import { useContext } from "react";

const FloatingBackButton: React.FC<{
  defaultHref?: string;
  icon?: string;
  color?: string;
  position?: "start" | "end";
  style?: { [key: string]: any };
  buttonStyle?: { [key: string]: any };
  onClick?: () => void;
}> = ({
  defaultHref = "/",
  icon = arrowBackSharp,
  color = 'light',
  position = "start",
  style = {
    marginTop: "calc(var(--ion-safe-area-top, 12px) + 4px)",
    top: "-6px",
    left: "6px",
  },
  buttonStyle,
  onClick,
}) => {
  const navContext = useContext(NavContext);

  const defaultClickHandler = (e: any) => {
    if (navContext.hasIonicRouter()) {
      e.stopPropagation();
      navContext.goBack(defaultHref);
    } else if (defaultHref !== undefined) {
      window.location.href = defaultHref;
    }
  };

  return (
    <IonFab
      vertical="top"
      horizontal={position}
      style={style}
    >
      <IonFabButton
        color={color}
        size="small"
        routerDirection="back"
        className="pointer-events-auto z-50 h-[36px] w-[36px]"
        onClick={onClick || defaultClickHandler}
        style={buttonStyle}
      >
        <IonIcon icon={icon} style={{ fontSize: "26px" }} />
      </IonFabButton>
    </IonFab>
  );
};

export default FloatingBackButton;
