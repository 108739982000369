import { useIonModal, useIonRouter } from '@ionic/react';
import { createContext, useContext, useEffect, useState, } from 'react';

import useAppState from '../hooks/useAppState';
import useRoutes from '../hooks/useRoutes';
import useAuthStore from '../stores/useAuthStore';
import {
  TourCreationRequestStatisticsByUser,
  TourCreationRequestStatus,
  useGetTourCreationRequestStatisticsByUserLazyQuery,
  useTourCreationRequestUpdatedSubscription
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import CreateTourSuccessModal from '../components/modals/CreateTourSuccessModal';
import { Tour } from '../interfaces/Interfaces';
import useError from '../hooks/useError';
import useToast from '../hooks/useToast';

const useValue = () => {
  const { isAppActive } = useAppState();
  const { tourPath } = useRoutes();
  const router = useIonRouter();
  const { handleBackendError } = useError();
  const { presentToast } = useToast();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [tourCreationRequestStatisticsByUser, setTourCreationRequestStatisticsByUser] = useState<
    TourCreationRequestStatisticsByUser
  >({ inProgress: 0, success: 0, total: 0, userId: '' });
  const [createdTourId, setCreatedTourId] = useState<string>();

  const [getTourCreationRequestStatisticsByUserQuery] = useGetTourCreationRequestStatisticsByUserLazyQuery();
  const { data: userTourCreationRequestUpdatedData } = useTourCreationRequestUpdatedSubscription({
    // subscribe when user is authenticated
    skip: !isAuthenticated,
  });

  const [present, dismiss] = useIonModal(CreateTourSuccessModal, {
    tourId: createdTourId,
    navigateToTour: (tour: Tour) => {
      const path = tourPath(tour);
      path && router.push(path);
    },
    onDismiss: () => dismiss(),
  });

  useEffect(() => {
    const getTourCreationRequestStatisticsByUser = async () => {
      await handleBackendError(async () => {
        const { data, error } = await getTourCreationRequestStatisticsByUserQuery({
          fetchPolicy: 'no-cache',
        });

        if (error) return error;

        const statistics = data?.tourCreationRequest?.getTourCreationRequestStatisticsByUser;
        if (statistics) {
          setTourCreationRequestStatisticsByUser(statistics);
        }
      });
    };

    if (isAuthenticated && isAppActive) {
      getTourCreationRequestStatisticsByUser();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, isAppActive]
  );

  useEffect(() => {
    const tourCreationRequestUpdated = userTourCreationRequestUpdatedData?.tourCreationRequestUpdated;
    const createdTourId = tourCreationRequestUpdated?.userTour?.datoTourId;
    const tourCreationRequestStatus = tourCreationRequestUpdated?.status;

    switch (tourCreationRequestStatus) {
      case TourCreationRequestStatus.Success:
        setTourCreationRequestStatisticsByUser({
          ...tourCreationRequestStatisticsByUser,
          inProgress: tourCreationRequestStatisticsByUser?.inProgress - 1,
          success: tourCreationRequestStatisticsByUser?.success + 1,
        });
        break;
      case TourCreationRequestStatus.Failure:
        presentToast('createTour.failureMessageForTourCreationRequest', 'danger');
        setTourCreationRequestStatisticsByUser({
          ...tourCreationRequestStatisticsByUser,
          inProgress: tourCreationRequestStatisticsByUser?.inProgress - 1,
        });
        break;
    }

    if (createdTourId) setCreatedTourId(createdTourId);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userTourCreationRequestUpdatedData]
  );

  useEffect(() => {
    if (createdTourId) present();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createdTourId]
  );

  return {
    tourCreationRequestStatisticsByUser,
    setTourCreationRequestStatisticsByUser,
  };
};

const TourCreationRequestContext = createContext({} as ReturnType<typeof useValue>);

const TourCreationRequestProvider: React.FC = ({ children }) => {
  return (
    <TourCreationRequestContext.Provider value={useValue()}>
      {children}
    </TourCreationRequestContext.Provider>
  );
};

const useTourCreationRequest = () => {
  return useContext(TourCreationRequestContext);
};

export { TourCreationRequestProvider, useTourCreationRequest };
