import { create } from "zustand";
import { Story, StorySlide, Tour, TourStop } from "../interfaces/Interfaces";

interface PlaybackState {
  isPlaying: boolean;
  startAudioTime: number;
  currentAudioTime: number;
  currentAudioDuration: number;
  percentagePlayed: number;
}

interface MediaPlayerStoreState {
  // Records currently being played / loaded in the media player
  currentTour: Tour | null;
  currentTourStop: TourStop | null;
  currentStory: Story | null;
  currentStorySlide: StorySlide | null;
  setCurrentStory: (story: Story) => void;
  setCurrentStorySlide: (storySlide: StorySlide) => void;

  // Playback state
  playbackState: PlaybackState;
  setPlaybackState: (playbackState: PlaybackState) => void;
  resetPlaybackState: () => void;

  // Playback rate
  playbackRate: number;
  setPlaybackRate: (playbackRate: number) => void;

  // Reset state
  unloadMedia: () => void;
}

export const initialPlaybackState = {
  isPlaying: false,
  startAudioTime: 0,
  currentAudioTime: 0,
  currentAudioDuration: 0,
  percentagePlayed: 0,
};

const useMediaPlayerStore = create<MediaPlayerStoreState>((set, get) => ({
  // Records currently being played / loaded in the media player
  currentTour: null,
  currentTourStop: null,
  currentStory: null,
  currentStorySlide: null,
  setCurrentStory: (story) => {
    const firstStorySlide = story.storySlides?.[0];
    const tourStop = story.tourStop;

    set((state) => ({
      ...state,
      currentTour: tourStop?.tour || null,
      currentTourStop: tourStop || null,
      currentStory: story,
      currentStorySlide: firstStorySlide,
    }));
  },
  setCurrentStorySlide: (storySlide) => {
    const { story } = storySlide;
    const tourStop = story?.tourStop;

    set((state) => ({
      ...state,
      currentTour: tourStop?.tour || null,
      currentTourStop: tourStop || null,
      currentStory: story,
      currentStorySlide: storySlide,
    }));
  },

  // Playback state
  playbackState: initialPlaybackState,
  setPlaybackState: (playbackState) => {
    set((state) => ({
      ...state,
      playbackState,
    }));
  },
  resetPlaybackState: () => {
    set((state) => ({
      ...state,
      playbackState: initialPlaybackState,
    }));
  },

  // Playback rate
  playbackRate: 1,
  setPlaybackRate: (playbackRate: number) => {
    set((state) => ({
      ...state,
      playbackRate,
    }));
  },

  // Reset state
  unloadMedia: () => {
    set((state) => ({
      ...state,
      currentTour: null,
      currentTourStop: null,
      currentStory: null,
      currentStorySlide: null,
      playbackState: initialPlaybackState,
      playbackRate: 1,
    }));
  },
}));

export default useMediaPlayerStore;
