import { useTranslation } from 'react-i18next';

import OnboardingHint from './OnboardingHint';

const StoryPlayerMapButtonHint: React.FC<{
  onDismiss: () => void;
}> = ({ onDismiss }) => {
  const { t } = useTranslation();

  return (
    <OnboardingHint onDismiss={onDismiss}>
      <div className="p-4 text-[0.875rem] text-white">{t('onboardingHints.storyPlayerMapButton.text')}</div>
    </OnboardingHint>
  );
};

export default StoryPlayerMapButtonHint;
