import { QuizTaskType } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';

type quizTaskRecordTypename = 'MultipleChoiceQuestionRecord' | 'TextInputRecord' | 'LocationTaskRecord' | 'QrCodeTaskRecord';

const getQuizTaskType = (typename?: quizTaskRecordTypename) => {
  switch (typename) {
    case 'MultipleChoiceQuestionRecord':
      return QuizTaskType.MultipleChoiceQuestion;
    case 'TextInputRecord':
      return QuizTaskType.TextInput;
    case 'LocationTaskRecord':
      return QuizTaskType.LocationTask;
    case 'QrCodeTaskRecord':
      return QuizTaskType.QrCodeTask;
  }
};

export { getQuizTaskType };
