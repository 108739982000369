import { create } from "zustand";

import { uniq } from 'lodash-es';

interface AnalyticsStoreState {
  viewedStoryIds: string[];
  trackStoryViews: (storyIds: string[]) => void;
}

const useAnalyticsStore = create<AnalyticsStoreState>((set, get) => ({
  viewedStoryIds: [],
  trackStoryViews: (storyIds) => {
    const viewedStoryIds = get().viewedStoryIds;

    set((state) => ({
      ...state,
      viewedStoryIds: uniq([...viewedStoryIds, ...storyIds]),
    }));
  },
}));

export default useAnalyticsStore;
