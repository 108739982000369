import { IonButton, UseIonRouterResult } from '@ionic/react';
import { useTranslation } from "react-i18next";
import { generatePath } from 'react-router';
import { identity, pickBy } from 'lodash-es';
import queryString from 'query-string';

import { MixpanelEvents, useMixpanel } from "../../contexts/MixpanelContext";
import { Tour } from "../../interfaces/Interfaces";
import { useLocale } from '../../contexts/LocaleContext';
import { useChangeGroupSharingMutation } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useGroupSharingStore from '../../stores/useGroupSharingStore';

const StartTourButton: React.FC<{
  tour: Tour;
  router?: UseIonRouterResult;
  onButtonClick?: () => void;
}> = ({ tour, router, onButtonClick }) => {
  const { t } = useTranslation();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { locale } = useLocale();

  const [changeGroupSharingMutation] = useChangeGroupSharingMutation();

  const myGroupSharing = useGroupSharingStore((state) => state.myGroupSharing);

  return (
    <IonButton
      className="pointer-events-auto mt-4 normal-case font-medium text-[1rem] w-full min-h-[56px]"
      size="large"
      shape="round"
      style={{
        '--background': '#EA7358'
      }}
      onClick={() => {
        // duplicate the logic of navigateToStory function from useStoryPlayer hook
        // because 'react-router' returns an error for the modals;

        // find the first tour stop that is not a preview one
        const tourStop = tour.tourStops?.find((tourStop) => !tourStop.isPreviewTourStop);
        const story = tourStop?.stories?.[0];

        if (mixpanelEnabled) {
          mixpanel.track(MixpanelEvents.START_TOUR, {
            tourId: tour.id,
            tourTitle: tour.title,
            tourStopId: tourStop?.id,
            tourStopTitle: tourStop?.title,
            storyId: story?.id,
            storyTitle: story?.title,
          });
        }

        if (story) {
          const { tourStop } = story;

          const queryParams = pickBy(
            {
              storyId: story.id,
              tourId: tour?.id,
              tourStopId: tourStop?.id,
            },
            identity
          );

          const storyPlayerPath = generatePath("/:locale(de|en|fr|es)/story?:queryParams", {
            locale,
            queryParams: queryString.stringify(queryParams),
          });

          // update Group Sharing if the user is in Group Sharing mode
          if (myGroupSharing) {
            // delete isGroupSharingStart query param from the route
            const updatedStoryPlayerPath = storyPlayerPath.replace('&isGroupSharingStart=true', '');

            changeGroupSharingMutation({
              variables: {
                input: {
                  navigationUrl: updatedStoryPlayerPath,
                  startAudioTime: new Date(),
                  isPlaying: true,
                }
              }
            });
          }

          // Replace current history entry using replaceState, and instruct the router
          // to not animate in the new page
          router?.push(storyPlayerPath, "none", "replace");

          onButtonClick && onButtonClick();
        } else {
          console.log("Tour stop doesn't have any stories to play");
        }
      }}
    >
      <span className="font-bold">{t("startTourPage.buttons.startTour")}</span>
    </IonButton>
  );
};

export default StartTourButton;
