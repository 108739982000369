import { create } from "zustand";
import { cloneDeep, filter, uniq } from 'lodash-es';

interface GroupSharingStoreState {
  downloadingEntityIds: string[];
  downloadedEntityIds: string[];
  downloadProgress: { [id: string]: { downloaded: number; total: number; }};
  setDownloadingEntityId: (id: string, numberOfTourMediaFiles: number) => void;
  setDownloadedEntityId: (id: string) => void;
  increaseProgress: (id: string) => void;
  deleteDownloadedEntityId: (id: string) => void;
}

const useDownloadStore = create<GroupSharingStoreState>((set, get) => ({
  downloadingEntityIds: [],
  downloadedEntityIds: [],
  downloadProgress: {},
  setDownloadingEntityId: (id, numberOfTourMediaFiles) => {
    set((state) => ({
      ...state,
      downloadingEntityIds: uniq([...state.downloadingEntityIds, id]),
      downloadedEntityIds: filter(state.downloadedEntityIds, downloadedEntityId => downloadedEntityId !== id),
      downloadProgress: {
        ...state.downloadProgress,
        [id]: { downloaded: 0, total: numberOfTourMediaFiles }
      }
    }));
  },
  setDownloadedEntityId: (id) => {
    set((state) => {
      const clonedDownloadProgress = cloneDeep(state.downloadProgress);
      delete clonedDownloadProgress[id];

      return {
        ...state,
        downloadingEntityIds: filter(state.downloadingEntityIds, downloadingEntityId => downloadingEntityId !== id),
        downloadedEntityIds: uniq([...state.downloadingEntityIds, id]),
        downloadProgress: clonedDownloadProgress,
      }
    });
  },
  increaseProgress: (id) => {
    set((state) => ({
      ...state,
      downloadProgress: {
        ...state.downloadProgress,
        [id]: {
          ...state.downloadProgress[id],
          downloaded: state.downloadProgress[id].downloaded + 1,
        }
      }
    }));
  },
  deleteDownloadedEntityId: (id) => {
    set((state) => ({
      ...state,
      downloadedEntityIds: filter(state.downloadingEntityIds, downloadingEntityId => downloadingEntityId !== id),
    }));
  }
}));

export default useDownloadStore;
