import { create } from "zustand";

import { GroupSharing } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { isNil, omitBy } from 'lodash-es';

interface GroupSharingStoreState {
  myGroupSharing: GroupSharing & { isGroupSharingStart?: boolean } | null;
  setMyGroupSharing: (myGroupSharing: GroupSharing | null | undefined, isGroupSharingStart?: boolean) => void;
}

const useGroupSharingStore = create<GroupSharingStoreState>((set, get) => ({
  myGroupSharing: null,
  setMyGroupSharing: async (myGroupSharing, isGroupSharingStart) => {
    set((state) => {
      const updatedFields: any = omitBy(myGroupSharing, isNil);
      return {
        ...state,
        myGroupSharing: myGroupSharing ? {
          ...state.myGroupSharing,
          ...updatedFields || {},
          isGroupSharingStart: !!isGroupSharingStart,
        } : null,
      }
  });
  },
}));

export default useGroupSharingStore;
