import { LocalNotifications, ScheduleOptions, Channel } from '@capacitor/local-notifications';
import { Capacitor } from '@capacitor/core';
import { map, some } from 'lodash-es';

const useLocalNotifications = () => {
  const checkPermissions = async () => {
    // check the permission for the local notifications
    let permission = await LocalNotifications.checkPermissions();
    // request the permission if it is not granted yet
    if (permission.display !== 'granted') {
      permission = await LocalNotifications.requestPermissions();
    }
    // return the result of whether the user has granted permission
    return permission.display === 'granted';
  };

  const sendNotification = async (options: ScheduleOptions, channel: Channel) => {
    const isAndroidPlatform = Capacitor.getPlatform() === 'android';

    let platformOptions = options;

    if (isAndroidPlatform) {
      // create a channel for Android if it is not created yet
      const { channels } = await LocalNotifications.listChannels();
      if (!some(channels, ['id', channel.id])) {
        await LocalNotifications.createChannel(channel);
      }

      // add channel id and allowWhileIdle options for Android
      platformOptions = {
        notifications: map(options.notifications, notification => ({
          ...notification,
          channelId: channel.id,
          schedule: {
            allowWhileIdle: true,
          },
        })),
      }
    }

    return LocalNotifications.schedule(platformOptions);
  };

  return {
    checkPermissions,
    sendNotification,
  }
};

export default useLocalNotifications;
