import { DeepPartial } from 'react-hook-form';

import { useMediaPlayer } from '../../contexts/MediaPlayerContext';
import StoryPlayer from './StoryPlayer';
import WebStoryPlayer from './WebStoryPlayer';
import { UserTour } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';

const DynamicStoryPlayer: React.FC<{
  userTour?: DeepPartial<UserTour>,
  getUserTour?: () => void;
}> = ({ userTour, getUserTour }) => {
  const { currentStory } = useMediaPlayer();

  // Decide which player component to render
  if (currentStory?.externalStoryUrl) {
    return <WebStoryPlayer />;
  } else {
    return <StoryPlayer userTour={userTour} getUserTour={getUserTour}/>;
  }
};

export default DynamicStoryPlayer;
