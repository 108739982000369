import { memo, RefObject, useEffect, useState } from 'react';
import { MapRef, Marker as MapMarker } from 'react-map-gl';
import type { ViewState } from 'react-map-gl';
import { LngLatBounds } from 'mapbox-gl';
import useSupercluster from 'use-supercluster';
import { Image } from 'react-datocms';
import Supercluster, { AnyProps } from 'supercluster';
import { reduce } from 'lodash-es';

import { City } from '../../interfaces/Interfaces';
import { useCity } from '../../contexts/CityContext';
import PlaceSearchImage from '../media/PlaceSearchImage';
import { getDistanceBetweenCoordinates } from '../../helpers/turf-helpers';

const ClusterMarker: React.FC<{
  cluster: any;
  supercluster?: Supercluster<AnyProps, AnyProps>;
  mapRef: RefObject<MapRef> | null;
}> = ({ cluster, supercluster, mapRef }) => {
  const [longitude, latitude] = cluster.geometry.coordinates;

  const [storiesCount, setStoriesCount] = useState<number>(0);

  useEffect(() => {
    if (!supercluster) return;

    const leaves = supercluster.getLeaves(cluster.id, Infinity);
    const storiesCount = reduce(leaves, (acc, leaf) => acc + leaf.properties.count, 0);
    setStoriesCount(storiesCount);
  }, [cluster, supercluster]);

  return (
    <MapMarker
      latitude={latitude}
      longitude={longitude}
      style={{ zIndex: 1 }}
    >
      <div
        className="rounded-full bg-[#6CD7BD] text-center text-[#232437] font-semibold min-w-[30px] h-[30px] leading-[30px] px-1"
        onClick={() => {
          if (!supercluster || !cluster.id || typeof cluster.id !== 'number') return;

          const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 13);

          mapRef?.current?.flyTo({
            center: [longitude, latitude],
            zoom: expansionZoom,
            essential: true
          });
        }}
      >
        {storiesCount}
      </div>
    </MapMarker>
  );
}

const CityWithoutImageMarker: React.FC<{ cluster: any; }> = ({ cluster }) => {
  const { setCurrentCityToStateAndLocalStorage } = useCity();

  const [longitude, latitude] = cluster.geometry.coordinates;

  return (
    <MapMarker
      latitude={latitude}
      longitude={longitude}
      style={{ zIndex: 2 }}
    >
      <div
        className="rounded-full bg-[#6CD7BD] text-center text-[#232437] font-semibold min-w-[30px] h-[30px] leading-[30px] px-1"
        onClick={() => setCurrentCityToStateAndLocalStorage(cluster.properties.city)}
      >
        {cluster.properties.count}
      </div>
    </MapMarker>
  );
}

const CityWithImageMarker: React.FC<{ cluster: any; zoom: number; }> = ({ cluster, zoom }) => {
  const { setCurrentCityToStateAndLocalStorage } = useCity();

  const [longitude, latitude] = cluster.geometry.coordinates;

  return (
    <MapMarker
      latitude={latitude}
      longitude={longitude}
      style={{ zIndex: 2 }}
    >
      <div
        className="relative rounded-full bg-[#e38873]"
        style={{
          width: `${zoom > 9 ? 80 : 35}px`,
          height: `${zoom > 9 ? 80 : 35}px`
        }}
        onClick={() => setCurrentCityToStateAndLocalStorage(cluster.properties.city)}
      >
        {!!cluster.properties?.city?.preview?.[0]?.image?.responsiveImage ?
          <Image
            data={cluster.properties?.city?.preview?.[0]?.image?.responsiveImage}
            className="rounded-full"
            pictureClassName="rounded-full w-full h-full"
          /> :
          <PlaceSearchImage
            searchText={cluster.properties.city.name}
            location={cluster.properties.city.location}
            maxWidth={100}
            className="absolute rounded-full"
          />
        }
        <div
          className="absolute left-[50%] top-0 transform -translate-y-[30%] text-center font-bold rounded-full bg-[#E38873] px-1"
          style={{
            minWidth: `${zoom > 9 ? 30 : 13}px`,
            height: `${zoom > 9 ? 30 : 13}px`,
            lineHeight: `${zoom > 9 ? 30 : 13}px`,
            fontSize: `${zoom > 9 ? 9 : 4}px`,
          }}
        >{cluster.properties.count}</div>
      </div>
      <div
        className="absolute left-1/2 bottom-[-25px] -translate-x-1/2 text-[0.5rem] rounded-md bg-[#e38873] text-white whitespace-nowrap px-2"
      >{cluster.properties.city.name}</div>
    </MapMarker>
  );
}

const CityMarkers: React.FC<{
  cities: City[];
  mapRef: RefObject<MapRef> | null;
  mapBounds: LngLatBounds;
  zoom: number;
  viewState: Pick<ViewState, 'latitude' | 'longitude' | 'zoom'>;
  radius: number;
}> = ({ cities = [], mapRef, mapBounds, zoom, viewState, radius }) => {
  const [points, setPoints] = useState<any[]>([]);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds: [mapBounds.getWest(), mapBounds.getSouth(), mapBounds.getEast(), mapBounds.getNorth()],
    zoom,
    options: { maxZoom: 20, radius: 70 }
  });

  useEffect(() => {
    if (!cities?.length) return;

    const points = cities
      // only display cities that have at least 1 story
      .filter((city) => {
        if ((city._allReferencingStoriesMeta?.count || 0) < 1) return false;

        // filter markers that are inside the search place
        const distance = getDistanceBetweenCoordinates({
          latitude: viewState.latitude,
          longitude: viewState.longitude
        }, {
          latitude: city?.location?.latitude,
          longitude: city?.location?.longitude
        });
        return (distance || 0) > radius;
      })
      .map((cityData) => {
        const { _allReferencingStoriesMeta, ...city } = cityData;
        return {
          type: 'Feature',
          properties: {
            cluster: false,
            city: city,
            count: _allReferencingStoriesMeta?.count || 0,
          },
          geometry: {
            type: 'Point',
            coordinates: [city?.location?.longitude, city?.location?.latitude],
          },
        };
      });

    setPoints(points);
  }, [cities, viewState, radius]);

  return (
    <>
      {clusters?.map((cluster) => {

        // Check if it's a cluster or a single point
        if (cluster.properties.cluster) {
          return (<ClusterMarker
            key={`cluster-${cluster.id}`}
            cluster={cluster}
            supercluster={supercluster}
            mapRef={mapRef}
          />)
        }

        if (!cluster.properties.city.isImageVisibleOnMap) {
          return (<CityWithoutImageMarker
            key={`city-${cluster.properties.city.id}`}
            cluster={cluster}
          />)
        }

        return (<CityWithImageMarker
          key={`city-${cluster.properties.city.id}`}
          cluster={cluster}
          zoom={zoom}
        />);
      })}
    </>
  );
};

export default memo(CityMarkers);
