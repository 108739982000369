const isAppVersionSupported = (currentVersion: string, minimumSupportedVersion: string): boolean => {
  const minimumVersionParts = minimumSupportedVersion.split('.').map(Number);
  const currentVersionParts = currentVersion.split('.').map(Number);

  for (let i = 0; i < 3; i++) {
    switch (true) {
      case currentVersionParts[i] < minimumVersionParts[i]:
        return false;
      case currentVersionParts[i] > minimumVersionParts[i]:
        return true;
    }
  }

  return true;
};

export { isAppVersionSupported };
