import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { IonBackdrop, IonButton, IonItem, IonList, useIonRouter } from '@ionic/react';
import { Icon, IconifyIcon } from '@iconify/react';
import { map, filter, includes, find } from 'lodash-es';
import clsx from 'clsx';

import { useLocale } from '../contexts/LocaleContext';
import { INITIAL_ITEM_BY_LANGUAGE_OPTIONS } from '../constants';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';
import { ItemByLanguageOption } from '../interfaces/ItemByLanguageOption';

const LanguageSelector: React.FC = () => {
  const router = useIonRouter();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { locale } = useLocale();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [availableOptions, setAvailableOptions] = useState<ItemByLanguageOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<ItemByLanguageOption>();

  useEffect(() => {
    // set selected option
    const selectedOption = find(
      INITIAL_ITEM_BY_LANGUAGE_OPTIONS,
      (option) => option.languageCode === locale
    );
    selectedOption && setSelectedOption(selectedOption);

    // set sorted available options so that the current locale is the first option
    const availableOptions = filter(
      INITIAL_ITEM_BY_LANGUAGE_OPTIONS,
      (option) => includes(['en', 'de', 'es', 'fr'], option.languageCode)
    );

    const sortedAvailableOptions = availableOptions.sort((a, b) => {
      // Check if either languageCode is the primaryLanguage
      if (a.languageCode === locale) return -1;
      if (b.languageCode === locale) return 1;

      // If neither languageCode is the current locale, sort alphabetically by languageCode
      return a.languageCode.localeCompare(b.languageCode);
    });

    setAvailableOptions(sortedAvailableOptions);
  }, [locale]);

  const changeLocale = (selectedLocale: string) => {
    setIsOptionsVisible(false);

    if (selectedLocale === locale) return;

    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.CHANGE_LANGUAGE, {
        from: locale,
        to: selectedLocale,
      });
    }

    i18n.changeLanguage(selectedLocale);

    const currentPathWithoutLocalePrefix = location.pathname.substring(4);
    const searchParams = location.search;
    router.push(`/${selectedLocale}/${currentPathWithoutLocalePrefix}${searchParams}`, "none");
  };

  return (
    <>
      <IonBackdrop
        className={clsx("h-[100vh] w-[100vw]", isOptionsVisible ? "" : "hidden")}
        onIonBackdropTap={() => setIsOptionsVisible(false)}
      />

      <div className="relative">
        <IonButton
          className="w-full text-[1rem] m-0 mb-2 font-normal normal-case tracking-normal"
          style={{
            '--box-shadow': 'none',
            '--padding-start': '16px',
            '--padding-top': 0,
            '--padding-bottom': 0,
          }}
          color="light"
          onClick={() => setIsOptionsVisible(true)}
        >
          <div className="w-full h-[48px] text-[#414254] flex items-center">
            <Icon icon={selectedOption?.flag as IconifyIcon} className="w-6 h-6 mr-5" />
            {selectedOption?.languageName}
          </div>
        </IonButton>

        {isOptionsVisible && <IonList className="absolute -top-1 left-0 z-50 w-full p-1 bg-white rounded-[4px]">
          {map(availableOptions, (itemByLanguage) => <IonItem
            key={itemByLanguage.languageCode}
            lines="none"
            className="text-[1rem] text-[#18181B] rounded-[4px]"
            style={{
              '--background': itemByLanguage.languageCode === locale ? '#f4f4f5' : '#ffffff',
              '--padding-start': '12px',
            }}
            onClick={() => changeLocale(itemByLanguage.languageCode)}
          >
            <Icon icon={itemByLanguage.flag} className="w-6 h-6 mr-5" />
            {itemByLanguage.languageName}
          </IonItem>)}
        </IonList>}
      </div>
    </>
  );
};

export default LanguageSelector;
