import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { IonButton, useIonRouter } from '@ionic/react';
import clsx from 'clsx';
import { Icon } from '@iconify/react';
import { filter } from 'lodash-es';

import { ItemByLanguageOption } from '../interfaces/ItemByLanguageOption';
import { useLocale } from '../contexts/LocaleContext';
import { INITIAL_ITEM_BY_LANGUAGE_OPTIONS } from '../constants';
import { SiteLocale } from '../graphql/dato/__generated__/dato-graphql.generated';
import './ToursLanguageSwitcher.scss';

const ToursLanguageSwitcher: React.FC<{
  toursNumberForLocales: { [key: string]: number }
  isVisible: boolean;
  wrapperClassName?: string;
  wrapperStyle?: { [key: string]: string | number }
}> = ({ toursNumberForLocales, isVisible, wrapperClassName, wrapperStyle }) => {
  const router = useIonRouter();
  const location = useLocation();
  const { locale, tourQueryLocale } = useLocale();

  const [availableOptions, setAvailableOptions] = useState<ItemByLanguageOption[]>([]);

  useEffect(() => {
    // display only locales that has tours
    const availableOptions = filter(
      INITIAL_ITEM_BY_LANGUAGE_OPTIONS,
      (option) => !!toursNumberForLocales[option.languageCode]
    );
    setAvailableOptions(availableOptions);
  }, [toursNumberForLocales]);

  useEffect(() => {
      // Redirect the user to a locale that has tours if there are no tours for the current locale
      if (isVisible && availableOptions?.length && !toursNumberForLocales[tourQueryLocale]) {
        navigateWithSearchParams(availableOptions[0].languageCode);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableOptions, toursNumberForLocales, isVisible]
  );

  const navigateWithSearchParams = (optionLanguageCode: string) => {
    const searchParams = new URLSearchParams(location.search);
    optionLanguageCode === locale ?
      searchParams.delete('tourQueryLocale') :
      searchParams.set('tourQueryLocale', optionLanguageCode);

    const newSearchParams = searchParams.toString();
    const newPath = `${location.pathname}${newSearchParams ? `?${newSearchParams}` : ''}`;
    router.push(newPath, 'none');
  }

  return (
    <div
      className={clsx("w-full max-w-xl flex overflow-x-auto px-3 scrollbar-hide", wrapperClassName)}
      style={wrapperStyle}
    >
      {availableOptions?.map((option) => (
        <IonButton
          key={option.languageCode}
          shape="round"
          fill="outline"
          className="normal-case"
          style={{
            '--padding-start': '12px',
            '--padding-end': '12px',
            '--background': '#ffffff',
            '--border-width': tourQueryLocale === option.languageCode as unknown as SiteLocale ? '2px' : '1px',
            '--border-color': tourQueryLocale === option.languageCode as unknown as SiteLocale ? 'var(--ion-color-primary)' : '#C4C4C4',
          }}
          onClick={() => navigateWithSearchParams(option.languageCode)}
        >
          <div className={clsx(
            "flex items-center font-medium whitespace-nowrap",
            tourQueryLocale === option.languageCode as unknown as SiteLocale ? 'text-primary' : 'text-[#535E69]',
          )}>
            <Icon icon={option.flag} className="mr-2 w-5 h-5" />
            {option.languageName} ({toursNumberForLocales[option.languageCode]})
          </div>
        </IonButton>
      ))}
    </div>
  )
}

export default ToursLanguageSwitcher;
