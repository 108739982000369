import { IonIcon, useIonModal } from '@ionic/react';
import { personRemoveOutline } from 'ionicons/icons';
import { OverlayEventDetail } from '@ionic/core/components';
import { useRef } from 'react';

import useGroupSharingStore from '../../stores/useGroupSharingStore';
import { useDetachMeFromGroupSharingMutation } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useToast from '../../hooks/useToast';
import ConfirmationModal from '../modals/ConfirmationModal';
import useError from '../../hooks/useError';
import { isIosVersion } from '../../helpers/device-helpers';
import useOnboardingHint from '../../hooks/useOnboardingHint';
import StoryPlayerCompleteGroupSharingButtonHint from '../onboardingHints/StoryPlayerCompleteGroupSharingButtonHint';
import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';
import { useMediaPlayer } from '../../contexts/MediaPlayerContext';

const DetachFromGroupSharingButton: React.FC<{ displayHint?: boolean }> = ({ displayHint }) => {
  const { presentToast } = useToast();
  const { handleBackendError } = useError();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { currentTour, currentTourStop, currentStory } = useMediaPlayer();

  const hintTargetRef = useRef<HTMLIonIconElement>(null);

  const [present, dismiss] = useIonModal(ConfirmationModal, {
    onDismiss: (data?: boolean) => dismiss(data),
    title: 'detachFromGroupSharing.confirmationModal.title',
    text: 'detachFromGroupSharing.confirmationModal.text',
    confirmButtonText: 'detachFromGroupSharing.confirmationModal.buttons.confirm',
    cancelButtonText: 'detachFromGroupSharing.confirmationModal.buttons.cancel'
  });

  const [detachMeFromGroupSharingMutation] = useDetachMeFromGroupSharingMutation();

  const setMyGroupSharing = useGroupSharingStore((state) => state.setMyGroupSharing);
  const myGroupSharing = useGroupSharingStore((state) => state.myGroupSharing);

  useOnboardingHint({
    component: StoryPlayerCompleteGroupSharingButtonHint,
    hintTargetRef,
    hintName: 'storyPlayerCompleteGroupSharingButton',
    isVisible: !!myGroupSharing && !!displayHint,
  });

  const detachMeFromGroupSharing = () => {
    present({
      // set animated false for ios 17,because animation breaks the popup
      // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
      animated: !isIosVersion(17),
      onWillDismiss: async (e: CustomEvent<OverlayEventDetail>) => {
        if (e.detail.data) {
          if (mixpanelEnabled) {
            mixpanel.track(MixpanelEvents.LEAVE_GROUP_SHARING, {
              tourId: currentTour?.id,
              tourTitle: currentTour?.title,
              tourStopId: currentTourStop?.id,
              tourStopTitle: currentTourStop?.title,
              storyId: currentStory?.id,
              storyTitle: currentStory?.title,
            });
          }

          await handleBackendError(async () => {
            const { data, errors } = await detachMeFromGroupSharingMutation();

            if (errors) return errors;

            if (data?.groupSharing?.detachMeFromGroupSharing) {
              setMyGroupSharing(null);
              presentToast('detachFromGroupSharing.success');
            }
          })
        }
      }
    });
  };

  return (
    <>
      {myGroupSharing && <IonIcon
        ref={hintTargetRef}
        icon={personRemoveOutline}
        className="shrink-0 cursor-pointer text-[24px] leading-[24px] text-white"
        onClick={(e) => {
          // Prevent triggering the overlay player
          e.stopPropagation();

          detachMeFromGroupSharing();
        }}
      />}
    </>
  );
};

export default DetachFromGroupSharingButton;
