import { useEffect, useState } from 'react';
import {
  IonButtons,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonPage,
  IonIcon,
  UseIonRouterResult,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { generatePath } from 'react-router';
import queryString from 'query-string';

import { useLocale } from '../../contexts/LocaleContext';
import { GroupSharing } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import GroupSharingUsersList from './GroupSharingUsersList';
import useAuthStore from '../../stores/useAuthStore';
import StartTourButton from '../../pages/TourPage/StartTourButton';
import { Tour } from '../../interfaces/Interfaces';

const DEEP_LINK_BASE_URL = process.env.REACT_APP_DEEP_LINK_BASE_URL;

const GroupSharingQrCodeModal: React.FC<{
  groupSharing: GroupSharing;
  tour?: Tour;
  router?: UseIonRouterResult;
  onDismiss: () => void;
}> = ({ onDismiss, groupSharing, tour, router }) => {
  const { t } = useTranslation();
  const { locale } = useLocale();

  const me = useAuthStore(state => state.me);

  const [joinGroupSharingUrl, setJoinGroupSharingUrl] = useState<string>('');

  useEffect(() => {
    let url;

    if (groupSharing && me) {
      const inviter = (me?.profile?.firstName && me?.profile?.lastName) ?
        `${me?.profile?.firstName} ${me?.profile?.lastName}` : me?.email;

      url = generatePath(`${DEEP_LINK_BASE_URL}/:locale(de|en|fr|es)/group-sharing/join?:queryParams`, {
        locale,
        queryParams: queryString.stringify({
          groupSharingId: groupSharing.id,
          inviter,
        }),
      });
    }

    setJoinGroupSharingUrl(url ? url : '');
  }, [groupSharing, me, locale]);

  useEffect(() => {
    if (!groupSharing) {
      onDismiss();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupSharing]
  );

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {!!joinGroupSharingUrl && <div className="min-h-full text-center flex flex-col justify-between">
          <div className="px-5 pb-10">
            <h2 className="text-[1.25rem] text-[#434343] font-bold px-5 pb-5">{t('groupSharingQrCodeModal.title')}</h2>
            <div className="bg-white p-2 w-full max-w-[170px] h-auto mx-auto">
              <QRCode
                className="w-full h-full"
                bgColor="#ffffff"
                fgColor="#000000"
                value={joinGroupSharingUrl}
              />
            </div>
            <div className="text-[#979797] px-5 max-w-md mx-auto">
              <p className="py-5">{t('groupSharingQrCodeModal.shareQrCode')}</p>
              <p>{t('groupSharingQrCodeModal.mustHaveAccount')}</p>
            </div>
          </div>

          {!!tour && <div className="px-5 pb-10 w-full max-w-md mx-auto">
            <StartTourButton tour={tour} router={router} onButtonClick={() => onDismiss()}/>
          </div>}

          <GroupSharingUsersList users={groupSharing?.users} />
        </div>}
      </IonContent>
    </IonPage>
  );
};

export default GroupSharingQrCodeModal;
