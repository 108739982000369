import { find, get, map, reduce, values } from 'lodash-es';
import { useEffect, useState } from 'react';
import {
  IonBackdrop,
  IonButton,
  IonItem,
  IonList,
  useIonRouter
} from '@ionic/react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Icon, IconifyIcon } from '@iconify/react';

import { Tour } from '../../interfaces/Interfaces';
import { useLocale } from '../../contexts/LocaleContext';
import { useTourTranslationByTourIdQuery } from '../../graphql/dato/__generated__/dato-graphql.generated';
import useRoutes from '../../hooks/useRoutes';
import { ItemByLanguageOption } from '../../interfaces/ItemByLanguageOption';
import { INITIAL_ITEM_BY_LANGUAGE_OPTIONS } from '../../constants';

interface TourByLanguageOption extends ItemByLanguageOption {
  itemUrl?: string;
}

const TourTranslationSelector: React.FC<{ tour: Tour; isVisible: boolean; }> = ({ tour, isVisible }) => {
  const { t } = useTranslation();
  const { locale, locales } = useLocale();
  const { currentPath } = useRoutes();
  const router = useIonRouter();

  const [tourTranslationByTourId] = useTourTranslationByTourIdQuery({
    variables: {
      tourId: tour?.id
    },
    pause: !isVisible,
  });

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [itemByLanguages, setItemByLanguages] = useState<TourByLanguageOption[]>([]);

  // set options for available tour translations
  useEffect(() => {
    const allTourLocales = reduce(values(locales), (acc, l) => {
      const result = (tourTranslationByTourId?.data as any)?.[l]?._allTourLocales;
      return result || acc;
    }, null);

    if (allTourLocales) {
      const updatedItemByLanguages = reduce(INITIAL_ITEM_BY_LANGUAGE_OPTIONS, (
        acc: TourByLanguageOption[],
        itemByLanguage: TourByLanguageOption
      ) => {
        const itemSlug = get(find(allTourLocales, ['locale', itemByLanguage.languageCode]), 'value.slug');
        return itemSlug ?
          [...acc, { ...itemByLanguage, itemUrl: `/${locale}/tours/${itemSlug}` }] :
          acc;
      }, []);

      setItemByLanguages(updatedItemByLanguages);
    }
  }, [tourTranslationByTourId, locale, locales]);

  if (itemByLanguages?.length <= 1) {
    return null;
  }

  return (
    <>
      <IonBackdrop
        className={clsx(
          "h-[100vh] w-[100vw]",
          isOptionsVisible ? "" : "hidden"
        )}
        onIonBackdropTap={() => {
          setIsOptionsVisible(false);
        }}
      />

      <div className="relative mx-4">
        <IonButton
          className="w-full text-[0.75rem] text-[#535E69] normal-case tracking-normal"
          shape="round"
          style={{
            '--background': '#f4f4f5',
            '--box-shadow': 'none'
          }}
          onClick={() => setIsOptionsVisible(true)}
        >
          <div className="w-full min-h-[40px] flex justify-between items-center">
            {t('tourPage.languageSelector.placeholder', {amount: itemByLanguages?.length})}
            <Icon
              icon={itemByLanguages?.find((itemByLanguage) =>
                currentPath() === itemByLanguage?.itemUrl)?.flag as IconifyIcon}
              style={{ width: "22px", height: "22px" }}
            />
          </div>
        </IonButton>

        {isOptionsVisible && <IonList className="absolute top-0 left-0 z-50 w-full bg-white rounded-[8px]">
          {map(itemByLanguages, (itemByLanguage) => <IonItem
            key={itemByLanguage.languageCode}
            lines="none"
            className="text-[0.75rem] text-[#18181B] mx-3"
            style={{
              '--background': currentPath() === itemByLanguage?.itemUrl ? '#f4f4f5' : '#ffffff',
              '--border-radius': '10px'
            }}
            onClick={() => {
              router.push(itemByLanguage?.itemUrl as string, 'none', 'replace');
              setIsOptionsVisible(false);
            }}>
              {itemByLanguage.languageName}
              <div slot="end"><Icon icon={itemByLanguage.flag} style={{ width: "22px", height: "22px" }}/></div>
            </IonItem>)}
        </IonList>}
      </div>
    </>
  )
};

export default TourTranslationSelector;
