import { useEffect } from 'react';
import { App as CapacitorApp } from "@capacitor/app";
import { Device } from '@capacitor/device';
import { isPlatform, useIonModal } from '@ionic/react';

import {
  SupportedAppVersion,
  useGetSupportedAppVersionLazyQuery,
  useSupportedAppVersionChangedSubscription,
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useAppState from './useAppState';
import { isAppVersionSupported } from '../helpers/application-settings-helpers';
import UpdateAppModal from '../components/modals/UpdateAppModal';

const useSupportedAppVersion = () => {
  const { isAppActive } = useAppState();

  const [getSupportedAppVersionQuery] = useGetSupportedAppVersionLazyQuery();

  const { data: supportedAppVersionChanged } = useSupportedAppVersionChangedSubscription({
    skip: !isAppActive || isPlatform('mobileweb') || isPlatform('desktop'),
  });

  const [present, dismiss] = useIonModal(UpdateAppModal, {
    onDismiss: () => dismiss(),
  });

  const checkAppVersion = async (isSubscription?: boolean, supportedAppVersion?: SupportedAppVersion) => {
    const deviceInfo = await Device.getInfo();
    const devicePlatform = deviceInfo.platform;
    const isRunningInBrowser = isPlatform('mobileweb') || devicePlatform === 'web';

    if (!isRunningInBrowser) {
      let minimumSupportedVersion = supportedAppVersion?.[devicePlatform];

      if (!isSubscription) {
        const { data } = await getSupportedAppVersionQuery({
          fetchPolicy: 'no-cache',
          context: {
            headers: {
              'skip-app-version-checking': true
            }
          }
        });
        minimumSupportedVersion = data?.applicationSettings?.getSupportedAppVersion?.[devicePlatform];
      }

      const appInfo = await CapacitorApp.getInfo();
      const currentAppVersion = appInfo?.version;

      if (currentAppVersion && minimumSupportedVersion) {
        const isSupported = isAppVersionSupported(currentAppVersion, minimumSupportedVersion);

        if (!isSupported) {
          present({ backdropDismiss: false });
        }
      }
    }
  };

  useEffect(() => {
    if (isAppActive) {
      dismiss();
      checkAppVersion();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAppActive]
  );

  useEffect(() => {
    if (supportedAppVersionChanged?.supportedAppVersionChanged) {
      checkAppVersion(true, supportedAppVersionChanged?.supportedAppVersionChanged);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [supportedAppVersionChanged]
  );
};

export default useSupportedAppVersion;
