import { memo, useEffect, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonLabel,
  IonPage,
  IonProgressBar,
} from '@ionic/react';
import { useForm, Controller } from 'react-hook-form';
import { filter, find } from 'lodash-es';
import { chevronBackOutline, planet } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import { Category, City, ExperienceLevel, SightseeingSpot } from '../../interfaces/Interfaces';
import useError from '../../hooks/useError';
import {
  CreateStoryByGptInput,
  useCreateStoryByGptMutation
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';

const CreateStoryForSightseeingSpot: React.FC<{
  sightSeeingSpot?: SightseeingSpot;
  currentCity?: City;
  categories: Category[];
  experienceLevels: ExperienceLevel[];
  setIsCreationPage: (isCreationPage: boolean) => void;
}> = ({ sightSeeingSpot, currentCity, categories, experienceLevels, setIsCreationPage }) => {
  const { handleBackendError } = useError();
  const { t } = useTranslation();

  const { reset, control, handleSubmit } = useForm<CreateStoryByGptInput>();

  const [createStoryByGptMutation] = useCreateStoryByGptMutation();

  const [isStoryCreationRequestSuccessful, setIsStoryCreationRequestSuccessful] = useState<boolean>();
  const [isRequestPerformed, setIsRequestPerformed] = useState<boolean>();

  useEffect(() => {
    reset({
      datoCityId: currentCity?.id,
      datoSightseeingSpotId: sightSeeingSpot?.id,
      datoExperienceLevelId: null,
      datoCategoryIds: [],
    })
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCity, sightSeeingSpot]
  );

  const onSubmit = async (input: CreateStoryByGptInput) => {
    setIsRequestPerformed(true);

    await handleBackendError(async () => {
      try {
        const { data, errors } = await createStoryByGptMutation({ variables: { input }});

        if (errors) {
          setIsCreationPage(false);
          return errors;
        }

        setIsStoryCreationRequestSuccessful(data?.story?.createStoryByGpt);
      } catch (e) {
        setIsCreationPage(false);
        return e;
      }
    });
  };

  return (
    <IonPage className="min-h-[100vh]">
      <IonContent color="dark" className="ion-padding">
        <div className="p-5 min-h-full">
          <div className="flex items-center">
            <IonButton
              fill="clear"
              color="medium"
              style={{
                '--padding-start': 0
              }}
              onClick={() => setIsCreationPage(false)}
            >
              <IonIcon slot="icon-only" icon={chevronBackOutline} />
            </IonButton>
            <h3 className="text-[1.125rem] text-white text-center font-bold py-5 pr-10 w-full">
              {t(isRequestPerformed ?
                'createStoryForSightseeingSpot.creationProcess.title' :
                'createStoryForSightseeingSpot.createStoryForm.title')}
            </h3>
          </div>

          {!isRequestPerformed ?
            <form onSubmit={handleSubmit(onSubmit)}>
              <IonLabel className="block text-[0.875rem] text-white font-bold my-3">
                {t('createStoryForSightseeingSpot.createStoryForm.levelOfKnowledge.label')}
              </IonLabel>
              <Controller
                control={control}
                name="datoExperienceLevelId"
                render={({ field: { value, onChange } }) => (
                  <div className="grid sm:grid-cols-3 grid-cols-2 gap-2">
                    {experienceLevels?.map((experienceLevel) =>
                      <IonButton
                        key={experienceLevel.id}
                        color={value === experienceLevel.id ? "primary" : "medium"}
                        className="text-[0.75rem] text-[#414254] font-bold normal-case whitespace-normal"
                        onClick={() => onChange(experienceLevel.id)}
                      >{experienceLevel?.title}</IonButton>
                    )}
                  </div>
                )}
              />

              <IonLabel className="block text-[0.875rem] text-white font-bold mb-3 mt-6">
                {t('createStoryForSightseeingSpot.createStoryForm.categories.label')}
              </IonLabel>
              <Controller
                control={control}
                name="datoCategoryIds"
                render={({ field: { value, onChange } }) => (
                  <div className="grid sm:grid-cols-3 grid-cols-2 gap-2">
                    {categories?.map((category) =>
                      <IonButton
                        key={category.id}
                        color={find(value, (id) => id === category.id) ? "primary" : "medium"}
                        className="text-[0.75rem] text-[#414254] font-bold normal-case whitespace-normal"
                        onClick={() => onChange(
                          find(value, (id) => id === category.id) ?
                            filter(value, (id) => id !== category.id) :
                            [...(value || []), category.id]
                        )}
                      >{category?.title}</IonButton>
                    )}
                  </div>
                )}
              />

              <IonButton type="submit" className="block capitalize font-bold text-[1rem] max-w-sm mx-auto mt-6">
                {t('createStoryForSightseeingSpot.createStoryForm.buttons.create')}
              </IonButton>
            </form>
            :
            <>
              {!isStoryCreationRequestSuccessful ?
                <div className="text-white text-center">
                  <IonProgressBar type="indeterminate" color="primary" />
                </div>
                :
                <div className="text-white text-center">
                  <p className="pb-5 pt-10 font-bold">
                    {t('createStoryForSightseeingSpot.creationProcess.inProgress.messageOne')}
                  </p>
                  <IonIcon icon={planet} className="text-[10rem] mb-5" />
                  <p className="pb-5">
                    {t('createStoryForSightseeingSpot.creationProcess.inProgress.messageTwo')}
                  </p>
                  <p className="pb-5">
                    {t('createStoryForSightseeingSpot.creationProcess.inProgress.messageThree')}
                  </p>
                </div>
              }
            </>
          }
          <div style={{ paddingBottom: "calc(var(--ion-safe-area-bottom) + 30px)" }} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default memo(CreateStoryForSightseeingSpot);
