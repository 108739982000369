import { ApolloLink } from '@apollo/client';
import i18next from 'i18next';
import { upperCase } from 'lodash-es';

const languageMiddleware = new ApolloLink((operation, forward) => {
  const queryLocale = i18next.language;

  // add the recent-activity custom header to the headers
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      'content-language': `${upperCase(queryLocale)}`
    }
  }));

  return forward(operation);
});

export default languageMiddleware;
