import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonButton } from '@ionic/react';

import TourCardSlider from '../../components/sliders/TourCardSlider';
import SectionHeader from './CitySections/SectionHeader';
import { usePopularToursQuery } from '../../graphql/dato/__generated__/dato-graphql.generated';
import { Tour } from '../../interfaces/Interfaces';
import { useLocale } from '../../contexts/LocaleContext';
import useRoutes from '../../hooks/useRoutes';
import useUserToursAudioStatistics from '../../hooks/useUserToursAudioStatistics';
import { map } from 'lodash-es';
import useToursRatingStatistics from '../../hooks/useToursRatingStatistics';

const PopularToursSection: React.FC<{
  cityId?: string;
  isAuthenticated: boolean | null;
  isVisible: boolean;
}> = ({ cityId, isAuthenticated, isVisible }) => {
  const { t } = useTranslation();
  const { queryLocale } = useLocale();
  const { localePath } = useRoutes();

  const [tours, setTours] = useState<Tour[]>([]);

  const { userToursAudioStatistics } = useUserToursAudioStatistics(map(tours, ({ id }) => id), isAuthenticated, isVisible);
  const { toursRatingStatistics } = useToursRatingStatistics(map(tours, ({ id }) => id), isVisible);

  const [result] = usePopularToursQuery({
    variables: {
      locale: queryLocale,
      filter: {
        title: { isBlank: false },
        isDiscoverable: { eq: true },
        ...cityId ? { cities: { anyIn: [cityId] }} : {},
        isPopular: { eq: true }
      }
    },
    pause: !isVisible,
  });
  const { data } = result;

  useEffect(() => {
    if (data) setTours(data.tours as Tour[]);
  }, [data]);

  if (!tours?.length) return null;

  return (
    <div>
      <div className="w-full flex justify-between items-center pl-4 pr-0 mt-4 mb-0.5">
        <SectionHeader title={t('home.popularTours.title')} />
        {!!cityId && <IonButton
          fill="clear"
          className="normal-case text-primary tracking-normal"
          routerLink={localePath(`tours`)}
          routerDirection="forward"
        ><span className="font-semibold">{t('home.popularTours.buttons.showMore')}</span></IonButton>}
      </div>
      <div className="mx-3">
        <TourCardSlider
          tours={tours}
          toursRatingStatistics={toursRatingStatistics}
          userToursAudioStatistics={userToursAudioStatistics}
        />
      </div>
    </div>
  );
};

export default PopularToursSection;
