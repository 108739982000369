import { IonCard } from '@ionic/react';
import { Image, ResponsiveImageType } from 'react-datocms';

import { City } from '../../../../interfaces/Interfaces';
import { useCity } from '../../../../contexts/CityContext';
import { LatLonField } from '../../../../graphql/dato/__generated__/dato-graphql.generated';
import PlaceSearchImage from '../../../../components/media/PlaceSearchImage';

const CityCard: React.FC<{ city: City; }> = ({ city }) => {
  const { setCurrentCityToStateAndLocalStorage } = useCity();

  const responsiveImage = city?.preview?.[0]?.image?.responsiveImage as ResponsiveImageType;

  return (
    <IonCard
      className="my-1 p-0 cursor-pointer bg-transparent shadow-none"
      onClick={() => setCurrentCityToStateAndLocalStorage(city)}
    >
      <div
        className="relative w-full h-full min-h-[200px] rounded-[6px] overflow-hidden bg-white shadow-[0px_4px_10px_-4px_rgba(14,0,54,0.28)]"
      >
        {!!responsiveImage ?
          <Image
            data={responsiveImage}
            layout="fill"
            objectFit="cover"
          />
          :
          <PlaceSearchImage
            searchText={city?.name as string}
            location={city?.location as LatLonField}
            maxWidth={400}
            className="absolute"
          />
        }
      </div>

      <div className="text-[0.875rem] text-white font-semibold pl-1 pt-2">{city?.name}</div>
    </IonCard>
  );
};

export default CityCard;
