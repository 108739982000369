import { memo } from 'react';

const StoryMarkerIcon: React.FC<{
  isPremium: boolean;
  color: string;
}> = memo(({ isPremium = false, color }) => {
  if (isPremium) {
    return (
      <svg width="43" height="48" viewBox="0 0 43 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_4980_25923)">
          <path d="M36.3571 20.7273C36.3571 27.1571 32.6203 32.1001 28.8117 35.4663C26.9122 37.1452 25.0105 38.4172 23.583 39.2696C22.8697 39.6954 22.2763 40.0157 21.8625 40.2288C21.6933 40.316 21.5543 40.3852 21.4494 40.4363C21.3429 40.3745 21.1998 40.2901 21.0246 40.1841C20.6091 39.9325 20.0138 39.5591 19.2985 39.0741C17.867 38.1034 15.9601 36.6888 14.0553 34.9127C10.2253 31.3414 6.5 26.3986 6.5 20.7273C6.5 12.8492 13.2039 5.5 21.4286 5.5C29.6533 5.5 36.3571 12.8492 36.3571 20.7273Z" fill="url(#paint0_linear_4980_25923)" stroke="white"/>
          <g clipPath="url(#clip0_4980_25923)">
            <g filter="url(#filter1_d_4980_25923)">
              <path d="M21.4308 26.391L26.7279 29.4993L25.3222 23.641L30.0022 19.6993L23.8394 19.191L21.4308 13.666L19.0222 19.191L12.8594 19.6993L17.5394 23.641L16.1337 29.4993L21.4308 26.391Z" fill="white"/>
            </g>
          </g>
        </g>
        <defs>
          <filter id="filter0_d_4980_25923" x="0" y="0" width="42.8555" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="3"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4980_25923"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4980_25923" result="shape"/>
          </filter>
          <filter id="filter1_d_4980_25923" x="10.8594" y="12.666" width="21.1445" height="19.834" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
            <feOffset dy="1"/>
            <feGaussianBlur stdDeviation="1"/>
            <feComposite in2="hardAlpha" operator="out"/>
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0"/>
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4980_25923"/>
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4980_25923" result="shape"/>
          </filter>
          <linearGradient id="paint0_linear_4980_25923" x1="0.857144" y1="-4" x2="31.0714" y2="44.8571" gradientUnits="userSpaceOnUse">
            <stop stopColor="#E9C25F"/>
            <stop offset="1" stopColor="#CA8C15"/>
          </linearGradient>
          <clipPath id="clip0_4980_25923">
            <rect width="20.5714" height="20" fill="white" transform="translate(11.1445 12)"/>
          </clipPath>
        </defs>
      </svg>
    )
  }

  return (
    <svg width="43" height="48" viewBox="0 0 43 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g filter="url(#filter0_d_6260_50179)">
          <path d="M36.3571 20.7273C36.3571 27.1571 32.6203 32.1001 28.8117 35.4663C26.9122 37.1452 25.0105 38.4172 23.583 39.2696C22.8697 39.6954 22.2763 40.0157 21.8625 40.2288C21.6933 40.316 21.5543 40.3852 21.4494 40.4363C21.3429 40.3745 21.1998 40.2901 21.0246 40.1841C20.6091 39.9325 20.0138 39.5591 19.2985 39.0741C17.867 38.1034 15.9601 36.6888 14.0553 34.9127C10.2253 31.3414 6.5 26.3986 6.5 20.7273C6.5 12.8492 13.2039 5.5 21.4286 5.5C29.6533 5.5 36.3571 12.8492 36.3571 20.7273Z" fill={color} stroke="white"/>
          <path d="M20.9163 12.8522C16.8192 13.1105 13.7163 16.6022 13.7163 20.5939V26.1689C13.7163 27.5522 14.8649 28.6689 16.2877 28.6689H17.1449C18.0877 28.6689 18.8592 27.9189 18.8592 27.0022V23.6689C18.8592 22.7522 18.0877 22.0022 17.1449 22.0022H15.4306V20.5772C15.4306 17.3772 17.9677 14.5939 21.2506 14.5022C24.6535 14.4105 27.4306 17.0522 27.4306 20.3355V22.0022H25.7163C24.7735 22.0022 24.002 22.7522 24.002 23.6689V27.0022C24.002 27.9189 24.7735 28.6689 25.7163 28.6689H26.5735C27.9963 28.6689 29.1449 27.5522 29.1449 26.1689V20.3355C29.1449 16.0272 25.4077 12.5689 20.9163 12.8522Z" fill="white"/>
        </g>
      </g>
      <defs>
        <filter id="filter0_d_6260_50179" x="0" y="0" width="42.8569" height="48" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dy="1"/>
          <feGaussianBlur stdDeviation="3"/>
          <feComposite in2="hardAlpha" operator="out"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6260_50179"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6260_50179" result="shape"/>
        </filter>
      </defs>
    </svg>
  );
});

export default memo(StoryMarkerIcon);
