import { create } from "zustand";

import { setItemToStorage } from '../helpers/storage-helpers';

interface OnboardingStoreState {
  isOnboardingOver: boolean | null;
  setIsOnboardingOver: (isOnboardingOver: boolean) => void;
}

const useOnboardingStore = create<OnboardingStoreState>((set, get) => ({
  isOnboardingOver: null,
  setIsOnboardingOver: async (isOnboardingOver) => {
    await setItemToStorage('isOnboardingOver', isOnboardingOver);

    set((state) => ({
      ...state,
      isOnboardingOver,
    }));
  },
}));

export default useOnboardingStore;
