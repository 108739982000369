import { ErrorMessage } from '@hookform/error-message';
import { head } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useState } from 'react';
import { IonDatetime, IonPopover } from '@ionic/react';
import * as moment from 'moment';

const AppDateInput: React.FC<{
  register: any;
  setValue: (...value: any) => void;
  placeholder: string;
  name: string;
  disabled?: boolean;
  validators?: any;
  frontendErrors?: any;
  backendErrors?: any;
  inputClassNames?: string;
  labelClassNames?: string;
}> = ({
  register,
  setValue,
  placeholder,
  name,
  disabled = false,
  validators,
  frontendErrors,
  backendErrors,
  inputClassNames = 'rounded-[8px]',
  labelClassNames = 'text-gray-500'
}) => {
  const { t } = useTranslation();
  const [showPicker, setShowPicker] = useState(false);

  const handleDateChange = (event: any) => {
    setValue(name, moment(event.detail.value).format('YYYY-MM-DD'), { shouldValidate: true });
  };

  return (
    <div className="mb-3 flex flex-col w-full">
      <div className="relative">
        <input
          className={clsx(
            "border w-full pb-2 pt-4 px-4 font-normal focus:outline-none peer",
            inputClassNames
          )}
          id={name}
          placeholder=" "
          type="text"
          disabled={disabled}
          onClick={() => setShowPicker(true)}
          {...register(name, validators || {})}
        />
        <label
          htmlFor={name}
          className={clsx(
            "absolute text-sm duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4",
            labelClassNames
          )}
        >{t(placeholder)}</label>
      </div>

      <ErrorMessage
        errors={frontendErrors}
        name={name}
        render={({ message }) => (
          <p className="text-[0.7rem] text-red-500 text-left ml-4 mt-[2px]">
            {message}
          </p>
        )}
      />

      {backendErrors?.[name] && <p
        className="text-[0.7rem] text-red-500 text-left ml-4 mt-[2px]"
      >{head(backendErrors?.[name])}</p> }

      <IonPopover
        keepContentsMounted={true}
        isOpen={showPicker}
        onWillDismiss={() => setShowPicker(false)}
        style={{
          '--min-width': '300px'
        }}
      >
        <IonDatetime
          presentation="date"
          onIonChange={handleDateChange}
          max={new Date().toISOString()}
          min={moment().subtract(120, 'years').toISOString()}
          showDefaultButtons={true}
          showClearButton={false}
        />
      </IonPopover>
    </div>
  );
};

export default AppDateInput;
