import { useContext, useEffect, useState } from 'react';
import { NavContext } from '@ionic/react';
import { DeepPartial } from 'react-hook-form';
import { usePrevious } from 'react-use';

import DynamicStoryPlayer from '../../components/players/DynamicStoryPlayer';
import useSearchParams from '../../hooks/useSearchParams';
import { useMediaPlayer } from '../../contexts/MediaPlayerContext';
import useTour from '../../hooks/useTour';
import useStory from '../../hooks/useStory';
import {
  useCreateUserTourMutation,
  useGetUserTourLazyQuery,
  UserTour,
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useAuthStore from '../../stores/useAuthStore';
import useError from '../../hooks/useError';
import { getInputToCreateUserTour } from '../../helpers/user-tour-helpers';
import useIonVisible from '../../hooks/useIonVisible';
import { isAccessibleTour } from '../../helpers/tour-helpers';
import { useTransaction } from '../../contexts/TransactionContext';
import useGroupSharingStore from '../../stores/useGroupSharingStore';
import { isPremiumStory } from '../../helpers/story-helpers';
import useTourStop from '../../hooks/useTourStop';

const StoryPlayer: React.FC = () => {
  const {
    storyId,
    tourId,
    tourStopId,
  }: {
    storyId: string | null;
    tourId?: string | null;
    tourStopId?: string | null;
  } = useSearchParams();

  const {
    currentTour,
    currentStory,
    currentStorySlide,
    playStory,
    pause,
    unloadMediaPlayer
  } = useMediaPlayer();

  const navContext = useContext(NavContext);
  const { isVisible } = useIonVisible();
  const { handleBackendError } = useError();
  const previousIsVisible = usePrevious(isVisible);
  const { hasPremiumAccess, accessibleTourIds } = useTransaction();

  const [getUserTourQuery] = useGetUserTourLazyQuery();
  const [createUserTourMutation] = useCreateUserTourMutation();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const isUserInitialised = useAuthStore((state) => state.isUserInitialised);
  const myGroupSharing = useGroupSharingStore((state) => state.myGroupSharing);

  const [userTour, setUserTour] = useState<DeepPartial<UserTour>>();
  const [shouldUnloadMediaPlayer, setShouldUnloadMediaPlayer] = useState<boolean>(false);

  // Fetch story with or without tour data, depending on the available URL query params
  const { tour } = useTour({ tourId, pause: !tourId });
  const { tourStop: tourStopByTourStopId } = useTourStop({ tourStopId, pause: !tourStopId || !!tourId });
  const { story: storyByStoryId } = useStory({ storyId, pause: !!tourId || !!tourStopId });

  const tourStop = tourStopByTourStopId || tour?.tourStops?.find(
    (tourStop) => tourStop.id === tourStopId
  );
  const storyByStoryIdWithTourStopAndTourData = tourStop?.stories?.find(
    (story) => story.id === storyId
  );

  const story = storyByStoryIdWithTourStopAndTourData || storyByStoryId;

  const getUserTour = async () => {
    await handleBackendError(async () => {
      if (currentTour) {
        const { error, data } = await getUserTourQuery({
          fetchPolicy: 'no-cache',
          variables: {
            input: {
              datoTourId: currentTour?.id
            },
          }
        });
        if (error) return error;

        const userTour = data?.userTour?.getUserTour;
        if (userTour) {
          setUserTour(userTour);
        }
      }
    })
  };

  useEffect(() => {
    let isMounted = true;

    const createUserTour = async () => {
      if (
        currentTour &&
        currentStory &&
        currentStorySlide &&
        isAuthenticated &&
        ((!previousIsVisible && isVisible) || userTour?.datoTourId !== currentTour.id)
      ) {
        const { errors, data } = await createUserTourMutation({
          variables: {
            input: getInputToCreateUserTour(currentTour),
          }
        });
        if (errors) return errors;

        if (isMounted) setUserTour(data?.userTour?.createUserTour);
      }
    };

    createUserTour();

    return () => {
      isMounted = false;
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTour, currentStory, currentStorySlide, isAuthenticated, userTour, isVisible]
  );

  // If there's no story playing yet, wait for the story to be loaded
  // and play it
  useEffect(
    () => {
      if (!currentStory && story) {
        playStory(story);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStory, story]
  );

  // If there's a story playing, but a new story is expected to be played,
  // wait till the story data is loaded for the new story, then play it
  useEffect(
    () => {
      if (currentStory && storyId && currentStory.id !== storyId) {
        // Pause currently playing story
        pause();

        // Wait for the changed story to be loaded, then play it
        if (story?.id === storyId) {
          playStory(story);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStory, story, storyId]
  );

  useEffect(() => {
    const closeMediaPlayer = async () => {
      setShouldUnloadMediaPlayer(true);

      // navigate back
      if (navContext.hasIonicRouter()) {
        navContext.goBack('/');
      } else {
        window.location.href = '/';
      }
    };

    // close player if user doesn't have premium access or access to the tour, the tour or story is not free,
    // and the user is not in group sharing mode
    if (
      isUserInitialised &&
      ((currentTour && !isAccessibleTour(currentTour, accessibleTourIds)) || (currentStory && isPremiumStory(currentStory))) &&
      !hasPremiumAccess &&
      !myGroupSharing
    ) {
      closeMediaPlayer();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isUserInitialised, currentTour, hasPremiumAccess, accessibleTourIds, myGroupSharing]
  );

  useEffect(() => {
    // unload media player on the component destroying
    return () => {
      if (shouldUnloadMediaPlayer) {
        unloadMediaPlayer()
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldUnloadMediaPlayer],
  );

  return <DynamicStoryPlayer userTour={userTour} getUserTour={getUserTour}/>;
};

export default StoryPlayer;
