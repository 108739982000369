import { useTranslation } from 'react-i18next';
import { IonButton, IonIcon } from '@ionic/react';
import { searchSharp } from 'ionicons/icons';

import { useCity } from '../../contexts/CityContext';
import CreateTourSection from './CreateTourSection';

const NoAvailableToursSection: React.FC = () => {
  const { t } = useTranslation();

  const { currentCity, setPlaceNameData } = useCity();

  return (
    <div className="w-full p-5 text-center">
      <IonIcon icon={searchSharp} className="w-[45px] h-[45px] text-[#A3A9BC] mb-9"/>

      <div className="text-[0.875rem] text-[#232437] mb-2">
        {t('tours.noAvailableToursSection.title')}&#160;
        <span className="font-semibold">"{currentCity?.name}"</span>
      </div>

      <div className="text-[0.8125rem] text-[#687582] mb-3">
        {t('tours.noAvailableToursSection.message.textPart')}&#160;
        <a href="mailto:hello@guidable.com" className="underline">
          {t('tours.noAvailableToursSection.message.linkPart')}
        </a>
      </div>

      <IonButton
        fill="clear"
        className="ext-[0.875rem] text-[#687582] font-semibold normal-case tracking-normal"
        onClick={() => setPlaceNameData({ placeName: '', resetAllData: true })}
      >{t('tours.noAvailableToursSection.buttons.clearSearch')}</IonButton>

      {/* TODO: delete this condition when the GPT functionality is ready */}
      {process.env.REACT_APP_DISPLAY_CREATE_TOUR_BUTTON === 'true' && <CreateTourSection />}
    </div>
  );
};

export default NoAvailableToursSection;
