import { IonIcon, useIonModal } from '@ionic/react';
import { refreshOutline } from 'ionicons/icons';

import useAuthStore from '../../stores/useAuthStore';
import { useResetUserTourMutation } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useError from '../../hooks/useError';
import ConfirmationModal from '../modals/ConfirmationModal';
import { OverlayEventDetail } from '@ionic/core';
import { isIosVersion } from '../../helpers/device-helpers';

const ResetTourButton: React.FC<{
  tourId: string;
  getUserTourAudioStatistics: () => void;
}> = ({ tourId, getUserTourAudioStatistics }) => {
  const { handleBackendError } = useError();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [resetUserTourMutation] = useResetUserTourMutation();

  const [present, dismiss] = useIonModal(ConfirmationModal, {
    onDismiss: (data?: boolean) => dismiss(data),
    title: 'resetUserTour.confirmationModal.title',
    text: 'resetUserTour.confirmationModal.text',
    confirmButtonText: 'resetUserTour.confirmationModal.buttons.confirm',
    cancelButtonText: 'resetUserTour.confirmationModal.buttons.cancel'
  });

  const resetUserTour = async () => {
    present({
      // set animated false for ios 17,because animation breaks the popup
      // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
      animated: !isIosVersion(17),
      onWillDismiss: async (e: CustomEvent<OverlayEventDetail>) => {
        if (e.detail.data) {
          await handleBackendError(async () => {
            if (tourId) {
              const { errors } = await resetUserTourMutation({
                variables: {
                  input: {
                    datoTourId: tourId
                  },
                }
              });
              if (errors) return errors;

              getUserTourAudioStatistics();
            }
          })
        }
      }
    });
  };

  if (!isAuthenticated) return null;

  return (
    <>
      <div className="relative flex w-[24px] h-[24px]">
        <IonIcon
          icon={refreshOutline}
          className="shrink-0 cursor-pointer w-full h-full"
          onClick={resetUserTour}
        />
      </div>
    </>
  );
};

export default ResetTourButton;
