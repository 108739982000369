import { useEffect, useState } from 'react';
import { DeepPartial } from 'react-hook-form';

import TourStopList from '../../components/TourStopList';
import { SightseeingSpot, Tour } from '../../interfaces/Interfaces';
import {
  UserTour,
  UserTourAudioStatistics
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';

const TourTab: React.FC<{
  tour: Tour;
  userTour?: DeepPartial<UserTour> | null;
  userTourAudioStatistics?: UserTourAudioStatistics | null;
  sightseeingSpots?: SightseeingSpot[];
}> = ({ tour, userTour, userTourAudioStatistics, sightseeingSpots }) => {
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [currentUserTourAudioStatistics, setCurrentUserTourAudioStatistics] = useState<UserTourAudioStatistics | null>();

  useEffect(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_TOUR_STOP_LIST_TAB, {
        tourId: tour?.id,
        tourTitle: tour?.title,
      });
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  []
  );

  // use to perform animation of audio progress
  useEffect(() => {
    setCurrentUserTourAudioStatistics(userTourAudioStatistics);
    return () => setCurrentUserTourAudioStatistics(null);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userTourAudioStatistics]
  );

  return (
    <div className="w-full px-3 pb-16">
      <TourStopList
        tour={tour}
        userTour={userTour}
        userTourAudioStatistics={currentUserTourAudioStatistics}
        sightseeingSpots={sightseeingSpots}
      />
    </div>
  );
};

export default TourTab;
