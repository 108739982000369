import { ConnectionStatus, Network } from '@capacitor/network';

const useNetwork = () => {

  const getNetworkStatus = (): Promise<ConnectionStatus> => {
    return Network.getStatus();
  };

  return {
    getNetworkStatus,
  };
};

export default useNetwork;
