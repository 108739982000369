import { useSwiperSlide } from "swiper/react";
import { reduce, sortBy } from 'lodash-es';
import { useEffect, useState } from 'react';

import Slider, { SliderSlideProps, SliderSliderProps } from "./Slider";
import StoryCard from '../cards/StoryCard';
import { Story } from "../../interfaces/Interfaces";
import {
  useGetStoriesLikeStatisticsLazyQuery
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useError from '../../hooks/useError';
import useAnalyticsStore from '../../stores/useAnalyticsStore';

const StoryCardSlide: React.FC<{
  story: Story;
  isViewed: boolean;
  storyLikesAmount: number;
  navigateToStory: ({ story }: { story: Story }) => void;
  setHighlightedStory?: (story: Story) => void;
}> = ({ story, isViewed, storyLikesAmount, navigateToStory, setHighlightedStory }) => {
  const swiperSlide = useSwiperSlide();

  if (setHighlightedStory && swiperSlide.isActive) {
    setHighlightedStory(story);
  }

  return <StoryCard
    story={story}
    isViewed={isViewed}
    storyLikesAmount={storyLikesAmount}
    navigateToStory={navigateToStory}
  />;
};

const StoryCardSlider: React.FC<{
  stories: Story[];
  isAllStoriesReceived?: boolean;
  navigateToStory: ({ story }: { story: Story }) => void;
  sliderProps?: SliderSliderProps;
  slideProps?: SliderSlideProps;
  setHighlightedStory?: (story: Story) => void;
  slideToStoryIndex?: number | null;
}> = ({
  stories,
  isAllStoriesReceived,
  navigateToStory,
  sliderProps,
  slideProps,
  setHighlightedStory,
  slideToStoryIndex
}) => {
  const { handleBackendError } = useError();

  const viewedStoryIds = useAnalyticsStore((state) => state.viewedStoryIds);

  const [storiesLikesStatistics, setStoriesLikesStatistics] = useState<{ [key: string]: number; }>({});

  const [sortedStories, setSortedStories] = useState<Story[]>([]);
  const [getStoriesLikeStatisticsQuery] = useGetStoriesLikeStatisticsLazyQuery();

  useEffect(() => {
    const getStoriesLikeStatistics = async () => {
      await handleBackendError(async () => {
        const { data, error } = await getStoriesLikeStatisticsQuery({
          variables: {
            input: {
              datoStoryIds: stories.map(({ id }) => id),
            },
          },
          fetchPolicy: 'no-cache',
        });

        if (error) return error;

        const storiesLikesStatisticsData = reduce(
          data?.storyEvaluation?.getStoriesLikeStatistics,
          (acc, storyLikeStatistics) => ({
            ...acc,
            [storyLikeStatistics.datoStoryId]: storyLikeStatistics.totalLikesAmount
          }),
        {});

        setStoriesLikesStatistics(storiesLikesStatisticsData);
      });
    };

    if (stories?.length && isAllStoriesReceived) {
      getStoriesLikeStatistics();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAllStoriesReceived, stories]
  );

  useEffect(() => {
    // sort stories by amount of likes
    const sortedStories = sortBy(stories, (story) => {
      const totalLikesAmount = storiesLikesStatistics[story.id] || 0;
      return -totalLikesAmount;
    });

    setSortedStories(sortedStories);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [stories, storiesLikesStatistics]
  );

  return (
    <Slider sliderProps={sliderProps} slideProps={slideProps} slideToIndex={slideToStoryIndex}>
      {sortedStories.map((story, index) => (
        <StoryCardSlide
          story={story}
          isViewed={viewedStoryIds.includes(story.id)}
          storyLikesAmount={storiesLikesStatistics[story.id] || 0}
          navigateToStory={navigateToStory}
          setHighlightedStory={setHighlightedStory}
          key={`story-card-${index}`}
        />
      ))}
    </Slider>
  );
};

export default StoryCardSlider;
