import { memo, useRef, useState } from 'react';
import Map, { MapRef, Marker as MapMarker } from 'react-map-gl';

import { Tour } from '../../interfaces/Interfaces';
import TourRoute from './TourRoute';
import TourStopMarkers from './TourStopMarkers';
import GeolocateControl from './GeolocateControl';
import { setMapLanguage } from '../../helpers/map-helpers';
import { useLocale } from '../../contexts/LocaleContext';

const SingleLocationMap: React.FC<{
  location: any;
  initialZoom?: number;
  markerScale?: number;
  showGeolocateControl?: boolean;
  // uses for dynamic height of the map
  mapHeight?: number;
  currentTour?: Tour | null;
}> = ({ location, initialZoom = 13, markerScale = 0.3, showGeolocateControl, mapHeight, currentTour }) => {
  const mapRef = useRef<MapRef>(null);
  const { locale } = useLocale();

  const [viewState, setViewState] = useState({
    latitude: location.latitude,
    longitude: location.longitude,
    zoom: initialZoom,
  });

  return (
    <Map
      ref={mapRef}
      {...viewState}
      onMove={(e) => setViewState(e.viewState)}
      attributionControl={false}
      reuseMaps={true}
      dragRotate={false}
      mapStyle="mapbox://styles/thomas-guidable/ckwju2mpv96fa14mplcpx79ld"
      style={mapHeight ? {
        width: '100%',
        height: mapHeight + 'px'
      } : {}}
      onLoad={(e) => {
        setMapLanguage(e, locale);
      }}
    >
      {currentTour && <>
        <TourRoute tour={currentTour} lineColor="#e38873" isActive={true}/>
        <TourStopMarkers tour={currentTour} />
      </>}

      {showGeolocateControl && <GeolocateControl mapRef={mapRef}/>}

      <MapMarker
        latitude={location?.latitude}
        longitude={location?.longitude}
        scale={markerScale}
        color="#E38873"
      />
    </Map>
  );
};

export default memo(SingleLocationMap);
