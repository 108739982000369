import { memo, useEffect, useState } from 'react';
import clsx from 'clsx';

import { LatLonField } from '../../graphql/dato/__generated__/dato-graphql.generated';
import axios from '../../config/axios.config';

const PlaceSearchImage: React.FC<{
  searchText: string;
  location: LatLonField,
  maxWidth?: number;
  className?: string;
}> = ({ searchText, location, maxWidth, className = "" }) => {
  const [image, setImage] = useState<string | null>();

  useEffect(() => {
    let isMounted = true;

    const getImage = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_BACKEND_API_URL}/media/google-places-image`, {
          params: {
            searchText,
            location,
            maxWidth,
          },
          responseType: 'blob',
        });

        if (isMounted && res?.data) setImage(URL.createObjectURL(res?.data));
      } catch (e) {
        console.error('Place image receiving error: ', e);
      }
    };

    getImage();

    return () => {
      isMounted = false;
      setImage(null);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchText, location, maxWidth]
  );

  return (
    <div
      className={clsx("w-full h-full bg-cover bg-center bg-gradient-to-r from-gray-600 to-gray-300", className)}
      style={image ? { backgroundImage: `url(${image})` } : {}}
    />
  );
};

export default memo(PlaceSearchImage);
