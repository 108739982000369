import clsx from "clsx";

const SegmentHeader: React.FC<{ title: string; className?: string }> = ({
  title,
  className,
}) => {
  return (
    <div
      className={clsx(
        "mb-0.5 text-base font-semibold uppercase text-primary",
        className
      )}
    >
      {title}
    </div>
  );
};

export default SegmentHeader;
