import { useCallback, useEffect, useState } from 'react';
import { mapValues, reduce } from 'lodash-es';

import {
  useCitiesWithToursNumberForLocalesQuery,
} from '../graphql/dato/__generated__/dato-graphql.generated';
import { useCity } from '../contexts/CityContext';

const useCitiesWithToursNumberForLocales = (isVisible: boolean) => {
  const { currentCity } = useCity();

  const [toursNumberForLocales, setToursNumberForLocales] = useState<{ [key: string]: number }>({});

  const citiesWithToursNumberForLocalesPageSize = 100;
  const [citiesWithToursNumberForLocalesPageNumber, setCitiesWithToursNumberForLocalesPageNumber] = useState(0);

  const citiesWithToursNumberForLocalesQueryVariables = useCallback(() => {
    return {
      latitude: currentCity?.location?.latitude,
      longitude: currentCity?.location?.longitude,
      radius: 50000,
      first: citiesWithToursNumberForLocalesPageSize,
      skip: citiesWithToursNumberForLocalesPageNumber * citiesWithToursNumberForLocalesPageSize,
    };
  }, [citiesWithToursNumberForLocalesPageNumber, currentCity]);

  const [result] = useCitiesWithToursNumberForLocalesQuery({
    variables: citiesWithToursNumberForLocalesQueryVariables(),
    pause: !currentCity || !isVisible,
  });

  useEffect(() => {
    const citiesWithToursNumber = result.data?.cities as unknown as Array<{
      [locale: string]: { count: number }
    }>;

    if (citiesWithToursNumber) {
      // Fetch more cities if available
      if (citiesWithToursNumber.length === citiesWithToursNumberForLocalesPageSize) {
        // By setting the page size another GraphQL query for the next page gets executed
        setCitiesWithToursNumberForLocalesPageNumber(citiesWithToursNumberForLocalesPageNumber + 1);
      } else {
        // Sum the count values for each locale
        const result = reduce(citiesWithToursNumber, (acc, city) =>
          mapValues(acc, (value, key) => ({ count: value.count + city[key].count }))
        );
        const toursNumberForLocales = mapValues(result, (locale) => locale.count);
        setToursNumberForLocales(toursNumberForLocales);
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result.data]
  );

  return { toursNumberForLocales };
};

export default useCitiesWithToursNumberForLocales;
