import React from 'react';
import { useTranslation } from 'react-i18next';
import { radioButtonOn } from 'ionicons/icons';
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle, IonIcon,
  IonItem,
  IonLabel,
  IonList
} from '@ionic/react';

import { User } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useAuthStore from '../../stores/useAuthStore';
import DetachFromGroupSharingButton from '../buttons/DetachFromGroupSharingButton';

const GroupSharingUsersList: React.FC<{ users: User[] | undefined | null }> = ({ users }) => {
  const { t } = useTranslation();

  const me = useAuthStore(state => state.me);

  return (
    <>
      {users?.length && <IonCard color="dark" className="m-0 rounded-t-2xl rounded-b-none grow min-h-[250px] w-full">
        <IonCardHeader className="text-center pt-6">
          <IonCardTitle className="text-[1rem] font-semibold">{t('groupSharingUsersList.title')}</IonCardTitle>
          <IonCardSubtitle
            className="text-[0.875rem] font-light pt-1"
          >{t('groupSharingUsersList.subtitle')}</IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          <IonList className="p-0 bg-[#414254]">
            {users?.map(user => (
              <IonItem
                color="dark"
                lines="none"
                key={user.id}
                className="text-[0.875rem] font-semibold max-w-md mx-auto"
              >
                <IonLabel>
                  {user?.profile?.firstName ? `${user?.profile?.firstName} ${user?.profile?.lastName}` : user?.email}
                </IonLabel>
                {user?.id === me?.id ? <DetachFromGroupSharingButton /> : <IonIcon
                  src={radioButtonOn}
                  slot="end"
                  className="m-0 text-[#A4D5CD]"
                />}
              </IonItem>
            ))}
          </IonList>
        </IonCardContent>
      </IonCard>}
    </>
  );
};

export default GroupSharingUsersList;
