import { useEffect, useState } from 'react';
import { IonContent, useIonViewDidEnter } from '@ionic/react';

import AppLayout from '../layouts/AppLayout';
import AppHeader from '../components/AppHeader';
import CityOverviewSection from './HomePage/CitySections/CityOverviewSection';
import PopularToursSection from './HomePage/PopularToursSection';
import PopularSightseeingSpotsSection from './HomePage/CitySections/PopularSightseeingSpotsSection';
import TourCollectionsSection from './HomePage/CitySections/TourCollectionsSection';
import UnviewedStoriesSection from './HomePage/CitySections/UnviewedStoriesSection';
import useNetwork from '../hooks/useNetwork';
import OfflineWall from './HomePage/OfflineWall';
import useIonVisible from '../hooks/useIonVisible';
import useAuthStore from '../stores/useAuthStore';
import OpenExploreModeSection from './HomePage/OpenExploreModeSection';
import { useCity } from '../contexts/CityContext';
import GeneralOverviewSection from './HomePage/GeneralSections/GeneralOverviewSection/GeneralOverviewSection';
import NoCityDataSection from './HomePage/NoCityDataSection';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';

const HomePage: React.FC = () => {
  const { getNetworkStatus } = useNetwork();
  const { isVisible } = useIonVisible();
  const { currentCity } = useCity();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [isInternetConnection, setIsInternetConnection] = useState<boolean>();

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_HOME);
    }
  });

  useEffect(() => {
    const checkNetworkStatus = async () => {
      const networkStatus = await getNetworkStatus();
      setIsInternetConnection(networkStatus.connected);
    };

    checkNetworkStatus();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!isInternetConnection) {
    return (
      <OfflineWall />
    )
  }

  return (
    <AppLayout>
      <AppHeader isAbsolutelyPositioned={true}/>

      <IonContent>
        {!!currentCity?.id && <div className="relative mx-auto min-h-full max-w-xl bg-[#FAFAFA]">
          <CityOverviewSection city={currentCity} isVisible={isVisible} />

          <PopularToursSection cityId={currentCity?.id} isAuthenticated={isAuthenticated} isVisible={isVisible} />

          <OpenExploreModeSection />

          <UnviewedStoriesSection cityId={currentCity?.id} isVisible={isVisible} />

          <PopularSightseeingSpotsSection city={currentCity} isVisible={isVisible} />

          <TourCollectionsSection city={currentCity} isVisible={isVisible} />
        </div>}

        {!currentCity && <div className="relative mx-auto min-h-full max-w-xl bg-[#FAFAFA]">
          <GeneralOverviewSection isVisible={isVisible} />

          <PopularToursSection isAuthenticated={isAuthenticated} isVisible={isVisible}  />

          <OpenExploreModeSection />
        </div>}

        {!!currentCity && !currentCity?.id && <div className="mx-auto min-h-full max-w-xl bg-[#FAFAFA] flex items-center">
          <NoCityDataSection />
        </div>}
      </IonContent>
    </AppLayout>
  );
};

export default HomePage;
