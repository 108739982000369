import { useCallback, useEffect, useState } from 'react';
import { Pagination, Virtual } from "swiper";
import {
  Swiper,
  SwiperProps,
  SwiperSlide,
  SwiperSlideProps,
  useSwiper,
  useSwiperSlide,
} from "swiper/react";
import { isNumber } from 'lodash-es';

import "./Slider.scss";

// Note: The autoHeight slider incours a heavy performance penalty, adding many event listeners
//  to each slide in the slider, plus being unable to virtualize the slides since slidesPerView: "auto"
//  and slide virtualization are not compatible with one another in Swiper.js
//  Only use autoHeight where it is absolutely necessary.
const HeightAdjustableSlide: React.FC = ({ children }) => {
  const swiper = useSwiper();
  const swiperSlide = useSwiperSlide();

  const updateSliderHeight = useCallback(() => {
    if (!swiper || !swiperSlide) {
      return;
    }

    if (swiperSlide.isActive) {
      swiper.updateAutoHeight();

      const timer1 = setTimeout(() => {
        swiper.updateAutoHeight();
      }, 100);

      const timer2 = setTimeout(() => {
        swiper.updateAutoHeight();
      }, 600);

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      };
    }
  }, [swiper, swiperSlide]);

  // Update the slider height when the slide becomes active
  useEffect(() => {
    const clearTimerCallback = updateSliderHeight();
    return clearTimerCallback;
  }, [updateSliderHeight]);

  return <div className="slide-content">{children}</div>;
};

export interface SliderSliderProps extends SwiperProps {}
export interface SliderSlideProps extends SwiperSlideProps {}

interface SliderProps {
  sliderProps?: SliderSliderProps;
  slideProps?: SliderSlideProps;
  children: React.ReactElement[];
  slideToIndex?: number | null;
}

const Slider: React.FC<SliderProps> = ({
  sliderProps = {},
  slideProps = {},
  children,
  slideToIndex,
}) => {
  const [swiper, setSwiper] = useState<any>(null);
  const swiperModules = [Pagination];

  const isVirtualSlider = !!sliderProps.virtual;
  if (isVirtualSlider) {
    swiperModules.push(Virtual);
  }

  useEffect(() => {
    if (isNumber(slideToIndex)) {
      swiper.slideTo(slideToIndex);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slideToIndex]
  );

  return (
    <Swiper
      {...{
        modules: swiperModules,
        ...sliderProps,
        pagination: {
          clickable: true,
          dynamicBullets: true,
        },
        onAfterInit: (swiper) => {
          // For a slider with virtual slides, force an update directly after initialization
          //  to make sure all slides that should be visible get rendered
          if (isVirtualSlider) {
            swiper.virtual.update(true);
          }
        },
        onSwiper: (swiper) => setSwiper(swiper),
      }}
    >
      {children.map((slide, index) => (
        <SwiperSlide
          {...slideProps}
          key={`slider-slide-${index}`}
          virtualIndex={isVirtualSlider ? index : undefined}
        >
          {sliderProps?.autoHeight ? (
            <HeightAdjustableSlide>{slide}</HeightAdjustableSlide>
          ) : (
            slide
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
