import { memo, Ref } from 'react';
import { IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';

const MapSearchForSelectedPlaceButton: React.FC<{
  buttonRef: Ref<HTMLIonButtonElement> | undefined;
  onClick: () => void;
}> = ({ buttonRef, onClick }) => {
  const { t } = useTranslation();

  return (
    <IonButton
      ref={buttonRef}
      size="small"
      shape="round"
      className="absolute z-50 bottom-7 left-[50%] transform -translate-x-2/4 normal-case"
      onClick={onClick}
    >
      {t('storyMap.searchInSelectedPlaceButton')}
    </IonButton>
  );
};

export default memo(MapSearchForSelectedPlaceButton);
