import { Line } from "rc-progress";
import { useMediaPlayer } from "../../contexts/MediaPlayerContext";
import useStoryPlayer from "../../hooks/useStoryPlayer";

const StoryPlayerNavigation: React.FC = () => {
  const {
    currentStorySlides,
    currentStorySlide,
    percentagePlayed,
    playStorySlide,
  } = useMediaPlayer();
  const { navigateToStorySlide } = useStoryPlayer();

  if (!currentStorySlides || !currentStorySlide) {
    return null;
  }

  return (
    <div className="mt-5 flex items-center justify-start space-x-1.5">
      {currentStorySlides.map((storySlide, index) => {
        const isCurrentStorySlide = storySlide?.id === currentStorySlide.id;

        const currentStorySlideIndex = currentStorySlides.findIndex(
          (storySlide) => storySlide?.id === currentStorySlide.id
        );
        const isPastStorySlide = index < currentStorySlideIndex;
        const isFutureStorySlide = index > currentStorySlideIndex;

        let slidePercentagePlayed;
        if (isCurrentStorySlide) {
          slidePercentagePlayed = percentagePlayed * 100;
        } else if (isPastStorySlide) {
          slidePercentagePlayed = 100;
        } else if (isFutureStorySlide) {
          slidePercentagePlayed = 0;
        }

        return (
          <Line
            key={`story-slide-progress-${index}-${storySlide?.id}`}
            percent={slidePercentagePlayed}
            strokeColor="rgba(255, 255, 255, 0.95)"
            trailColor="rgba(255, 255, 255, 0.60)"
            className="grow cursor-pointer"
            onClick={() => {
              if (!isCurrentStorySlide && storySlide) {
                navigateToStorySlide({ storySlide });
                playStorySlide(storySlide);
              }
            }}
            style={{
              height: "4px",
              borderRadius: "4px",
            }}
          />
        );
      })}
    </div>
  );
};

export default StoryPlayerNavigation;
