import { memo, useEffect, useState } from 'react';
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  IonItem, useIonRouter,
} from '@ionic/react';
import { checkmarkOutline, closeOutline } from 'ionicons/icons';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { isNumber, toLower, replace, trim } from 'lodash-es';
import { useDebouncedEffect } from '@react-hookz/web';
import { Capacitor } from '@capacitor/core';

import {
  LocationTaskRecord,
  Maybe,
  MultipleChoiceQuestionRecord,
  QrCodeTaskRecord,
  TextInputRecord
} from '../../graphql/dato/__generated__/dato-graphql.generated';
import { ALPHABET } from '../../constants';
import {
  UpdateUserQuizTaskInput,
  UserQuizTask,
  useUpdateUserQuizTaskMutation
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useError from '../../hooks/useError';
import useAuthStore from '../../stores/useAuthStore';
import AppInput from '../form/AppInput';
import { getCurrentLocation } from '../../helpers/geolocation-helpers';
import { getDistanceBetweenCoordinates } from '../../helpers/turf-helpers';
import '../modals/QuizModal.scss';
import useRoutes from '../../hooks/useRoutes';

const TaskBlockHeader: React.FC<{
  title?: Maybe<string>;
}> = ({ title }) => {
  return (
    <IonCardHeader>
      <IonCardTitle className="text-white text-center text-[1.25rem] leading-7 font-bold pr-8">
        {title}
      </IonCardTitle>
    </IonCardHeader>
  );
};

const AnswerCorrectnessMessage: React.FC<{
  userTask?: UserQuizTask;
  correctAnswerMessage: string;
  wrongAnswerMessage: string;
  messageVariables?: { [key: string]: any };
  isTryAgainMode: boolean;
  tryAgain: () => void;
}> = ({ userTask, correctAnswerMessage, wrongAnswerMessage, messageVariables = {}, isTryAgainMode, tryAgain }) => {
  const { t } = useTranslation();

  if (!userTask?.isCompleted || isTryAgainMode) {
    return null;
  }

  return (
    <div className="p-5 pb-2 text-center">
      <div className={clsx(
        "text-[0.875rem]",
        userTask?.isCorrectAnswer ? "text-[#085c14]" : "text-[#b81c1c]"
      )}>
        {t(userTask?.isCorrectAnswer ? correctAnswerMessage : wrongAnswerMessage, messageVariables)}
      </div>
      {!userTask?.isCorrectAnswer && <IonButton
        fill="clear"
        className="normal-case underline text-[#b81c1c] h-[20px]"
        onClick={tryAgain}
      >{t('storyPlayer.quizModal.buttons.tryAgain')}</IonButton>}
    </div>
  );
};

const MultipleChoiceQuestionBlock: React.FC<{
  task: MultipleChoiceQuestionRecord;
  userTask?: UserQuizTask;
  updateUserQuizTask: (input: UpdateUserQuizTaskInput) => void;
}> = ({ task, userTask, updateUserQuizTask }) => {
  const [isTryAgainMode, setIsTryAgainMode] = useState<boolean>(false);

  useEffect(() => {
    setIsTryAgainMode(false);
  }, [userTask]);

  const tryAgain = () => {
    setIsTryAgainMode(true);
  };

  return (
    <IonCard className="rounded-[25px] bg-[#1972F5] m-0">
      <TaskBlockHeader title={task?.question}/>

      <IonCardContent className="rounded-[25px] bg-white p-0">
        <AnswerCorrectnessMessage
          userTask={userTask}
          correctAnswerMessage="storyPlayer.quizModal.multipleChoiceQuestionBlock.messages.correctAnswer"
          wrongAnswerMessage="storyPlayer.quizModal.multipleChoiceQuestionBlock.messages.wrongAnswer"
          isTryAgainMode={isTryAgainMode}
          tryAgain={tryAgain}
        />

        <div className="p-2 pb-[1px]">{task?.options?.map((option, index) =>
          <IonItem
            button
            lines="none"
            className="mb-2 opacity-100"
            key={option.id}
            style={{
              '--background': (userTask?.answerId === option.id && !isTryAgainMode) ?
                (option.isCorrect ? '#085c14' : '#b81c1c') :
                '#ffffff',
              '--border-width': '1px',
              '--border-radius': '25px',
              '--padding-start': '5px',
            }}
            disabled={!!userTask?.isCompleted && !isTryAgainMode}
            onClick={() => {
              updateUserQuizTask({
                datoQuizTaskId: task.id,
                answerId: option.id,
                isCompleted: true,
                isCorrectAnswer: option.isCorrect
              });
            }}
          >
            <div
              className={clsx(
                "w-[40px] min-w-[40px] h-[40px] border-2 rounded-full uppercase text-center font-bold leading-[36px] mr-2 bg-white",
                userTask?.isCompleted && !isTryAgainMode ?
                  (option.isCorrect ? 'text-[#085c14] border-[#085c14]' : 'text-[#b81c1c] border-[#b81c1c]') :
                  'text-[#1972F5] border-[#1972F5]'
              )}
            >
              {userTask?.isCompleted && !isTryAgainMode ?
                <IonIcon
                  icon={option.isCorrect ? checkmarkOutline : closeOutline}
                  className="w-[24px] h-[24px] py-[6px]"
                /> :
                <span>{ALPHABET.charAt(index)}</span>
              }
            </div>
            <div
              className={userTask?.answerId === option.id && !isTryAgainMode ? 'text-white' : 'text-[#5f6367]'}
            >{option.answer}</div>
          </IonItem>
        )
      }</div>
      </IonCardContent>
    </IonCard>
  );
};

const TextInputBlock: React.FC<{
  task: TextInputRecord;
  userTask?: UserQuizTask;
  updateUserQuizTask: (input: UpdateUserQuizTaskInput) => void;
}> = ({ task, userTask, updateUserQuizTask }) => {
  const { t } = useTranslation();
  const { register, reset, handleSubmit, formState: { errors } } = useForm<{ answer: string }>();

  const [isTryAgainMode, setIsTryAgainMode] = useState<boolean>(false);

  useEffect(() => {
    reset({ answer: userTask?.answerText || '' });
    setIsTryAgainMode(false);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userTask]
  );

  const saveAnswer = async ({ answer }: { answer: string }) => {
    const processedAnswer = toLower(trim(replace(answer, /\s{2,}/g, ' ')));
    const processedCorrectAnswer = toLower(trim(replace(task.correctAnswer || '', /\s{2,}/g, ' ')));

    updateUserQuizTask({
      datoQuizTaskId: task?.id,
      answerText: processedAnswer,
      isCompleted: true,
      isCorrectAnswer: processedAnswer === processedCorrectAnswer,
    });
  };

  const tryAgain = () => {
    reset({ answer: '' });
    setIsTryAgainMode(true);
  };

  return (
    <IonCard className="rounded-[25px] bg-[#1972F5] m-0">
      <TaskBlockHeader title={task?.question}/>

      <IonCardContent className="rounded-[25px] bg-white p-0 text-[1rem]">
        <AnswerCorrectnessMessage
          userTask={userTask}
          correctAnswerMessage="storyPlayer.quizModal.textInputBlock.messages.correctAnswer"
          wrongAnswerMessage="storyPlayer.quizModal.textInputBlock.messages.wrongAnswer"
          isTryAgainMode={isTryAgainMode}
          tryAgain={tryAgain}
        />

        <div className="p-2">
          <form onSubmit={handleSubmit(saveAnswer)} className="flex flex-col">

            <AppInput
              placeholder="storyPlayer.quizModal.textInputBlock.form.answer.placeholder"
              name="answer"
              register={register}
              disabled={!!userTask?.isCompleted && !!userTask?.isCorrectAnswer}
              validators={{
                required: t('storyPlayer.quizModal.textInputBlock.form.answer.errors.required'),
              }}
              frontendErrors={errors}
              inputClassNames={clsx(
                "rounded-[25px]",
                (userTask?.isCompleted && !isTryAgainMode) ?
                  userTask?.isCorrectAnswer ? 'bg-[#085c14] text-white' : 'bg-[#b81c1c] text-white' :
                  ''
              )}
              labelClassNames={userTask?.isCompleted && !isTryAgainMode ? 'text-white' : ''}
            />

            <IonButton
              type="submit" size="large" shape="round" className="normal-case font-medium text-[1rem]"
              style={{ '--background': '#1972F5' }}
              disabled={!!userTask?.isCompleted && !isTryAgainMode}
            >{t('storyPlayer.quizModal.textInputBlock.form.buttons.save')}</IonButton>
          </form>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

const LocationTaskBlock: React.FC<{
  task: LocationTaskRecord;
  userTask?: UserQuizTask;
  updateUserQuizTask: (input: UpdateUserQuizTaskInput) => void;
}> = ({ task, userTask, updateUserQuizTask }) => {
  const { t } = useTranslation();

  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(false);
  const [isTryAgainMode, setIsTryAgainMode] = useState<boolean>(false);
  const [distance, setDistance] = useState<number>();

  useEffect(() => {
    setIsTryAgainMode(false);
  }, [userTask]);

  const saveAnswer = async () => {
    try {
      setIsButtonDisabled(true);

      const userLocation = await getCurrentLocation();

      if (userLocation && task?.location) {
        const distance = getDistanceBetweenCoordinates(userLocation, task?.location);

        if (isNumber(distance)) {
          setDistance(Math.round(distance));

          updateUserQuizTask({
            datoQuizTaskId: task?.id,
            answerLocation: JSON.stringify(userLocation),
            isCompleted: true,
            isCorrectAnswer: distance <= task.maximumDistanceToPoint,
          });
        }
      }

      setIsButtonDisabled(false);
    } catch (e) {
      setIsButtonDisabled(false);
    }
  };

  const tryAgain = () => {
    setIsTryAgainMode(true);
  };

  return (
    <IonCard className="rounded-[25px] bg-[#1972F5] m-0">
      <TaskBlockHeader title={task?.task}/>

      <IonCardContent className="rounded-[25px] bg-white p-0">
        {(!userTask?.isCompleted || isTryAgainMode) && <div className={clsx(
          "p-5 pb-2 text-center text-[0.875rem]"
        )}>
          {t('storyPlayer.quizModal.locationTaskBlock.messages.goToLocation')}
        </div>}

        <AnswerCorrectnessMessage
          userTask={userTask}
          correctAnswerMessage="storyPlayer.quizModal.locationTaskBlock.messages.rightLocation"
          wrongAnswerMessage={`storyPlayer.quizModal.locationTaskBlock.messages.${distance ?
            'wrongLocationWithDistance' :
            'wrongLocationWithoutDistance'}`}
          messageVariables={{distance: distance}}
          isTryAgainMode={isTryAgainMode}
          tryAgain={tryAgain}
        />

        <div className="p-2 flex flex-col">
          <IonButton
            size="large" shape="round" className="normal-case font-medium text-[1rem]"
            style={{ '--background': '#1972F5' }}
            disabled={isButtonDisabled || (!!userTask?.isCompleted && !isTryAgainMode)}
            onClick={saveAnswer}
          >{t('storyPlayer.quizModal.locationTaskBlock.buttons.save')}</IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

const QrCodeTaskBlock: React.FC<{
  task: QrCodeTaskRecord;
  userTask?: UserQuizTask;
  updateUserQuizTask: (input: UpdateUserQuizTaskInput) => void;
}> = ({ task, userTask, updateUserQuizTask }) => {
  const { localePath } = useRoutes();
  const { t } = useTranslation();

  const [messageTranslationKey, setMessageTranslationKey] = useState<string>(
    'storyPlayer.quizModal.qrCodeTaskBlock.messages.inProcessing'
  );

  useDebouncedEffect(() => {
    updateUserQuizTask({
      datoQuizTaskId: task?.id,
      isStarted: true,
    });
  }, [], 1000);

  useEffect(() => {
    let translationKey = 'storyPlayer.quizModal.qrCodeTaskBlock.messages.';
    switch (true) {
      case !!userTask?.isCompleted:
        translationKey += 'isCompleted';
        break;
      case !!userTask?.isStarted:
        translationKey += 'isStarted';
        break;
      default:
         translationKey += 'inProcessing';
    }

    setMessageTranslationKey(translationKey);
  }, [userTask]);

  return (
    <IonCard className="rounded-[25px] bg-[#1972F5] m-0">
      <TaskBlockHeader title={task?.task}/>

      <IonCardContent className="rounded-[25px] bg-white p-0">
        <div className="p-2 flex flex-col">
          <p className="text-center p-2 text-green-600">{t(messageTranslationKey)}</p>

          {Capacitor.isNativePlatform() && !!userTask?.isStarted && !userTask?.isCompleted && <IonButton
            size="large" shape="round" className="normal-case font-medium text-[1rem]"
            style={{ '--background': '#1972F5' }}
            disabled={!!userTask?.isCorrectAnswer}
            routerLink={localePath('barcode-scanner')}
            routerDirection="forward"
          >{t('storyPlayer.quizModal.qrCodeTaskBlock.buttons.openScanner')}</IonButton>}
        </div>
      </IonCardContent>
    </IonCard>
  );
};

const TaskBlockRenderer: React.FC<{
  quiz?: any;
  userQuiz?: any;
  getUpdatedUserTour?: () => void;
}> = ({ quiz, userQuiz, getUpdatedUserTour }) => {
  const router = useIonRouter();
  const { currentPath, loginPath } = useRoutes();
  const { handleBackendError } = useError();

  const [updateUserQuizTaskMutation] = useUpdateUserQuizTaskMutation();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const task = quiz?.quizTasks?.[0];
  const userTask = userQuiz?.userQuizTasks?.[0];

  const updateUserQuizTask = async (input: UpdateUserQuizTaskInput) => {
    if (!isAuthenticated) {
      router.push(loginPath(currentPath()));
    } else {
      await handleBackendError(async () => {
        const { errors, data } = await updateUserQuizTaskMutation({ variables: { input }});
        if (errors) return errors;

        const userTask = data?.userQuizTask?.updateUserQuizTask;
        if (userTask) {
          getUpdatedUserTour && getUpdatedUserTour();
        }
      });
    }
  };

  switch (task?.__typename) {
    case 'MultipleChoiceQuestionRecord':
      return <MultipleChoiceQuestionBlock task={task} userTask={userTask} updateUserQuizTask={updateUserQuizTask}/>;
    case 'TextInputRecord':
      return <TextInputBlock task={task} userTask={userTask} updateUserQuizTask={updateUserQuizTask}/>;
    case 'LocationTaskRecord':
      return <LocationTaskBlock task={task} userTask={userTask} updateUserQuizTask={updateUserQuizTask}/>;
    case 'QrCodeTaskRecord':
      return <QrCodeTaskBlock
        task={task}
        userTask={userTask}
        updateUserQuizTask={updateUserQuizTask}
      />;
    default:
      return null;
  }
};

export default memo(TaskBlockRenderer);
