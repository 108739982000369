import { IonPage, IonFooter } from "@ionic/react";
import MiniPlayer from "../components/players/MiniPlayer";
import AuthHeader from '../components/auth/AuthHeader';

const AuthLayout: React.FC<{
  titleTranslationPath: string;
}> = ({ children, titleTranslationPath }) => {
  return (
    <IonPage>
      <AuthHeader titleTranslationPath={titleTranslationPath}/>

      {children}

      <IonFooter>
        <MiniPlayer />
      </IonFooter>
    </IonPage>
  );
};

export default AuthLayout;
