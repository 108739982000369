import { generatePath, useLocation } from 'react-router';
import { identity, pickBy } from "lodash-es";
import queryString from "query-string";

import {
  CreatorProfile,
  Story,
  Tour,
  TourStop,
} from "../interfaces/Interfaces";
import { useLocale, Locales } from "../contexts/LocaleContext";

const useRoutes = () => {
  const { locale: currentLocale } = useLocale();
  const location = useLocation();

  const currentPath = () => {
    return window.location.pathname + window.location.search;
  };

  const creatorProfilePath = (creatorProfile: CreatorProfile) => {
    return generatePath(
      "/:locale(de|en|fr|es)/stories/creators/:creatorProfileUsername",
      {
        locale: currentLocale,
        creatorProfileUsername: creatorProfile.username!,
      }
    );
  };

  const storyPath = ({
    story,
    tour,
    tourStop,
  }: {
    story: Story;
    tour?: Tour | null | undefined;
    tourStop?: TourStop | null | undefined;
  }) => {
    const queryParams = pickBy(
      {
        storyId: story.id,
        tourId: tour?.id,
        tourStopId: tourStop?.id,
      },
      identity
    );

    return generatePath("/:locale(de|en|fr|es)/story?:queryParams", {
      locale: currentLocale,
      queryParams: queryString.stringify(queryParams),
    });
  };

  const toursPath = (locale: Locales = currentLocale) => {
    return generatePath("/:locale(de|en|fr|es)/tours", { locale: locale });
  };

  const tourPath = (tour: Tour) => {
    return tour.slug ? generatePath("/:locale(de|en|fr|es)/tours/:tourSlug", {
      locale: currentLocale,
      tourSlug: tour.slug,
    }) : undefined;
  };

  const userProfilePath = (returnTo?: string) => {
    if (returnTo) {
      return generatePath("/:locale(de|en|fr|es)/user-profile?:queryParams", {
        locale: currentLocale,
        queryParams: queryString.stringify({ returnTo }),
      });
    } else {
      return generatePath("/:locale(de|en|fr|es)/user-profile", {
        locale: currentLocale,
      });
    }
  };

  const authFlowPath = (returnTo?: string) => {
    if (returnTo) {
      return generatePath("/:locale(de|en|fr|es)/auth?:queryParams", {
        locale: currentLocale,
        queryParams: queryString.stringify({ returnTo }),
      });
    } else {
      return generatePath("/:locale(de|en|fr|es)/auth", {
        locale: currentLocale,
      });
    }
  };

  const loginPath = (returnTo?: string) => {
    if (returnTo) {
      return generatePath("/:locale(de|en|fr|es)/login?:queryParams", {
        locale: currentLocale,
        queryParams: queryString.stringify({ returnTo }),
      });
    } else {
      return generatePath("/:locale(de|en|fr|es)/login", {
        locale: currentLocale,
      });
    }
  };

  const localePath = (path: string, locale: Locales = currentLocale, keepQueryParams?: boolean) => {
    const params = new URLSearchParams(location.search);

    if (keepQueryParams && params.toString()) {
      return generatePath(`/:locale(de|en|fr|es)/${path}?:queryParams`, {
        locale,
        queryParams: params.toString(),
      });
    }

    const returnTo = params.get('returnTo');
    if (returnTo) {
      return generatePath(`/:locale(de|en|fr|es)/${path}?returnTo=:returnTo`, {
        locale: locale,
        returnTo: returnTo,
      });
    }

    return generatePath(`/:locale(de|en|fr|es)/${path}`, { locale: locale });
  };

  const premiumAccessPurchasingPath = (returnTo?: string, locale: Locales = currentLocale) => {
    if (returnTo) {
      return generatePath(`/:locale(de|en|fr|es)/premium-access-purchasing?:queryParams`, {
        locale: locale,
        queryParams: queryString.stringify({ returnTo }),
      });
    } else {
      return generatePath(`/:locale(de|en|fr|es)/premium-access-purchasing`, {
        locale: locale,
      });
    }
  };

  return {
    currentPath,
    creatorProfilePath,
    authFlowPath,
    loginPath,
    storyPath,
    toursPath,
    tourPath,
    userProfilePath,
    localePath,
    premiumAccessPurchasingPath,
  };
};

export default useRoutes;
