import { Icon } from '@iconify/react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Marker as MapMarker } from 'react-map-gl/dist/esm/exports-mapbox';

import { Story } from '../../interfaces/Interfaces';
import { isPremiumStory } from '../../helpers/story-helpers';
import guidableLogo from '../../assets/GU_Logo_RZ-RGB_Icon-neg.svg';
import StoryMarkerIcon from './StoryMarkerIcon';

const StoryMarker: React.FC<{
  story: Story;
  isCurrent?: boolean;
  isHighlighted?: boolean;
  setSelectedStory: (story: Story) => void;
  markerSize: number;
}> = memo(({
  story,
  isCurrent = false,
  isHighlighted = false,
  setSelectedStory,
  markerSize
}) => {
  const { t } = useTranslation();

  const storyLocation = story.location!;

  const dotStyles: React.CSSProperties = {
    backgroundColor: undefined,
    opacity: undefined,
  };

  // Marker color
  const fallbackColor = "#64748b"; // Tailwind Color: bg-slate-500
  const mainCategoryColor = story.categories?.[0]?.color?.hex;
  dotStyles.backgroundColor = mainCategoryColor || fallbackColor;

  const isPremium = useMemo(() => isPremiumStory(story), [story]);

  return (
    <MapMarker
      latitude={storyLocation.latitude}
      longitude={storyLocation.longitude}
      style={{
        zIndex: isHighlighted ? 2 : isCurrent ? 2 : 1,
      }}
    >
      <div className="relative">
        {(isHighlighted || isCurrent) && (
          <div
            className={clsx(
              "story-marker-dot-highlighted absolute h-[32px] w-[32px] rounded-full leading-[32px] opacity-75",
              isCurrent ? "animate-ping" : "animate-ping-once"
            )}
            style={dotStyles}
          />
        )}
        <div
          onClick={() => setSelectedStory(story)}
          className={clsx(
            "story-marker",
            "relative flex items-center justify-center rounded-full",
            isHighlighted
              ? "z-30 opacity-100 shadow"
              : isCurrent
                ? "z-20 opacity-90 shadow"
                : "z-10"
          )}
          style={{
            ...(isHighlighted || isCurrent) ? dotStyles : {},
            width: `${(isHighlighted || isCurrent) ? 34 : markerSize}px`,
            height: `${(isHighlighted || isCurrent) ? 34 : markerSize}px`,
            lineHeight: `${(isHighlighted || isCurrent) ? 34 : markerSize}px`,
          }}
        >
          {!isHighlighted && !isCurrent && <StoryMarkerIcon
            isPremium={isPremium}
            color={mainCategoryColor || fallbackColor}
          />}

          {isCurrent && (
            <Icon icon="wpf:audio-wave" className="text-[20px] text-white" />
          )}

          {isHighlighted && !isCurrent && (
            <img
              src={guidableLogo}
              alt={t("storyMap.currentlyPlayingStory") || ""}
              className="h-[22px]"
            />
          )}
        </div>
      </div>
    </MapMarker>
  );
});

export default memo(StoryMarker);
