import { get, head, reduce, values } from 'lodash-es';

import { BackendValidationError } from '../interfaces/Interfaces';

const getBackendValidationErrors = (e: any): BackendValidationError | undefined => {
  const graphQLErrors = head(get(e, 'graphQLErrors'));
  const dtoErrors = get(graphQLErrors, 'fields');
  const notValidationError = get(graphQLErrors, 'message');

  if (dtoErrors) {
    return reduce(dtoErrors, (acc, err) => ({ ...acc, ...err ? err : {} }), {});
  } else if (notValidationError) {
    return { notValidationBackendError: [notValidationError] };
  } else {
    return undefined;
  }
};

const getFirstBackendValidationError = (e: any): string => {
  const validationErrors = getBackendValidationErrors(e);
  return head(head(values(validationErrors))) || '';
};

export { getBackendValidationErrors, getFirstBackendValidationError };
