import { useTranslation } from 'react-i18next';
import { filter, uniq } from 'lodash-es';

import useBackgroundGeolocationStore from '../stores/useBackgroundGeolocationStore';

import { registerPlugin } from '@capacitor/core';
import { BackgroundGeolocationPlugin, Location, CallbackError } from '@capacitor-community/background-geolocation';
const BackgroundGeolocation = registerPlugin<BackgroundGeolocationPlugin>("BackgroundGeolocation");

const watcherOptions = {
  backgroundTitle: "Tracking the nearest point of interest",
  requestPermissions: true,
  stale: false,
  distanceFilter: 50,
};

const useBackgroundGeolocation = () => {
  const { t } = useTranslation();

  const setCurrentLocation = useBackgroundGeolocationStore((state) => state.setCurrentLocation);
  const currentWatcherId = useBackgroundGeolocationStore((state) => state.currentWatcherId);
  const setCurrentWatcherId = useBackgroundGeolocationStore((state) => state.setCurrentWatcherId);
  const watcherConsumers = useBackgroundGeolocationStore((state) => state.watcherConsumers);
  const setWatcherConsumers = useBackgroundGeolocationStore((state) => state.setWatcherConsumers);

  const backgroundGeolocationWatcherCallback = async (location?: Location, error?: CallbackError) => {
    if (error) {
      if (error.code === "NOT_AUTHORIZED") openSettings();
      console.log('BackgroundGeolocation error: ', error);
      return;
    }

    setCurrentLocation(location);
  };

  const openSettings = () => {
    const errorMessage = t('backgroundGeolocation.errors.permission');
    if (window.confirm(errorMessage)) {
      BackgroundGeolocation.openSettings();
    }
  };

  const addWatcher = async (consumer: string) => {
    // set watcher consumers to then remove watcher if all consumers don't need it
    setWatcherConsumers(uniq([...watcherConsumers, consumer]));

    if (currentWatcherId) return;

    const watcherId = await BackgroundGeolocation.addWatcher(
      watcherOptions,
      backgroundGeolocationWatcherCallback,
    );
    setCurrentWatcherId(watcherId);
  };

  const removeWatcher = async (consumer: string) => {
    if (!currentWatcherId) return;

    // delete consumer from the list
    const leftConsumers = filter(watcherConsumers, watcherConsumer => watcherConsumer !== consumer);
    setWatcherConsumers(leftConsumers);

    // remove watcher if all consumers don't need it
    if (!leftConsumers?.length) {
      await BackgroundGeolocation.removeWatcher({ id: currentWatcherId });
      setCurrentWatcherId(null);
    }
  };

  return {
    addWatcher,
    removeWatcher,
  }
};

export default useBackgroundGeolocation;
