import { IonButton, IonContent, useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';

import AppLayout from '../layouts/AppLayout';
import useAuthStore from '../stores/useAuthStore';
import useRoutes from '../hooks/useRoutes';
import { useAttachMeToGroupSharingMutation } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useGroupSharingStore from '../stores/useGroupSharingStore';
import useSearchParams from '../hooks/useSearchParams';
import joinGroupSharingBackground from '../assets/backgrounds/join-group-sharing-background.svg';
import guidableLogo from '../assets/GU_Logo_RZ-RGB_neg.svg';
import GroupSharingUsersList from '../components/modals/GroupSharingUsersList';
import useError from '../hooks/useError';

const JoinGroupSharingPage: React.FC = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { currentPath, loginPath } = useRoutes();
  const { handleBackendError } = useError();
  const { groupSharingId, inviter }: {
    groupSharingId: string | null;
    inviter: string | null;
  } = useSearchParams();

  const [attachMeToGroupSharingMutation] = useAttachMeToGroupSharingMutation();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const setMyGroupSharing = useGroupSharingStore((state) => state.setMyGroupSharing);
  const myGroupSharing = useGroupSharingStore((state) => state.myGroupSharing);

  const [isParticipatedInAnotherGroupSharing, setIsParticipatedInAnotherGroupSharing] = useState<boolean>();

  useEffect(() => {
    setIsParticipatedInAnotherGroupSharing(!!myGroupSharing && myGroupSharing?.id !== groupSharingId);
  }, [groupSharingId, myGroupSharing]);

  const attachMeToGroupSharing = async () => {
    if (!isAuthenticated) {
      router.push(loginPath(currentPath()));
      return;
    }

    if (groupSharingId) {
      await handleBackendError(async () => {
        const { data, errors } = await attachMeToGroupSharingMutation({
          variables: {
            input: {
              groupSharingId,
            }
          }
        });

        if (errors) return errors;

        const groupSharing = data?.groupSharing?.attachMeToGroupSharing;
        if (groupSharing) {
          setMyGroupSharing(groupSharing, true);
        }
      });
    }
  };

  return (
    <AppLayout>
      <IonContent>
        <div
          className="relative z-10 mx-auto flex flex-col items-center min-h-full max-w-xl bg-cover bg-center pt-10"
          style={{ backgroundImage: `url(${joinGroupSharingBackground})` }}
        >
          <img
            src={guidableLogo}
            alt="guidable – stories to explore"
            className="w-[150px]"
          />

          {isParticipatedInAnotherGroupSharing ?
            <div className="text-center text-white flex flex-col items-center px-5 pb-10 pt-20">
              <h2 className="text-[1.5rem] font-bold px-5 pb-5">
                {t('joinGroupSharingPage.isInAnotherGroupSharingBlock.title')}
              </h2>
              <p>{t('joinGroupSharingPage.isInAnotherGroupSharingBlock.text')}</p>
            </div> :
            <div className="flex flex-col items-center">
              <h2 className="text-[1.5rem] text-white font-bold text-center px-5 pb-10 pt-20">
                {!!myGroupSharing ?
                  t(`joinGroupSharingPage.${inviter ? 'joinedTitleWithInviter' : 'joinedTitleWithoutInviter'}`, {
                    inviter: inviter && decodeURIComponent(inviter),
                  }) :
                  t(`joinGroupSharingPage.${inviter ? 'joinTitleWithInviter' : 'joinTitleWithoutInviter'}`, {
                    inviter: inviter && decodeURIComponent(inviter),
                  })}
              </h2>

              {!myGroupSharing && <IonButton
                size="large"
                color="light"
                className="capitalize font-bold mb-10"
                style={{
                  '--padding-end': '80px',
                  '--padding-start': '80px',
                }}
                onClick={attachMeToGroupSharing}
              >{t('joinGroupSharingPage.buttons.join')}</IonButton>}
            </div>
          }

          <GroupSharingUsersList users={myGroupSharing?.users}/>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default JoinGroupSharingPage;
