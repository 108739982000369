import { OverlayEventDetail } from '@ionic/core';
import { IonButton, IonIcon, NavContext, useIonModal } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { closeOutline } from 'ionicons/icons';

import ConfirmationModal from '../modals/ConfirmationModal';
import { useMediaPlayer } from '../../contexts/MediaPlayerContext';
import useToast from '../../hooks/useToast';
import useError from '../../hooks/useError';
import { useDetachMeFromGroupSharingMutation } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useGroupSharingStore from '../../stores/useGroupSharingStore';
import { isIosVersion } from '../../helpers/device-helpers';
import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';

const CloseStoryPlayerButton: React.FC = () => {
  const navContext = useContext(NavContext);
  const { currentTour, currentTourStop, currentStory, unloadMediaPlayer } = useMediaPlayer();
  const { presentToast } = useToast();
  const { handleBackendError } = useError();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [shouldUnloadMediaPlayer, setShouldUnloadMediaPlayer] = useState<boolean>(false);

  const [detachMeFromGroupSharingMutation] = useDetachMeFromGroupSharingMutation();

  const setMyGroupSharing = useGroupSharingStore((state) => state.setMyGroupSharing);
  const myGroupSharing = useGroupSharingStore((state) => state.myGroupSharing);

  const [present, dismiss] = useIonModal(ConfirmationModal, {
    onDismiss: (data?: boolean) => dismiss(data),
    title: 'storyPlayer.buttons.closeTour.confirmationModal.title',
    text: 'storyPlayer.buttons.closeTour.confirmationModal.text',
    note: myGroupSharing ? 'storyPlayer.buttons.closeTour.confirmationModal.note' : '',
    confirmButtonText: 'storyPlayer.buttons.closeTour.confirmationModal.buttons.confirm',
    cancelButtonText: 'storyPlayer.buttons.closeTour.confirmationModal.buttons.cancel'
  });

  const closeMediaPlayer = () => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.STORY_PLAYER_CLOSE_TOUR_BUTTON);
    }

    present({
      // set animated false for ios 17,because animation breaks the popup
      // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
      animated: !isIosVersion(17),
      onWillDismiss: async (e: CustomEvent<OverlayEventDetail>) => {
        if (e.detail.data) {
          setShouldUnloadMediaPlayer(true);

          if (myGroupSharing) {
            if (mixpanelEnabled) {
              mixpanel.track(MixpanelEvents.LEAVE_GROUP_SHARING, {
                tourId: currentTour?.id,
                tourTitle: currentTour?.title,
                tourStopId: currentTourStop?.id,
                tourStopTitle: currentTourStop?.title,
                storyId: currentStory?.id,
                storyTitle: currentStory?.title,
              });
            }

            await handleBackendError(async () => {
              const { data, errors } = await detachMeFromGroupSharingMutation();

              if (errors) return errors;

              if (data?.groupSharing?.detachMeFromGroupSharing) {
                setMyGroupSharing(null);
                presentToast('detachFromGroupSharing.success');
              }
            });
          }

          // navigate back
          if (navContext.hasIonicRouter()) {
            navContext.goBack('/');
          } else {
            window.location.href = '/';
          }
        }
      }
    });
  };

  useEffect(() => {
    // unload media player on the component destroying
    return () => {
      if (shouldUnloadMediaPlayer) {
        unloadMediaPlayer()
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldUnloadMediaPlayer],
  );

  if (!currentTour) {
    return null;
  }

  return (
    <IonButton
      fill="clear"
      className="absolute top-[6px] right-0 z-50"
      onClick={closeMediaPlayer}
      style = {{ marginTop: "calc(var(--ion-safe-area-top, 12px) + 4px)" }}
    >
      <IonIcon color="light" slot="icon-only" icon={closeOutline} />
    </IonButton>
  );
};

export default CloseStoryPlayerButton;
