import { create } from "zustand";

import { Location } from '@capacitor-community/background-geolocation';

interface BackgroundGeolocationStoreState {
  currentLocation: Location | null | undefined;
  currentWatcherId: string | null | undefined;
  watcherConsumers: string[];
  setCurrentLocation: (currentLocation: Location | null | undefined) => void;
  setCurrentWatcherId: (currentWatcherId: string | null | undefined) => void;
  setWatcherConsumers: (watcherConsumers: string[]) => void;
}

const useBackgroundGeolocationStore = create<BackgroundGeolocationStoreState>((set, get) => ({
  currentLocation: null,
  currentWatcherId: null,
  watcherConsumers: [],
  setCurrentLocation: (currentLocation) => {
    set((state) => ({
      ...state,
      currentLocation
    }));
  },
  setCurrentWatcherId: (currentWatcherId) => {
    set((state) => ({
      ...state,
      currentWatcherId
    }));
  },
  setWatcherConsumers: (watcherConsumers) => {
    set((state) => ({
      ...state,
      watcherConsumers
    }));
  },
}));

export default useBackgroundGeolocationStore;
