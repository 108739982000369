import { IonButton, IonButtons, IonHeader, IonIcon, IonProgressBar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import useStoryExplorationStore from '../stores/useStoryExplorationStore';
import StoriesListInExploreModeButton from './buttons/StoriesListInExploreModeButton';
import AppCitySearch from './form/AppCitySearch';
import { useTourCreationRequest } from '../contexts/TourCreationRequestContext';
import pinIcon from '../assets/header/pin.svg';
import useRoutes from '../hooks/useRoutes';
import { useTransaction } from '../contexts/TransactionContext';

const AppHeader: React.FC<{
  isAbsolutelyPositioned?: boolean;
  citySearchAdditionalButton?: JSX.Element;
}> = ({ isAbsolutelyPositioned = false, citySearchAdditionalButton }) => {
  const { t } = useTranslation();
  const { tourCreationRequestStatisticsByUser } = useTourCreationRequest();
  const { localePath } = useRoutes();
  const { hasPremiumAccess } = useTransaction();

  const isExploreModeEnabled = useStoryExplorationStore((state) => state.isExploreModeEnabled);

  return (
    <IonHeader className={clsx(
      isAbsolutelyPositioned ? "absolute" : "",
      "ion-no-border"
    )}>
      <div
        className="max-w-xl mx-auto relative flex justify-between items-center pb-4 pl-4 pr-2"
        style={{ paddingTop: "calc(var(--ion-safe-area-top, 6px) + 6px)" }}
      >
        <AppCitySearch additionalButton={citySearchAdditionalButton}/>

        <IonButtons>
          <StoriesListInExploreModeButton />
          {!hasPremiumAccess && <IonButton
            routerLink={localePath('premium-access-purchasing')}
            style={{
              '--padding-bottom': 0,
              '--padding-top': 0,
              '--padding-start': 0,
              '--padding-end': 0,
            }}
          >
            <IonIcon icon={pinIcon} slot="icon-only" className="w-10 h-10"/>
          </IonButton>}
        </IonButtons>

        {isExploreModeEnabled && <div
          className="absolute bottom-[-8px] left-0 right-0 text-[0.75em] text-white text-center bg-[#A4D5CD]"
        >{t('header.exploreMode.active')}</div>}

        {!!tourCreationRequestStatisticsByUser?.inProgress && <div
          className="absolute bottom-[10px] left-0 right-0"
        ><IonProgressBar type="indeterminate"/></div>}
      </div>
    </IonHeader>
  );
};

export default AppHeader;
