import { ItemByLanguageOption } from '../interfaces/ItemByLanguageOption';

// Locale flags
import flagForGermany from "@iconify/icons-emojione/flag-for-germany";
import flagForUnitedKingdom from "@iconify/icons-emojione/flag-for-united-kingdom";
import flagForSpain from "@iconify/icons-emojione/flag-for-spain";
import flagForFrance from "@iconify/icons-emojione/flag-for-france";
import flagForItaly from "@iconify/icons-emojione/flag-for-italy";
import flagForUkraine from "@iconify/icons-emojione/flag-for-ukraine";

export const INITIAL_ITEM_BY_LANGUAGE_OPTIONS: ItemByLanguageOption[] = [
  { languageName: 'Deutsch', languageCode: 'de', flag: flagForGermany },
  { languageName: 'English', languageCode: 'en', flag: flagForUnitedKingdom },
  { languageName: 'Español', languageCode: 'es', flag: flagForSpain },
  { languageName: 'Français', languageCode: 'fr', flag: flagForFrance },
  { languageName: 'Italiano', languageCode: 'it', flag: flagForItaly },
  { languageName: 'Українська', languageCode: 'uk', flag: flagForUkraine },
];
