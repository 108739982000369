import { useIonViewDidEnter, useIonViewWillLeave } from "@ionic/react";
import { useState } from "react";

const useIonVisible = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useIonViewDidEnter(() => {
    setIsVisible(true);
  });

  useIonViewWillLeave(() => {
    setIsVisible(false);
  });

  return { isVisible };
};

export default useIonVisible;
