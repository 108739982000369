import { useTranslation } from "react-i18next";
import { ToastButton, useIonToast } from '@ionic/react';

const useToast = () => {
  const [present] = useIonToast();
  const { t } = useTranslation();

  const presentToast = (
    messageTranslationPath: string,
    color: 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'danger' | 'light' | 'medium' | 'dark' = 'secondary',
    duration: number = 3000,
    position: 'top' | 'bottom' | 'middle' = 'top',
    buttons?: (ToastButton | string)[],
  ) => {
    return present({
      message: t(messageTranslationPath),
      duration,
      position,
      color,
      buttons,
    });
  };

  return {
    presentToast,
  }
};

export default useToast;
