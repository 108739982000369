import { IonIcon } from '@ionic/react';

import { Category } from '../../interfaces/Interfaces';

const CategoryTag: React.FC<{ category: Category; }> = ({ category }) => {
  const categoryIcon = category?.colorIcon?.url || category?.icon?.url;

  const color = category?.color;
  const backgroundColor = `rgba(${color ? color?.red : 71},${color ? color?.green : 85},${color ? color?.blue : 105}, 0.2)`;
  const textColor = `rgb(${color ? color?.red : 255},${color ? color?.green : 255},${color ? color?.blue : 255})`;

  return (
    <span className="inline-block rounded-full mr-1 bg-white overflow-hidden">
      <span
        className="inline-block flex items-center whitespace-nowrap w-full h-full px-3 py-1"
        style={{ backgroundColor }}
      >
        {!!categoryIcon && <IonIcon src={categoryIcon} className="mr-0.5 h-[15px] w-[15px] shrink-0" />}

        <span
          className="font-medium text-[0.75rem] font-medium leading-[0.75]"
          style={{ color: textColor  }}
        >{category.title}</span>
      </span>
    </span>
  );
};

export default CategoryTag;
