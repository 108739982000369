import { IonIcon, IonRange } from '@ionic/react';
import {
  pauseCircleSharp as pauseIcon,
  playCircleSharp as playIcon,
  chevronBackSharp as navigateBackwardIcon,
  chevronForwardSharp as navigateForwardIcon,
} from 'ionicons/icons';
import { Icon } from '@iconify/react';
import seekBackwardIcon from '@iconify/icons-fluent/skip-backward-10-32-filled';
import seekForwardIcon from '@iconify/icons-fluent/skip-forward-10-32-filled';
import { DeepPartial } from 'react-hook-form';
import clsx from 'clsx';
import { useMemo } from 'react';

import { useMediaPlayer } from '../../contexts/MediaPlayerContext';
import { formatMinutesAndSeconds } from '../../helpers/format-minutes-and-seconds';
import { getNextTourStop, getPreviousTourStop } from '../../helpers/tour-helpers';
import { TourStop } from '../../interfaces/Interfaces';
import useStoryPlayer from '../../hooks/useStoryPlayer';
import { UserTour } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { checkQuizzesCompletionOfPreviousTourStops } from '../../helpers/tour-stop-helpers';

const NavigateBackwardButton: React.FC<{
  className?: string;
  skipIfEmpty?: boolean;
}> = ({ className, skipIfEmpty }) => {
  const { previousStorySlide, currentTourStop, playStorySlide } = useMediaPlayer();
  const { navigateToStory, navigateToStorySlide } = useStoryPlayer();

  let previousTourStop: TourStop | null | undefined = null;
  if (currentTourStop) {
    previousTourStop = getPreviousTourStop(currentTourStop);
  }

  if (skipIfEmpty && !previousStorySlide && !previousTourStop) {
    return null;
  }

  return (
    <IonIcon
      icon={navigateBackwardIcon}
      onClick={() => {
        if (previousStorySlide) {
          navigateToStorySlide({ storySlide: previousStorySlide });
          playStorySlide(previousStorySlide);
        } else if (previousTourStop) {
          const story = previousTourStop.stories?.[0];
          if (story) {
            navigateToStory({ story });
          }
        }
      }}
      className={clsx(
        "shrink-0 p-1.5 text-[28px]",
        className,
        previousStorySlide || previousTourStop
          ? "cursor-pointer"
          : "text-white/60"
      )}
    />
  );
};

const NavigateForwardButton: React.FC<{
  className?: string;
  skipIfEmpty?: boolean;
  userTour?: DeepPartial<UserTour>;
}> = ({ className, skipIfEmpty, userTour }) => {
  const { nextStorySlide, currentTourStop, playStorySlide, currentTour } = useMediaPlayer();
  const { navigateToStory, navigateToStorySlide } = useStoryPlayer();

  const { isNextTourStopEnabled, nextTourStop } = useMemo(() => {

    let isNextTourStopDisabledUntilQuizCompletion = false;

    let nextStop;
    if (currentTourStop) {
      nextStop = getNextTourStop(currentTourStop);

      if (nextStop && currentTour) {
        // disable tour stop if some of the previous ones have uncompleted quiz
        isNextTourStopDisabledUntilQuizCompletion = checkQuizzesCompletionOfPreviousTourStops(nextStop, userTour);
      }
    }

    return {
      isNextTourStopEnabled: nextStop && !currentTourStop?.isPreviewTourStop && !isNextTourStopDisabledUntilQuizCompletion,
      nextTourStop: nextStop,
    }
  }, [currentTourStop, userTour, currentTour]);

  if (skipIfEmpty && !nextStorySlide && !isNextTourStopEnabled) {
    return null;
  }

  return (
    <IonIcon
      icon={navigateForwardIcon}
      onClick={() => {
        if (nextStorySlide) {
          navigateToStorySlide({ storySlide: nextStorySlide });
          playStorySlide(nextStorySlide);
        } else if (isNextTourStopEnabled) {
          const story = nextTourStop?.stories?.[0];
          if (story) {
            navigateToStory({ story });
          }
        }
      }}
      className={clsx(
        "shrink-0 p-1.5 text-[28px]",
        className,
        nextStorySlide || isNextTourStopEnabled ? "cursor-pointer" : "text-white/60"
      )}
    />
  );
};

const PlaybackRateControl: React.FC = () => {
  const { playbackRate, togglePlaybackRate } = useMediaPlayer();

  let textSizeClass: string;

  if (playbackRate === 1 || playbackRate === 2) {
    textSizeClass = "text-[0.95rem]";
  } else if (playbackRate === 1.5) {
    textSizeClass = "text-[0.875rem]";
  } else {
    textSizeClass = "text-[0.8rem]";
  }

  return (
    <div
      className={clsx(
        "w-[28px] whitespace-nowrap text-center font-bold",
        textSizeClass
      )}
      onClick={togglePlaybackRate}
    >
      {playbackRate}x
    </div>
  );
};

const SeekBar: React.FC = () => {
  const { currentAudioTime, currentAudioDuration, seekTo } = useMediaPlayer();

  return (
    <div>
      <IonRange
        min={0}
        max={currentAudioDuration}
        value={currentAudioTime}
        onIonChange={(e) => {
          const newTime = e.detail.value as number;
          seekTo(newTime);
        }}
        className="-mx-2 -mb-3"
        style={{
          "--bar-height": "4px",
          "--bar-border-radius": "3px",
          "--bar-background": "rgba(255, 255, 255, 0.6)",
          "--bar-background-active": "rgba(255, 255, 255, 0.95)",
          "--knob-size": "22px",
          "--knob-background": "rgba(255, 255, 255, 1)",
        }}
      />

      <div className="mx-1.5 flex items-center justify-between text-xs font-medium text-white/75">
        <div>{formatMinutesAndSeconds(currentAudioTime)}</div>
        <div>{formatMinutesAndSeconds(currentAudioDuration)}</div>
      </div>
    </div>
  );
};

const SkipBackwardButton: React.FC = () => {
  const { jumpBackward } = useMediaPlayer();

  return (
    <div className="shrink-0 px-1.5">
      <Icon
        icon={seekBackwardIcon}
        onClick={() => {
          jumpBackward(10);
        }}
        className="cursor-pointer text-[24px]"
      />
    </div>
  );
};

const SkipForwardButton: React.FC = () => {
  const { jumpForward } = useMediaPlayer();

  return (
    <div className="shrink-0 px-1.5">
      <Icon
        icon={seekForwardIcon}
        onClick={() => {
          jumpForward(10);
        }}
        className="cursor-pointer text-[24px]"
      />
    </div>
  );
};

const TogglePlayPauseButton: React.FC = () => {
  const { isPlaying, togglePlayPause } = useMediaPlayer();

  return (
    <IonIcon
      icon={isPlaying ? pauseIcon : playIcon}
      onClick={() => togglePlayPause()}
      className="shrink-0 cursor-pointer px-1 text-[54px]"
    />
  );
};

export {
  NavigateBackwardButton,
  NavigateForwardButton,
  PlaybackRateControl,
  SeekBar,
  SkipBackwardButton,
  SkipForwardButton,
  TogglePlayPauseButton,
};
