import { useEffect, useState } from 'react';
import { ApolloClient, from, HttpLink, InMemoryCache, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { some } from 'lodash-es';
import { GraphQLError } from 'graphql/error';

import { getItemFromStorage } from '../helpers/storage-helpers';
import authMiddleware from '../middlewares/auth.middleware';
import languageMiddleware from '../middlewares/language.middleware';
import useAppState from './useAppState';
import useAuthStore from '../stores/useAuthStore';
import appVersionMiddleware from '../middlewares/app-version.middleware';
import timezoneMiddleware from '../middlewares/timezone.middleware';
import useGroupSharingStore from '../stores/useGroupSharingStore';
import useStoryExplorationStore from '../stores/useStoryExplorationStore';

const useBackend = () => {
  const { isAppActive } = useAppState();
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const setMe = useAuthStore(state => state.setMe);
  const setMyGroupSharing = useGroupSharingStore(state => state.setMyGroupSharing);
  const setIsExploreModeEnabled = useStoryExplorationStore((state) => state.setIsExploreModeEnabled);

  const [apolloClient, setApolloClient] = useState<any>();

  const link = () => {
    const httpLink = new HttpLink({
      uri: `${process.env.REACT_APP_BACKEND_API_URL!}/graphql`,
    });

    const wsLink = new GraphQLWsLink(createClient({
      url: `${process.env.REACT_APP_WS_BACKEND_API_URL!}/graphql`,
      retryAttempts: 15,
      retryWait: async (retries) => {
        await new Promise((resolve) =>
          // maximum interval between retries is 50 sec because backend sends the subscription 1 minute after starting
          setTimeout(resolve, retries < 5 ? retries * 5000 : 50000),
        );
      },
      shouldRetry: () => true,
      connectionParams: async () => {
        const me = await getItemFromStorage('me');
        const accessToken = me?.token;

        return {
          ...accessToken ? { authorization: `Bearer ${accessToken}` } : {},
        };
      },
    }));

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        if (some(graphQLErrors, (graphQLError: GraphQLError & { statusCode?: number }) => graphQLError.statusCode === 401)) {
          setMe(null);
          setMyGroupSharing(null);
          setIsExploreModeEnabled(false);
        }
      }
    });

    const link = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    );

    return from([authMiddleware, languageMiddleware, appVersionMiddleware, timezoneMiddleware, errorLink, link]);
  };

  useEffect(() => {
    setApolloClient(new ApolloClient({
      link: link(),
      cache: new InMemoryCache(),
    }));
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    // recreate link if user authentication status or app status changes
    apolloClient?.setLink(link());
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAppActive, isAuthenticated],
  );

  return { apolloClient };
};

export default useBackend;
