import { useEffect, useState } from "react";
import { find } from 'lodash-es';

import { useLocale } from "../contexts/LocaleContext";
import {
  useTourByTourIdQuery,
  useTourByTourSlugQuery,
} from "../graphql/dato/__generated__/dato-graphql.generated";
import { Story, StorySlide, Tour, TourStop } from "../interfaces/Interfaces";
import { getItemFromStorage, getStorageKeys } from '../helpers/storage-helpers';
import useDownload from './useDownload';

const useTour = ({
  tourId,
  tourSlug,
  pause = false,
}: {
  tourId?: string | null;
  tourSlug?: string | null;
  pause?: boolean;
}) => {
  const { queryLocale } = useLocale();
  const { transformTour } = useDownload();

  const [tour, setTour] = useState<Tour | null>(null);
  const [isTourDownloaded, setIsTourDownloaded] = useState<boolean>(true);
  const [downloadedTour, setDownloadedTour] = useState<Tour | null>(null);

  const [tourByTourIdResult] = useTourByTourIdQuery({
    variables: { tourId: tourId!, locale: queryLocale },
    pause: pause || !tourId || isTourDownloaded,
  });

  const [tourByTourSlugResult] = useTourByTourSlugQuery({
    variables: { tourSlug: tourSlug!, locale: queryLocale },
    pause: !tourSlug || isTourDownloaded,
  });

  const { data: tourByTourIdData } = tourByTourIdResult;
  const { data: tourByTourSlugData } = tourByTourSlugResult;

  useEffect(() => {
    const checkIfTourIsDownloaded = async () => {
      if (tourId || tourSlug) {
        // check if the tour is in the capacitor storage
        const storageKeys = await getStorageKeys();
        const tourKey = find(
          storageKeys,
          (storageKey) => storageKey.includes(tourId as string) || storageKey.endsWith(tourSlug as string)
        );
        const tour = tourKey ? await getItemFromStorage(tourKey) : null;

        let transformedTour;
        if (tour) {
          const transformTourData = await transformTour(tour, 'transformRelativePathToSrc');
          transformedTour = transformTourData.transformedTour;
        }

        // use tour from the capacitor storage if it is downloaded by the user before
        // perform request by setting isTourDownloaded to false if the tour isn't downloaded by the user
        transformedTour ? setDownloadedTour(transformedTour) : setIsTourDownloaded(false);
      }
    };

    checkIfTourIsDownloaded();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tourId, tourSlug]
  );

  // Set the tour on the local hook state
  useEffect(() => {
    const tour = prepareTour((tourByTourIdData?.tour || tourByTourSlugData?.tour || downloadedTour) as Tour);
    if (tour) {
      setTour(tour);
    }
  }, [tourByTourIdData, tourByTourSlugData, downloadedTour]);

  return { tour };
};

const prepareTour = (tourToPrepare: Tour) => {
  if (!tourToPrepare) {
    return null;
  }

  const { tourStops, ...tourData } = tourToPrepare;

  const tour = tourData as Tour;

  tour.tourStops = tourStops?.map(({ stories, ...tourStopData }) => {
    const tourStop = {
      ...tourStopData,
      // Set reference to tour
      tour,
    } as TourStop;

    tourStop.stories = stories?.map(({ storySlides, ...storyData }) => {
      const story = {
        ...storyData,
        // Set reference to tour stop
        tourStop,
      } as Story;

      story.storySlides = storySlides?.map((storySlide) => {
        return {
          ...storySlide,
          // Set reference to story
          story,
        } as StorySlide;
      });

      return story;
    });

    return tourStop;
  });

  return tour;
};

export default useTour;
