import { createContext, useContext, useEffect, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { generatePath } from 'react-router';

import OneSignal from 'onesignal-cordova-plugin';

import { User, useSetOneSignalSubscriptionId } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { useLocale } from '../contexts/LocaleContext';
import useAuthStore from '../stores/useAuthStore';

const useValue = () => {
  const router = useIonRouter();
  const [setOneSignalSubscriptionIdMutation] = useSetOneSignalSubscriptionId();
  const { locale } = useLocale();

  const me = useAuthStore(state => state.me);

  const [isOneSignalInitialized, setIsOneSignalInitialized] = useState<boolean>(false);

  useEffect(() => {
    oneSignalInit();
  }, []);

  useEffect(() => {
    if (isOneSignalInitialized) {
      addClickEventListener();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isOneSignalInitialized]
  );

  useEffect(() => {
    if (locale && isOneSignalInitialized) {
      setLanguage(locale);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, isOneSignalInitialized]
  );

  useEffect(() => {
    if (me && isOneSignalInitialized) {
      setOneSignalSubscriptionId(me);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [me, isOneSignalInitialized]
  );

  const oneSignalInit = async () => {
    if (!Capacitor.isNativePlatform() || !process.env.REACT_APP_ONESIGNAL_APP_ID) return;

    OneSignal.initialize(process.env.REACT_APP_ONESIGNAL_APP_ID!);

    setIsOneSignalInitialized(true);
  };

  const addClickEventListener = () => {
    OneSignal.Notifications.addEventListener('click', (event: any) => {
      const createdTourByGpt = event?.notification?.additionalData?.createdTourByGpt;
      const navigationPath = event?.notification?.additionalData?.navigationPath;

      switch (true) {
        case !!createdTourByGpt:
          router.push(generatePath('/:locale(de|en|fr|es)/tours/:tourSlug', {
            locale: createdTourByGpt?.tourLanguage,
            tourSlug: createdTourByGpt?.tourSlug,
          }));
          break;
        case !!navigationPath:
          router.push(navigationPath);
          break;
      }
    });
  };

  const setLanguage = (language: string) => {
    OneSignal.User.setLanguage(language);
  };

  const setOneSignalSubscriptionId = async (currentUser?: User | null) => {
    if (!currentUser) return;

    OneSignal.login(currentUser.id);

    const oneSignalSubscriptionId = getOneSignalSubscriptionId();

    if (oneSignalSubscriptionId) {
      try {
        await setOneSignalSubscriptionIdMutation({ variables: { input: { oneSignalSubscriptionId }}});
      } catch (e: any) {
        console.log('SetOneSignalSubscriptionId error: ', e);
      }
    }
  };

  const getOneSignalSubscriptionId = () => {
    return OneSignal?.User?.pushSubscription?.id;
  };

  return {};
};

const OneSignalContext = createContext({} as ReturnType<typeof useValue>);

const OneSignalProvider: React.FC = ({ children }) => {
  return (
    <OneSignalContext.Provider value={useValue()}>
      {children}
    </OneSignalContext.Provider>
  );
};

const useOneSignal = () => {
  return useContext(OneSignalContext);
};

export { OneSignalProvider, useOneSignal };
