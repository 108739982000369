import { IonList } from '@ionic/react';
import { find, isEqual } from 'lodash-es';
import { DeepPartial } from 'react-hook-form';
import { useMemo } from 'react';

import { SightseeingSpot, Tour, TourStop } from '../interfaces/Interfaces';
import { UserTour, UserTourAudioStatistics } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import TourStopListItem from './TourStopListItem';
import { checkQuizzesCompletionOfPreviousTourStops } from '../helpers/tour-stop-helpers';
import { getPreviousTourStop, isAccessibleTour } from '../helpers/tour-helpers';
import { useTransaction } from '../contexts/TransactionContext';

const TourStopList: React.FC<{
  tour: Tour;
  userTour?: DeepPartial<UserTour> | null;
  userTourAudioStatistics?: UserTourAudioStatistics | null;
  sightseeingSpots?: SightseeingSpot[];
}> = ({ tour, userTourAudioStatistics, userTour, sightseeingSpots }) => {
  const { hasPremiumAccess, accessibleTourIds } = useTransaction();

  const tourStopsWithoutPreviewOne = tour.tourStops?.filter((tourStop) => !tourStop.isPreviewTourStop);

  const tourStopListItemsData = useMemo(() => tourStopsWithoutPreviewOne?.reduce((
    acc: { tourStopListItems: any, isUnblockingByQuizzesAvailable: boolean },
    tourStop: TourStop
  ) => {
    const userTourStopAudioStatistics = find(
      userTourAudioStatistics?.userTourStopsAudioStatistics,
      ['datoTourStopId', tourStop.id],
    );

    // get audio statistics of previous tour stop
    const previousTourStop = getPreviousTourStop(tourStop);
    const previousUserTourStopAudioStatistics = find(
      userTourAudioStatistics?.userTourStopsAudioStatistics,
      ['datoTourStopId', previousTourStop?.id],
    );

    // disable tour stop if some of the previous ones have uncompleted quiz
    const isDisabledByQuizzes = checkQuizzesCompletionOfPreviousTourStops(tourStop, userTour);
    // unblocking is available if it is the first disabled tour stop and the previous one is fully heard
    const isUnblockingByQuizzesAvailable =
      !acc.isUnblockingByQuizzesAvailable &&
      isDisabledByQuizzes &&
      (previousUserTourStopAudioStatistics?.audioProgress === 1);

    const sightseeingSpot = find(sightseeingSpots, (sightseeingSpot) =>
      isEqual(sightseeingSpot?.location, tourStop?.location)
    );

    const tourStopListItem = <TourStopListItem
      tourStop={tourStop}
      sightseeingSpot={sightseeingSpot}
      userTourStopAudioStatistics={userTourStopAudioStatistics}
      previousTourStop={previousTourStop}
      isDisabledByQuizzes={isDisabledByQuizzes}
      isDisabledByPremiumAccess={!hasPremiumAccess && !isAccessibleTour(tour, accessibleTourIds)}
      isUnblockingByQuizzesAvailable={isUnblockingByQuizzesAvailable}
      key={`tour-stop-${tourStop.id}`}
    />;

    return {
      tourStopListItems: [...acc.tourStopListItems, tourStopListItem],
      isUnblockingByQuizzesAvailable: acc.isUnblockingByQuizzesAvailable || isUnblockingByQuizzesAvailable,
    }
  }, {
    tourStopListItems: [],
    isUnblockingByQuizzesAvailable: false,
  }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userTourAudioStatistics, userTour, accessibleTourIds]
  );

  return (
    <IonList>{tourStopListItemsData?.tourStopListItems}</IonList>
  );
};

export default TourStopList;
