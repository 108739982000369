import {
  ImageComparerRecord,
  ImageRecord,
  VideoRecord,
} from "../../graphql/dato/__generated__/dato-graphql.generated";
import ImageElement from "./ImageElement";
import ImageComparer from "./ImageComparer";
import ExternalVideoPlayer from "./ExternalVideoPlayer";

const BlockRenderer: React.FC<{ record: any }> = ({ record }) => {
  switch (record.__typename) {
    case "ImageRecord":
      return <ImageElement imageRecord={record as ImageRecord} />;
    case "ImageComparerRecord":
      return (
        <ImageComparer imageComparerRecord={record as ImageComparerRecord} />
      );
    case "VideoRecord":
      return <ExternalVideoPlayer videoRecord={record as VideoRecord} className="max-h-[320px]" />;
    default:
      return null;
  }
};

export default BlockRenderer;
