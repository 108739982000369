import { memo } from 'react';

import { find, get, head } from 'lodash-es';
import { Marker as MapMarker } from 'react-map-gl';
import { Image } from 'react-datocms';

import { SightseeingSpot, Story } from '../../interfaces/Interfaces';
import { LatLonField } from '../../graphql/dato/__generated__/dato-graphql.generated';
import PlaceSearchImage from '../media/PlaceSearchImage';

const SightseeingSpotMarkers: React.FC<{
  clusters: Story[][];
  sightSeeingSpots: SightseeingSpot[];
  setCluster: (cluster: Story[]) => void
  setSightseeingSpot: (sightSeeingSpot: SightseeingSpot) => void
  zoom: number;
}> = ({ clusters, sightSeeingSpots, setCluster, setSightseeingSpot, zoom }) => {
  const markerSize = Math.pow(zoom, 2) / (zoom >= 13 ? 4 : 5);

  return (
    <>
      {sightSeeingSpots?.map((sightSeeingSpot) => {
        // not display sightseeing spot marker on the map is it has the value false for isVisibleOnMap.
        // In this case cluster marker is displayed
        if (!sightSeeingSpot?.isVisibleOnMap) return null;

        const cluster = find(clusters, (cluster) =>
          sightSeeingSpot?.location?.latitude === get(head(cluster), 'location.latitude') &&
          sightSeeingSpot?.location?.longitude === get(head(cluster), 'location.longitude')
        );
        const sightSeeingSpotPreviewImage = get(head(sightSeeingSpot?.preview), 'roundPreviewImage.responsiveImage');

        return <MapMarker
          key={sightSeeingSpot?.id}
          latitude={sightSeeingSpot?.location?.latitude}
          longitude={sightSeeingSpot?.location?.longitude}
          onClick={() => cluster ? setCluster(cluster) : setSightseeingSpot(sightSeeingSpot) }
          style={{ zIndex: 2 }}
        >
          <div className="relative rounded-full bg-[#e38873] text-center border-2 border-white" style={{
            width: `${markerSize > 50 ? 50 : markerSize}px`,
            height: `${markerSize > 50 ? 50 : markerSize}px`
          }}>
            {!!sightSeeingSpotPreviewImage ?
              <Image
                data={sightSeeingSpotPreviewImage}
                className="rounded-full shadow"
                pictureClassName="rounded-full w-full h-full"
              /> :
              <PlaceSearchImage
                searchText={sightSeeingSpot?.name as string}
                location={sightSeeingSpot?.location as LatLonField}
                maxWidth={100}
                className="absolute rounded-full"
              />
            }
            {!!cluster?.length && <div
              className="absolute right-[-3px] top-[-3px] rounded-full bg-primary text-center text-white font-semibold"
              style={{
                width: `${zoom}px`,
                height: `${zoom}px`,
                lineHeight: `${zoom}px`,
                fontSize: `${zoom / 2}px`,
              }}
            >{cluster?.length}</div>}
            {zoom >= 13 && <div
              className="absolute left-1/2 bottom-[-25px] -translate-x-1/2 text-[0.5rem] rounded-md bg-primary text-white whitespace-nowrap px-2"
            >{sightSeeingSpot.name}</div>}
          </div>
        </MapMarker>
      })}
    </>
  );
};

export default memo(SightseeingSpotMarkers);
