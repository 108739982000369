import { memo } from "react";
import { StorySlide } from "../../interfaces/Interfaces";
import Slider from "./Slider";
import BlockRenderer from "../players/BlockRenderer";
import PanoramaViewer from "../players/PanoramaViewer";
import { LatLonField } from '../../graphql/dato/__generated__/dato-graphql.generated';
import PlaceSearchImage from '../media/PlaceSearchImage';

const renderPanoramaSlide = (storySlide: StorySlide) => {
  if (!storySlide.panoramaImage?.length) {
    return null;
  }

  return (
    <div
      key="media-slider-story-slide-panorama-image"
      className="absolute z-0 h-full w-full"
    >
      <PanoramaViewer storySlide={storySlide} />
    </div>
  );
};

const renderMediaGallerySlides = (storySlide: StorySlide) => {
  const { mediaGallery } = storySlide;

  if (!mediaGallery) {
    return [];
  }

  const mediaGallerySlides = mediaGallery.map((record, index) => {
    return (
      <div
        key={`media-slider-story-slide-media-gallery-${index}`}
        className="px-4"
      >
        <BlockRenderer record={record} />
      </div>
    );
  });

  return mediaGallerySlides;
};

const MediaSlider: React.FC<{ storySlide: StorySlide }> = memo(
  ({ storySlide }) => {
    const panoramaSlide = renderPanoramaSlide(storySlide);
    const mediaGallerySlides = renderMediaGallerySlides(storySlide);

    // If a panorama image is present, it's rendered full screen as a background without any slider wrapper
    if (panoramaSlide) {
      return panoramaSlide;
    }

    const slides = [panoramaSlide, ...mediaGallerySlides].filter(Boolean);

    if (!slides.length) {
      if (storySlide?.story?.placeName) {
        return <div className="relative mx-10 aspect-[3/2]">
          <PlaceSearchImage
            searchText={storySlide?.story?.placeName as string}
            location={storySlide?.story?.location as LatLonField}
            maxWidth={576}
            className="absolute"
          />
        </div>
      }

      return null;
    }

    return (
      <Slider
        key={`story-slide-slider-${storySlide.id}`}
        sliderProps={{
          autoHeight: true,
          // Don't swipe on photosphere viewer and image comparer
          noSwipingSelector: ".psv-container, sl-image-comparer",
          slidesPerView: 1,
          className: "w-full px-1",
          spaceBetween: 12,
          style: {
            "--swiper-theme-color": "#fff",
            "--swiper-pagination-bullet-inactive-color": "#fff",
          } as any,
        }}
        slideProps={{
          className: "cursor-grab active:cursor-grabbing",
        }}
      >
        {slides as any}
      </Slider>
    );
  }
);

export default MediaSlider;
