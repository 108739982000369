import { memo } from 'react';
import {
  IonButton,
  IonButtons,
  IonCard,
  IonContent,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar
} from '@ionic/react';
import { checkmarkCircle, close, heart, playCircle } from 'ionicons/icons';
import { Image } from 'react-datocms';
import { useTranslation } from 'react-i18next';

import { Story } from '../../interfaces/Interfaces';
import CategoryTags from '../category-tags/CategoryTags';
import ToursRelatedToStories from '../ToursRelatedToStories';
import PlaceSearchImage from '../media/PlaceSearchImage';
import { LatLonField } from '../../graphql/dato/__generated__/dato-graphql.generated';
import { isPremiumStory } from '../../helpers/story-helpers';
import { useGetStoryLikeStatisticsQuery } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';

const StoryMapStoryDetailModal: React.FC<{
  story: Story;
  isViewed?: boolean;
  navigateToStory: ({ story }: { story: Story }) => void;
  onDismiss: () => void;
}> = ({ story, isViewed, navigateToStory, onDismiss }) => {
  const { t } = useTranslation();
  const { data } = useGetStoryLikeStatisticsQuery({
    variables: {
      input: {
        datoStoryId: story.id
      }
    }
  });
  const storyLikesAmount = data?.storyEvaluation?.getStoryLikeStatistics?.totalLikesAmount;

  const creatorName = story.creatorProfile?.creatorName;
  const creatorProfileImage = (story.creatorProfile as any)?.roundProfileImage?.responsiveImage;

  return (
    <IonPage className="bg-white">
      <IonHeader className="ion-no-border pl-5 pr-2 pt-2">
        <IonToolbar style={{ '--background': '#ffffff' }}>
          <div className="text-[#687582] text-[0.875rem] font-medium">{t('storyDetailModal.header.storyDetails')}</div>

          {!!story?.displayedPlaceName && <div className="text-[#232437] text-[1.25rem] font-bold">{story?.displayedPlaceName}</div>}

          <IonButtons slot="end">
            <IonButton
              className="rounded-full bg-[#F4F4F4] text-[#737373] w-[32px] h-[32px]"
              style={{
                '--padding-start': '5px',
                '--padding-end': '5px',
              }}
              onClick={() => onDismiss()}
            >
              <IonIcon icon={close} className="w-[32px] h-[32px]"/>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="flex flex-col bg-white px-5 pt-2">
          {!!story?.placeName && <IonCard
            className="mx-0 mt-0 mb-7 h-full max-w-[270px] flex rounded-[12px] shadow-[0px_3px_10px_0px_rgba(0,0,0,0.07)]"
          >
            <div className="relative w-full aspect-[3/2]">
              <PlaceSearchImage
                searchText={(story?.placeName) as string}
                location={(story?.location) as LatLonField}
                maxWidth={576}
                className="absolute"
              />
            </div>
          </IonCard>}

          <div className="text-[#232437] text-[1.25rem] font-bold mb-2">
            {story.title}
          </div>

          <div className="flex justify-between items-center mb-4">
            <div>
              <div className="flex items-center mb-3">
                {creatorProfileImage && (
                  <div className="mr-2 h-[20px] w-[20px] shrink-0">
                    <Image
                      data={creatorProfileImage}
                      className="rounded-full shadow"
                      pictureClassName="rounded-full"
                    />
                  </div>
                )}
                <div className="text-[0.8125rem] text-[#535E69] font-medium line-clamp-2">{creatorName}</div>
              </div>

              <div className="flex justify-between items-center">
                <div className="flex items-center gap-4">
                  <div className="flex items-center">
                    <IonIcon icon={heart} slot="end" className="text-[#5A5A5A] w-[13px] h-[13px] mr-0.5"/>
                    <div className="text-[#5A5A5A] text-[0.8125rem] font-semibold">
                      {storyLikesAmount || 0}
                    </div>
                  </div>

                  {isPremiumStory(story) && <span
                    className="text-[#976808] text-[0.875rem] font-bold py-1 px-2 rounded-[5px] bg-[#9768084a]"
                  >{t('storyCard.premium')}</span>}

                  {!isViewed && <div className="flex items-center">
                      <IonIcon icon={checkmarkCircle} className="text-[#8ED283] w-[20px] h-[20px] mr-1"/>
                      <div className="text-[#8ED283] text-[0.8125rem] font-medium">{t('storyCard.played')}</div>
                  </div>}
                </div>
              </div>
            </div>

            <IonIcon
              icon={playCircle}
              className="text-[#E16A4F] w-[65px] h-[65px] mr-1"
              onClick={() => navigateToStory({ story })}
            />
          </div>

          {/* set min-height because the badges are cut off in ios 17 if use line-clamp-2 */}
          <CategoryTags
            categories={story.categories}
            wrapperClassName="line-clamp-2 leading-[18px] mt-0.5 mb-5 min-h-[54px]"
          />

          <ToursRelatedToStories stories={[story]} labelTranslationPath="storyDetailModal.relatedTours.label"/>

          <div style={{ paddingBottom: "calc(var(--ion-safe-area-bottom) + 30px)" }} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default memo(StoryMapStoryDetailModal);
