import { create } from "zustand";

import { User } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { removeItemFromStorage, setItemToStorage } from '../helpers/storage-helpers';

interface AuthStoreState {
  isAuthenticated: boolean | null;
  me: User | null;
  isUserInitialised: boolean;
  setMe: (me: User | null | undefined) => void;
}

const useAuthStore = create<AuthStoreState>((set, get) => ({
  isAuthenticated: null,
  me: null,
  isUserInitialised: false,
  setMe: async (me) => {
    me ?
      await setItemToStorage('me', me) :
      await removeItemFromStorage('me');

    set((state) => ({
      ...state,
      isAuthenticated: !!me,
      me,
      isUserInitialised: true,
    }));
  },
}));

export default useAuthStore;
