import { StorySlide } from "../../interfaces/Interfaces";
import SubtitlesFromSpeechMarks from "./SubtitlesFromSpeechMarks";
import SubtitlesFromTextTrack from "./SubtitlesFromTextTrack";

const Subtitles: React.FC<{ storySlide: StorySlide }> = ({ storySlide }) => {
  let subtitles;
  if (storySlide.speechMarks) {
    subtitles = <SubtitlesFromSpeechMarks storySlide={storySlide} />;
  } else if (storySlide.textTrack?.url) {
    subtitles = <SubtitlesFromTextTrack storySlide={storySlide} />;
  }

  if (subtitles) {
    return <div className="z-10 mb-6 mt-2 w-full px-6">{subtitles}</div>;
  } else {
    return null;
  }
};

export default Subtitles;
