import { IonButton, IonIcon, useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { arrowForwardOutline } from 'ionicons/icons';

import createTourIcon from '../../assets/tours/create-tour.svg';
import useRoutes from '../../hooks/useRoutes';
import useAuthStore from '../../stores/useAuthStore';
import { useLocale } from '../../contexts/LocaleContext';

const CreateTourSection: React.FC = () => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const { currentPath, loginPath, localePath } = useRoutes();
  const { locale } = useLocale();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const navigateToCreateTourPage = () => {
    if (isAuthenticated) {
      router.push(localePath('tours/create', locale), 'forward');
    } else {
      router.push(loginPath(currentPath()));
    }
  };

  return (
    <div className="p-10 text-center bg-[#F3F9FF]">
      <IonIcon src={createTourIcon} className="w-[48px] h-[48px] mb-6"/>
      <div className="text-[1.125rem] text-[#232437] font-semibold mb-4">{t('tours.createTourSection.title')}</div>
      <div className="text-[0.875rem] text-[#687582] mb-6">{t('tours.createTourSection.text')}</div>
      <IonButton
        className="pointer-events-auto normal-case tracking-normal font-semibold mt-4 min-h-[56px]"
        shape="round"
        style={{
          '--background': '#ec765a',
          '--padding-start': '32px',
          '--padding-end': '32px'
        }}
        onClick={() => navigateToCreateTourPage()}
      >
        <IonIcon slot="end" icon={arrowForwardOutline} className="ml-1"/>
        {t('tours.createTourSection.buttons.createTour')}
      </IonButton>
    </div>
  );
};

export default CreateTourSection;
