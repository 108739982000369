import { useEffect, useRef, useState } from 'react';
import { IonButton, IonContent, useIonViewDidEnter } from '@ionic/react';
import Map, { Layer, MapRef, Popup, Source, Marker } from 'react-map-gl';
import { circle } from '@turf/circle';
import { groupBy, head, values } from 'lodash-es';

import AppLayout from '../layouts/AppLayout';
import AppHeader from '../components/AppHeader';
import { useLocale } from '../contexts/LocaleContext';
import { setMapLanguage } from '../helpers/map-helpers';
import { useTourCreationTestDatumByTourIdQuery } from '../graphql/dato/__generated__/dato-graphql.generated';
import useSearchParams from '../hooks/useSearchParams';
import useRoutes from '../hooks/useRoutes';

const TourCreationMapPage: React.FC = () => {
  const { locale } = useLocale();
  const { tourId } = useSearchParams();
  const { localePath } = useRoutes();

  const [tourCreationTestDatum, setTourCreationTestDatum] = useState<any>();
  const [tour, setTour] = useState<any>();
  const routeLineString = tourCreationTestDatum?.geoJson?.features?.find((data: any) => data.geometry.type === 'LineString');

  const [result] = useTourCreationTestDatumByTourIdQuery({
    variables: { tourId }
  });

  const mapRef = useRef<MapRef>(null);
  const initialViewState = {
    latitude: 52.5142,
    longitude: 13.39,
    zoom: 13,
  };
  const [viewState, setViewState] = useState(initialViewState);

  const [displayWaypoints, setDisplayWaypoints] = useState(false);
  const [displayAllReceivedStories, setDisplayAllReceivedStories] = useState(false);
  const [displayAllTourStops, setDisplayAllTourStops] = useState(false);
  const [displayFilteredTourStops, setDisplayFilteredTourStops] = useState(false);
  const [displayFilteredTourStopsWithFilteredStories, setDisplayFilteredTourStopsWithFilteredStories] = useState(true);

  const [selectedStories, setSelectedStories] = useState<typeof tourCreationTestDatum['allReceivedStories']>([]);

  useIonViewDidEnter(() => { resizeMap() });

  useEffect(() => {
    if (result.data?.tourCreationTestDatum) {
      setTourCreationTestDatum(result.data.tourCreationTestDatum.testData);
      setTour(result.data.tourCreationTestDatum.tour);

      setViewState({
        ...initialViewState,
        latitude: result.data.tourCreationTestDatum.testData?.waypoints?.[0]?.geometry?.coordinates[1],
        longitude: result.data.tourCreationTestDatum.testData?.waypoints?.[0]?.geometry?.coordinates[0],
      })
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result.data]
  );

  const resizeMap = () => { mapRef?.current?.resize() };

  return (
    <AppLayout>
      <AppHeader isAbsolutelyPositioned={true}/>

      <IonContent>
        <div className="relative h-full w-full overflow-hidden">
          <div className="absolute z-10 left-1/2 -translate-x-1/2" style={{ top: `calc(var(--ion-safe-area-top, 12px) + 65px)` }}>
            <IonButton color="secondary" target="_blank" routerLink={localePath(`tours/${tour?.slug}`)}>Open tour</IonButton>
            <IonButton color={displayWaypoints ? 'primary' : 'light'} onClick={() => setDisplayWaypoints((value) => !value)}>waypoints</IonButton>
            <IonButton color={displayAllReceivedStories ? 'primary' : 'light'} onClick={() => setDisplayAllReceivedStories((value) => !value)}>all received stories</IonButton>
            <IonButton color={displayAllTourStops ? 'primary' : 'light'} onClick={() => setDisplayAllTourStops((value) => !value)}>all tour stops</IonButton>
            <IonButton color={displayFilteredTourStops ? 'primary' : 'light'} onClick={() => setDisplayFilteredTourStops((value) => !value)}>filtered tour stops</IonButton>
            <IonButton color={displayFilteredTourStopsWithFilteredStories ? 'primary' : 'light'} onClick={() => setDisplayFilteredTourStopsWithFilteredStories((value) => !value)}>filtered tour stops with filtered content</IonButton>
          </div>

          {!!tourCreationTestDatum && <Map
            ref={mapRef}
            {...viewState}
            onMove={(evt) => setViewState(evt.viewState)}
            attributionControl={false}
            reuseMaps={true}
            dragRotate={false}
            style={{
              height: '100%',
              width: '100%',
            }}
            mapStyle="mapbox://styles/thomas-guidable/ckwju2mpv96fa14mplcpx79ld"
            onLoad={(e) => {
              setMapLanguage(e, locale);
            }}
          >
            {/* Route */}
            <Source type="geojson" data={{
              type: 'Feature',
              properties: {},
              geometry: { type: 'LineString', coordinates: routeLineString?.geometry.coordinates }
            }}>
              <Layer type="line" paint={{ 'line-color': '#e38873', 'line-width': 3, 'line-opacity': 0.4, }}/>
            </Source>

            {/* Waypoint markers */}
            {displayWaypoints && tourCreationTestDatum?.waypoints?.map((waypoint: any, i: number) => <Marker
              key={`waypoint-${i}`}
              latitude={waypoint.geometry.coordinates[1]}
              longitude={waypoint.geometry.coordinates[0]}
              style={{ zIndex: 1 }}
            >
              <div className="relative text-[8px] leading-[8px] bg-amber-200 rounded-full p-1">w</div>
            </Marker>)}

            {/* Waypoint circle */}
            {displayWaypoints && tourCreationTestDatum?.waypoints?.map((waypoint: any, i: number) => <Source
              key={`waypoint-circle-${i}`} id={`waypoint-${i}`} type="geojson" data={circle(
              waypoint.geometry.coordinates,
              tourCreationTestDatum?.queryRadiusFromWaypoint || 0,
              { steps: 64, units: 'meters' },
            )}>
              <Layer id={`waypoint-layer-${i}`} type="fill"
                     paint={{ 'fill-color': '#fde68a', 'fill-opacity': 0.3, 'fill-outline-color': '#e38873' }}/>
            </Source>)}

            {/* All stories in waypoints radius markers */}
            {displayAllReceivedStories && values(groupBy(tourCreationTestDatum?.allReceivedStories, (story) =>
              `${story?.attributes?.location?.latitude || story?.location?.latitude} ${story?.attributes?.location?.longitude || story?.location?.longitude}`)
            )?.map((stories: any[], i) => <Marker
              key={stories[0].id}
              latitude={stories?.[0]?.attributes?.location?.latitude || stories?.[0]?.location?.latitude}
              longitude={stories?.[0]?.attributes?.location?.longitude || stories?.[0]?.location?.longitude}
              style={{ zIndex: 2 }}
              onClick={() => setSelectedStories(stories)}
            >
              <div className="relative text-[12px] leading-[12px] bg-amber-400 rounded-full p-1 cursor-pointer">
                s
                {stories?.length > 1 && <div
                  className="absolute -top-[6px] -left-[6px] bg-amber-400 text-[10px] p-[2px] rounded-full"
                >{stories?.length}</div>}
              </div>
            </Marker>)}

            {/* All tour stops */}
            {displayAllTourStops && tourCreationTestDatum?.allTourStops?.map((tourStop: any[]) => <Marker
              key={`route-story-${tourStop[0].id}`}
              latitude={tourStop?.[0]?.attributes?.location?.latitude || tourStop?.[0]?.location?.latitude}
              longitude={tourStop?.[0]?.attributes?.location?.longitude || tourStop?.[0]?.location?.longitude}
              style={{ zIndex: 3 }}
              onClick={() => setSelectedStories(tourStop)}
            >
              <div className="relative text-white text-[12px] leading-[12px] bg-amber-600 rounded-full p-1 cursor-pointer">
                ts
                {tourStop?.length > 1 && <div
                  className="absolute -top-[6px] -left-[6px] bg-amber-600 text-[10px] p-[2px] text-white rounded-full"
                >{tourStop?.length}</div>}
              </div>
            </Marker>)}

            {/* All tour stops */}
            {displayFilteredTourStops && tourCreationTestDatum?.filteredTourStops?.map((tourStop: any[]) => <Marker
              key={`route-story-${tourStop[0].id}`}
              latitude={tourStop?.[0]?.attributes?.location?.latitude || tourStop?.[0]?.location?.latitude}
              longitude={tourStop?.[0]?.attributes?.location?.longitude || tourStop?.[0]?.location?.longitude}
              style={{ zIndex: 4 }}
              onClick={() => setSelectedStories(tourStop)}
            >
              <div className="relative text-white text-[12px] leading-[12px] bg-amber-800 rounded-full p-1 cursor-pointer">
                ts
                {tourStop?.length > 1 && <div
                  className="absolute -top-[6px] -left-[6px] bg-amber-800 text-[10px] p-[2px] text-white rounded-full"
                >{tourStop?.length}</div>}
              </div>
            </Marker>)}

            {/* Filtered tour stops with filtered content */}
            {displayFilteredTourStopsWithFilteredStories && tourCreationTestDatum?.filteredTourStopsWithFilteredStories?.map((tourStop: any[]) =>
              <Marker
                key={`route-story-${tourStop[0].id}`}
                latitude={tourStop?.[0]?.attributes?.location?.latitude || tourStop?.[0]?.location?.latitude}
                longitude={tourStop?.[0]?.attributes?.location?.longitude || tourStop?.[0]?.location?.longitude}
                style={{ zIndex: 5 }}
                onClick={() => setSelectedStories(tourStop)}
              >
                <div
                  className="relative text-white text-[12px] leading-[12px] bg-amber-900 rounded-full p-1 cursor-pointer">
                  ts
                  {tourStop?.length > 1 && <div
                    className="absolute -top-[6px] -right-[6px] bg-amber-900 text-[10px] p-[2px] text-white rounded-full"
                  >{tourStop?.length}</div>}
                </div>
              </Marker>)}

            {/* Selected stories popup */}
            {!!selectedStories?.length && (
              <Popup
                latitude={selectedStories?.[0]?.attributes?.location?.latitude || selectedStories?.[0]?.location?.latitude}
                longitude={selectedStories?.[0]?.attributes?.location?.longitude || selectedStories?.[0]?.location?.longitude}
                closeOnClick={false}
                onClose={() => setSelectedStories([])} // Close the popup when the user clicks the close button
                style={{ zIndex: 100 }}
              >
                <div>
                  {selectedStories?.map((story: any) => <div key={story.id} className="bg-blue-400 mb-1">
                    {story.id} - {head(values(story?.attributes?.title || story?.title))} <br/>
                    Is premium - {!!(story?.attributes?.is_premium || story?.is_premium) ? 'yes' : 'no'} <br/>
                    Categories - {(story?.attributes?.categories || story?.categories)?.join(', ')} <br/>
                    Exp. levels - {(story?.attributes?.experience_levels || story?.experience_levels)?.join(', ')}
                  </div>)}
                </div>
              </Popup>
            )}
          </Map>}
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default TourCreationMapPage;
