import { useEffect, useState } from "react";
import {
  useStoryByStoryIdQuery,
  StoryByStoryIdQuery,
} from "../graphql/dato/__generated__/dato-graphql.generated";
import { Story, StorySlide } from "../interfaces/Interfaces";
import { useLocale } from '../contexts/LocaleContext';

const useStory = ({
  storyId,
  pause = false,
}: {
  storyId: string | null;
  pause?: boolean;
}) => {
  const { queryLocale } = useLocale();
  const [story, setStory] = useState<Story | null>(null);

  const [result] = useStoryByStoryIdQuery({
    variables: { storyId, locale: queryLocale },
    pause: pause || !storyId,
  });

  const { data } = result;

  // Set the story on the local hook state
  useEffect(() => {
    const story = prepareStory(data);
    if (story) {
      setStory(story);
    }
  }, [data]);

  return { story };
};

const prepareStory = (data: StoryByStoryIdQuery | undefined) => {
  if (!data?.story) {
    return null;
  }

  const { storySlides, ...storyData } = data.story;

  const story = storyData as Story;

  story.storySlides = storySlides.map((storySlideData) => {
    const storySlide = {
      ...storySlideData,
      // Set reference to story
      story,
    } as StorySlide;

    return storySlide;
  });

  return story;
};

export default useStory;
