import { IonContent, useIonViewDidEnter } from "@ionic/react";
import { Image, StructuredText } from "react-datocms";
import { useTranslation } from "react-i18next";
import { render as renderStructuredTextToPlainText } from "datocms-structured-text-to-plain-text";
import { useRouteMatch } from "react-router";
import { isEmpty, map } from 'lodash-es';

import {
  useCreatorProfileQuery,
  useStoriesForCreatorProfileQuery,
  useToursForCreatorProfileQuery,
} from "../graphql/dato/__generated__/dato-graphql.generated";
import { useLocale } from "../contexts/LocaleContext";
import FloatingBackButton from "../components/buttons/FloatingBackButton";
import AppLayout from "../layouts/AppLayout";
import { Story, Tour, Category } from "../interfaces/Interfaces";
import CategoryTags from "../components/category-tags/CategoryTags";
import TourCardSlider from "../components/sliders/TourCardSlider";
import StoryCardSlider from "../components/sliders/StoryCardSlider";
import BlockRenderer from "../components/players/BlockRenderer";
import SegmentHeader from "../components/SegmentHeader";
import { MixpanelEvents, useMixpanel } from "../contexts/MixpanelContext";
import {
  useGetToursRatingStatisticsQuery,
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useIonVisible from '../hooks/useIonVisible';
import useAuthStore from '../stores/useAuthStore';
import useStoryPlayer from '../hooks/useStoryPlayer';
import useUserToursAudioStatistics from '../hooks/useUserToursAudioStatistics';

const CreatorProfilePage: React.FC = () => {
  const match = useRouteMatch<{ creatorProfileUsername: string }>();
  const { creatorProfileUsername } = match.params;

  const { queryLocale } = useLocale();
  const { t } = useTranslation();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { isVisible } = useIonVisible();
  const { navigateToStory } = useStoryPlayer();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [creatorProfileQueryResult] = useCreatorProfileQuery({
    variables: {
      locale: queryLocale,
      creatorProfileUsername,
    },
  });

  const creatorProfile = creatorProfileQueryResult?.data?.creatorProfile;
  const creatorProfileId = creatorProfile?.id;
  const creatorProfileImage =
    creatorProfile?.roundProfileImage?.responsiveImage;
  const aboutMeText = renderStructuredTextToPlainText(
    creatorProfile?.aboutMe as any
  );
  const audioPreview = creatorProfile?.audioPreview;

  const [creatorStoriesQueryResult] = useStoriesForCreatorProfileQuery({
    variables: {
      locale: queryLocale,
      creatorProfileId,
    },
  });

  const [creatorToursQueryResult] = useToursForCreatorProfileQuery({
    variables: {
      locale: queryLocale,
      creatorProfileId,
    },
  });

  const { data } = useGetToursRatingStatisticsQuery();
  const toursRatingStatistics = data?.tourReview?.getToursRatingStatistics;

  const categories = creatorProfile?.categories as Category[];
  const stories = creatorStoriesQueryResult?.data?.stories as Story[];
  const tours = creatorToursQueryResult?.data?.tours as Tour[];

  const { userToursAudioStatistics } = useUserToursAudioStatistics(map(tours, ({ id }) => id), isAuthenticated, isVisible);

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_CREATOR_PROFILE, {
        creatorProfileId: creatorProfile?.id,
        creatorProfileName: creatorProfile?.creatorName,
        creatorProfileUsername: creatorProfile?.username,
      });
    }
  });

  return (
    <AppLayout>
      <IonContent>
        <div className="relative mx-auto max-w-xl">
          <FloatingBackButton />

          {creatorProfile && (
            <div
              className="px-4 pb-4"
              style={{
                paddingTop: "calc(var(--ion-safe-area-top, 12px) + 48px)",
              }}
            >
              <div className="flex items-center justify-start">
                {creatorProfileImage && (
                  <div className="mr-2 h-[100px] w-[100px] shrink-0">
                    <Image
                      data={creatorProfileImage}
                      className="rounded-full shadow"
                      pictureClassName="rounded-full"
                    />
                  </div>
                )}
                <div>
                  <div className="mb-0.5 pl-1 leading-5">
                    <div className="text-2xl font-bold leading-[1.8rem] text-slate-800">
                      {creatorProfile.creatorName}
                    </div>
                    {/* <div className="text-sm font-medium text-primary">
                      @{creatorProfile.username}
                    </div> */}
                    <div className="text-base text-slate-500">
                      {creatorProfile.slogan}
                    </div>
                  </div>

                  {/* <IonButton size="small">
                    {t("creatorProfile.follow")}
                  </IonButton> */}
                </div>
              </div>

              <div className="mt-4 space-y-5">
                {aboutMeText && (
                  <div className="prose prose-slate prose-p:mt-0 prose-p:mb-1 prose-p:leading-6">
                    <SegmentHeader
                      title={t("creatorProfile.aboutMe")}
                      className="mb-0"
                    />
                    <StructuredText
                      data={creatorProfile.aboutMe as any}
                      renderBlock={BlockRenderer}
                    />
                  </div>
                )}

                {categories && !isEmpty(categories) && (
                  <div>
                    <SegmentHeader title={t("creatorProfile.myCategories")} />
                    <CategoryTags
                      categories={creatorProfile.categories as Category[]}
                      wrapperClassName="gap-1.5"
                    />
                  </div>
                )}

                {audioPreview && (
                  <div>
                    <SegmentHeader title={t("creatorProfile.audioPreview")} />
                    <audio controls preload="metadata">
                      <source
                        src={audioPreview.url}
                        type={audioPreview.mimeType}
                      />
                    </audio>
                  </div>
                )}

                {stories && !isEmpty(stories) && (
                  <div>
                    <SegmentHeader title={t("creatorProfile.myStories")} />
                    <div className="-mx-1">
                      <StoryCardSlider
                        stories={stories}
                        isAllStoriesReceived={!!stories?.length}
                        navigateToStory={navigateToStory}
                        sliderProps={{
                          // Enable virtual slides
                          virtual: true,

                          // Default config (when window width is < 320px)
                          slidesPerView: 1.5,
                          spaceBetween: 6,

                          // Responsive breakpoints
                          breakpoints: {
                            480: {
                              slidesPerView: 2,
                              spaceBetween: 6,
                            }
                          },
                        }}
                      />
                    </div>
                  </div>
                )}

                {tours && !isEmpty(tours) && (
                  <div>
                    <SegmentHeader title={t("creatorProfile.myTours")} />
                    <div className="-mx-1">
                      <TourCardSlider
                        tours={tours}
                        toursRatingStatistics={toursRatingStatistics}
                        userToursAudioStatistics={userToursAudioStatistics}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default CreatorProfilePage;
