import { IonButton, IonContent, IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';

const OnboardingHint: React.FC<{
  onDismiss: () => void;
}> = ({ onDismiss, children }) => {
  return (
    <IonContent>
      <div className="bg-[#3797DA]">
        <IonButton
          className="absolute top-[-5px] right-[-5px] rounded-full text-white w-[28px] h-[28px]"
          fill="clear"
          style={{
            '--padding-start': '5px',
            '--padding-end': '5px',
          }}
          onClick={() => onDismiss()}
        >
          <IonIcon icon={close}/>
        </IonButton>

        {children}
      </div>
    </IonContent>
  );
};

export default OnboardingHint;
