const ImageCaption: React.FC<{ caption: string | null | undefined }> = ({
  caption,
}) => {
  if (!caption) {
    return null;
  }

  return (
    <div className="mx-2 mt-2 mb-1 text-center text-[0.8rem] font-semibold italic leading-[1rem] text-slate-50">
      {caption}
    </div>
  );
};

export default ImageCaption;
