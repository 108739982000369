import { IonCard, IonCardHeader } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Image, ResponsiveImageType } from 'react-datocms';
import { useCallback, useEffect, useState } from 'react';

import SectionHeader from './SectionHeader';
import { City, TourCollection } from '../../../interfaces/Interfaces';
import Slider from '../../../components/sliders/Slider';
import { useTourCollectionsByCityIdQuery } from '../../../graphql/dato/__generated__/dato-graphql.generated';
import useRoutes from '../../../hooks/useRoutes';
import './TourCollectionsSection.scss';
import { useLocale } from '../../../contexts/LocaleContext';

const sliderProps = {
  // Default config (when window width is < 320px)
  slidesPerView: 1,
  spaceBetween: 6,

  // Responsive breakpoints
  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: 1.3,
      spaceBetween: 6,
    },
    // when window width is >= 480px
    480: {
      slidesPerView: 1.7,
      spaceBetween: 6,
    },
    // when window width is >= 640px
    640: {
      slidesPerView: 2.4,
      spaceBetween: 6,
    },
  },

  // Round lengths of slides to whole numbers
  // since there's text on most images
  roundLengths: true,
};

const TourCollectionsSection: React.FC<{ city: City; isVisible: boolean }> = ({ city, isVisible }) => {
  const { queryLocale } = useLocale();
  const { t } = useTranslation();
  const { localePath } = useRoutes();

  const [tourCollections, setTourCollections] = useState<TourCollection[]>([]);

  const queryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      cityId: city?.id,
    };
  }, [queryLocale, city?.id]);

  const [result] = useTourCollectionsByCityIdQuery({
    variables: queryVariables(),
    pause: !isVisible,
  });

  const { data } = result;

  useEffect(() => {
      if (data?.tourCollections) {
        setTourCollections(data?.tourCollections as TourCollection[])
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  if (!tourCollections?.length) return null;

  return (
    <div className="py-5">
      <SectionHeader title={`${t('home.tourCollections.title')} ${city?.name}:`} className="px-4 mt-4 mb-4" />
      <div className="mx-3 mt-3">
        <Slider sliderProps={sliderProps}>
          {tourCollections?.map((tourCollection) => (
            <IonCard
              key={tourCollection.id}
              className="m-1 my-0 h-full flex rounded-[12px] shadow-[0px_3px_10px_0px_rgba(0,0,0,0.07)]"
              routerDirection="forward"
              routerLink={localePath(`tour-collections/${tourCollection.id}`)}
            >
              <div className="relative w-full aspect-[2/1]">
                {tourCollection?.preview?.[0]?.image?.responsiveImage && <Image
                  data={tourCollection?.preview?.[0]?.image?.responsiveImage as ResponsiveImageType}
                  layout="fill"
                  objectFit="cover"
                />}
              </div>
              <IonCardHeader className="text-white bg-white h-full px-4 pb-4 pt-3.5">
                <p className="text-[1.25rem] text-[#232437] font-bold leading-[1.5rem] pb-2">{tourCollection?.name}</p>
                <p className="text-[0.875rem] text-[#535E69]">{tourCollection?.shortDescription}</p>
              </IonCardHeader>
            </IonCard>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default TourCollectionsSection;
