import { Capacitor } from '@capacitor/core';
import * as SentryCapacitor from '@sentry/capacitor';
import * as SentryReact from '@sentry/react';
import { omit, toLower } from 'lodash-es';

import { SignUpForm } from '../pages/SignUpPage';
import { User } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { LoginForm } from '../pages/LoginPage';

const checkReturnedRegistrationData = (
  sentUserData: Omit<SignUpForm, 'password'> & Omit<LoginForm, 'password'>,
  returnedUserData: User,
): void => {
  if (
    toLower(returnedUserData.email) !== toLower(sentUserData.email) ||
    (!!sentUserData?.profile?.firstName && returnedUserData.profile?.firstName !== sentUserData.profile.firstName) ||
    (!!sentUserData?.profile?.lastName && returnedUserData.profile?.lastName !== sentUserData.profile.lastName)
  ) {
    const wrongDataType = toLower(returnedUserData.email) !== toLower(sentUserData.email) ? 'email' : 'profile'

    if (Capacitor.isNativePlatform()) {
      SentryCapacitor.withScope(scope => {
        scope.setLevel('error');
        scope.setExtra('sentUserData', sentUserData);
        scope.setExtra('returnedUserData', omit(returnedUserData, 'token'));
        SentryCapacitor.captureMessage(`Wrong registration data returned - ${wrongDataType}`);
      });
    } else {
      SentryReact.withScope(scope => {
        scope.setLevel('error');
        scope.setExtra('sentUserData', sentUserData);
        scope.setExtra('returnedUserData', omit(returnedUserData, 'token'));
        SentryReact.captureMessage(`Wrong registration data returned - ${wrongDataType}`);
      });
    }
  }
};

export { checkReturnedRegistrationData };
