import { memo, useEffect, useState } from 'react';
import { Layer, Marker as MapMarker, Source } from 'react-map-gl';
import { FeatureCollection, LineString } from 'geojson';
import { filter, head, last } from 'lodash-es';
import { IonIcon } from '@ionic/react';

import { Tour } from '../../interfaces/Interfaces';

const getMarker = (color: string) => {
  return `
    data:image/svg+xml;utf8,<svg width="36" height="40" viewBox="0 0 36 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_5834_23077)">
    <path d="M29.5 17.2323C29.5 22.1874 26.6203 26.0041 23.6689 28.6127C22.1979 29.9128 20.7247 30.8983 19.6187 31.5587C19.0662 31.8886 18.6066 32.1366 18.2866 32.3014C18.1827 32.3549 18.0935 32.3997 18.0207 32.4356C17.9457 32.3917 17.8526 32.3365 17.7433 32.2703C17.4216 32.0756 16.9602 31.7862 16.4056 31.4101C15.2955 30.6574 13.8172 29.5607 12.341 28.1842C9.36813 25.4121 6.5 21.5955 6.5 17.2323C6.5 11.1617 11.6688 5.5 18 5.5C24.3312 5.5 29.5 11.1617 29.5 17.2323Z" fill="${color}" stroke="white"/>
    <path d="M22.6667 20.2831V14.9813C22.6667 13.5489 21.4733 12.3887 20 12.3887C18.5267 12.3887 17.3333 13.5489 17.3333 14.9813V21.4627C17.3333 22.1757 16.7333 22.759 16 22.759C15.2667 22.759 14.6667 22.1757 14.6667 21.4627V16.1609C15.44 15.8887 16 15.1757 16 14.3331C16 13.2572 15.1067 12.3887 14 12.3887C12.8933 12.3887 12 13.2572 12 14.3331C12 15.1757 12.56 15.8887 13.3333 16.1609V21.4627C13.3333 22.8952 14.5267 24.0553 16 24.0553C17.4733 24.0553 18.6667 22.8952 18.6667 21.4627V14.9813C18.6667 14.2683 19.2667 13.685 20 13.685C20.7333 13.685 21.3333 14.2683 21.3333 14.9813V20.2831C20.56 20.5489 20 21.2618 20 22.1109C20 23.1868 20.8933 24.0553 22 24.0553C23.1067 24.0553 24 23.1868 24 22.1109C24 21.2683 23.44 20.5553 22.6667 20.2831ZM14 14.9813C13.6333 14.9813 13.3333 14.6896 13.3333 14.3331C13.3333 13.9766 13.6333 13.685 14 13.685C14.3667 13.685 14.6667 13.9766 14.6667 14.3331C14.6667 14.6896 14.3667 14.9813 14 14.9813ZM22 22.759C21.6333 22.759 21.3333 22.4674 21.3333 22.1109C21.3333 21.7544 21.6333 21.4627 22 21.4627C22.3667 21.4627 22.6667 21.7544 22.6667 22.1109C22.6667 22.4674 22.3667 22.759 22 22.759Z" fill="white"/>
    </g>
    <defs>
    <filter id="filter0_d_5834_23077" x="0" y="0" width="36" height="40" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="1"/>
    <feGaussianBlur stdDeviation="3"/>
    <feComposite in2="hardAlpha" operator="out"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5834_23077"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5834_23077" result="shape"/>
    </filter>
    </defs>
    </svg>
  `;
};

const getRandomColor = () => {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const TourRoute: React.FC<{
  tour: Tour,
  lineColor?: string;
  isActive?: boolean;
  isStartStopMarkersVisible?: boolean;
}> = ({ tour, lineColor, isActive, isStartStopMarkersVisible }) => {
  const route = tour.routeGeoJson ? tour.routeGeoJson as FeatureCollection<LineString> : null;
  const routeLineString = route ? route.features.filter(data => data.geometry.type === 'LineString')[0] : null;

  const [color, setColor] = useState(lineColor);

  useEffect(() => {
    if (!lineColor) setColor(getRandomColor());
  }, [lineColor]);

  const firstTourStop = head(filter(tour?.tourStops, (tourStop) => !tourStop.isIntroTourStop));
  const lastTourStop = last(filter(tour?.tourStops, (tourStop) => !tourStop.isOutroTourStop));

  if (!routeLineString || !color) return null;

  return (
    <>
      {isActive && isStartStopMarkersVisible && <>
        <MapMarker
          key={tour?.id + firstTourStop?.id}
          latitude={firstTourStop?.location?.latitude}
          longitude={firstTourStop?.location?.longitude}
          style={{ zIndex: 2 }}
        >
          <IonIcon className="text-[3rem]" src={getMarker(color)}/>
        </MapMarker>

        <MapMarker
          key={tour?.id + lastTourStop?.id}
          latitude={lastTourStop?.location?.latitude}
          longitude={lastTourStop?.location?.longitude}
          style={{ zIndex: 2 }}
        >
          <IonIcon className="text-[3rem]" src={getMarker(color)}/>
        </MapMarker>
      </>}

      {/* Active route */}
      {isActive && (<Source
        type="geojson"
        data={{
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: routeLineString.geometry.coordinates
          }
        }}
      >
      {[-1, 0, 1, 0].map((x, i) => <Layer
        key={tour.id + '-line-part-' + i}
        type="line"
        layout={{
          'line-cap': i === 3 ? 'butt' : 'square'
        }}
        paint={{
          'line-color': i === 3 ? (color || '#e38873') : '#ffffff',
          'line-width': i === 3 ? 5 : 5,
          'line-offset': x * 5
        }}
      />)}
      </Source>)}

      {/* Not active route */}
      {!isActive && (
        <Source
          type="geojson"
          data={{
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: routeLineString.geometry.coordinates
            }
          }}
        >
          {/* Layer displayed on the map */}
          <Layer
            type="line"
            paint={{
              'line-color': color || '#e38873',
              'line-width': 3,
              'line-opacity': 0.4,
            }}
          />
          {/* Layer needed to grow the clickable area */}
          <Layer
            id={tour.id}
            type="line"
            paint={{
              'line-color': '#ffffff',
              'line-width': 10,
              'line-opacity': 0.01,
            }}
          />
        </Source>
      )}
    </>
  );
};

export default memo(TourRoute);
