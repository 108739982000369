// values from the "properties" field get used for the mapbox search API.
// values "text" and "place_name" get used for the mapbox places API.
const getPlaceNameOrAddress = (suggestion: any) => {
  return suggestion?.properties?.name || suggestion?.text ||
    suggestion?.properties?.full_address || suggestion?.place_name ||
    suggestion?.properties?.place_formatted ||
    suggestion?.properties?.address;
};

export { getPlaceNameOrAddress };
