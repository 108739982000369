import {
  IonButton,
  IonContent,
  IonPage,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Device } from '@capacitor/device';

import coverImage from '../../assets/update-app-modal/cover.png';

const UpdateAppModal: React.FC = () => {
  const { t } = useTranslation();

  const [appStoreUrl, setAppStoreUrl] = useState<string>();

  useEffect(() => {
    const getAppStoreUrl = async () => {
      const deviceInfo = await Device.getInfo();
      const devicePlatform = deviceInfo.platform;

      setAppStoreUrl(devicePlatform === 'ios' ?
        'https://apps.apple.com/app/guidable/id1615027421':
        'https://play.google.com/store/apps/details?id=de.guidable.app'
      );
    };

    getAppStoreUrl();
  }, []);

  return (
    <IonPage>
      <IonContent className="ion-padding">
        <img src={coverImage} className="mt-4 mb-10" alt=""/>

        <div className="text-center flex flex-col justify-center px-10">

          <h3 className="text-[2rem] text-[#232437] font-bold mb-10">{t('updateAppModal.title')}</h3>

          <div
            className="text-[#535E69] mb-16"
            dangerouslySetInnerHTML={{ __html: t(`updateAppModal.text`) as string}}
          />

          <div className="w-full max-w-md mx-auto">
            <IonButton
              className="normal-case font-medium text-[1rem] w-full"
              size="large"
              shape="round"
              href={appStoreUrl}
            >
              {t('updateAppModal.buttons.update')}
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default UpdateAppModal;
