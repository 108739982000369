import { create } from "zustand";
import { differenceBy, uniqBy } from 'lodash-es';

import { Story } from '../interfaces/Interfaces';

interface StoryExplorationStoreState {
  isExploreModeEnabled: boolean;
  // all stories for the explore mode
  storiesToExplore: Story[];
  // stories with which the user has not yet been
  unexploredStories: Story[];
  // stories with which the user has already been
  nearestStoriesToExplore: Story[];

  setIsExploreModeEnabled: (isExploreModeEnabled: boolean) => void;
  setStoriesToExplore: (stories: Story[]) => void;
  setNearestStoriesToExplore: (stories: Story[]) => void;
}

const initialStoryExplorationStore = {
  isExploreModeEnabled: false,
  storiesToExplore: [],
  unexploredStories: [],
  nearestStoriesToExplore: [],
};

const useStoryExplorationStore = create<StoryExplorationStoreState>((set, get) => ({
  ...initialStoryExplorationStore,
  setIsExploreModeEnabled: (isExploreModeEnabled) =>
    set(() => ({
      ...initialStoryExplorationStore,
      isExploreModeEnabled,
    })
  ),
  setStoriesToExplore: (stories) => set((state) => {
    const uniqStories = uniqBy([...state.storiesToExplore, ...stories], (story) => story.id);

    return {
      ...state,
      storiesToExplore: uniqStories,
      unexploredStories: uniqStories,
    }
  }),
  setNearestStoriesToExplore: (stories) => set((state) => {
    const nearestStoriesToExplore = uniqBy([...state.nearestStoriesToExplore, ...stories], (story) => story.id);
    const unexploredStories = differenceBy(state.unexploredStories, nearestStoriesToExplore, 'id');

    return {
      ...state,
      nearestStoriesToExplore,
      unexploredStories,
    }
  }),
}));

export default useStoryExplorationStore;
