import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import { searchSharp } from 'ionicons/icons';

const SelectCitySection: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full p-5 text-center">
      <IonIcon icon={searchSharp} className="w-[45px] h-[45px] text-[#A3A9BC] mb-9"/>

      <div className="text-[0.875rem] text-[#232437] mb-2">
        {t('tours.selectCitySection.title')}
      </div>

      <div className="text-[0.8125rem] text-[#687582] mb-3">
        {t('tours.selectCitySection.message.textPart')}&#160;
        <a href="mailto:hello@guidable.com" className="underline">
          {t('tours.selectCitySection.message.linkPart')}
        </a>
      </div>
    </div>
  );
};

export default SelectCitySection;
