import { useTranslation } from 'react-i18next';
import { IonButton, IonIcon } from '@ionic/react';
import { searchSharp } from 'ionicons/icons';

import { useCity } from '../../contexts/CityContext';

const NoCityDataSection: React.FC = () => {
  const { t } = useTranslation();

  const { currentCity, setPlaceNameData } = useCity();

  return (
    <div className="w-full p-5 text-center">
      <IonIcon icon={searchSharp} className="w-[45px] h-[45px] text-[#A3A9BC] mb-9"/>

      <div className="text-[0.875rem] text-[#232437] mb-2">
        {t('home.noCityDataSection.lookingForCityQuestion')}&#160;
        <span className="font-semibold">{currentCity?.name}?</span>
      </div>

      <div
        className="text-[0.8125rem] text-[#687582] mb-2"
      >{t('home.noCityDataSection.noContentAvailable', { cityName: currentCity?.name })}</div>

      <div className="text-[0.8125rem] text-[#687582] mb-3">
        {t('home.noCityDataSection.sendEmailMessage.textPart')}&#160;
        <a href="mailto:hello@guidable.com" className="underline">
          {t('home.noCityDataSection.sendEmailMessage.linkPart')}
        </a>
      </div>

      <IonButton
        fill="clear"
        className="ext-[0.875rem] text-[#687582] font-semibold normal-case tracking-normal"
        onClick={() => setPlaceNameData({ placeName: '', resetAllData: true })}
      >{t('home.noCityDataSection.buttons.clearSearch')}</IonButton>
    </div>
  );
};

export default NoCityDataSection;
