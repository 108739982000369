import { IonIcon, IonLabel, IonSegment, IonSegmentButton } from '@ionic/react';
import { useTranslation } from "react-i18next";

import './TourPageSegments.scss';
import headphoneIconGray from '../../assets/tour/headphone-gray.svg';
import mapIconGray from '../../assets/tour/map-gray.svg';
import wayIconGray from '../../assets/tour/way-gray.svg';
import headphoneIconWhite from '../../assets/tour/headphone-white.svg';
import mapIconWhite from '../../assets/tour/map-white.svg';
import wayIconWhite from '../../assets/tour/way-white.svg';
import clsx from 'clsx';

export enum TourPageTabs {
  overview = "overview",
  tour = "tour",
  map = "map",
}

export const TourPageSegmentSwitcher: React.FC<{
  tab: TourPageTabs;
  setTab: (tabValue: TourPageTabs) => void;
}> = ({ tab, setTab }) => {
  const { t } = useTranslation();

  return (
    <IonSegment
      mode="md"
      value={tab}
      onIonChange={(e) => {
        const newTabValue = e.detail.value! as TourPageTabs;
        setTab(newTabValue);
      }}
      className="w-full px-4 pt-3"
    >
      <IonSegmentButton value={TourPageTabs.overview} layout="icon-start">
        <IonIcon icon={tab === TourPageTabs.overview ? headphoneIconWhite : headphoneIconGray}></IonIcon>
        <IonLabel
          className={clsx('text-[0.75rem] tracking-normal ml-1', tab === TourPageTabs.overview ? 'font-bold' : '')}
        >{t("tourPage.tourPageTabs.overview")}</IonLabel>
      </IonSegmentButton>
      <IonSegmentButton value={TourPageTabs.tour} layout="icon-start">
        <IonIcon icon={tab === TourPageTabs.tour ? wayIconWhite : wayIconGray}></IonIcon>
        <IonLabel
          className={clsx('text-[0.75rem] tracking-normal ml-1', tab === TourPageTabs.tour ? 'font-bold' : '')}
        >{t("tourPage.tourPageTabs.tour")}</IonLabel>
      </IonSegmentButton>
      <IonSegmentButton value={TourPageTabs.map} layout="icon-start">
        <IonIcon icon={tab === TourPageTabs.map ? mapIconWhite : mapIconGray}></IonIcon>
        <IonLabel
          className={clsx('text-[0.75rem] tracking-normal ml-1', tab === TourPageTabs.map ? 'font-bold' : '')}
        >{t("tourPage.tourPageTabs.map")}</IonLabel>
      </IonSegmentButton>
    </IonSegment>
  );
};
