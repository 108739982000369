import { useCallback, useEffect, useState } from 'react';
import { IonCard, IonCardHeader, IonContent, useIonViewDidEnter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { find, get, head, map } from 'lodash-es';
import * as moment from 'moment';

import AppLayout from '../layouts/AppLayout';
import FloatingBackButton from '../components/buttons/FloatingBackButton';
import FullWidthTourCard from '../components/cards/FullWidthTourCard';
import { useTransaction } from '../contexts/TransactionContext';
import { useToursByTourIdsQuery } from '../graphql/dato/__generated__/dato-graphql.generated';
import { useLocale } from '../contexts/LocaleContext';
import { Tour } from '../interfaces/Interfaces';
import useIonVisible from '../hooks/useIonVisible';
import useAuthStore from '../stores/useAuthStore';
import logo from '../assets/GU_Logo_RZ-RGB_neg.svg';
import useToursRatingStatistics from '../hooks/useToursRatingStatistics';
import useUserToursAudioStatistics from '../hooks/useUserToursAudioStatistics';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';

const OrdersPage: React.FC = () => {
  const { hasPremiumAccess, accessibleTourIds, activePremiumTransactions} = useTransaction();
  const { queryLocale } = useLocale();
  const { isVisible } = useIonVisible();
  const { t } = useTranslation();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [tours, setTours] = useState<Tour[]>([]);

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const { toursRatingStatistics } = useToursRatingStatistics(map(tours, ({ id }) => id), isVisible);
  const { userToursAudioStatistics } = useUserToursAudioStatistics(map(tours, ({ id }) => id), isAuthenticated, isVisible);

  const queryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      tourIds: accessibleTourIds,
      first: 100,
      skip: 0,
    };
  }, [accessibleTourIds, queryLocale]);

  const [toursResult] = useToursByTourIdsQuery({
    variables: queryVariables(),
    pause: !isVisible || !accessibleTourIds?.length
  });

  useEffect(() => {
    const tours = toursResult?.data?.tours as Tour[];
    setTours(tours || []);
  }, [toursResult]);

  useEffect(() => {
    if (!accessibleTourIds?.length) setTours([]);
  }, [accessibleTourIds]);

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_YOUR_ORDERS);
    }
  });

  return (
    <AppLayout>
      <IonContent style={{
        '--padding-top': 'var(--ion-safe-area-top)'
      }}>
        <div className="relative mx-auto max-w-md">
          <FloatingBackButton style={{ left: "6px" }}/>

          <div className="pt-[75px]">
            {!tours?.length && !hasPremiumAccess && <h3
              className="pl-3 pb-2 font-semibold ml-1 text-[1.1rem] text-[#232437]"
            >{t('profile.orders.doNotHaveActiveOrders')}</h3>}

            {hasPremiumAccess && <div className="mb-6">
              <h3 className="pl-3 pb-2 font-semibold ml-1 text-[1.1rem] text-[#232437]">
                {t('profile.orders.subscriptions.title')}
              </h3>

              <p className="pl-3 pb-2 ml-1 text-[0.875rem] text-[#535E69]">{t('profile.orders.subscriptions.subtitle')}</p>

              <IonCard
                className="my-0 mx-4 p-4 pb-6 rounded-[20px] bg-white shadow-[0px_5px_12px_0px_rgba(0,0,0,0.06)] overflow-visible [contain:inherit]"
              >
                <IonCardHeader className="p-0 font-semibold">
                  <p className="text-[1rem] text-[#202839] mb-3">
                    {t('profile.orders.subscriptions.card.title')}
                  </p>
                  <p className="text-[0.8125rem] text-[#535E69]">
                    {t('profile.orders.subscriptions.card.validityPeriod', {
                      purchaseDate: moment(get(head(activePremiumTransactions), 'purchaseDate')).format('DD.MM.yyyy'),
                      expirationDate: moment(get(head(activePremiumTransactions), 'expirationDate')).format('DD.MM.yyyy'),
                    })}
                  </p>
                  <p className="text-[0.8125rem] text-[#535E69]">
                    {t('profile.orders.subscriptions.card.notRenewAutomatically')}
                  </p>
                  <div className="absolute top-[-18px] right-[20px] w-[52px] h-[52px] bg-[#d84c24] rounded-b-[6px]">
                    <img src={logo} alt=""/>
                  </div>
                </IonCardHeader>
              </IonCard>
            </div>}

            {!!tours?.length && <>
              <h3 className="pl-3 pb-2 font-semibold ml-1 text-[1.1rem] text-[#232437]">
                {t('profile.orders.accessibleTours.title')}
              </h3>

              <p className="pl-3 pb-2 ml-1 text-[0.875rem] text-[#535E69]">
                {t('profile.orders.accessibleTours.subtitle')}
              </p>

              {map(tours, (tour) => <div key={tour.id}>
                <FullWidthTourCard
                  tour={tour}
                  tourRatingStatistics={find(toursRatingStatistics, ['datoTourId', tour.id])}
                  userTourAudioStatistics={find(userToursAudioStatistics, ['datoTourId', tour.id])}
                />
              </div>)}
            </>}
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default OrdersPage;
