import {
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
} from '@ionic/react';
import { Icon } from '@iconify/react';
import flagTwotone from '@iconify/icons-ant-design/flag-twotone';
import clsx from 'clsx';
import { checkmarkCircle, lockClosed, lockOpen, playCircle } from 'ionicons/icons';
import { last } from 'lodash-es';
import { Image } from 'react-datocms';

import { SightseeingSpot, TourStop } from '../interfaces/Interfaces';
import useStoryPlayer from '../hooks/useStoryPlayer';
import { numberOfTourStop } from '../helpers/tour-stop-helpers';
import {
  UserTourStopAudioStatistics
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import ProgressBar from './ProgressBar';
import useAuthStore from '../stores/useAuthStore';
import PlaceSearchImage from './media/PlaceSearchImage';
import { LatLonField } from '../graphql/dato/__generated__/dato-graphql.generated';

const TourStopListItem: React.FC<{
  tourStop: TourStop;
  sightseeingSpot?: SightseeingSpot;
  previousTourStop?: TourStop | null;
  userTourStopAudioStatistics?: UserTourStopAudioStatistics;
  isDisabledByQuizzes?: boolean;
  isDisabledByPremiumAccess?: boolean;
  isUnblockingByQuizzesAvailable?: boolean;
}> = ({
  tourStop,
  sightseeingSpot,
  previousTourStop,
  userTourStopAudioStatistics,
  isDisabledByQuizzes,
  isDisabledByPremiumAccess,
  isUnblockingByQuizzesAvailable,
}) => {
  const { navigateToStory, navigateToStorySlide } = useStoryPlayer();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const navigateToLastStorySlideOfPreviousTourStop = () => {
    const lastStoryOfPreviousTourStop = last(previousTourStop?.stories);
    const lastStorySlideOfPreviousTourStop = last(lastStoryOfPreviousTourStop?.storySlides);
    if (lastStorySlideOfPreviousTourStop) {
      navigateToStorySlide({ storySlide: lastStorySlideOfPreviousTourStop, skipRouteMatching: true });
    }
  };

  const responsiveImage = sightseeingSpot?.preview?.[0]?.rectangularPreviewImage?.responsiveImage;

  return (
    <IonCard
      onClick={() => {
        if (isDisabledByQuizzes || isDisabledByPremiumAccess) return;

        const story = tourStop.stories?.[0];
        if (story) {
          navigateToStory({ story });
        } else {
          console.log("Tour stop doesn't have any stories to play");
        }
      }}
      className={clsx(
        "rounded-[16px] shadow-[0px_7px_24px_-8px_#B3BCC5]",
        userTourStopAudioStatistics?.audioProgress === 1 ? "opacity-70" : ""
      )}
    >
      {!tourStop?.isIntroTourStop && !tourStop?.isOutroTourStop && (responsiveImage || tourStop?.placeName) && <div
        className={clsx("relative aspect-[2/1]", isDisabledByQuizzes ? "opacity-50": "")}
      >
        {!!responsiveImage ?
          <Image
            data={responsiveImage}
            layout="fill"
            objectFit="cover"
          />
          :
          <PlaceSearchImage
            searchText={(sightseeingSpot?.name || tourStop?.placeName) as string}
            location={(sightseeingSpot?.location || tourStop?.location) as LatLonField}
            maxWidth={576}
            className="absolute"
          />
        }
      </div>}

      <IonCardHeader>
        <div className="flex items-center justify-between">
          <div className={clsx("relative mr-3 flex items-center", isDisabledByQuizzes ? "opacity-50": "")}>
            {userTourStopAudioStatistics?.audioProgress === 1 ?
              <IonIcon icon={checkmarkCircle} className="text-[#85D2BE] w-[28px] h-[28px]"/> :
              <div className="h-[32px] w-[32px] leading-[32px] rounded-full border border-[#E4E4E4] text-[14px] font-semibold text-center text-[#687582]">
                {tourStop.isIntroTourStop ? <Icon
                  icon={flagTwotone}
                  className="absolute top-1/2 left-1/2 ml-px -translate-x-1/2 -translate-y-1/2"
                  style={{ fontSize: '18px' }}
                /> : numberOfTourStop(tourStop)}
              </div>}
          </div>

          <div className={clsx("flex-1", isDisabledByQuizzes ? "opacity-50": "")}>
            <IonCardTitle className="font-medium text-[0.875rem] text-[#232437]">
              {tourStop.title}
            </IonCardTitle>
            {(!!sightseeingSpot?.name || !!tourStop?.displayedPlaceName) && <IonCardSubtitle
              className="text-[0.8125em] text-[#687582]"
            >{sightseeingSpot?.name || tourStop?.displayedPlaceName}</IonCardSubtitle>}
          </div>

          {isDisabledByQuizzes ?
            isUnblockingByQuizzesAvailable ?
              <IonIcon
                icon={lockOpen}
                className="text-[#E16A4F] min-w-[32px] w-[32px] h-[32px] ml-1"
                onClick={navigateToLastStorySlideOfPreviousTourStop}
              /> :
              <IonIcon icon={lockClosed} className="text-[#E16A4F] min-w-[32px] w-[32px] h-[32px] ml-1 opacity-50"/> :
            <IonIcon icon={playCircle} className="text-[#E16A4F] min-w-[40px] w-[40px] h-[40px] ml-1"/>}
        </div>

        {isAuthenticated && userTourStopAudioStatistics?.audioProgress !== 1 && <div
          className={clsx("py-2 pl-[45px]", isDisabledByQuizzes ? "opacity-50": "")}
        ><ProgressBar progress={userTourStopAudioStatistics?.audioProgress}/></div>}
      </IonCardHeader>
    </IonCard>
  );
};

export default TourStopListItem;
