import { ErrorMessage } from '@hookform/error-message';
import { head } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

const AppInput: React.FC<{
  register: any;
  placeholder: string;
  name: string;
  type?: string;
  disabled?: boolean;
  validators?: any;
  frontendErrors?: any;
  backendErrors?: any;
  inputClassNames?: string;
  labelClassNames?: string;
  autoComplete?: string;
  trimSpacesExceptLastOne?: boolean;
  trimAllSpaces?: boolean;
}> = ({
  register,
  placeholder,
  name,
  type = 'text',
  disabled = false,
  validators,
  frontendErrors,
  backendErrors,
  inputClassNames = 'rounded-[8px]',
  labelClassNames = 'text-gray-500',
  autoComplete,
  trimSpacesExceptLastOne,
  trimAllSpaces,
}) => {
  const { t } = useTranslation();

  return (
    <div className="mb-3 flex flex-col w-full">
      <div className="relative z-10">
        <input
          className={clsx(
            "border w-full pb-2 pt-4 px-4 font-normal focus:outline-none peer",
            inputClassNames
          )}
          id={name}
          placeholder=" "
          autoComplete={autoComplete}
          type={type}
          disabled={disabled}
          {...register(
            name,
            {
              ...(validators || {}),
              ...(trimSpacesExceptLastOne ?
                {
                  onChange: (e: any) => {
                    // give a possibility user to make space between 2 words
                    e.target.value = e.target.value
                      .replace(/^\s+/, '')  // Trim leading spaces
                      .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                      .replace(/\s$/, ' ')  // Keep one trailing space if it exists
                  }
                } :
                {}
              ),
              ...(trimAllSpaces ?
                  {
                    onChange: (e: any) => {
                      e.target.value = e.target.value.trim().replace(/\s+/g, ''); // delete all spaces
                    }
                  } :
                  {}
              )
            }
          )}
        />
        <label
          htmlFor={name}
          className={clsx(
            "absolute text-sm duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] left-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4",
            labelClassNames
          )}
        >{t(placeholder)}</label>
      </div>

      <ErrorMessage
        errors={frontendErrors}
        name={name}
        render={({ message }) => (
          <p className="text-[0.7rem] text-red-500 text-left ml-4 mt-[2px]">
            {message}
          </p>
        )}
      />

      { backendErrors?.[name] && <p
        className="text-[0.7rem] text-red-500 text-left ml-4 mt-[2px]"
      >{head(backendErrors?.[name])}</p> }
    </div>
  );
};

export default AppInput;
