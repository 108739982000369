import { IonButton, IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SignInWithApple } from '@capacitor-community/apple-sign-in';
import { Capacitor } from '@capacitor/core';
import { v4 as uuidv4 } from 'uuid';
import jwtDecode from 'jwt-decode';

import {
  SocialProvider,
  useSocialSignInMutation
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useToast from '../../hooks/useToast';
import appleIcon from '../../assets/auth/icons/apple.svg';
import useError from '../../hooks/useError';
import { checkReturnedRegistrationData } from '../../helpers/auth-helpers';
import { useLocale } from '../../contexts/LocaleContext';
import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';
import { useAuth } from '../../contexts/AuthContext';

const AppleSingInButton: React.FC<{ source: 'login' | 'sign-up' }> = ({ source }) => {
  const { presentToast } = useToast();
  const { t } = useTranslation();
  const { setCurrentUserWithRelatedData } = useAuth();
  const { handleBackendError } = useError();
  const { locale } = useLocale();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [socialSignInMutation] = useSocialSignInMutation();

  const signInWithApple = async () => {
    if (mixpanelEnabled) {
      mixpanel.track(
        source === 'login' ? MixpanelEvents.LOG_IN_VIA_APPLE : MixpanelEvents.SIGN_UP_VIA_APPLE
      );
    }

    let resourceUrlResponse: any;

    const redirectURI = Capacitor.isNativePlatform() ?
      'de.guidable.app:/' :
      `${process.env.REACT_APP_DEEP_LINK_BASE_URL}/${locale}/${source}`;

    try {
      resourceUrlResponse = await SignInWithApple.authorize({
        clientId: process.env.REACT_APP_APPLE_AUTH_APP_ID as string,
        redirectURI,
        scopes: 'email name',
        state: uuidv4(),
        nonce: 'nonce',
      });
    } catch (e) {
      presentToast('socialAuth.apple.error', 'danger');
    }

    if (!resourceUrlResponse) return;

    await handleBackendError(async () => {
      const { data, errors } = await socialSignInMutation({
        variables: {
          input: {
            socialProvider: SocialProvider.Apple,
            code: resourceUrlResponse.response.authorizationCode,
            redirectUri: redirectURI,
          }
        }
      });

      if (errors) return errors;

      const me = data?.auth?.socialSignIn;

      let emailFromIdentityToken;
      if (me) {
        if (resourceUrlResponse?.response?.email) {
          checkReturnedRegistrationData({ email: resourceUrlResponse?.response?.email } as any, me);
        } else if (resourceUrlResponse?.response?.identityToken) {
          try {
            const decoded = jwtDecode<any>(resourceUrlResponse?.response?.identityToken);
            emailFromIdentityToken = decoded?.email;
            if (emailFromIdentityToken) {
              checkReturnedRegistrationData({ email: emailFromIdentityToken } as any, me);
            }
          } catch (e) {
            console.error('Decode JWT error', e);
          }
        }
      }

      if (mixpanelEnabled && me) {
        mixpanel.track(
          source === 'login' ? MixpanelEvents.LOG_IN_SUCCESS : MixpanelEvents.SIGN_UP_SUCCESS,
          {
            sentEmail: resourceUrlResponse?.response?.email || emailFromIdentityToken,
            returnedEmail: me.email
          }
        );
      }

      setCurrentUserWithRelatedData(me);
    });
  };

  return (
    <>
      {['ios', 'web'].includes(Capacitor.getPlatform()) && <IonButton
        onClick={signInWithApple}
        className="normal-case text-white text-[1rem]"
        size="large"
        style={{
          '--background': '#000000'
        }}
      >
        <IonIcon icon={appleIcon} slot="start" className="mr-2"/>
        {t('socialAuth.apple.buttonText')}
      </IonButton>}
    </>
  );
};

export default AppleSingInButton;
