import { IonHeader, IonTitle, IonToolbar } from '@ionic/react';
import FloatingBackButton from '../buttons/FloatingBackButton';
import { useTranslation } from 'react-i18next';
import { chevronBackOutline } from 'ionicons/icons';

const AuthHeader: React.FC<{
  titleTranslationPath: string;
}> = ({ titleTranslationPath }) => {
  const { t } = useTranslation();

  return (
    <IonHeader className="ion-no-border">
      <IonToolbar color="primary">
        <div className="relative mx-auto max-w-xl">
          <FloatingBackButton
            style={{ marginTop: 0, top: '-10px' }}
            buttonStyle={{'--box-shadow': 'none'}}
            icon={chevronBackOutline}
            color="primary"
          />

          <IonTitle className="text-center mx-10">{t(titleTranslationPath)}</IonTitle>
        </div>
      </IonToolbar>
    </IonHeader>
  );
};

export default AuthHeader;
