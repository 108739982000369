import { IonContent, useIonViewDidEnter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import UpdateProfile from './UpdateProfilePage/UpdateProfile';
import ChangePassword from './UpdateProfilePage/ChangePassword';
import AppLayout from '../layouts/AppLayout';
import useAuthStore from '../stores/useAuthStore';
import useRoutes from '../hooks/useRoutes';
import FloatingBackButton from '../components/buttons/FloatingBackButton';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';

const UpdateProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const { localePath } = useRoutes();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const user = useAuthStore(state => state.me);

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_YOUR_ACCOUNT);
    }
  });

  return (
    <AppLayout>
      <IonContent style={{
        '--padding-top': 'var(--ion-safe-area-top)'
      }}>
        <div className="relative mx-auto max-w-md p-5 pt-[65px]">
          <FloatingBackButton style={{ left: "6px" }}/>

          <p className="mb-10 font-semibold text-[1.1rem]">
            {t('profile.updateProfile.yourEmail')}: {user?.email}
          </p>

          <UpdateProfile />
          <ChangePassword />

          <p className="text-[0.75rem] text-[#C6CAD6] text-center pt-[40px]">
            {t('profile.updateProfile.deleteAccountMessage.toDelete')}
            <Link
              to={localePath('user-profile/delete-account')}
            > {t('profile.updateProfile.deleteAccountMessage.deleteAccountLink')} </Link>
            {t('profile.updateProfile.deleteAccountMessage.willDelete')}
          </p>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default UpdateProfilePage;
