import { useEffect, useState } from 'react';
import { IonCard, IonContent, IonLabel, useIonRouter } from '@ionic/react';
import { useParams } from 'react-router';
import { map as _map } from 'lodash-es';
import { Image, ResponsiveImageType } from 'react-datocms';
import { useTranslation } from 'react-i18next';

import FloatingBackButton from '../components/buttons/FloatingBackButton';
import AppLayout from '../layouts/AppLayout';
import { useLocale } from '../contexts/LocaleContext';
import {
  LatLonField,
  useExploreStoriesByCoordinatesQuery,
  useSightseeingSpotByIdQuery
} from '../graphql/dato/__generated__/dato-graphql.generated';
import { SightseeingSpot, Story } from '../interfaces/Interfaces';
import Slider from '../components/sliders/Slider';
import useStoryPlayer from '../hooks/useStoryPlayer';
import StoryCardSlider from '../components/sliders/StoryCardSlider';
import ToursRelatedToStories from '../components/ToursRelatedToStories';
import PlaceSearchImage from '../components/media/PlaceSearchImage';
import { isPremiumStory } from '../helpers/story-helpers';
import { useTransaction } from '../contexts/TransactionContext';
import useAuthStore from '../stores/useAuthStore';
import useRoutes from '../hooks/useRoutes';

const sliderProps = {
  // Enable virtual slides
  virtual: true,

  // Default config
  slidesPerView: 1,
  spaceBetween: 6,
};

const SightseeingSpotPage: React.FC = () => {
  const { sightseeingSpotId }: { sightseeingSpotId?: string | null; } = useParams();
  const { t } = useTranslation();
  const { queryLocale } = useLocale();
  const { navigateToStory } = useStoryPlayer();
  const { hasPremiumAccess, activeTransactionsLoading } = useTransaction();
  const router = useIonRouter();
  const { loginPath, premiumAccessPurchasingPath, currentPath } = useRoutes();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [sightseeingSpot, setSightseeingSpot] = useState<SightseeingSpot | null>(null);
  const [stories, setStories] = useState<Story[]>([]);

  const [storiesData] =  useExploreStoriesByCoordinatesQuery({
    variables: {
      locale: queryLocale,
      latitude: sightseeingSpot?.location?.latitude,
      longitude: sightseeingSpot?.location?.longitude,
      radius: 0,
      first: 100,
      skip: 0
    },
  });

  useEffect(() => {
    if (storiesData) {
      setStories(storiesData.data?.stories as Story[]);
    }
  }, [storiesData]);

  const [sightseeingSpotResult] = useSightseeingSpotByIdQuery({
    variables: {
      sightseeingSpotId,
      locale: queryLocale,
    },
  });

  useEffect(() => {
    const sightseeingSpot = sightseeingSpotResult?.data?.sightseeingSpot;
    if (sightseeingSpot) setSightseeingSpot(sightseeingSpot as SightseeingSpot);
  }, [sightseeingSpotResult]);

  const startStory = ({ story }: { story: Story }) => {
    if (activeTransactionsLoading || !story) return;

    if (hasPremiumAccess || !isPremiumStory(story)) {
      navigateToStory({ story });
    } else {
      router?.push(isAuthenticated ?
        premiumAccessPurchasingPath(currentPath()) :
        loginPath(premiumAccessPurchasingPath(currentPath()))
      );
    }
  };

  if (!sightseeingSpot) return null;

  return (
    <AppLayout>
      <IonContent style={{
        '--padding-top': 'var(--ion-safe-area-top)'
      }}>
        <div className="relative mx-auto h-full max-w-xl bg-[#FAFAFA]">
          <FloatingBackButton style={{ left: "6px" }}/>

          {!!sightseeingSpot?.mediaGallery?.length ?
            <Slider sliderProps={sliderProps}>
              {_map(sightseeingSpot?.mediaGallery, (media) => (
                <IonCard key={media?.image?.id} className="relative m-0 rounded-[0px] aspect-[2/1]">
                  <Image data={media?.image?.responsiveImage as ResponsiveImageType} layout="fill" objectFit="cover" />
                </IonCard>
              ))}
            </Slider>
            :
            <IonCard className="relative m-0 rounded-[0px] aspect-[2/1]">
              <PlaceSearchImage
                searchText={sightseeingSpot?.name as string}
                location={sightseeingSpot?.location as LatLonField}
                maxWidth={576}
              />
            </IonCard>
          }

          <div className="text-[#414254] px-5 pb-5 pt-2">
            <h3 className="text-[1.25rem] font-bold pb-3">{sightseeingSpot?.name}</h3>
            <p className="text-[0.75rem]">{sightseeingSpot?.description}</p>
          </div>

          <div className="px-5">
            {!!stories?.length && <div>
              <IonLabel className="block text-[1rem] text-[#535E69] font-medium py-4">
                {t('sightseeingSpotDetails.topStories.label')}
              </IonLabel>
              <div className="flex items-center justify-center mx-[-4px]">
                <StoryCardSlider
                  stories={stories}
                  isAllStoriesReceived={!!stories?.length}
                  navigateToStory={startStory}
                  sliderProps={{
                    // Enable virtual slides
                    virtual: true,

                    // Default config (when window width is < 320px)
                    slidesPerView: 1.5,
                    spaceBetween: 4,

                    // Responsive breakpoints
                    breakpoints: {
                      // when window width is >= 480px
                      480: {
                        slidesPerView: 2,
                        spaceBetween: 6,
                      },
                    },
                  }}
                />
              </div>
            </div>}

            <ToursRelatedToStories stories={stories} labelTranslationPath="sightseeingSpotDetails.relatedTours.label" />
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default SightseeingSpotPage;
