import { IonButton, IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { FacebookLogin } from '@greytch/capacitor-facebook-login';
import { Capacitor } from '@capacitor/core';

import {
  SocialProvider,
  useSocialSignInMutation
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import facebookIcon from '../../assets/auth/icons/facebook.svg';
import useError from '../../hooks/useError';
import { useAuth } from '../../contexts/AuthContext';
import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';
import { checkReturnedRegistrationData } from '../../helpers/auth-helpers';

const FacebookSingInButton: React.FC<{ source: 'login' | 'sign-up' }> = ({ source }) => {
  const { t } = useTranslation();
  const { setCurrentUserWithRelatedData } = useAuth();
  const { handleBackendError } = useError();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [socialSignInMutation] = useSocialSignInMutation();

  const signInWithFacebook = async () => {
    if (mixpanelEnabled) {
      mixpanel.track(
        source === 'login' ? MixpanelEvents.LOG_IN_VIA_FACEBOOK : MixpanelEvents.SIGN_UP_VIA_FACEBOOK
      );
    }

    const FACEBOOK_PERMISSIONS = [
      'email',
      'public_profile'
    ];

    if (!Capacitor.isNativePlatform()) {
      await FacebookLogin.initialize({ appId: process.env.REACT_APP_FACEBOOK_AUTH_APP_ID });
    }

    let accessToken: string | undefined;
    let sentEmail: string | undefined;
    if (Capacitor.getPlatform() === 'ios') {
      const result = await FacebookLogin.limitedLogin({
        permissions: FACEBOOK_PERMISSIONS,
        tracking: 'limited',
      });
      accessToken = result?.authenticationToken?.token;
      sentEmail = result?.authenticationToken?.email;
    } else {
      const result = await FacebookLogin.login({ permissions: FACEBOOK_PERMISSIONS });
      accessToken = result?.accessToken?.token;
    }

    if (accessToken) {
      await handleBackendError(async () => {
        const { data, errors } = await socialSignInMutation({
          variables: {
            input: {
              socialProvider: SocialProvider.Facebook,
              accessToken,
            }
          }
        });

        if (errors) return errors;

        const me = data?.auth?.socialSignIn;

        if (sentEmail && me) {
          checkReturnedRegistrationData({ email: sentEmail } as any, me);
        }

        if (mixpanelEnabled && me) {
          mixpanel.track(
            source === 'login' ? MixpanelEvents.LOG_IN_SUCCESS : MixpanelEvents.SIGN_UP_SUCCESS,
            {
              sentEmail,
              returnedEmail: me.email
            }
          );
        }

        setCurrentUserWithRelatedData(me);
      });
    }
  };

  return (
    <IonButton
      onClick={signInWithFacebook}
      className="normal-case text-white text-[1rem]"
      size="large"
      style={{
        '--background': '#1877F2'
      }}
    >
      <IonIcon icon={facebookIcon} slot="start" className="mr-2"/>
      {t('socialAuth.facebook.buttonText')}
    </IonButton>
  );
};

export default FacebookSingInButton;
