import { useState } from 'react';
import { App } from '@capacitor/app';

const useAppState = () => {
  const [isAppActive, setIsAppActive] = useState<boolean>(true);

  App.addListener('appStateChange', ({ isActive }) => {
    setIsAppActive(isActive);
  });

  return {
    isAppActive,
  };
};

export default useAppState;
