import { IonProgressBar } from '@ionic/react';
import { Maybe } from 'graphql/jsutils/Maybe';
import { useTranslation } from 'react-i18next';

const ProgressBar: React.FC<{
  progress?: Maybe<number>;
}> = ({ progress }) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center gap-3">
      <IonProgressBar
        value={progress || 0}
        className="rounded-full h-[6px]"
        style={{
          '--background': '#dadada',
          '--progress-background': '#a4d5cd'
        }}
      />
      <span className="text-[#979797] text-[0.7rem] whitespace-nowrap">
        {Math.round((1 - (progress || 0)) * 100)}% {t('progressBar.left')}
      </span>
    </div>
  );
};

export default ProgressBar;
