import { useEffect, useState } from 'react';
import { find, isNumber } from 'lodash-es';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';

import { getDistanceBetweenCoordinates } from '../helpers/turf-helpers';
import useLocalNotifications from './useLocalNotifications';
import { useMediaPlayer } from '../contexts/MediaPlayerContext';
import useBackgroundGeolocation from './useBackgroundGeolocation';
import useStoryPlayer from './useStoryPlayer';
import useAppState from './useAppState';
import useBackgroundGeolocationStore from '../stores/useBackgroundGeolocationStore';
import { getPreviousTourStop } from '../helpers/tour-helpers';

const defaultMaxDistanceToTourStop = 150; // in meters

const useNearTourStopForActiveTour = () => {
  const { currentTour, currentTourStop } = useMediaPlayer();
  const { checkPermissions: checkLocalNotificationsPermissions, sendNotification } = useLocalNotifications();
  const {
    addWatcher: addBackgroundGeolocationWatcher,
    removeWatcher: removeBackgroundGeolocationWatcher
  } = useBackgroundGeolocation();
  const { navigateToStory } = useStoryPlayer();
  const { isAppActive } = useAppState();
  const { t } = useTranslation();

  const currentLocation = useBackgroundGeolocationStore((state) => state.currentLocation);

  const [lastNearestTourStopId, setLastNearestTourStopId] = useState<string | null>(null);
  const [lastNavigatedTourStopId, setLastNavigatedTourStopId] = useState<string | null>(null);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    checkLocalNotificationsPermissions();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    if (!!currentTour?.isTrackableForTourStops) {
      // set the tour stop that was opened with the tour as the initial one
      if (currentTourStop) {
        setLastNearestTourStopId(currentTourStop.id);
        setLastNavigatedTourStopId(currentTourStop.id);
      }

      addBackgroundGeolocationWatcher('tourStopsForActiveTour');
      checkLocalNotificationsPermissions();
    } else {
      removeBackgroundGeolocationWatcher('tourStopsForActiveTour');
      setLastNearestTourStopId(null);
      setLastNavigatedTourStopId(null);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentTour]
  );

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    // navigate to the tour stop if the app is active
    // or if the user opens the app after background mode and is still near the tour stop
    if (isAppActive) {
      const tourStop = find(currentTour?.tourStops, ['id', lastNearestTourStopId]);

      if (
        !tourStop ||
        tourStop.id === lastNavigatedTourStopId ||
        currentTourStop?.id === lastNearestTourStopId
      ) return;

      const distance = getDistanceBetweenCoordinates(
        currentLocation,
        tourStop?.notificationTriggerLocation || tourStop?.location
      );

      if (
        isNumber(distance) && distance < (
        tourStop?.maximumDistanceToNotificationTrigger ||
        currentTour?.maximumDistanceToTourStop ||
        defaultMaxDistanceToTourStop)
      ) {
        const story = tourStop?.stories?.[0];
        if (story && currentTourStop?.id !== tourStop?.id) {
          setLastNavigatedTourStopId(tourStop.id);
          navigateToStory({ story });
        }
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAppActive, lastNearestTourStopId]
  );

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    const trackNearestTourStop = async () => {
      if (!currentTour || !currentLocation) return;

      const tourStop = find(currentTour?.tourStops, (tourStop) => {
        const distance = getDistanceBetweenCoordinates(
          currentLocation,
          tourStop?.notificationTriggerLocation || tourStop?.location
        );

        return isNumber(distance) && distance < (
          tourStop?.maximumDistanceToNotificationTrigger ||
          currentTour?.maximumDistanceToTourStop ||
          defaultMaxDistanceToTourStop
        );
      });

      if (!tourStop || tourStop.id === lastNearestTourStopId) return;

      // check if the last nearest tour stop was the previous tour stop in order
      // or this is the first tour stop in the tour
      const prevTourStop = getPreviousTourStop(tourStop);
      if (prevTourStop && prevTourStop.id !== lastNearestTourStopId) return;

      setLastNearestTourStopId(tourStop.id);

      const localNotificationsPermission = await checkLocalNotificationsPermissions();
      if (localNotificationsPermission) {
        await sendNotification(
          {
            notifications: [{
              id: Math.floor(Math.random() * 2147483647),
              title: t('nearestTourStopTracking.notification.title'),
              body: t('nearestTourStopTracking.notification.text', { tourStopTitle: tourStop.title }),
              sound: 'tour_stop_notification.wav'
            }],
          },
          {
            id: 'near-tour-stop-of-active-tour',
            name: 'Nearest tour stop tracking',
            description: 'Tracking the nearest tour stop of the active tour',
            importance: 4,
            visibility: 1,
            vibration: true,
            sound: 'tour_stop_notification.wav'
          }
        );
      }
    };

    trackNearestTourStop();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLocation, currentTour]
  );
};

export default useNearTourStopForActiveTour;
