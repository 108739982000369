import { useMediaPlayer } from "../../contexts/MediaPlayerContext";
import { StorySlide } from "../../interfaces/Interfaces";

// This component renders speech marks generated from AWS Polly as subtitles /
//  as text on the screen, sentence by sentence.
const SubtitlesFromSpeechMarks: React.FC<{ storySlide: StorySlide }> = ({
  storySlide,
}) => {
  const { speechMarks } = storySlide;
  const { currentAudioTime } = useMediaPlayer();

  if (!currentAudioTime || !speechMarks) {
    return null;
  }

  const speechMarksSentences = speechMarks.filter(
    (speechMark: any) => speechMark.type === "sentence"
  );
  // const speechMarksWords = speechMarks.filter(
  //   (speechMark: any) => speechMark.type === "word"
  // );

  const currentTimeInMilliseconds = Math.round(currentAudioTime * 1000);
  const currentSpeechMarkSentence = speechMarksSentences
    .filter((speechMark: any) => speechMark.time <= currentTimeInMilliseconds)
    .pop();

  if (!currentSpeechMarkSentence) {
    return null;
  }

  // const highlightWordsInAdvanceDuration = 190; // in milliseconds
  // const currentSpeechMarksWordsInSentence = speechMarksWords.filter(
  //   (speechMark: any) =>
  //     speechMark.time >= currentSpeechMarkSentence.time &&
  //     speechMark.time <=
  //       currentTimeInMilliseconds + highlightWordsInAdvanceDuration
  // );

  // const lastSpeechMarkWordInSentence = currentSpeechMarksWordsInSentence.pop();
  // const textDecoder = new TextDecoder();

  // const sentenceInBytes = new TextEncoder().encode(
  //   currentSpeechMarkSentence.value
  // );

  // let currentSpokenString = "";

  // if (lastSpeechMarkWordInSentence) {
  //   const spokenWordsInSentence = sentenceInBytes.slice(
  //     0,
  //     lastSpeechMarkWordInSentence.end - currentSpeechMarkSentence.start
  //   );

  //   currentSpokenString = textDecoder.decode(spokenWordsInSentence);
  // }

  // const remainingSpokenString = currentSpeechMarkSentence.value.substring(
  //   currentSpokenString.length
  // );

  return (
    <div className="mx-auto max-w-[380px] text-center">
      <div className="inline rounded-[1px] bg-white/90 box-decoration-clone px-1.5 pt-[2px] pb-[3px] text-center text-lg font-medium leading-[30px] text-primary shadow">
        {/* <span className="text-primary">{currentSpokenString}</span>
        <span className="text-primary">{remainingSpokenString}</span> */}
        {currentSpeechMarkSentence.value}
      </div>
    </div>
  );
};

export default SubtitlesFromSpeechMarks;
