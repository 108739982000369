import { createContext, useContext, useEffect, useState } from 'react';
import { filter, isEqual } from 'lodash-es';
import * as moment from 'moment';

import {
  AppAccessType,
  Transaction,
  useGetActiveTransactionsLazyQuery,
  useTransactionCreatedSubscription
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useAppState from '../hooks/useAppState';
import useAuthStore from '../stores/useAuthStore';
import useError from '../hooks/useError';

const getTransactionsByAccessType = (transactions: Transaction[], appAccessType: AppAccessType) => filter(
  transactions,
  (transaction) => transaction.accessType === appAccessType
);

const getActiveTransactions = (transactions: Transaction[]) => filter(transactions, (transaction) =>
  moment().isBefore(transaction.expirationDate)
);

const useValue = () => {
  const { isAppActive } = useAppState();
  const { handleBackendError } = useError();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [activeTransactions, setActiveTransactions] = useState<Transaction[]>([]);
  const [activePremiumTransactions, setActivePremiumTransactions] = useState<Transaction[]>([]);
  const [activeTourTransactions, setActiveTourTransactions] = useState<Transaction[]>([]);
  const [hasPremiumAccess, setHasPremiumAccess] = useState<boolean>(false);
  const [accessibleTourIds, setAccessibleTourIds] = useState<string[]>([]);

  const { data: transactionCreatedData } = useTransactionCreatedSubscription({
    // subscribe when user is authenticated
    skip: !isAuthenticated,
  });

  const [getActiveTransactionsQuery, { loading: activeTransactionsLoading }] = useGetActiveTransactionsLazyQuery();

  useEffect(() => {
    if (!activeTransactions?.length) return;

    // set interval to check active transactions every minute
    const interval = setInterval(() => {
      setActiveTransactions(transactions => {
        const newTransactions = getActiveTransactions(transactions);
        return isEqual(transactions, newTransactions) ? transactions : newTransactions;
      });
    }, 60000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [activeTransactions.length]);

  useEffect(() => {
    setActivePremiumTransactions(getTransactionsByAccessType(activeTransactions, AppAccessType.Premium));
    setActiveTourTransactions(getTransactionsByAccessType(activeTransactions, AppAccessType.Tour));
  }, [activeTransactions]);

  useEffect(() => {
    setHasPremiumAccess(!!activePremiumTransactions?.length);
  }, [activePremiumTransactions]);

  useEffect(() => {
    setAccessibleTourIds(activeTourTransactions.map(({ tourId }) => tourId as string));
  }, [activeTourTransactions]);

  useEffect(() => {
    const createdTransaction = transactionCreatedData?.transactionCreated as Transaction;
    if (createdTransaction) {
      addCreatedTransaction(createdTransaction);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transactionCreatedData]
  );

  useEffect(() => {
    const receiveActiveTransactions = async () => {
      await handleBackendError(async () => {
        const { data, error } = await getActiveTransactionsQuery({
          fetchPolicy: 'no-cache',
        });

        if (error) return error;

        const transactions = data?.transaction?.getActiveTransactions as Transaction[];
        if (transactions) {
          setActiveTransactions(transactions);
        }
      });
    };

    if (isAppActive && isAuthenticated) {
      receiveActiveTransactions();
    }

    if (!isAuthenticated) {
      setActiveTransactions([]);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAppActive, isAuthenticated]
  );

  const addCreatedTransaction = (transaction: Transaction) => {
    setActiveTransactions((transactions) =>
      getActiveTransactions([...transactions, transaction])
    );
  }

  return {
    addCreatedTransaction,

    activePremiumTransactions,
    hasPremiumAccess,
    activeTourTransactions,
    accessibleTourIds,
    activeTransactionsLoading,
  };
};

const TransactionContext = createContext({} as ReturnType<typeof useValue>);

const TransactionProvider: React.FC = ({ children }) => {
  return (
    <TransactionContext.Provider value={useValue()}>
      {children}
    </TransactionContext.Provider>
  );
};

const useTransaction = () => {
  return useContext(TransactionContext);
};

export { TransactionProvider, useTransaction };
