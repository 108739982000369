import { IonContent, useIonViewDidEnter } from '@ionic/react';

import { useTranslation } from "react-i18next";

import guidableLogo from "../assets/GU_Logo_RZ-RGB_neg.svg";
import authFlowBackgroundImage from '../assets/auth/auth-flow-background.png';
import useRoutes from '../hooks/useRoutes';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';
import AppLayout from '../layouts/AppLayout';
import AppButton from '../components/buttons/AppButton';

const AuthFlowPage: React.FC = () => {
  const { t } = useTranslation();
  const { localePath } = useRoutes();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_SIGN_UP_OR_LOGIN);
    }
  });

  return (
    <AppLayout>
      <IonContent>
        <div
          className="max-w-xl mx-auto flex flex-col min-h-full items-center justify-center p-4 bg-no-repeat bg-cover bg-center"
          style={{ backgroundImage: `url(${authFlowBackgroundImage})` }}
        >
          <img
            src={guidableLogo}
            alt="guidable – stories to explore"
            style={{ paddingTop: "calc(var(--ion-safe-area-top, 12px) + 50px)" }}
          />

          <div className="grow"></div>

          <div className="text-center text-[1.875rem] text-white font-medium leading-[2rem] pb-5">
            {t("authFlow.subtitle")}<br/>
            #storiestoexplore
          </div>

          <div className="flex flex-col pb-10">
            <AppButton routerLink={localePath('sign-up')}>
              {t('authFlow.buttons.createAccount')}
            </AppButton>
            <AppButton
              fill="outline"
              routerLink={localePath('login')}
            >{t('authFlow.buttons.alreadyHaveAccount')}</AppButton>
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default AuthFlowPage;
