import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonIcon,
  IonItem,
  IonItemGroup,
  IonRadio,
  IonRadioGroup,
  IonRange,
  useIonRouter
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { pick } from 'lodash-es';
import { ErrorMessage } from '@hookform/error-message';
import { closeOutline } from 'ionicons/icons';

import AppLayout from '../layouts/AppLayout';
import AppHeader from '../components/AppHeader';
import useError from '../hooks/useError';
import useAuthStore from '../stores/useAuthStore';
import useCategories from '../hooks/useCategories';
import useExperienceLevels from '../hooks/useExperienceLevels';
import { Category, ExperienceLevel } from '../interfaces/Interfaces';
import { useLocale } from '../contexts/LocaleContext';
import {
  CreateCityInput,
  CreateTourByGptInput,
  useCreateTourByGptMutation,
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import './CreateTourPage.scss';
import useRoutes from '../hooks/useRoutes';
import useToast from '../hooks/useToast';
import { useCity } from '../contexts/CityContext';
import { useTourCreationRequest } from '../contexts/TourCreationRequestContext';
import useIonVisible from '../hooks/useIonVisible';

const ExperienceLevelsSection: React.FC<{
  experienceLevels: ExperienceLevel[];
  control: any;
  fieldName: string;
  errors: any;
}> = ({
  experienceLevels,
  control,
  fieldName,
  errors,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => (
          <p className="text-[0.7rem] text-red-500 text-left ml-4 mt-[2px]">
            {message}
          </p>
        )}
      />
      <Controller
        name={fieldName}
        control={control}
        rules={{ required: t('createTour.form.experienceLevel.validators.required') as string }}
        render={({ field: { onChange, value } }) => (
          <IonRadioGroup className="pb-2" value={value} onIonChange={onChange}>
            {experienceLevels?.map((experienceLevel) => (
              <IonItem key={experienceLevel.id} lines="none" className="mb-6">
                <IonRadio
                  slot="end"
                  mode="md"
                  color="primary"
                  value={experienceLevel.id}
                />
                <div>
                  <div className="text-[#232437] font-medium">{experienceLevel.title}</div>
                  <div className="text-[#687582] text-[0.875rem]">{experienceLevel?.description}</div>
                </div>
              </IonItem>
            ))}
          </IonRadioGroup>
        )}
      />
    </>
  )
};

const CategoriesSection: React.FC<{
  categories: Category[];
  fieldName: string;
  getValues: (...args: any) => any;
  setValue: (...args: any) => void;
  errors: any;
}> = ({
  categories,
  fieldName,
  getValues,
  setValue,
  errors
}) => {
  return (
    <div>
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => (
          <p className="text-[0.7rem] text-red-500 text-left ml-4 mt-[2px]">
            {message}
          </p>
        )}
      />
      <IonItemGroup className="pb-2">
        {categories?.map((category) => {
          const itemIcon = category?.icon?.url;

          return (
            <IonItem key={category.id} lines="none">
              <IonCheckbox
                slot="end"
                mode="md"
                color="primary"
                checked={getValues(fieldName)?.includes(category.id)}
                onIonChange={(e) => {
                  const currentValue = getValues(fieldName);
                  setValue(fieldName, e.detail.checked
                    ? [...currentValue, category.id]
                    : currentValue?.filter((option: string) => option !== category.id))
                }}
              />
              <div className="flex items-center">
                {!!itemIcon && <IonIcon src={itemIcon} className="mr-[10px] h-[20px] w-[20px] shrink-0" />}
                <span className="text-[#232437] font-medium">{category.title}</span>
              </div>
            </IonItem>
          );
        })}
      </IonItemGroup>
    </div>
  )
};

const DurationSection: React.FC<{
  register: any;
  fieldName: string;
}> = ({
  register,
  fieldName,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <IonRange
        {...register(fieldName)}
        color="primary"
        pin={true}
        pinFormatter={(value: number) => `${value / 60} ${t('units.time.hours.fullForm')}`}
        min={30}
        max={300}
        step={30}
      />
      <div className="flex justify-between absolute left-0 right-0 bottom-0 text-[#687582] text-[0.75rem]">
        <span>30 {t('units.time.minutes.shortForm')}</span>
        <span>5 {t('units.time.hours.fullForm')}</span>
      </div>
    </>
  )
};

const CreateTourPage: React.FC = () => {
  const { t } = useTranslation();
  const { handleBackendError } = useError();
  const { isVisible } = useIonVisible();
  const { categoriesInQueryLocale } = useCategories(isVisible, true);
  const { experienceLevelsInQueryLocale } = useExperienceLevels(isVisible);
  const { locale } = useLocale();
  const router = useIonRouter();
  const { currentPath, loginPath } = useRoutes();
  const { presentToast } = useToast();
  const { currentCity } = useCity();
  const { tourCreationRequestStatisticsByUser, setTourCreationRequestStatisticsByUser } = useTourCreationRequest();

  const [createTourByGptMutation] = useCreateTourByGptMutation();

  const {
    register, watch, reset, control, handleSubmit,
    getValues, setValue, setError, clearErrors, formState: { errors }
  } = useForm<CreateTourByGptInput>();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const watchDatoCategoryIds = watch('datoCategoryIds');

  useEffect(() => {
    resetForm();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale, currentCity]
  );

  useEffect(() => {
    // reset errors for the datoCategoryIds field if it changes
    clearErrors('datoCategoryIds');
  }, [watchDatoCategoryIds, clearErrors]);

  const resetForm = async () => {
    if (currentCity) {
      await reset({
        ...currentCity.id ?
          { datoCityId: currentCity.id } :
          { cityData: pick(currentCity, ['name', 'location', 'countryCode']) as CreateCityInput },
        tourDuration: 30,
        datoExperienceLevelId: '',
        datoCategoryIds: [],
      });

      // avoid displaying errors after resetting form
      clearErrors(['datoExperienceLevelId', 'datoCategoryIds']);
    }
  };

  const createTour = (input: CreateTourByGptInput) => {
    // set error to datoCategoryIds ids if this field is empty
    if (!input?.datoCategoryIds?.length) {
      setError('datoCategoryIds', {
        type: 'minLength',
        message: t('createTour.form.categories.validators.minLength') as string,
      });
      return;
    }

    if (!isAuthenticated) {
      router.push(loginPath(currentPath()));
      return;
    }

    resetForm();

    const createTour = async () => {
      await handleBackendError(async () => {
        const { errors } = await createTourByGptMutation({ variables: { input }});
        if (errors) return errors;

        setTourCreationRequestStatisticsByUser({
          ...tourCreationRequestStatisticsByUser,
          inProgress: tourCreationRequestStatisticsByUser?.inProgress + 1,
        });

        presentToast('createTour.successMessageForTourCreationRequest', 'secondary', 20000, 'top', [{
          icon: closeOutline,
          role: 'cancel',
        }]);
      });
    };

    createTour();
  };

  return (
    <AppLayout>
      <AppHeader />

      <IonContent>
        {!!currentCity && <div className="relative mx-auto min-h-full max-w-xl bg-[#FAFAFA] px-2">
          <form onSubmit={handleSubmit(createTour)} className="flex flex-col mb-10">
            <div className="border-b border-[rgba(217,217,217,0.2)]">
              <div className="pl-4 py-5">
                <h2 className="text-[#232437] text-[1.25rem] font-semibold">
                  1. {t('createTour.form.experienceLevel.title')}
                </h2>
              </div>
              <ExperienceLevelsSection
                experienceLevels={experienceLevelsInQueryLocale[locale]}
                control={control}
                fieldName="datoExperienceLevelId"
                errors={errors}
              />
            </div>

            <div className="border-b border-[rgba(217,217,217,0.2)]">
              <div className="pl-4 py-5">
                <h2 className="text-[#232437] text-[1.25rem] font-semibold">
                  2. {t('createTour.form.categories.title')}
                </h2>
                <div className="text-[0.875em] text-[#687582]">
                  {t('createTour.form.categories.text')}
                </div>
              </div>
              <CategoriesSection
                categories={categoriesInQueryLocale[locale]}
                fieldName="datoCategoryIds"
                getValues={getValues}
                setValue={setValue}
                errors={errors}
              />
            </div>

            <div>
              <div className="pl-4 pt-5">
                <h2 className="text-[#232437] text-[1.25rem] font-semibold">
                  3. {t('createTour.form.duration.title')}
                </h2>
                <div className="text-[0.875rem] text-[#687582]">
                  {t('createTour.form.duration.text')}
                </div>
              </div>
              <div className="mx-7 mb-10 relative">
                <DurationSection
                  register={register}
                  fieldName="tourDuration"
                />
              </div>
            </div>

            <div className="text-[0.875rem] text-[#848D96] flex items-center justify-center mb-3">
              <div className="w-[6px] h-[6px] rounded-full bg-[#E59953] mr-2"/>
              <span className="text-[#535E69] font-semibold">{t('createTour.statistics.message.partOne', {
                total: tourCreationRequestStatisticsByUser?.total,
                remainder: tourCreationRequestStatisticsByUser?.total -
                  tourCreationRequestStatisticsByUser?.success -
                  tourCreationRequestStatisticsByUser?.inProgress
              })}</span>&#160;
              {t('createTour.statistics.message.partTwo')}
            </div>

            <IonButton type="submit" shape="round" className="font-medium normal-case h-[45px]">
              <span className="font-semibold">{t('createTour.form.buttons.create')}</span>
            </IonButton>
          </form>
        </div>}
      </IonContent>
    </AppLayout>
  );
};

export default CreateTourPage;
