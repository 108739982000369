import { memo } from 'react';

import { Story } from '../../interfaces/Interfaces';
import { useMediaPlayer } from '../../contexts/MediaPlayerContext';
import StoryMarker from './StoryMarker';

const StoryMarkers: React.FC<{
  stories: Story[];
  highlightedStory: Story | null;
  setSelectedStory: (story: Story) => void;
  markerSize: number;
}> = memo(({ stories, highlightedStory, setSelectedStory, markerSize }) => {
  const { currentStory } = useMediaPlayer();

  return (
    <>
      {/* Render all stories except the current and the highlighted story */}
      {stories.map((story) => {
        const isCurrentStory = !!(currentStory && story.id === currentStory.id);
        const isHighlightedStory = !!(
          highlightedStory && story.id === highlightedStory.id
        );

        let storyMarkerKey = `story-marker-${story.id}`;

        if (isCurrentStory) storyMarkerKey += "-is-current";
        if (isHighlightedStory) storyMarkerKey += "-is-highlighted";

        return (
          <StoryMarker
            story={story}
            markerSize={markerSize}
            key={storyMarkerKey}
            isCurrent={isCurrentStory}
            isHighlighted={isHighlightedStory}
            setSelectedStory={setSelectedStory}
          />
        );
      })}
    </>
  );
});

export default memo(StoryMarkers);
