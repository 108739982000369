import { memo } from 'react';
import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
} from '@ionic/react';
import { checkmarkDoneCircle, chevronBackOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import { Story } from '../../interfaces/Interfaces';

const CreateStorySuccessModal: React.FC<{
  storyId?: string;
  navigateToStory: ({ story }: { story: Story }) => void;
  onDismiss: () => void;
}> = ({ storyId, navigateToStory, onDismiss }) => {
  const { t } = useTranslation();

  return (
    <IonPage className="min-h-[100vh]">
      <IonContent color="dark" className="ion-padding">
        <div className="p-5 min-h-full">
          <div className="flex items-center">
            {/*TODO: add functionality to dismiss modal */}
            <IonButton
              fill="clear"
              color="medium"
              style={{
                '--padding-start': 0
              }}
              onClick={() => onDismiss()}
            >
              <IonIcon slot="icon-only" icon={chevronBackOutline} />
            </IonButton>
            <h3 className="text-[1.125rem] text-white text-center font-bold py-5 pr-10 w-full">
              {t('createStoryForSightseeingSpot.createStoryForm.title')}
            </h3>
          </div>

          <div className="text-white text-center">
            <p className="pb-5 pt-10 font-bold">
              {t('createStoryForSightseeingSpot.creationProcess.success.messageOne')}
            </p>
            <IonIcon icon={checkmarkDoneCircle} className="text-[10rem] mb-5" />
            <p className="py-5">
              {t('createStoryForSightseeingSpot.creationProcess.success.messageTwo')}
            </p>
            <IonButton
              className="block normal-case font-bold text-[1rem] max-w-sm mx-auto mt-6"
              onClick={() => {
                navigateToStory({ story: { id: storyId } } as any);
                onDismiss();
              }}
            >{t('createStoryForSightseeingSpot.creationProcess.success.buttons.exploreStory')}</IonButton>
          </div>

          <div style={{ paddingBottom: "calc(var(--ion-safe-area-bottom) + 30px)" }} />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default memo(CreateStorySuccessModal);
