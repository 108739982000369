import React, { useCallback, useEffect, useState } from 'react';
import { IonCard, IonContent, IonInfiniteScroll, IonInfiniteScrollContent, useIonRouter } from '@ionic/react';
import { filter } from 'lodash-es';
import { Image, ResponsiveImageType } from 'react-datocms';
import { useTranslation } from 'react-i18next';

import FloatingBackButton from '../components/buttons/FloatingBackButton';
import AppLayout from '../layouts/AppLayout';
import {
  AppContentCountQuery,
  LatLonField, useAppContentCountQuery,
  useCitiesWithToursAndStoriesCountQuery,
} from '../graphql/dato/__generated__/dato-graphql.generated';
import { City } from '../interfaces/Interfaces';
import { useLocale } from '../contexts/LocaleContext';
import useIonVisible from '../hooks/useIonVisible';
import PlaceSearchImage from '../components/media/PlaceSearchImage';
import { useCity } from '../contexts/CityContext';
import useRoutes from '../hooks/useRoutes';

const CitiesPage: React.FC = () => {
  const router = useIonRouter();
  const { queryLocale, locale } = useLocale();
  const { isVisible } = useIonVisible();
  const { setCurrentCityToStateAndLocalStorage } = useCity();
  const { localePath } = useRoutes();
  const { t } = useTranslation();

  const [appContentCount, setAppContentCount] = useState<AppContentCountQuery>();
  const [citiesInQueryLocale, setCitiesInQueryLocale] = useState<{ [key: string]: City[]; }>({});
  const [infiniteScrollEventTarget, setInfiniteScrollEventTarget] = useState<any>(null);
  const [isAllCitiesReceivedInQueryLocale, setIsAllCitiesReceivedInQueryLocale] = useState<{ [key: string]: boolean; }>({});

  // Query for cities when rending the component
  const citiesPageSize = 100;
  const [citiesPageNumber, setCitiesPageNumber] = useState(0);

  const citiesQueryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      first: citiesPageSize,
      skip: citiesPageNumber * citiesPageSize,
    };
  }, [queryLocale, citiesPageNumber]);

  const [citiesResult] = useCitiesWithToursAndStoriesCountQuery({
    variables: citiesQueryVariables(),
    pause: !isVisible,
  });

  const { data: citiesData, fetching } = citiesResult;

  // Query for app content count when rending the component
  const [appContentCountResult] = useAppContentCountQuery({
    pause: !isVisible,
  });

  const { data: appContentCountData } = appContentCountResult;

  // Set cities when data is available
  useEffect(() => {
    if (citiesData?.cities) {
      // Add cities to the list
      const returnedCities = citiesData?.cities as City[];
      // Filter cities that don't have content
      const filteredCities = filter(returnedCities, city =>
        city._allReferencingStoriesMeta?.count || city._allReferencingToursMeta?.count
      ) as City[];
      const newCitiesInQueryLocale = [...(citiesInQueryLocale?.[locale] || []), ...filteredCities];

      setCitiesInQueryLocale({
        ...citiesInQueryLocale,
        [locale]: newCitiesInQueryLocale,
      });

      // Fetch more cities if available
      if (returnedCities.length !== citiesPageSize) {
        setIsAllCitiesReceivedInQueryLocale({
          ...isAllCitiesReceivedInQueryLocale,
          [locale]: true,
        });
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [citiesData]
  );

  useEffect(() => {
    if (appContentCountData) setAppContentCount(appContentCountData);
  }, [appContentCountData]);

  useEffect(() => {
    if (!fetching) {
      infiniteScrollEventTarget?.complete();
      setInfiniteScrollEventTarget(null);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetching]
  );

  useEffect(() => {
    setCitiesPageNumber(0);
    infiniteScrollEventTarget?.complete();
    setInfiniteScrollEventTarget(null);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryLocale]
  );

  return (
    <AppLayout>
      <IonContent style={{
        '--padding-top': 'var(--ion-safe-area-top)'
      }}>
        <div className="relative px-4 mx-auto h-full max-w-xl bg-[#FAFAFA]">
          <FloatingBackButton style={{ left: "6px" }}/>

          <div className="px-[70px] pt-[25px] mb-5 text-center">
            <h2 className="text-[1.25rem] text-[#232437] font-bold mb-2">{t('cities.title')}</h2>

            {!!appContentCount && <p className="text-[0.875rem] text-[#687582] font-medium">
              {t('cities.appContentCount', {
                toursCount: new Intl.NumberFormat(locale).format(appContentCount?._allToursMeta?.count),
                storiesCount: new Intl.NumberFormat(locale).format(appContentCount?._allStoriesMeta?.count),
                citiesCount: new Intl.NumberFormat(locale).format(appContentCount?._allCitiesMeta?.count),
              })}
            </p>}
          </div>

          <div className="ion-content-scroll-host grid grid-cols-2 gap-4">
            {citiesInQueryLocale?.[locale]?.map((city) => {
              const responsiveImage = city?.preview?.[0]?.image?.responsiveImage as ResponsiveImageType;

              return (
                <IonCard
                  key={city.id}
                  className="relative m-0 p-0 cursor-pointer bg-transparent shadow-none"
                  onClick={() => {
                    setCurrentCityToStateAndLocalStorage(city);
                    router.push(localePath('home'));
                  }}>
                  <div
                    className="relative aspect-square rounded-[6px] overflow-hidden bg-[#D9D9D9] shadow-[0px_4px_10px_-4px_rgba(14,0,54,0.28)]"
                  >
                    {!!responsiveImage ?
                      <Image
                        data={responsiveImage}
                        layout="fill"
                        objectFit="cover"
                      />
                      :
                      <PlaceSearchImage
                        searchText={city?.name as string}
                        location={city?.location as LatLonField}
                        maxWidth={400}
                        className="absolute"
                      />
                    }
                  </div>

                  <div className="absolute bottom-4 left-1/2 -translate-x-1/2 w-full px-2 text-center text-white">
                    <p className="text-[1rem] font-medium">{city.name}</p>
                    <p className="text-[0.75rem] font-light">
                      {!!city._allReferencingToursMeta?.count && t('cities.cityCard.toursCount', {
                        toursCount: new Intl.NumberFormat(locale).format(city._allReferencingToursMeta?.count),
                        count: city._allReferencingToursMeta?.count,
                      })}
                      {!!city._allReferencingToursMeta?.count && !!city._allReferencingStoriesMeta?.count && ', '}
                      {!!city._allReferencingStoriesMeta?.count && t('cities.cityCard.storiesCount', {
                        storiesCount: new Intl.NumberFormat(locale).format(city._allReferencingStoriesMeta?.count),
                        count: city._allReferencingStoriesMeta?.count,
                      })}
                    </p>
                  </div>
                </IonCard>
              );
            })}
          </div>
          {!isAllCitiesReceivedInQueryLocale[queryLocale] && <IonInfiniteScroll
            onIonInfinite={(ev) => {
              // By setting the page size another GraphQL query for the next page gets executed
              setCitiesPageNumber(citiesPageNumber + 1);
              setInfiniteScrollEventTarget(ev.target);
            }}
          >
              <IonInfiniteScrollContent loadingSpinner="bubbles" />
          </IonInfiniteScroll>}
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default CitiesPage;
