import { useEffect, useState } from 'react';
import { Image, ResponsiveImageType } from 'react-datocms';
import { useTranslation } from 'react-i18next';
import { IonButton } from '@ionic/react';

import { City } from '../../../interfaces/Interfaces';
import {
  LatLonField,
  useToursAndStoriesNumberByCityIdQuery,
} from '../../../graphql/dato/__generated__/dato-graphql.generated';
import PlaceSearchImage from '../../../components/media/PlaceSearchImage';
import { MixpanelEvents, useMixpanel } from '../../../contexts/MixpanelContext';
import { useLocale } from '../../../contexts/LocaleContext';

const CityOverviewSection: React.FC<{ city: City | null; isVisible: boolean }> = ({ city, isVisible }) => {
  const { t } = useTranslation();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { locale } = useLocale();

  const responsiveImage = city?.preview?.[0]?.image?.responsiveImage as ResponsiveImageType;

  const [toursNumber, setToursNumber] = useState(0);
  const [storiesNumber, setStoriesNumber] = useState(0);

  const [result] = useToursAndStoriesNumberByCityIdQuery({
    variables: { cityId: city?.id },
    pause: !city?.id || !isVisible,
  });

  useEffect(() => {
    const numbers = result.data?.numbers;
    if (numbers) {
      setToursNumber(numbers.toursNumber.count || 0);
      setStoriesNumber(numbers.storiesNumber.count || 0)
    }
  }, [result.data]);

  const buttons = [
    {
      translationPath: 'home.cityOverviewSection.buttons.tours',
      entitiesNumber: new Intl.NumberFormat(locale).format(toursNumber),
      routerLink: 'tours',
      onClick: () => {
        trackClickingOnButton(MixpanelEvents.HOME_TOURS_BUTTON);
      }
    },
    {
      translationPath: 'home.cityOverviewSection.buttons.stories',
      entitiesNumber: new Intl.NumberFormat(locale).format(storiesNumber),
      routerLink: 'stories',
      onClick: () => {
        trackClickingOnButton(MixpanelEvents.HOME_STORIES_BUTTON);
      }
    },
  ];

  const trackClickingOnButton = (event: MixpanelEvents) => {
    if (mixpanelEnabled) {
      mixpanel.track(event);
    }
  };

  return (
    <>
      <div className="relative h-[450px]">
        {responsiveImage ?
          <Image data={responsiveImage} layout="fill" objectFit="cover"/>
          :
          <PlaceSearchImage
            searchText={city?.name as string}
            location={city?.location as LatLonField}
            maxWidth={800}
            className="absolute"
          />
        }

        <div style={{
          background: 'linear-gradient(9deg, rgba(14, 33, 100, 0.90) 27.66%, rgba(170, 189, 255, 0.12) 75.35%)'
        }} className="absolute top-0 right-0 bottom-0 left-0"/>

        <div className="absolute top-0 right-0 bottom-0 left-0 flex flex-col justify-end pb-2">
          <h3 className="text-[2rem] text-white font-bold leading-10 px-7 mb-3">{city?.title}</h3>
          <p className="text-[1rem] text-[#D6D5DC] px-7 mb-7">{t('home.cityOverviewSection.text')}</p>

          <div className="flex flex-wrap gap-[6px] px-7 pb-10">
            {buttons.map((button) => <IonButton
              key={button.translationPath}
              shape="round"
              className="normal-case text-[0.75rem] font-medium"
              style={{
                '--background': "rgba(203, 204, 246, 0.35)",
                '--padding-start': '14px',
                '--padding-end': '14px',
                '--box-shadow': 'none'
              }}
              routerLink={button?.routerLink}
              onClick={button?.onClick}
            >
              <div className="flex items-center">
                {t(button.translationPath)}
                {!!button.entitiesNumber ? ` (${button.entitiesNumber})` : ''}
                <span className="text-[1.5rem] font-light pb-[2px] pl-1">&#8250;</span>
              </div>
            </IonButton>)}
          </div>
        </div>
      </div>
    </>
  );
};

export default CityOverviewSection;
