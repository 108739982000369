import { Device } from '@capacitor/device';

const isIosVersion = async (version: number) => {
  const deviceInfo = await Device.getInfo();
  const isIos = deviceInfo.platform === 'ios';
  if (isIos) {
    const majorVersion: number = +deviceInfo.osVersion.split('.')[0];
    return majorVersion === version;
  }
  return false;
};

export { isIosVersion };
