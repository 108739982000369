import { MapEvent } from 'react-map-gl';

import { Locales } from '../contexts/LocaleContext';

const setMapLanguage = (event: MapEvent, locale: Locales): void => {
  const map = event?.target;

  map?.getStyle().layers.forEach((layer) => {
    if (layer.type === 'symbol' && layer?.layout?.['text-field']) {
      map.setLayoutProperty(layer.id, 'text-field', [
        'coalesce',
        ['get', `name_${locale}`],
        ['get', `name`]
      ]);
    }
  });
};

export { setMapLanguage };
