import { Image, ResponsiveImageType } from "react-datocms";
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import { cloneDeep, filter, forEach, isNumber, map as _map, sortBy } from 'lodash-es';
import clsx from 'clsx';

import { Tour } from "../../interfaces/Interfaces";
import CategoryTags from "../../components/category-tags/CategoryTags";
import ShareButton from "../../components/buttons/ShareButton";
import PlayTourPreviewButton from "../../components/buttons/PlayTourPreviewButton";
import {
  TourRatingStatistics,
  UserTourAudioStatistics
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import StarRating from '../../components/StarRating';
import Slider from '../../components/sliders/Slider';
import DownloadTourButton from '../../components/buttons/DownloadTourButton';
import ProgressBar from '../../components/ProgressBar';
import useAuthStore from '../../stores/useAuthStore';
import ResetTourButton from '../../components/buttons/ResetTourButton';
import walkIcon from '../../assets/transportation-types/walk.svg';
import bikeIcon from '../../assets/transportation-types/bike.svg';
import boatIcon from '../../assets/transportation-types/boat.svg';
import busIcon from '../../assets/transportation-types/bus.svg';
import carIcon from '../../assets/transportation-types/car.svg';
import trainIcon from '../../assets/transportation-types/train.svg';
import { VideoRecord } from '../../graphql/dato/__generated__/dato-graphql.generated';
import ExternalVideoPlayer from '../../components/players/ExternalVideoPlayer';
import TourTranslationSelector from './TourTranslationSelector';

const sliderProps = {
  // Enable virtual slides
  virtual: true,

  // Default config
  slidesPerView: 1,
  spaceBetween: 6,
};

const TourPrice: React.FC<{ tour: Tour, displayDivider: boolean }> = ({ tour, displayDivider }) => {
  const { currentTourPrice, regularTourPrice } = tour;

  if (!currentTourPrice && !regularTourPrice) {
    return null;
  }

  const tourIsDiscounted =
    currentTourPrice !== null && currentTourPrice < regularTourPrice;
  const tourPrice = currentTourPrice || regularTourPrice;

  return (
    <div className="flex text-[0.7rem] text-[#414254] font-light leading-[27px]">
      {tourIsDiscounted ? (
        <>
          <div className="mr-1 text-red-600 line-through">
            {regularTourPrice} €
          </div>
          <div>{currentTourPrice} €</div>
        </>
      ) : (
        <div>{tourPrice} €</div>
      )}
      {displayDivider && <span>&nbsp;|&nbsp;</span>}
    </div>
  );
};

const TourInfo: React.FC<{
  tour: Tour;
  tourRatingStatistics: TourRatingStatistics | null | undefined;
}> = ({ tour, tourRatingStatistics }) => {

  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        "mx-4",
        tour?.mediaGallery?.length ? "mt-0" : "mt-3"
      )}
    >
      <div className="flex">
        <TourPrice tour={tour} displayDivider={!!tourRatingStatistics}/>

        {tourRatingStatistics && <StarRating
          tourRatingStatistics={tourRatingStatistics}
          captionClassName="text-[#414254] text-[0.7rem] font-light ml-1.5"
        />}
      </div>

      <div className="flex flex-wrap">
        <div className="flex text-[0.7rem] text-[#414254] font-light mr-1">
          {tour.distanceInKilometers && <div>
            {t('tourPage.tourDistance')}:
            <span className="font-bold"> {tour.distanceInKilometers} {t('tour.kilometersInShortForm')}</span>
          </div>}
          {tour.audioDurationInMinutes && <div className="ml-1">
            {t('tourPage.audioDuration')}:
            <span className="font-bold"> {tour.audioDurationInMinutes} {t('tour.minutesInShortForm')}</span>
          </div>}
        </div>

        {(tour.durationInMinutes ||
          tour.durationByBikeInMinutes ||
          tour.durationByCarInMinutes ||
          tour.durationByBusInMinutes ||
          tour.durationByTrainInMinutes ||
          tour.durationByBoatInMinutes) &&
        <div className="flex flex-wrap text-[0.7rem] text-[#414254] font-light">
          <span className="mr-0.5">{t('tourPage.tourDuration')}:</span>

          {tour.durationInMinutes && <div className="flex items-center justify-end mr-1.5">
            <IonIcon src={walkIcon} className="mr-0.5" />
            <span className="font-bold">{tour.durationInMinutes} {t('tour.minutesInShortForm')}</span>
          </div>}

          {tour.durationByBikeInMinutes && <div className="flex items-center justify-end mr-1.5">
              <IonIcon src={bikeIcon} className="mr-0.5" />
              <span className="font-bold">{tour.durationByBikeInMinutes} {t('tour.minutesInShortForm')}</span>
          </div>}

          {tour.durationByCarInMinutes && <div className="flex items-center justify-end mr-1.5">
              <IonIcon src={carIcon} className="mr-0.5" />
              <span className="font-bold">{tour.durationByCarInMinutes} {t('tour.minutesInShortForm')}</span>
          </div>}

          {tour.durationByBusInMinutes && <div className="flex items-center justify-end mr-1.5">
              <IonIcon src={busIcon} className="mr-0.5" />
              <span className="font-bold">{tour.durationByBusInMinutes} {t('tour.minutesInShortForm')}</span>
          </div>}

          {tour.durationByTrainInMinutes && <div className="flex items-center justify-end mr-1.5">
              <IonIcon src={trainIcon} className="mr-0.5" />
              <span className="font-bold">{tour.durationByTrainInMinutes} {t('tour.minutesInShortForm')}</span>
          </div>}

          {tour.durationByBoatInMinutes && <div className="flex items-center justify-end mr-1.5">
              <IonIcon src={boatIcon} className="mr-0.5" />
              <span className="font-bold">{tour.durationByBoatInMinutes} {t('tour.minutesInShortForm')}</span>
          </div>}
        </div>}
      </div>
    </div>
  )
};

const MediaBlockRenderer: React.FC<{ record: any }> = ({ record }) => {
  switch (record.__typename) {
    case "ImageRecord":
      return <Image data={record?.image?.responsiveImage as ResponsiveImageType} layout="fill" objectFit="cover"/>;
    case "VideoRecord":
      return <ExternalVideoPlayer videoRecord={record as VideoRecord} aspectRatio="3:2"/>;
    default:
      return null;
  }
};

const TourPageHeader: React.FC<{
  isVisible: boolean;
  tour: Tour;
  tourRatingStatistics: TourRatingStatistics | null | undefined;
  userTourAudioStatistics?: UserTourAudioStatistics | null;
  getUserTourAudioStatistics: () => void;
}> = ({ isVisible, tour, tourRatingStatistics, userTourAudioStatistics, getUserTourAudioStatistics }) => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const tourHighlightImage = tour.highlightImage
    ?.responsiveImage as ResponsiveImageType;

  // combine images and videos for the slider and place the videos according to the "order" field
  const orderedSliderMedia: any = cloneDeep(tour?.mediaGallery || []);
  forEach(
    sortBy(
      filter(tour?.videoGallery, (item) => item.__typename === 'VideoRecord'),
      'order'
    ),
    (item) => {
      const videoItem = item as VideoRecord;
      orderedSliderMedia.splice(isNumber(videoItem?.order) ? (videoItem?.order - 1) : 0, 0, videoItem);
    }
  );

  return (
    <div className="relative w-full">
      {(tourHighlightImage || orderedSliderMedia?.length) && (
        <div className="relative">
          {orderedSliderMedia?.length ?
            <Slider sliderProps={sliderProps}>
              {_map(orderedSliderMedia, (media, i) => (
                <div key={i} className="aspect-[3/2]">
                  <MediaBlockRenderer record={media}/>
                </div>
              ))}
            </Slider> :
            <Image data={tourHighlightImage} layout="responsive" />
          }

          <div
            className={clsx(
              "absolute right-[10px] z-10",
              (tour?.videoGallery?.length || tour?.mediaGallery?.length) ? "bottom-[45px]" : "bottom-[15px]"
            )}
          >
            <PlayTourPreviewButton tour={tour} />
          </div>
        </div>
      )}

      <TourInfo tour={tour} tourRatingStatistics={tourRatingStatistics} />

      {isAuthenticated && <div className="ml-4 mr-8 pt-2">
        <ProgressBar progress={userTourAudioStatistics?.audioProgress} />
      </div>}

      <div className="mx-4 mt-3 mb-2 flex items-center justify-between pr-16">
        <h1 className="text-[1.6rem] font-bold leading-[1.85rem] text-slate-800">
          {tour.title}
        </h1>
      </div>

      <div className="mx-4 mb-2 flex items-center justify-between space-x-2">
        <CategoryTags categories={tour.categories} />
        <div className="flex items-center space-x-2">
          <DownloadTourButton tour={tour} />
          <ResetTourButton tourId={tour.id} getUserTourAudioStatistics={getUserTourAudioStatistics} />
          <ShareButton tour={tour} />
        </div>
      </div>

      <TourTranslationSelector isVisible={isVisible} tour={tour}/>
    </div>
  );
};

export default TourPageHeader;
