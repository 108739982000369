import { useEffect, useState } from 'react';
import {
  IonButton,
  IonIcon,
} from '@ionic/react';
import { App as CapacitorApp } from '@capacitor/app';
import { useTranslation } from 'react-i18next';
import { Image, ResponsiveImageType } from 'react-datocms';
import { closeOutline } from 'ionicons/icons';

import { AppVersionFeature } from '../../interfaces/Interfaces';
import { isAppVersionSupported } from '../../helpers/application-settings-helpers';
import AppButton from '../buttons/AppButton';
import './AppVersionFeaturesModal.scss';

const AppVersionFeaturesModal: React.FC<{
  onDismiss: (data?: { pathWithoutLocale?: string | null }) => void;
  appVersionFeatures: AppVersionFeature;
  devicePlatform: 'ios' | 'android' | 'web';
}> = ({ onDismiss, appVersionFeatures, devicePlatform }) => {
  const { t } = useTranslation();

  const [isNeededToUpdateApp, setIsNeededToUpdateApp] = useState(false);

  const appStoreUrl = devicePlatform === 'web' ?
    null :
    devicePlatform === 'ios' ?
      'https://apps.apple.com/app/guidable/id1615027421':
      'https://play.google.com/store/apps/details?id=de.guidable.app';

  const responsiveImage = appVersionFeatures?.image?.responsiveImage as ResponsiveImageType;

  useEffect(() => {
    const checkAppVersion = async () => {
      if (!appVersionFeatures.appVersion || devicePlatform === 'web') return;

      const appInfo = await CapacitorApp.getInfo();
      const currentAppVersion = appInfo?.version;
      const isSupported = isAppVersionSupported(currentAppVersion, appVersionFeatures.appVersion);
      setIsNeededToUpdateApp(!isSupported)
    };

    checkAppVersion();
  }, [appVersionFeatures, devicePlatform]);

  return (
    <div className="content">
      <div className="relative aspect-[3/2]">
        <Image data={responsiveImage} layout="fill" objectFit="cover"/>

        <IonButton
          color="light"
          shape="round"
          className="absolute top-2 right-2"
          onClick={() => onDismiss()}
        >
          <IonIcon slot="icon-only" icon={closeOutline}/>
        </IonButton>
      </div>

      <div className="flex flex-col p-5">
        {!!appVersionFeatures.title && <h3 className="text-[1.25rem] text-[#232437] font-bold mb-4">
          {appVersionFeatures.title}
        </h3>}

        {!!appVersionFeatures.description && <div className="text-[0.875rem] text-[#868B90] mb-5">
          {appVersionFeatures.description}
        </div>}

        <div className="w-full max-w-md mx-auto">
          {isNeededToUpdateApp && appStoreUrl ?
            <AppButton href={appStoreUrl}>
              {t('appVersionFeaturesModal.buttons.updateApp')}
            </AppButton> :
            appVersionFeatures.buttons?.map((button) => {
              if (button.__typename !== "LinkButtonRecord") return null;

              return <AppButton
                key={button.id}
                fill={button.fill as any}
                onClick={() => onDismiss({ pathWithoutLocale: button.pathWithoutLocale })}
              >
                {button.text}
              </AppButton>
          })}
        </div>
      </div>
    </div>
  );
};

export default AppVersionFeaturesModal;
