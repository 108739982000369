import { setContext } from '@apollo/client/link/context';

import { getItemFromStorage } from '../helpers/storage-helpers';

const authMiddleware = setContext(async (req, { headers }) => {
  const me = await getItemFromStorage('me');
  const accessToken = me?.token;

  return {
    headers: {
      ...headers,
      ...accessToken ? { authorization: `Bearer ${accessToken}` } : {},
    },
  };
});

export default authMiddleware;
