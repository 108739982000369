import { IonIcon, useIonModal, useIonRouter } from '@ionic/react';
import { personAddOutline } from 'ionicons/icons';
import * as moment from 'moment';
import { useRef } from 'react';

import useGroupSharingStore from '../../stores/useGroupSharingStore';
import { useCreateGroupSharingMutation } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useRoutes from '../../hooks/useRoutes';
import useAuthStore from '../../stores/useAuthStore';
import GroupSharingQrCodeModal from '../modals/GroupSharingQrCodeModal';
import { useMediaPlayer } from '../../contexts/MediaPlayerContext';
import useError from '../../hooks/useError';
import { isIosVersion } from '../../helpers/device-helpers';
import useOnboardingHint from '../../hooks/useOnboardingHint';
import StoryPlayerStartGroupSharingButtonHint from '../onboardingHints/StoryPlayerStartGroupSharingButtonHint';
import { MixpanelEvents, useMixpanel } from '../../contexts/MixpanelContext';

const InviteToGroupSharingButton: React.FC<{ displayHint?: boolean }> = ({ displayHint }) =>{
  const router = useIonRouter();
  const { currentPath, loginPath } = useRoutes();
  const { currentTour, currentTourStop, currentStory, isPlaying, playbackRate, currentAudioTime } = useMediaPlayer();
  const { handleBackendError } = useError();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const hintTargetRef = useRef<HTMLIonIconElement>(null);

  useOnboardingHint({
    component: StoryPlayerStartGroupSharingButtonHint,
    hintTargetRef,
    hintName: 'storyPlayerStartGroupSharingButton',
    isVisible: !!displayHint,
  });

  const [createGroupSharingMutation] = useCreateGroupSharingMutation();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const setMyGroupSharing = useGroupSharingStore((state) => state.setMyGroupSharing);
  const myGroupSharing = useGroupSharingStore((state) => state.myGroupSharing);

  const [present, dismiss] = useIonModal(GroupSharingQrCodeModal, {
    groupSharing: myGroupSharing,
    onDismiss: () => dismiss(),
  });

  const inviteToGroupSharing = async () => {
    if (!isAuthenticated) {
      router.push(loginPath(currentPath()));
      return;
    }

    if (myGroupSharing) {
      if (mixpanelEnabled) {
        mixpanel.track(MixpanelEvents.INVITE_TO_GROUP_SHARING, {
          source: 'Story player',
          tourId: currentTour?.id,
          tourTitle: currentTour?.title,
          tourStopId: currentTourStop?.id,
          tourStopTitle: currentTourStop?.title,
          storyId: currentStory?.id,
          storyTitle: currentStory?.title,
        });
      }

      present({
        // set animated false for ios 17,because animation breaks the popup
        // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
        animated: !isIosVersion(17)
      });
    } else {
      if (mixpanelEnabled) {
        mixpanel.track(MixpanelEvents.START_GROUP_SHARING, {
          source: 'Story player',
          tourId: currentTour?.id,
          tourTitle: currentTour?.title,
          tourStopId: currentTourStop?.id,
          tourStopTitle: currentTourStop?.title,
          storyId: currentStory?.id,
          storyTitle: currentStory?.title,
        });
      }

      await handleBackendError(async () => {
        const { data, errors } = await createGroupSharingMutation({
          variables: {
            input: {
              navigationUrl: currentPath(),
              isPlaying,
              playbackRate,
              startAudioTime: moment().subtract(currentAudioTime, 'seconds').toDate(),
              ...!isPlaying ? { stopAudioTime: new Date() } : {}
            }
          }
        });

        if (errors) return errors;

        const groupSharing = data?.groupSharing?.createGroupSharing;
        if (groupSharing) {
          setMyGroupSharing(groupSharing, true);
          present({
            // set animated false for ios 17,because animation breaks the popup
            // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
            animated: !isIosVersion(17)
          });
        }
      })
    }
  };

  return (
    <IonIcon
      ref={hintTargetRef}
      icon={personAddOutline}
      className="shrink-0 cursor-pointer text-[24px] leading-[24px]"
      onClick={inviteToGroupSharing}
    />
  );
};

export default InviteToGroupSharingButton;
