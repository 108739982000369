import { memo, useState } from 'react';

import { Category, City, ExperienceLevel, SightseeingSpot, Story } from '../../interfaces/Interfaces';
import StoryMapSightseeingSpotInfo from './StoryMapSightseeingSpotInfo';
import CreateStoryForSightseeingSpot from './CreateStoryForSightseeingSpot';

const StoryMapSightseeingSpotModal: React.FC<{
  cluster: Story[];
  sightSeeingSpot: SightseeingSpot;
  currentCity: City;
  categories: Category[];
  experienceLevels: ExperienceLevel[];
  navigateToStory: ({ story }: { story: Story }) => void;
  navigateToLoginPage: () => void;
  onDismiss: () => void;
}> = ({
  cluster,
  sightSeeingSpot,
  currentCity,
  categories,
  experienceLevels,
  navigateToStory,
  navigateToLoginPage,
  onDismiss,
}) => {
  const [isCreationPage, setIsCreationPage] = useState(false);

  return isCreationPage ?
    <CreateStoryForSightseeingSpot
      sightSeeingSpot={sightSeeingSpot}
      currentCity={currentCity}
      categories={categories}
      experienceLevels={experienceLevels}
      setIsCreationPage={setIsCreationPage}
    /> :
    <StoryMapSightseeingSpotInfo
      sightSeeingSpot={sightSeeingSpot}
      cluster={cluster}
      navigateToStory={navigateToStory}
      navigateToLoginPage={navigateToLoginPage}
      setIsCreationPage={setIsCreationPage}
      onDismiss={onDismiss}
    />;
};

export default memo(StoryMapSightseeingSpotModal);
