import { useEffect, useState } from 'react';
import {
  IonButton,
  IonContent,
  IonLabel,
  useIonRouter, useIonViewDidEnter
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { some } from 'lodash-es';

import AppLayout from '../layouts/AppLayout';
import useIonVisible from '../hooks/useIonVisible';
import useAuthStore from '../stores/useAuthStore';
import { useTransaction } from '../contexts/TransactionContext';
import './PremiumAccessPurchasingPage.scss';
import useRoutes from '../hooks/useRoutes';
import useSearchParams from '../hooks/useSearchParams';
import coverImage from '../assets/promotional-code/cover.png';
import AppInput from '../components/form/AppInput';
import { BackendValidationError } from '../interfaces/Interfaces';
import { getBackendValidationErrors } from '../helpers/error-helpers';
import {
  Transaction,
  useRedeemPromoCodeMutation
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useToast from '../hooks/useToast';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';
import FloatingBackButton from '../components/buttons/FloatingBackButton';

interface PromotionalCodeForm {
  code: string;
}

const PromotionalCodePage: React.FC = () => {
  const router = useIonRouter();
  const { isVisible } = useIonVisible();
  const { hasPremiumAccess, activeTourTransactions, addCreatedTransaction } = useTransaction();
  const { t } = useTranslation();
  const { localePath } = useRoutes();
  const { code } = useSearchParams();
  const { register, reset, handleSubmit, getValues, formState: { errors } } = useForm<PromotionalCodeForm>();
  const { presentToast } = useToast();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [redeemPromoCodeMutation] = useRedeemPromoCodeMutation();

  const [backendErrors, setBackendErrors] = useState<BackendValidationError | null | undefined>(null);

  const isAuthenticated = useAuthStore(state => state.isAuthenticated);

  const [isLoaderShown, setIsLoaderShown] = useState<boolean>(false);

  useEffect(() => {
    if (isVisible) reset({ code });
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isVisible]
  );

  useEffect(() => {
    if (isVisible && (hasPremiumAccess || some(activeTourTransactions, ['promotionalCode', getValues('code')]))) {
      setIsLoaderShown(false);
      router.push(localePath('user-profile'), 'none', 'pop');
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasPremiumAccess, activeTourTransactions, isVisible]
  );

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_VOUCHER_CODE);
    }
  });

  const redeemPromotionalCode = async (input: PromotionalCodeForm) => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VOUCHER_CODE_REDEEM);
    }

    setIsLoaderShown(true);

    try {
      const { data } = await redeemPromoCodeMutation({ variables: { input }});
      const createdTransaction = data?.promoCode?.redeemPromoCode;
      if (createdTransaction) {
        addCreatedTransaction(createdTransaction as Transaction);
        presentToast('promotionalCode.successMessageForPromotionalCodeRedemption');
      }
    } catch (e: any) {
      setIsLoaderShown(false);
      let backendErrors = getBackendValidationErrors(e);

      if (backendErrors?.notValidationBackendError?.length) {
        backendErrors = {
          ...backendErrors,
          code: backendErrors?.notValidationBackendError,
        }
      }

      setBackendErrors(backendErrors);
    }
  };

  return (
    <AppLayout isLoaderShown={isLoaderShown}>
      <IonContent style={{
        '--padding-top': 'var(--ion-safe-area-top)'
      }}>
        <div className="relative mx-auto min-h-full max-w-xl bg-white px-4 pt-[65px] text-center">
          <FloatingBackButton style={{ left: "6px" }}/>

          <img src={coverImage} alt=""/>

          <div className="px-3">
            <h2 className="text-[1.125rem] text-[#232437] font-bold px-5 pt-8 pb-3">{t('promotionalCode.title')}</h2>

            <div className="text-[0.75rem] text-[#535E69] mb-8">{t('promotionalCode.text')}</div>

            <form onSubmit={handleSubmit(redeemPromotionalCode)}>
              <IonLabel className="block text-[0.75rem] text-[#535E69] text-center font-bold mb-3">
                {t('promotionalCode.form.code.label')}
              </IonLabel>
              <div className="max-w-[250px] mx-auto">
                <AppInput
                  placeholder="promotionalCode.form.code.placeholder"
                  name="code"
                  register={register}
                  validators={{ required: t('promotionalCode.form.code.errors.required') }}
                  frontendErrors={errors}
                  backendErrors={backendErrors}
                />
              </div>

              <IonButton
                className="normal-case font-semibold min-h-[56px] w-full text-[1rem] mt-10"
                type="submit"
                shape="round"
                style={{
                  '--background': '#ec765a',
                  '--padding-start': '32px',
                  '--padding-end': '32px',
                  '--box-shadow': 'none'
                }}
                disabled={isLoaderShown || !isAuthenticated}
              >{t('promotionalCode.form.buttons.redeem')}</IonButton>
            </form>
          </div>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default PromotionalCodePage;
