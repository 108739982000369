import { useCallback, useEffect, useState } from 'react';
import { filter, flatMap, isNumber, map, round, sortBy, uniqBy } from 'lodash-es';

import {
  useCitiesWithToursByCoordinatesQuery,
} from '../graphql/dato/__generated__/dato-graphql.generated';
import { City, Tour, TourWithCityDataForTourCard } from '../interfaces/Interfaces';
import { useCity } from '../contexts/CityContext';
import { useLocale } from '../contexts/LocaleContext';
import { getDistanceBetweenCoordinates } from '../helpers/turf-helpers';

const useCitiesWithToursByCoordinates = (isVisible: boolean) => {
  const { currentCity } = useCity();
  const { tourQueryLocale } = useLocale();

  const [citiesWithToursInQueryLocale, setCitiesWithToursInQueryLocale] = useState<{ [key: string]: City[]; }>({});
  const [toursWithCityDataInQueryLocale, setToursWithCityDataInQueryLocale] = useState<{ [key: string]: TourWithCityDataForTourCard[]; }>({});

  const citiesWithToursPageSize = 100;
  const [citiesWithToursPageNumber, setCitiesWithToursPageNumber] = useState(0);

  const citiesWithToursQueryVariables = useCallback(() => {
    return {
      locale: tourQueryLocale,
      latitude: currentCity?.location?.latitude,
      longitude: currentCity?.location?.longitude,
      radius: 50000,
      first: citiesWithToursPageSize,
      skip: citiesWithToursPageNumber * citiesWithToursPageSize,
    };
  }, [tourQueryLocale, citiesWithToursPageNumber, currentCity]);

  const [result] = useCitiesWithToursByCoordinatesQuery({
    variables: citiesWithToursQueryVariables(),
    pause: !currentCity || !isVisible,
  });

  useEffect(() => {
    if (!result.fetching && result.data?.cities) {
      const returnedCities = result.data?.cities as City[];
      const returnedCitiesWithTours = filter(returnedCities, (city) => !!city?._allReferencingTours?.length);

      // reset cities for the current locale if the query variable "skip" is 0
      const newCitiesInQueryLocale = result?.operation?.variables?.skip ?
        uniqBy([...(citiesWithToursInQueryLocale?.[tourQueryLocale] || []), ...returnedCitiesWithTours], (city) => city.id) :
        returnedCitiesWithTours;

      setCitiesWithToursInQueryLocale({
        ...citiesWithToursInQueryLocale,
        [tourQueryLocale]: newCitiesInQueryLocale,
      });

      // Fetch more cities if available
      if (returnedCities.length === citiesWithToursPageSize) {
        // By setting the page size another GraphQL query for the next page gets executed
        setCitiesWithToursPageNumber(citiesWithToursPageNumber + 1);
      } else {
        // Set tours with cities data
        const sortedCities = sortBy(newCitiesInQueryLocale, city => city.id === currentCity?.id ? 0 : 1);
        const tours = flatMap(sortedCities, (city) => {
          const isCurrentCity = city.id === currentCity?.id;
          if (isCurrentCity) return city?._allReferencingTours as TourWithCityDataForTourCard[];

          const distanceToCurrentCityInMeters = getDistanceBetweenCoordinates(currentCity?.location, city?.location);
          const distanceToCurrentCity = isNumber(distanceToCurrentCityInMeters) ?
            round(distanceToCurrentCityInMeters / 1000, 1) :
            0;

          return map(city?._allReferencingTours, (tour) => ({
            ...(tour as Tour),
            city: { name: city.name, distanceToCurrentCity }
          }));
        });

        setToursWithCityDataInQueryLocale({
          ...toursWithCityDataInQueryLocale,
          [tourQueryLocale]: tours,
        });
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [result, tourQueryLocale]
  );

  useEffect(() => {
    if (currentCity) setCitiesWithToursPageNumber(0);
  }, [currentCity, tourQueryLocale]);

  return {
    tours: currentCity ? (toursWithCityDataInQueryLocale[tourQueryLocale] || []) : [],
    isLoading: result.fetching,
  };
};

export default useCitiesWithToursByCoordinates;
