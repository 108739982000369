import { useEffect, useState } from 'react';
import { filter, find, includes, map, reduce } from 'lodash-es';
import { Icon, IconifyIcon } from '@iconify/react';

import { Tour } from '../../interfaces/Interfaces';
import { INITIAL_ITEM_BY_LANGUAGE_OPTIONS } from '../../constants';
import { ItemByLanguageOption } from '../../interfaces/ItemByLanguageOption';
import { useLocale } from '../../contexts/LocaleContext';

const IconsOfAvailableTourLocales: React.FC<{ tour: Tour }> = ({ tour }) => {
  const { tourQueryLocale } = useLocale();

  const [availableTranslationLanguageOptions, setAvailableTranslationLanguageOptions] = useState<ItemByLanguageOption[]>([]);

  useEffect(() => {
    const tourLocales = tour?._allReferencingTourTranslations?.[0]?._allTourLocales;
    if (tourLocales) {
      const locales = reduce(tourLocales, (acc: string[], tourLocale) => {
        if (tourLocale.value) return [...acc, tourLocale.locale as string];
        return acc;
      }, []);

      const availableTranslationLanguageOptions = filter(
        INITIAL_ITEM_BY_LANGUAGE_OPTIONS,
        (option) => includes(locales, option.languageCode)
      );
      setAvailableTranslationLanguageOptions(availableTranslationLanguageOptions);
    }
  }, [tour]);

  return (
    <div className="flex gap-1">
      {!!availableTranslationLanguageOptions?.length ?
        map(availableTranslationLanguageOptions, (option) => <Icon
          icon={option?.flag as IconifyIcon}
          key={option.languageCode}
          className="w-5 h-5"
        />) :
        <Icon
          icon={find(INITIAL_ITEM_BY_LANGUAGE_OPTIONS, ['languageCode', tourQueryLocale])?.flag as IconifyIcon}
          className="w-5 h-5"
        />
      }
    </div>
  );
};

export default IconsOfAvailableTourLocales;
