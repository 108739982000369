import { memo, useEffect, useState } from 'react';
import { Marker as MapMarker } from 'react-map-gl';
import { IonButton, IonIcon } from '@ionic/react';
import { Capacitor } from '@capacitor/core';
import { Geolocation, Position } from '@capacitor/geolocation';
import { useDebouncedEffect } from '@react-hookz/web';

import locationMarker from '../../assets/map/location-marker.svg';
import locationArrow from '../../assets/map/location-arrow.svg';
import locationArrowActive from '../../assets/map/location-arrow-active.svg';
import { useLocale } from '../../contexts/LocaleContext';
import { useCity } from '../../contexts/CityContext';

const GeolocateControl: React.FC<{
  mapRef: any;
  position?: { [key: string]: string },
  updateCityByUserLocationOnLocateButtonClicking?: boolean;
}> = ({ mapRef, position = { bottom: '16px', right: '16px '}, updateCityByUserLocationOnLocateButtonClicking }) => {
  const { locale } = useLocale();
  const { getCityByCoordinatesAndSet } = useCity();

  const [currentCallbackId, setCurrentCallbackId] = useState<string | null>();
  const [currentPosition, setCurrentPosition] = useState<Position | null>(null);
  const [isGeolocationPermissionGranted, setIsGeolocationPermissionGranted] = useState<boolean>(false);

  useEffect(() => {
    const getIsPermissionsGranted = async () => {
      const isPermissionsGranted = await requestPermissions();
      setIsGeolocationPermissionGranted(isPermissionsGranted);
    };
    getIsPermissionsGranted();
  }, []);

  useEffect(() => {
    return () => {
      clear();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentCallbackId]
  );

  useDebouncedEffect(() => {
    watch();
  }, [isGeolocationPermissionGranted, locale], 1000);

  const requestPermissions = async () => {
    if (Capacitor.isNativePlatform()) {
      const permissionStatus = await Geolocation.checkPermissions();

      if (permissionStatus.location !== 'granted' && permissionStatus.coarseLocation !== 'granted') {
        const permissionStatus = await Geolocation.requestPermissions();
        return permissionStatus.location === 'granted' || permissionStatus.coarseLocation === 'granted';
      } else {
        return true;
      }
    }
    return true;
  };

  const watch = async () => {
    if (isGeolocationPermissionGranted) {
      if (currentCallbackId) {
        await Geolocation.clearWatch({ id: currentCallbackId });
      }

      const callbackId = await Geolocation.watchPosition({
        enableHighAccuracy: true,
        timeout: 500,
      }, (position: Position | null) => {
        if (position) setCurrentPosition(position);
      });

      setCurrentCallbackId(callbackId);
    }
  };

  const clear = async () => {
    if (currentCallbackId && isGeolocationPermissionGranted) {
      Geolocation.clearWatch({ id: currentCallbackId });
    }
  };

  const flyToMyLocation = async () => {
    if (currentPosition && isGeolocationPermissionGranted) {
      mapRef?.current?.flyTo({
        center: [currentPosition?.coords?.longitude, currentPosition?.coords?.latitude],
        essential: true
      });

      if (updateCityByUserLocationOnLocateButtonClicking) {
        // get the city by the user location and set the current city
        getCityByCoordinatesAndSet(currentPosition?.coords)
      }
    }
  };

  return (
    <>
      {(currentCallbackId && currentPosition?.coords) && <MapMarker
        latitude={currentPosition?.coords?.latitude as number}
        longitude={currentPosition?.coords?.longitude as number}
        style={{ zIndex: 10, width: 40, height: 40 }}
      >
        <IonIcon src={locationMarker} className="w-full h-full" style={{
          'transform': `rotate(${currentPosition?.coords?.heading || 0}deg)`
        }}/>
      </MapMarker>}

      <IonButton
        className="absolute w-[64px] h-[64px] z-50"
        style={{
          '--border-radius': '50%',
          '--background': '#ffffff',
          '--box-shadow': '0px 4px 7px 0px rgba(0, 0, 0, 0.13)',
          '--border-style': '1px solid #D5D6DC',
          ...position,
        }}
        onClick={async () => {
          watch();
          flyToMyLocation();
        }}
      >
        <IonIcon
          src={(currentCallbackId && currentPosition?.coords) ? locationArrowActive : locationArrow}
          className="w-[28px] h-[28px]"
        />
      </IonButton>
    </>
  );
};

export default memo(GeolocateControl);
