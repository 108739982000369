import React, { useEffect, useState } from 'react';
import { Image } from 'react-datocms';
import { IonContent, useIonRouter } from '@ionic/react';
import { find, flattenDeep, get, isEmpty, map } from 'lodash-es';
import { DeepPartial } from 'react-hook-form';

import {
  PlaybackRateControl,
  SeekBar,
  SkipBackwardButton,
  SkipForwardButton,
  TogglePlayPauseButton,
  NavigateBackwardButton,
  NavigateForwardButton,
} from './MediaControls';
import FloatingBackButton from '../buttons/FloatingBackButton';
import Subtitles from './Subtitles';
import { useMediaPlayer } from '../../contexts/MediaPlayerContext';
import MediaSlider from '../sliders/MediaSlider';
import StoryPlayerNavigation from './StoryPlayerNavigation';
import { tourStopNumberLabel } from '../../helpers/tour-stop-helpers';
import useRoutes from '../../hooks/useRoutes';
import CategoryTags from '../category-tags/CategoryTags';
import storyPlayerBackground from '../../assets/backgrounds/story-player-background-1.svg';
import ShareButton from '../buttons/ShareButton';
import { CreatorProfile } from '../../interfaces/Interfaces';
import SlideActions from './SlideActions';
import StoryPlayerBottomMenu from './StoryPlayerBottomMenu';
import DetachFromGroupSharingButton from '../buttons/DetachFromGroupSharingButton';
import InviteToGroupSharingButton from '../buttons/InviteToGroupSharingButton';
import CloseStoryPlayerButton from '../buttons/CloseStoryPlayerButton';
import { UserQuiz, UserTour } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import StoryEvaluationButtons from '../buttons/StoryEvaluationButtons';
import TaskBlockRenderer from './TaskBlockRenderer';

const StoryPlayer: React.FC<{
  userTour?: DeepPartial<UserTour>;
  getUserTour?: () => void;
}> = ({ userTour, getUserTour }) => {
  const { currentTour, currentTourStop, currentStory, currentStorySlide, percentagePlayed } = useMediaPlayer();

  const router = useIonRouter();
  const { creatorProfilePath, tourPath } = useRoutes();

  const [userQuiz, setUserQuiz] = useState<UserQuiz>();

  useEffect(() => {
    const userStorySlides = flattenDeep(map(userTour?.userTourStops, (userTourStop) =>
      map(userTourStop?.userStories, (userStory) => userStory?.userStorySlides)
    ));
    const userQuiz = get(find(userStorySlides, ['datoStorySlideId', currentStorySlide?.id]), 'userQuiz');
    setUserQuiz(userQuiz);
  }, [userTour, currentStorySlide]);

  if (!currentStory || !currentStorySlide) {
    return null;
  }

  const creatorProfile = currentStory?.creatorProfile as CreatorProfile;

  let storyPlayerHeader;
  if (currentTour && currentTourStop) {
    const tourStopTitle = [
      tourStopNumberLabel(currentTourStop),
      currentTourStop.title,
    ].join(" – ");

    storyPlayerHeader = (
      <div
        className="absolute z-10 w-full px-[58px]"
        style={{
          marginTop: "calc(var(--ion-safe-area-top, 12px) + 6px)",
        }}
      >
        <div
          className="flex cursor-pointer items-center justify-start"
          onClick={() => {
            // Navigate to tour page
            const path = tourPath(currentTour);
            path && router.push(path);
          }}
        >
          <div className="overflow-hidden rounded-full bg-white/95 px-5 py-[3px] shadow">
            <div className="truncate text-sm font-bold leading-[16px] text-slate-700">
              {currentTour.title}
            </div>
            <div className="truncate text-[0.8rem] font-semibold leading-[16px] text-slate-600">
              {tourStopTitle}
            </div>
          </div>
        </div>
      </div>
    );
  }

  let creatorProfileBadge;
  if (creatorProfile) {
    creatorProfileBadge = (
      <div
        className="flex cursor-pointer items-center justify-start rounded-full bg-white/95 pr-5 shadow"
        onClick={() => {
          // Navigate to creator profile
          router.push(creatorProfilePath(creatorProfile));
        }}
      >
        <div className="mr-2.5 h-[50px] w-[50px] shrink-0">
          {creatorProfile.roundProfileImage?.responsiveImage && (
            <Image
              data={creatorProfile.roundProfileImage.responsiveImage}
              className="rounded-full shadow"
              pictureClassName="rounded-full"
            />
          )}
        </div>
        <div>
          <div className="text-sm font-bold leading-[17px] text-slate-800">
            {creatorProfile.creatorName}
          </div>
          <div className="text-xs font-medium text-slate-600">
            {creatorProfile.slogan}
          </div>
        </div>
      </div>
    );
  }

  const hasPanoramaImage = !isEmpty(currentStorySlide?.panoramaImage);

  return (
    <IonContent>
      <div
        className="relative z-10 mx-auto flex min-h-full max-w-xl flex-col items-center justify-between bg-cover bg-center"
        style={{ backgroundImage: `url(${currentStorySlide?.backgroundImage?.url || storyPlayerBackground})` }}
      >
        {/* Floating back button */}
        <FloatingBackButton />

        {/* Close tour button */}
        <CloseStoryPlayerButton />

        {/* Tour & tour stop */}
        {storyPlayerHeader}

        {/* Story player navigation */}
        <div
          className="absolute z-10 w-full px-4"
          style={{ marginTop: "calc(var(--ion-safe-area-top, 12px) + 34px)" }}
        >
          <StoryPlayerNavigation />
        </div>

        <div
          className="w-full grow"
          style={{
            marginTop: (hasPanoramaImage && !currentStorySlide?.quiz)
              ? 0
              : "calc(var(--ion-safe-area-top, 12px) + 72px)",
          }}
        >
          {/* Slide media in a slider */}
          {(!currentStorySlide?.quiz || !currentTour) && <MediaSlider storySlide={currentStorySlide} />}

          {/* Slide actions */}
          <SlideActions storySlide={currentStorySlide} />

          {!!currentStorySlide?.quiz && !!currentTour && (!currentStorySlide?.quiz?.isVisibleAtEndOfAudio || percentagePlayed === 1) && <div
            style={{ marginTop: "calc(var(--ion-safe-area-top, 12px) + 72px)" }}
            className="mx-5"
          >
            <TaskBlockRenderer
              quiz={currentStorySlide?.quiz}
              userQuiz={userQuiz}
              getUpdatedUserTour={getUserTour}
            />
          </div>}
        </div>

        {/* Slide subtitles */}
        <Subtitles storySlide={currentStorySlide} />

        {/* Story categories */}
        <CategoryTags
          categories={currentStory.categories}
          wrapperClassName="mb-2.5 w-full gap-1.5 px-6"
        />

        {/* Creator profile & like button */}
        <div className="z-10 mb-2.5 flex w-full items-center justify-between px-4">
          {/* Creator profile */}
          <div>{creatorProfileBadge}</div>

          {/* Evaluation buttons */}
          <StoryEvaluationButtons story={currentStory} />
        </div>

        {/* Story title */}
        <div className="z-10 w-full truncate px-4 text-center text-lg font-bold leading-6 text-white/95">
          {currentStory.title}
        </div>

        {/* Seek bar */}
        <div className="z-10 -mb-2 -mt-3 w-full px-4">
          <SeekBar />
        </div>

        {/* Media controls */}
        <div className="z-10 flex w-full items-center justify-between px-5 pb-4 text-white/95">
          <PlaybackRateControl />
          <NavigateBackwardButton />
          <SkipBackwardButton />
          <TogglePlayPauseButton />
          <SkipForwardButton />
          <NavigateForwardButton userTour={userTour} />
          <div className="flex items-center justify-center">
            <ShareButton story={currentStory} />
            <div className="flex items-center justify-center mx-2">
              <InviteToGroupSharingButton displayHint={true}/>
            </div>
            <DetachFromGroupSharingButton displayHint={true}/>
          </div>
        </div>

        <NavigateBackwardButton
          className="pointer-events-auto absolute left-0 top-1/2 bottom-1/2 z-20 -ml-2 rounded-tr-full rounded-br-full bg-white/[0.35] p-2 text-[2.25rem] text-white/95 transition-all hover:bg-white/50 hover:text-white"
          skipIfEmpty={true}
        />
        <NavigateForwardButton
          className="pointer-events-auto absolute right-0 top-1/2 bottom-1/2 z-20 -mr-2 rounded-tl-full rounded-bl-full bg-white/[0.35] p-2 text-[2.25rem] text-white/95 transition-all hover:bg-white/50 hover:text-white"
          skipIfEmpty={true}
          userTour={userTour}
        />

        <StoryPlayerBottomMenu
          storySlide={currentStorySlide}
          story={currentStory}
          tour={currentTour}
        />
      </div>
    </IonContent>
  );
};

export default StoryPlayer;
