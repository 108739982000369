// Light theme for Vime.js (optional, extends default theme)
import "@vime/core/themes/default.css";
import "@vime/core/themes/light.css";

import {
  Player as VimePlayer,
  Video as VimeVideo,
  Youtube as VimeYoutube,
} from "@vime/react";
import {
  BaseFileFieldsFragment,
  VideoRecord,
} from "../../graphql/dato/__generated__/dato-graphql.generated";
import ImageCaption from "./ImageCaption";
import ImageCopyright from "./ImageCopyright";

const ExternalVideoPlayer: React.FC<{
  videoRecord: VideoRecord;
  aspectRatio?: string;
  className?: string;
}> = ({
  videoRecord,
  aspectRatio = '16:9',
  className = "",
}) => {
  const isUploadedVideo = !!videoRecord.videoFile;
  const isExternalVideo = !!videoRecord.externalVideoUrl;

  const isYoutubeVideo = videoRecord.externalVideoUrl?.provider === "youtube";
  const youtubeVideoId = videoRecord.externalVideoUrl?.providerUid;

  return (
    <>
      <VimePlayer controls theme="light" aspectRatio={aspectRatio} className={className}>
        {/* Embed a video uploaded to DatoCMS */}
        {isUploadedVideo && (
          <VimeVideo>
            <source
              data-src={videoRecord.videoFile?.url}
              type={videoRecord.videoFile?.mimeType}
            />
          </VimeVideo>
        )}

        {/* Embed a video that's been referenced from Youtube */}
        {isExternalVideo && isYoutubeVideo && (
          <VimeYoutube videoId={youtubeVideoId!} />
        )}
      </VimePlayer>
      <ImageCaption caption={videoRecord.caption} />
      {isUploadedVideo && (
        <ImageCopyright
          fileField={videoRecord.videoFile as BaseFileFieldsFragment}
        />
      )}
    </>
  );
};

export default ExternalVideoPlayer;
