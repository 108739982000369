import { useTranslation } from "react-i18next";
import { IonButton, useIonViewDidEnter } from '@ionic/react';

import FloatingBackButton from "../../components/buttons/FloatingBackButton";
import guidableLogo from "../../assets/GU_Logo_RZ-RGB_horizontal.svg";
import { MixpanelEvents, useMixpanel } from "../../contexts/MixpanelContext";
import useRoutes from '../../hooks/useRoutes';

const SignUpOrLogInWall: React.FC = () => {
  const { t } = useTranslation();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { localePath } = useRoutes();

  useIonViewDidEnter(() => {
    // TODO: Track how many stories were viewed before the sign up wall popped up, if it popped up
    //  while viewing a tour or a story directly etc.
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_SIGN_UP_OR_LOGIN_WALL);
    }
  });

  return (
    <div className="relative mx-auto h-full w-full max-w-xl bg-primary/80">
      {/* Floating back button */}
      <FloatingBackButton />

      <div className="flex h-full flex-col items-center justify-center">
        <div className="mx-3 -mt-6 flex flex-col items-center justify-start rounded-lg bg-white/95 px-5 pt-6 pb-5 shadow-lg md:-mt-8 md:px-10 md:pt-8 md:pb-7">
          <img
            src={guidableLogo}
            alt="guidable – stories to explore"
            className="max-w-[260px] md:max-w-[340px]"
          />
          <div className="my-4 flex flex-col items-center md:my-6">
            <div className="mt-0.5 max-w-sm rounded-md bg-primary px-5 pt-3 pb-4 text-white shadow-md">
              <div className="text-xl font-semibold leading-[1.6rem]">
                {t("signUpOrLoginPage.subtitle")}
              </div>

              <div className="mt-2.5">
                <div className="mb-0.5 font-semibold">
                  {t("signUpOrLoginPage.reasons.headline")}
                </div>

                <ul className="ml-3 list-disc space-y-1 text-left">
                  <li className="leading-[1.35rem]">
                    {t("signUpOrLoginPage.reasons.reason1")}
                  </li>
                  <li className="leading-[1.35rem]">
                    {t("signUpOrLoginPage.reasons.reason2")}
                  </li>
                  <li className="leading-[1.35rem]">
                    {t("signUpOrLoginPage.reasons.reason3")}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-center">
            <IonButton routerLink={localePath('sign-up')}>{t("dictionary.signUp")}</IonButton>
            <div className="mx-2 text-slate-600 md:mx-3">
              {t("dictionary.or")}
            </div>
            <IonButton routerLink={localePath('login')}>{t("dictionary.logIn")}</IonButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpOrLogInWall;
