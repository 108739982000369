import { Preferences } from '@capacitor/preferences';

const setItemToStorage = async (key: string, value: any) => {
  await Preferences.set({
    key,
    value: JSON.stringify(value),
  });
};

const removeItemFromStorage = async (key: string) => {
  await Preferences.remove({ key });
};

const getItemFromStorage = async (key: string) => {
  const ret = await Preferences.get({ key });
  return ret.value ? JSON.parse(ret.value) : null;
};

const getStorageKeys = async () => {
  const { keys } = await Preferences.keys();
  return keys;
};

export { setItemToStorage, removeItemFromStorage, getItemFromStorage, getStorageKeys };
