import { useCallback, useEffect, useState } from 'react';
import { uniqBy } from 'lodash-es';

import { useLocale } from '../contexts/LocaleContext';
import { useCitiesWithStoriesCountQuery } from '../graphql/dato/__generated__/dato-graphql.generated';
import { City } from '../interfaces/Interfaces';

const useCitiesWithStoriesCount = (pause: boolean) => {
  const { queryLocale, locale } = useLocale();

  const [citiesInQueryLocale, setCitiesInQueryLocale] = useState<{ [key: string]: City[]; }>({});
  const [isAllCitiesReceivedInQueryLocale, setIsAllCitiesReceivedInQueryLocale] = useState<{ [key: string]: boolean; }>({});

  const citiesPageSize = 100;
  const [citiesPageNumber, setCitiesPageNumber] = useState(0);

  const citiesQueryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      first: citiesPageSize,
      skip: citiesPageNumber * citiesPageSize,
    };
  }, [queryLocale, citiesPageNumber]);

  const [citiesResult] = useCitiesWithStoriesCountQuery({
    variables: citiesQueryVariables(),
    pause,
  });

  const { data: citiesData } = citiesResult;

  // Set cities when data is available
  useEffect(() => {
    if (citiesData?.cities) {
      // Add cities to the list
      const returnedCities = citiesData?.cities as City[];
      const newCitiesInQueryLocale = uniqBy(
        [...(citiesInQueryLocale?.[locale] || []), ...returnedCities],
        (category) => category.id
      );
      setCitiesInQueryLocale({
        ...citiesInQueryLocale,
        [locale]: newCitiesInQueryLocale,
      });

      // Fetch more cities if available
      if (returnedCities.length === citiesPageSize) {
        // By setting the page size another GraphQL query for the next page gets executed
        setCitiesPageNumber(citiesPageNumber + 1);
      } else {
        setIsAllCitiesReceivedInQueryLocale({
          ...isAllCitiesReceivedInQueryLocale,
          [locale]: true,
        });
      }
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [citiesData]
  );

  useEffect(() => {
    // Reset the page number if the map center changes
    setCitiesPageNumber(0);
  }, [queryLocale]);

  return {
    citiesInQueryLocale,
    isAllCitiesReceivedInQueryLocale,
  }
};

export default useCitiesWithStoriesCount;
