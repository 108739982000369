import { useState } from 'react';
import {
  IonContent,
  IonIcon,
  IonItem,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonList,
  useIonViewDidEnter
} from '@ionic/react';
import { filter, map } from 'lodash-es';
import { trash } from 'ionicons/icons';

import AppLayout from '../layouts/AppLayout';
import { getItemFromStorage, getStorageKeys } from '../helpers/storage-helpers';
import { Tour } from '../interfaces/Interfaces';
import FloatingBackButton from '../components/buttons/FloatingBackButton';
import useDownload from '../hooks/useDownload';
import FullWidthTourCard from '../components/cards/FullWidthTourCard';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';

const DownloadsPage: React.FC = () => {
  const { removeTourFromDownloads, transformTour } = useDownload();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [downloadedTours, setDownloadedTours] = useState<Tour[]>([]);

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_YOUR_DOWNLOADS);
    }

    const getDownloadedTours = async () => {
      const storageKeys = await getStorageKeys();
      const tourStorageKeys = filter(storageKeys, storageKey => storageKey.startsWith('tour-'));
      const downloadedTours = await Promise.all(
        map(tourStorageKeys, tourStorageKey => getItemFromStorage(tourStorageKey))
      );
      const downloadedToursWithUpdatedMediaPaths = await Promise.all(downloadedTours?.map(async (tour) => {
        const { transformedTour } = await transformTour(tour, 'transformRelativePathToSrc');
        return transformedTour;
      }));

      setDownloadedTours(downloadedToursWithUpdatedMediaPaths);
    };

    getDownloadedTours();
  });

  const deleteTourFromDevice = async (tour: Tour) => {
    await removeTourFromDownloads(tour);
    const updatedDownloadedTours = filter(downloadedTours, downloadedTour => downloadedTour?.id !== tour.id);
    setDownloadedTours(updatedDownloadedTours);
  };

  return (
    <AppLayout>
      <IonContent style={{
        '--padding-top': 'var(--ion-safe-area-top)'
      }}>
        <div className="relative mx-auto max-w-md pt-[65px]">
          <FloatingBackButton style={{ left: '6px' }}/>

          <IonList>
            {map(downloadedTours, (tour) => <IonItemSliding key={`tour-card-${tour.id}`}>
              <IonItem lines="none" style={{ '--padding-start': 0, '--inner-padding-end': 0 }}>
                <div className="px-[2px] w-full">
                  <FullWidthTourCard tour={tour}/>
                </div>
              </IonItem>
              <IonItemOptions side="end" className="py-[10px]">
                <IonItemOption color="danger" onClick={() => deleteTourFromDevice(tour)}>
                  <IonIcon slot="icon-only" icon={trash}/>
                </IonItemOption>
              </IonItemOptions>
            </IonItemSliding>)}
          </IonList>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default DownloadsPage;
