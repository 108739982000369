import { useState } from 'react';
import { useCustomCompareEffect } from 'react-use';
import { isEqual } from 'lodash-es';

import {
  TourRatingStatistics,
  useGetToursRatingStatisticsLazyQuery,
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useError from './useError';

const useToursRatingStatistics = (tourIds: string[], isVisible: boolean) => {
  const { handleBackendError } = useError();

  const [getToursRatingStatisticsQuery] = useGetToursRatingStatisticsLazyQuery();

  const [toursRatingStatistics, setToursRatingStatistics] = useState<TourRatingStatistics[]>([]);

  useCustomCompareEffect(() => {
    const getToursRatingStatistics = async () => {
      if (isVisible && tourIds?.length) {
        await handleBackendError(async () => {
          const { data, error } = await getToursRatingStatisticsQuery({
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                datoTourIds: tourIds,
              }
            }
          });

          if (error) return error;

          const statistics = data?.tourReview?.getToursRatingStatistics;
          if (statistics) {
            setToursRatingStatistics(statistics);
          }
        });
      }
    };

    getToursRatingStatistics();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tourIds, isVisible],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  return { toursRatingStatistics };
};

export default useToursRatingStatistics;
