import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar
} from '@ionic/react';
import { chevronBackOutline, chevronDownOutline } from 'ionicons/icons';
import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { countBy, find, get, head, includes, map, reduce } from 'lodash-es';

import { Category, Tour } from '../../interfaces/Interfaces';
import { checkIfTourSelectedByCategory } from '../../helpers/tour-helpers';
import tuneIcon from '../../assets/tours/tune.svg';
import RecordsByCategoriesFilterSection from './RecordsByCategoriesFilterSection';
import { isIosVersion } from '../../helpers/device-helpers';

const ToursFilter: React.FC<{
  tours: Tour[];
  categories?: Category[];
  previouslySelectedCategoryIds: string[];
  setSelectedCategories: (categories: string[]) => void;
}> = ({
  tours,
  categories,
  previouslySelectedCategoryIds,
  setSelectedCategories,
}) => {
  const { t } = useTranslation();

  const [selectedCategoryIds, setSelectedCategoryIds] = useState<string[]>([]);
  const [tourCountByCategories, setTourCountByCategories] = useState<{ [key: string]: number }>({});
  const [totalToursCount, setTotalToursCount] = useState<number>(0);

  const modal = useRef<HTMLIonModalElement>(null);

  useEffect(() => {
    setSelectedCategoryIds(previouslySelectedCategoryIds);
  }, [previouslySelectedCategoryIds]);

  useEffect(() => {
    const toursByCategoriesCount: { [key: string]: number } = reduce(categories, (acc, category) => {
      const toursByCategory = tours.filter((tour: Tour) => {
        const tourCategoryIds = map(tour?.categories, category => category.id);
        return includes(tourCategoryIds, category.id)
      });

      return {
        ...acc,
        [category.id]: toursByCategory.length,
      }
    }, {});

    setTourCountByCategories(toursByCategoriesCount);

    // sort categories by the count of attached stories
    categories?.sort((a, b) => toursByCategoriesCount[b.id] - toursByCategoriesCount[a.id]);
  }, [tours, categories]);

  useEffect(() => {
    const selectedToursCount = countBy(tours, tour =>
      checkIfTourSelectedByCategory(tour, selectedCategoryIds, categories)
    );

    setTotalToursCount(selectedToursCount?.true || 0);
  }, [selectedCategoryIds, tours, categories]);

  const updateSelectedCategoryIds = (categoryId: string, checked: boolean) => {
    checked ?
      setSelectedCategoryIds([...selectedCategoryIds, categoryId]) :
      setSelectedCategoryIds(selectedCategoryIds.filter((id) => id !== categoryId));
  };

  const updateAllSelectedCategoryIds = (type: 'clearAll' | 'selectAll' = 'clearAll') => {
    const newSelectedCategoryIds = type === 'clearAll' ? [] : (categories?.map(category => category.id) || []);
    setSelectedCategoryIds(newSelectedCategoryIds)
  };

  const saveResults = () => {
    setSelectedCategories(selectedCategoryIds);
    modal?.current?.dismiss();
  };

  const closeFilter = () => {
    setSelectedCategoryIds(previouslySelectedCategoryIds);
    modal?.current?.dismiss();
  };

  return (
    <>
      <IonButton
        id="open-tours-filter-modal"
        expand="block"
        fill="outline"
        size="large"
        className="m-3"
        style={{
          '--background': '#ffffff',
          '--border-radius': '8px',
          '--border-width': '1px',
          '--border-color': '#D5D6DC'
        }}
      >
        <IonIcon slot="start" src={tuneIcon}/>
        <div className="w-full text-left text-[#687582] text-[0.875rem] normal-case tracking-normal">
          {selectedCategoryIds?.length === categories?.length ?
            t('tours.filter.buttons.allCategories') :
            selectedCategoryIds?.length ?
              `${get(find(categories, ['id', head(selectedCategoryIds)]), 'title')}${selectedCategoryIds?.length > 1 ?
                `, +${selectedCategoryIds?.length - 1}` :
                ''}` :
              ''
          }
        </div>
        <IonIcon slot="end" icon={chevronDownOutline} className="text-black opacity-50 text-[1.25rem]"/>
      </IonButton>

      {/*set animated false for ios 17,because animation breaks the popup*/}
      {/*on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)*/}
      <IonModal ref={modal} animated={!isIosVersion(17)} trigger="open-tours-filter-modal">
        <IonHeader className="ion-no-border">
          <IonToolbar color="light">
            <IonButtons slot="start">
              <IonButton onClick={closeFilter}>
                <IonIcon icon={chevronBackOutline} />
              </IonButton>
            </IonButtons>
            <IonTitle className="text-[1rem] text-center font-semibold text-[#000]">
              {t("tours.filter.header")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent className="ion-padding relative">
          <RecordsByCategoriesFilterSection
            sectionTitle="tours.filter.categoriesSection.title"
            categories={categories}
            selectedCategoryIds={selectedCategoryIds}
            recordsCountByCategories={tourCountByCategories}
            updateSelected={updateSelectedCategoryIds}
            updateAllSelected={updateAllSelectedCategoryIds}
          />

          <div className="h-[100px]"/>

          <IonButton
            expand="block"
            size="large"
            className="fixed bottom-0 z-10 left-0 right-0 mx-10 mt-5 mb-10 normal-case"
            onClick={saveResults}
          >
            <span
              className="font-semibold text-[1rem]"
            >{t("tours.filter.buttons.applyFilter", { number: totalToursCount })}</span>
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
};

export default memo(ToursFilter);
