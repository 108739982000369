import { Tour } from "../../interfaces/Interfaces";
import Slider from "./Slider";
import TourCard from "../cards/TourCard";
import {
  TourRatingStatistics,
  UserTourAudioStatistics
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';

import { find } from 'lodash-es';

const TourCardSlider: React.FC<{
  tours: Tour[];
  toursRatingStatistics?: TourRatingStatistics[];
  userToursAudioStatistics?: UserTourAudioStatistics[];
}> = ({ tours, toursRatingStatistics, userToursAudioStatistics }) => {
  return (
    <Slider
      sliderProps={{
        // Enable virtual slides
        virtual: true,

        // Default config (when window width is < 320px)
        slidesPerView: 1,
        spaceBetween: 6,

        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1.3,
            spaceBetween: 6,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1.7,
            spaceBetween: 6,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2.4,
            spaceBetween: 6,
          },
        },

        // Round lengths of slides to whole numbers
        //  since there's text on most images
        roundLengths: true,
      }}
    >
      {tours.map((tour) => {
        const tourRatingStatistics = find(toursRatingStatistics, ['datoTourId', tour.id]);
        const userTourAudioStatistics = find(userToursAudioStatistics, ['datoTourId', tour.id]);

        return (
          <TourCard
            tour={tour}
            tourRatingStatistics={tourRatingStatistics}
            userTourAudioStatistics={userTourAudioStatistics}
            key={`tour-card-${tour.id}`}
          />
        )
      })}
    </Slider>
  );
};

export default TourCardSlider;
