import {
  IonButtons,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonPage,
  IonIcon,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

const ConfirmationModal: React.FC<{
  onDismiss: (isConfirmed?: boolean) => void;
  title: string;
  text: string;
  note?: string;
  confirmButtonText: string;
  cancelButtonText: string;
}> = ({ onDismiss, title, text, note, confirmButtonText, cancelButtonText }) => {
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss()}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="px-5 pb-5 h-full">
          <div className="text-center h-full max-w-md p-5 mx-auto">
            <h3 className="text-[1.5rem] text-[#414254] font-bold pb-10">
              {t(title)}
            </h3>
            <div className="text-[#414254] pb-10">
              <p className="text-[0.875]">{t(text)}</p>
              {!!note && <p className="text-[0.75rem] pt-2">{t(note)}</p>}
            </div>

            <div className="flex flex-col">
              <IonButton fill="outline" onClick={() => onDismiss(true)}>{t(confirmButtonText)}</IonButton>
              <IonButton onClick={() => onDismiss()}>{t(cancelButtonText)}</IonButton>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ConfirmationModal;
