import { flatten, intersection, map, some } from 'lodash-es';

import { Category, ExperienceLevel, Story, Tour } from '../interfaces/Interfaces';
import { isFreeTour } from './tour-helpers';

const checkIfStorySelectedByCategory = (
  story: Story,
  selectedCategoriesInLocale: string[],
  categoriesInLocale?: Category[],
) => {
  // if all categories are selected in the filter mark the story as selected even if any category isn't attached to it
  if (selectedCategoriesInLocale?.length === categoriesInLocale?.length) {
    return true;
  }

  const storyCategoryIds = map(story?.categories, category => category.id);
  return !!intersection(storyCategoryIds, selectedCategoriesInLocale)?.length;
};

const checkIfStorySelectedByExperienceLevel = (
  story: Story,
  selectedExperienceLevelsInLocale: string[],
  experienceLevelsInLocale?: ExperienceLevel[],
) => {
  // if all experience levels are selected in the filter mark the story as selected even if any experience level isn't attached to it
  if (selectedExperienceLevelsInLocale?.length === experienceLevelsInLocale?.length) {
    return true;
  }

  const storyExperienceLevels = map(story?.experienceLevels, experienceLevel => experienceLevel.id);
  return !!intersection(storyExperienceLevels, selectedExperienceLevelsInLocale)?.length;
};

const isPremiumStory = (story: Story) => {
  return !!story.isPremium || some(
    flatten(map(story._allReferencingTourStops, (tourStop) => tourStop._allReferencingTours)),
    (tour) => !isFreeTour(tour as Tour)
  )
};

export { checkIfStorySelectedByCategory, checkIfStorySelectedByExperienceLevel, isPremiumStory };
