import { IonIcon, IonSpinner, useIonRouter } from '@ionic/react';
import { cloudDoneOutline, cloudDownloadOutline } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { Capacitor } from '@capacitor/core';
import { find, includes } from 'lodash-es';

import { getStorageKeys } from '../../helpers/storage-helpers';
import useDownload from '../../hooks/useDownload';
import useAuthStore from '../../stores/useAuthStore';
import useDownloadStore from '../../stores/useDownloadStore';
import useRoutes from '../../hooks/useRoutes';
import { isAccessibleTour } from '../../helpers/tour-helpers';
import { Tour } from '../../interfaces/Interfaces';
import { useTransaction } from '../../contexts/TransactionContext';

const DownloadTourButton: React.FC<{ tour: Tour; }> = ({ tour }) => {
  const router = useIonRouter();
  const { downloadTour } = useDownload();
  const { currentPath, loginPath } = useRoutes();
  const { hasPremiumAccess, accessibleTourIds } = useTransaction();

  const [isTourDownloading, setIsTourDownloading] = useState<boolean>(false);
  const [isTourDownloaded, setIsTourDownloaded] = useState<boolean>(false);
  const [downloadingProgress, setDownloadingProgress] = useState<number>(0);

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const downloadingEntityIds = useDownloadStore((state) => state.downloadingEntityIds);
  const downloadProgress = useDownloadStore((state) => state.downloadProgress);
  const downloadedEntityIds = useDownloadStore((state) => state.downloadedEntityIds);
  const setDownloadedEntityId = useDownloadStore((state) => state.setDownloadedEntityId);

  useEffect(() => {
    const checkIfTourIsDownloaded = async () => {
      if (tour?.id) {
        // check if the tour is in the capacitor storage
        const storageKeys = await getStorageKeys();
        const tourKey = find(storageKeys, (storageKey) => storageKey.includes(tour.id));
        if (tourKey) {
          setDownloadedEntityId(tour.id);
        }
      }
    };

    checkIfTourIsDownloaded();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    setIsTourDownloading(includes(downloadingEntityIds, tour?.id));
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [downloadingEntityIds]
  );

  useEffect(() => {
    setIsTourDownloaded(includes(downloadedEntityIds, tour?.id));
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [downloadedEntityIds]
  );

  useEffect(() => {
    const currentTourProgress = downloadProgress[tour?.id];
    const percentageProgress = currentTourProgress ?
      Math.round(currentTourProgress.downloaded / currentTourProgress.total * 100) :
      0;
    setDownloadingProgress(percentageProgress);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [downloadProgress]
  );

  const isNativePlatform = Capacitor.isNativePlatform();

  return (
    <>
      {isNativePlatform && <div className="relative flex w-[24px] h-[24px]">
        {(!isAuthenticated || (!isTourDownloaded && !isTourDownloading)) && <IonIcon
          icon={cloudDownloadOutline}
          className="shrink-0 cursor-pointer w-full h-full"
          onClick={() => {
            if (tour && !isAccessibleTour(tour, accessibleTourIds) && !hasPremiumAccess) return;

            isAuthenticated ? downloadTour(tour?.id) : router.push(loginPath(currentPath()));
          }}
        />}

        {isAuthenticated && isTourDownloading && <>
          <IonSpinner name="dots" color="primary" className="absolute bottom-[-8px] w-full h-full" />
          <div
            className="absolute text-[0.625rem] text-[#e38873] font-bold left-1/2 -translate-x-1/2 whitespace-nowrap"
          >{downloadingProgress} %</div>
        </>}

        {isAuthenticated && isTourDownloaded && <IonIcon
          icon={cloudDoneOutline}
          className="shrink-0 w-full h-full"
        />}
      </div>}
    </>
  );
};

export default DownloadTourButton;
