import { useState } from 'react';
import { Keyboard } from '@capacitor/keyboard';
import { Capacitor } from '@capacitor/core';
import { usePrevious } from 'react-use';

const useKeyboard = () => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState<boolean>(false);
  const wasKeyboardOpen = usePrevious(isKeyboardOpen);

  if (Capacitor.isNativePlatform()) {
    Keyboard.addListener('keyboardDidShow', () => {
      setIsKeyboardOpen(true);
    });

    Keyboard.addListener('keyboardDidHide', () => {
      setIsKeyboardOpen(false);
    });
  }

  return {
    isKeyboardOpen,
    wasKeyboardOpen
  };
};

export default useKeyboard;
