import { useCallback, useEffect, useState } from "react";
import { Share } from "@capacitor/share";
import { IonIcon } from "@ionic/react";
import { checkmarkSharp, shareOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { Story, Tour } from "../../interfaces/Interfaces";
import { useClipboard } from "use-clipboard-copy";
import clsx from "clsx";
import { useLocation } from "react-router";
import { ItemStatus } from "../../graphql/dato/__generated__/dato-graphql.generated";

const ShareButton: React.FC<{ tour?: Tour; story?: Story }> = ({
  tour,
  story,
}) => {
  const record = (tour || story)!;
  const { t } = useTranslation();

  const [canShare, setCanShare] = useState(false);
  const clipboard = useClipboard({
    copiedTimeout: 3000, // timeout duration in milliseconds
  });

  const location = useLocation();
  let shareHost: string;

  const { host: currentHost } = window.location;

  if (
    currentHost.match(/preview\.guidable\.(com|de)/g) ||
    record._status === ItemStatus.draft ||
    record._status === ItemStatus.updated
  ) {
    shareHost = "https://preview.guidable.com";
  } else {
    shareHost = "https://app.guidable.com";
  }

  const sharePath = location.pathname + location.search;
  const shareUrl = shareHost + sharePath;

  let shareText: string;
  if (tour) {
    shareText = `${tour.title} - ${t("shareButton.tourOnGuidable")}`;
  } else if (story) {
    shareText = `${story.title} - ${t("shareButton.storyOnGuidable")}`;
  }

  const shareStory = () => {
    Share.share({
      text: shareText,
      url: shareUrl,
    });
  };

  const copyStoryLinkToClipboard = useCallback(() => {
    clipboard.copy(shareUrl);
  }, [clipboard, shareUrl]);

  // Check if the browser supports the Web Sharing API
  useEffect(() => {
    let isMounted = true;

    Share.canShare().then((canShare) => {
      if (isMounted) {
        setCanShare(canShare.value);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  if (canShare) {
    return (
      <IonIcon
        icon={shareOutline}
        onClick={() => shareStory()}
        className="shrink-0 cursor-pointer text-[24px] leading-[24px]"
      />
    );
  } else {
    return (
      <IonIcon
        icon={clipboard.copied ? checkmarkSharp : shareOutline}
        onClick={() => copyStoryLinkToClipboard()}
        className={clsx(
          "shrink-0 text-[24px] leading-[24px]",
          !clipboard.copied && "cursor-pointer"
        )}
      />
    );
  }
};

export default ShareButton;
