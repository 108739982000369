import { IonButton, IonIcon, useIonRouter } from '@ionic/react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import useStoryExplorationStore from '../../stores/useStoryExplorationStore';
import useAuthStore from '../../stores/useAuthStore';
import { useExploreStoriesQuery } from '../../graphql/dato/__generated__/dato-graphql.generated';
import { Category, ExperienceLevel, Story } from '../../interfaces/Interfaces';
import { useLocale } from '../../contexts/LocaleContext';
import useRoutes from '../../hooks/useRoutes';
import useToast from '../../hooks/useToast';
import useAnalyticsStore from '../../stores/useAnalyticsStore';
import { useCity } from '../../contexts/CityContext';
import exploreModeIcon from '../../assets/map/explore-mode.svg';
import ExploreModeButtonHint from '../onboardingHints/ExploreModeButtonHint';
import useOnboardingHint from '../../hooks/useOnboardingHint';

const StoryMapExploreModeButton: React.FC<{
  selectedCategories: string[];
  categories: Category[];
  selectedExperienceLevels: string[];
  experienceLevels: ExperienceLevel[];
  selectedIsIncludingViewedStories: boolean;
}> = ({
  selectedCategories,
  categories,
  selectedExperienceLevels,
  experienceLevels,
  selectedIsIncludingViewedStories
}) => {
  const router = useIonRouter();
  const { currentPath, loginPath } = useRoutes();
  const { queryLocale } = useLocale();
  const { presentToast } = useToast();
  const { currentCity } = useCity();

  const hintTargetRef = useRef<HTMLIonButtonElement>(null);

  useOnboardingHint({
    component: ExploreModeButtonHint,
    hintTargetRef,
    hintName: 'storyMapPageExploreModeButton',
    isVisible: true,
  });

  const [isAllStoriesReceived, setIsAllStoriesReceived] = useState<boolean>(false);

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const isExploreModeEnabled = useStoryExplorationStore((state) => state.isExploreModeEnabled);
  const storiesToExplore = useStoryExplorationStore((state) => state.storiesToExplore);
  const setIsExploreModeEnabled = useStoryExplorationStore((state) => state.setIsExploreModeEnabled);
  const setStoriesToExplore = useStoryExplorationStore((state) => state.setStoriesToExplore);
  const viewedStoryIds = useAnalyticsStore((state) => state.viewedStoryIds);

  const pageSize = 100;
  const [pageNumber, setPageNumber] = useState(0);

  const queryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      first: pageSize,
      skip: pageNumber * pageSize,
      cityId: currentCity?.id,
      // avoid filtering by categories and/or experience levels if all categories and/or experience levels are selected in the filter
      // because it returns a result with no record to which no category or experience level is attached
      ...categories?.length === selectedCategories?.length ? {} : { categoryIds: selectedCategories },
      ...experienceLevels?.length === selectedExperienceLevels?.length ? {} : { experienceLevelIds: selectedExperienceLevels },
      ...selectedIsIncludingViewedStories ? {} : { excludeStoryIds: viewedStoryIds },
    };
  }, [
    queryLocale, pageNumber, selectedCategories, selectedExperienceLevels, currentCity?.id,
    categories?.length, experienceLevels?.length, selectedIsIncludingViewedStories, viewedStoryIds
  ]);

  const [result] = useExploreStoriesQuery({
    variables: queryVariables(),
    pause: isAllStoriesReceived ||
      !currentCity?.id ||
      !isExploreModeEnabled ||
      !selectedCategories?.length ||
      !selectedExperienceLevels?.length,
    requestPolicy: 'network-only',
  });

  const { data } = result;

  // Set stories when data is available
  useEffect(
    () => {
      if (data?.stories) {
        setStoriesToExplore(data.stories as Story[]);

        // Fetch more stories if available
        if (data?.stories?.length === pageSize) {
          // By setting the page size another GraphQL query for the next page gets executed
          setPageNumber(pageNumber + 1);
        } else {
          setIsAllStoriesReceived(true);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  useEffect(
    () => {
      if (isExploreModeEnabled && isAllStoriesReceived && !storiesToExplore?.length) {
        presentToast('storyMap.exploreModeButton.errors.noStoriesToExplore', 'danger');
        setIsExploreModeEnabled(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isExploreModeEnabled, isAllStoriesReceived, storiesToExplore]
  );

  useEffect(() => {
    if (!isExploreModeEnabled) {
      setIsAllStoriesReceived(false);
    }
  }, [isExploreModeEnabled]);

  const updateExploreModeStatus = () => {
    if (!isAuthenticated) {
      router.push(loginPath(currentPath()));
      return;
    }

    if (!selectedExperienceLevels?.length || !selectedCategories?.length) {
      presentToast('storyMap.exploreModeButton.errors.selectCategoryOrExperienceLevel', 'danger');
      return;
    }

    setIsExploreModeEnabled(!isExploreModeEnabled);
  };

  return (
    <IonButton
      ref={hintTargetRef}
      className="absolute w-[64px] h-[64px] z-50 bottom-[16px] left-[16px]"
      style={{
        '--border-radius': '50%',
        '--background': isExploreModeEnabled ? 'linear-gradient(160deg, #3EA9E5 13.45%, #3E6DE5 126.27%)' : '#ffffff',
        '--box-shadow': '0px 6px 20px 0px rgba(62, 145, 230, 0.21), 0px 2px 7px 0px rgba(0, 36, 85, 0.20)',
        '--border-style': '1px solid #D5D6DC',
      }}
      onClick={updateExploreModeStatus}
    >
      <IonIcon
        src={exploreModeIcon}
        className={clsx(
          "w-full h-full",
          isExploreModeEnabled ? "text-[#ffffff]" : "text-[#9aa0a6]"
        )}
      />
    </IonButton>
  );
};

export default memo(StoryMapExploreModeButton);
