import { memo } from 'react';
import { find } from 'lodash-es';
import { DeepPartial } from 'react-hook-form';

import { Tour, TourStop } from '../../interfaces/Interfaces';
import TourStopMarker from './TourStopMarker';
import { UserTour, UserTourAudioStatistics } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { isAccessibleTour } from '../../helpers/tour-helpers';
import { useTransaction } from '../../contexts/TransactionContext';
import { checkQuizzesCompletionOfPreviousTourStops } from '../../helpers/tour-stop-helpers';

const TourStopMarkers: React.FC<{
  tour: Tour;
  userTour?: DeepPartial<UserTour> | null;
  currentTourStop?: TourStop | null | undefined;
  userTourAudioStatistics?: UserTourAudioStatistics | null;
}> = ({ tour, userTour, currentTourStop, userTourAudioStatistics }) => {
  const { hasPremiumAccess, accessibleTourIds } = useTransaction();

  const isDisabledByQuizzes = currentTourStop && checkQuizzesCompletionOfPreviousTourStops(currentTourStop, userTour);

  return (
    <>
      {/* ignore preview tour stop */}
      {tour.tourStops?.filter((tourStop) => !tourStop.isPreviewTourStop)?.map((tourStop) => {
        const isActive = currentTourStop
          ? tourStop.id === currentTourStop.id
          : false;

        const userTourStopAudioStatistics = find(
          userTourAudioStatistics?.userTourStopsAudioStatistics,
          ['datoTourStopId', tourStop.id]
        );

        return (
          <TourStopMarker
            key={`tour-stop-marker-${tourStop.id}`}
            tourStop={tourStop}
            isActive={isActive}
            isCompleted={userTourStopAudioStatistics?.audioProgress === 1}
            isDisabledByQuizzes={!!isDisabledByQuizzes}
            isDisabledByPremiumAccess={!hasPremiumAccess && !isAccessibleTour(tour, accessibleTourIds)}
          />
        );
      })}
    </>
  );
};

export default memo(TourStopMarkers);
