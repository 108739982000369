import {
  IonButton,
  IonCheckbox,
  IonIcon,
  IonItem,
  IonItemGroup,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { Category } from '../../interfaces/Interfaces';

const RecordsByCategoriesFilterSection: React.FC<{
  sectionTitle: string;
  categories?: Category[];
  selectedCategoryIds: string[];
  recordsCountByCategories: { [key: string]: number };
  updateSelected: (id: string, isChecked: boolean) => void;
  updateAllSelected: (type?: 'clearAll' | 'selectAll') => void;
}> = ({
  sectionTitle,
  categories,
  selectedCategoryIds,
  recordsCountByCategories,
  updateSelected,
  updateAllSelected,
}) => {
  const { t } = useTranslation();

  return (
    <div className="m-2">
      <div className="flex items-center justify-between">
        <h2 className="pl-4 py-4 text-[#232437] text-[1.25rem] font-semibold">{t(sectionTitle)}</h2>
        <div>
          {categories?.length !== selectedCategoryIds?.length ?
            <IonButton
              fill="clear"
              className="normal-case text-[#EC6040] tracking-normal"
              onClick={() => updateAllSelected('selectAll')}
            ><span className="font-semibold">{t("tours.filter.buttons.selectAll")}</span></IonButton>
            :
            <IonButton
              fill="clear"
              className="normal-case text-[#EC6040] tracking-normal"
              onClick={() => updateAllSelected()}
            ><span className="font-semibold">{t("tours.filter.buttons.clearAll")}</span></IonButton>}
        </div>
      </div>

      <IonItemGroup className="pb-2">
        {categories?.map((category) => {
          const itemIcon = category?.icon?.url;
          const tourCountByItem = recordsCountByCategories[category.id];

          // hide categories without stories
          if (!tourCountByItem) return null;

          return (
            <IonItem key={category.id} lines="none">
              <IonCheckbox
                slot="end"
                mode="md"
                color="dark"
                value={category.id}
                onClick={(e: any) => {
                  updateSelected(category.id, e?.target?.checked);
                }}
                checked={selectedCategoryIds?.includes(category.id)}
              />
              <div className="flex items-center">
                {!!itemIcon && <IonIcon src={itemIcon} className="mr-[10px] h-[20px] w-[20px] shrink-0" />}
                <span className="text-[#232437] font-medium">{category.title}</span>
                <span className="ml-1 text-[0.875rem] text-[#535E69]">{tourCountByItem}</span>
              </div>
            </IonItem>
          );
        })}
      </IonItemGroup>
    </div>
  )
};

export default RecordsByCategoriesFilterSection;
