import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { Locales } from "../contexts/LocaleContext";

import translationsEN from "../locales/en/translation.json";
import translationsDE from "../locales/de/translation.json";
import translationsFR from "../locales/fr/translation.json";
import translationsES from "../locales/es/translation.json";

const isProduction = process.env.NODE_ENV === "production";

const resources = {
  en: {
    translation: translationsEN,
  },
  de: {
    translation: translationsDE,
  },
  fr: {
    translation: translationsFR,
  },
  es: {
    translation: translationsES,
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    // Tranlations
    resources,

    // Language configuration
    supportedLngs: Object.values(Locales),
    fallbackLng: {
      "de-DE": [Locales.DE],
      "de-AT": [Locales.DE],
      "de-CH": [Locales.DE],
      "en-US": [Locales.EN],
      "en-GB": [Locales.EN],
      'fr': [Locales.FR],
      'es': [Locales.ES],
      default: [Locales.DE],
    },

    // Show debug messages in non-production environments
    debug: !isProduction,

    detection: {
      order: [
        "querystring",
        "path",
        "cookie",
        "localStorage",
        "sessionStorage",
        "navigator",
      ],
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
