import { map } from 'lodash-es';

import { Story } from '../interfaces/Interfaces';
import { getQuizTaskType } from './quiz-helpers';
import { CreateUserStoryInput } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';

const getInputToCreateUserStory = (story: Story) => {
  return {
    datoStoryId: story.id,
    userStorySlides: map(story.storySlides, (storySlide) => ({
      datoStorySlideId: storySlide.id,
      userQuiz: storySlide?.quiz?.id ? {
        datoQuizId: storySlide?.quiz?.id,
        isBlocking: storySlide?.quiz?.isBlocking,
        userQuizTasks: map(storySlide?.quiz?.quizTasks, (quizTask: any) => ({
          datoQuizTaskId: quizTask.id,
          referenceId: quizTask?.referenceId,
          quizTaskType: getQuizTaskType(quizTask.__typename),
        })),
      } : null,
    })),
  } as CreateUserStoryInput;
};

export { getInputToCreateUserStory };
