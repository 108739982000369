import { useEffect, useMemo, useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import type { ViewState } from 'react-map-gl';
import { circle } from '@turf/circle';
import { v4 as uuidv4 } from 'uuid';
import { LineLayerSpecification, FillLayerSpecification } from '@maplibre/maplibre-gl-style-spec';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { point } from '@turf/helpers';
import { LngLatBounds } from 'mapbox-gl';

const CircleLayer: React.FC<{
  viewState: Pick<ViewState, 'latitude' | 'longitude' | 'zoom'>;
  hideCircleIfMapIsFullyInside: boolean;
  mapBounds?: LngLatBounds | null;
  radius: number;
  type: LineLayerSpecification['type'] | FillLayerSpecification['type'];
  paint: LineLayerSpecification['paint'] & FillLayerSpecification['paint'];
}> = ({ viewState, hideCircleIfMapIsFullyInside, mapBounds, radius, type, paint }) => {
  const [mapIsInsideCircle, setMapIsInsideCircle] = useState(true);

  const id = useMemo(() => uuidv4(), []);

  const circleData = useMemo(() => circle(
    [viewState.longitude, viewState.latitude],
    radius,
    { steps: 64, units: 'meters' },
  ), [viewState, radius]);

  useEffect(() => {
    if (!hideCircleIfMapIsFullyInside) {
      setMapIsInsideCircle(false);
    } else if (mapBounds) {
      // Get the current map bounds
      const sw = [mapBounds.getWest(), mapBounds.getSouth()];
      const ne = [mapBounds.getEast(), mapBounds.getNorth()];

      // Check if the map's visible bounds are inside the circle
      const swInside = booleanPointInPolygon(point(sw), circleData);
      const neInside = booleanPointInPolygon(point(ne), circleData);

      setMapIsInsideCircle(swInside && neInside);
    }
  }, [hideCircleIfMapIsFullyInside, mapBounds, circleData]);

  // hide the circle if the visible part of the map is completely inside it
  if (mapIsInsideCircle) return null;

  return (
    <Source id={`circle-source-${id}`} type="geojson" data={circleData}>
      <Layer
        id={`circle-layer-${id}`}
        type={type}
        paint={paint}
      />
    </Source>
  );
};

export default CircleLayer;
