import { useCallback, useEffect, useState } from 'react';
import { differenceWith, uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { useIonRouter } from '@ionic/react';

import {
  useExploreStoriesQuery,
} from '../../../graphql/dato/__generated__/dato-graphql.generated';
import { Story } from '../../../interfaces/Interfaces';
import { useLocale } from '../../../contexts/LocaleContext';
import StoryCardSlider from '../../../components/sliders/StoryCardSlider';
import SectionHeader from './SectionHeader';
import useAnalyticsStore from '../../../stores/useAnalyticsStore';
import useStoryPlayer from '../../../hooks/useStoryPlayer';
import { isPremiumStory } from '../../../helpers/story-helpers';
import { useTransaction } from '../../../contexts/TransactionContext';
import useAuthStore from '../../../stores/useAuthStore';
import useRoutes from '../../../hooks/useRoutes';

const UnviewedStoriesSection: React.FC<{ cityId: string; isVisible: boolean }> = ({ cityId, isVisible }) => {
  const { locale, queryLocale } = useLocale();
  const { t } = useTranslation();
  const { navigateToStory } = useStoryPlayer();
  const { hasPremiumAccess, activeTransactionsLoading } = useTransaction();
  const router = useIonRouter();
  const { loginPath, currentPath, premiumAccessPurchasingPath } = useRoutes();

  const viewedStoryIds = useAnalyticsStore((state) => state.viewedStoryIds);
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [storiesInQueryLocale, setStoriesInQueryLocale] = useState<{ [key: string]: Story[]; }>({});
  const [isAllStoriesReceivedInQueryLocale, setIsAllStoriesReceivedInQueryLocale] = useState<{ [key: string]: boolean; }>({});
  const [filteredStoriesInQueryLocale, setFilteredStoriesInQueryLocale] = useState<{ [key: string]: Story[]; }>({});

  // Query for stories when rending the component
  const pageSize = 100;
  const [pageNumber, setPageNumber] = useState(0);

  const queryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      first: pageSize,
      skip: pageNumber * pageSize,
      cityId: cityId,
    };
  }, [queryLocale, pageNumber, cityId]);

  const [result] = useExploreStoriesQuery({
    variables: queryVariables(),
    pause: !isVisible,
    requestPolicy: 'network-only'
  });

  const { data } = result;

  // Set stories when data is available
  useEffect(
    () => {
      if (data?.stories) {
        // Add stories to stories list
        const returnedStories = data.stories as Story[];
        const newStoriesInQueryLocale = uniqBy(
          [...(storiesInQueryLocale?.[locale] || []), ...returnedStories],
          (story) => story.id
        );
        setStoriesInQueryLocale({
          ...storiesInQueryLocale,
          [locale]: newStoriesInQueryLocale,
        });

        // Fetch more stories if available
        if (returnedStories.length === pageSize) {
          // By setting the page size another GraphQL query for the next page gets executed
          setPageNumber(pageNumber + 1);
        } else {
          setIsAllStoriesReceivedInQueryLocale((isAllStoriesReceivedInQueryLocale) => ({
            ...isAllStoriesReceivedInQueryLocale,
            [locale]: true,
          }))
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  useEffect(() => {
    // filter already viewed stories
    const newFilteredStoriesInQueryLocale = differenceWith(
      storiesInQueryLocale?.[locale] || [],
      viewedStoryIds,
      (arrVal, othVal) => arrVal.id === othVal,
    );

    setFilteredStoriesInQueryLocale({
      ...filteredStoriesInQueryLocale,
      [locale]: newFilteredStoriesInQueryLocale,
    })
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storiesInQueryLocale, viewedStoryIds, locale]
  );

  useEffect(() => {
    // Reset the page number if the locale changes
    setPageNumber(0);
    setIsAllStoriesReceivedInQueryLocale({});
  }, [queryLocale]);

  const startStory = ({ story }: { story: Story }) => {
    if (activeTransactionsLoading || !story) return;

    if (hasPremiumAccess || !isPremiumStory(story)) {
      navigateToStory({ story });
    } else {
      router?.push(isAuthenticated ?
        premiumAccessPurchasingPath(currentPath()) :
        loginPath(premiumAccessPurchasingPath(currentPath()))
      );
    }
  };

  return (
    <>
      {!!filteredStoriesInQueryLocale[locale]?.length && <div>
        <SectionHeader title={t('home.stories.title')} className="px-4 mt-4 mb-4" />
        <div className="mx-3 flex max-w-xl items-center justify-center">
          <StoryCardSlider
            stories={filteredStoriesInQueryLocale[locale]}
            isAllStoriesReceived={isAllStoriesReceivedInQueryLocale[locale]}
            navigateToStory={startStory}
            sliderProps={{
              // Enable virtual slides
              virtual: true,

              // Default config (when window width is < 320px)
              slidesPerView: 1.5,
              spaceBetween: 4,

              // Responsive breakpoints
              breakpoints: {
                480: {
                  slidesPerView: 2,
                  spaceBetween: 6,
                },
              },
            }}
          />
        </div>
      </div>}
    </>
  );
};

export default UnviewedStoriesSection;
