import { useQuery } from "react-query";
import { StorySlide } from "../../interfaces/Interfaces";
import SrtParser2 from "srt-parser-2";
import { useMediaPlayer } from "../../contexts/MediaPlayerContext";

const convertTimeStringToMilliseconds = (timeString: string) => {
  const [hoursMinutesSeconds, milliseconds] = timeString.split(",");
  const [hours, minutes, seconds] = hoursMinutesSeconds.split(":");

  return (
    parseInt(hours, 10) * 60 * 60 * 1000 +
    parseInt(minutes, 10) * 60 * 1000 +
    parseInt(seconds, 10) * 1000 +
    parseInt(milliseconds, 10)
  );
};

// Note: A similar and another approach to subtitle parsing using vtt-to-json
//  is noted here: https://github.com/nriccar/react-native-subtitles/blob/main/src/Subtitles.js
// Only discovered this after the inital implementation down below
const parseSubtitles = (text: string) => {
  const parser = new SrtParser2();
  const parsedSubtitles = parser.fromSrt(text);

  const convertedSubtitles = parsedSubtitles.map((subtitle) => ({
    ...subtitle,
    startTime: convertTimeStringToMilliseconds(subtitle.startTime),
    endTime: convertTimeStringToMilliseconds(subtitle.endTime),
  }));

  return convertedSubtitles;
};

const TextTrackSubtitles: React.FC<{ textTrackUrl: string }> = ({
  textTrackUrl,
}) => {
  const { currentAudioTime } = useMediaPlayer();

  const {
    data: subtitles,
    isLoading,
    error,
  } = useQuery("fetch-text-track", () => {
    return fetch(textTrackUrl)
      .then((response) => response.text())
      .then((text) => parseSubtitles(text));
  });

  // Don't render if there's no audio being played
  if (!currentAudioTime) {
    return null;
  }

  // Don't render if the subtitles haven't loaded yet
  if (isLoading || error) {
    return null;
  }

  // Don't render if there's no subtitles
  if (!subtitles) {
    return null;
  }

  const advanceTimeBy = 190; // in milliseconds
  const currentTimeInMilliseconds =
    Math.round(currentAudioTime * 1000) + advanceTimeBy;
  const currentSubtitle = subtitles
    .filter(
      (subtitle: any) =>
        subtitle.startTime <= currentTimeInMilliseconds &&
        subtitle.endTime >= currentTimeInMilliseconds
    )
    .pop();

  if (!currentSubtitle) {
    return null;
  }

  return (
    <div className="mx-auto max-w-[380px] text-center">
      <span className="inline rounded-[1px] bg-white/90 box-decoration-clone px-1.5 pt-[2px] pb-[3px] text-center text-lg font-medium leading-[30px] text-primary shadow">
        {currentSubtitle.text}
      </span>
    </div>
  );
};

const SubtitlesFromTextTrack: React.FC<{ storySlide: StorySlide }> = ({
  storySlide,
}) => {
  const textTrackUrl = storySlide.textTrack?.url;

  if (!textTrackUrl) {
    return null;
  }

  return <TextTrackSubtitles textTrackUrl={textTrackUrl} />;
};

export default SubtitlesFromTextTrack;
