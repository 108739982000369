import {
  IonContent,
  useIonViewDidEnter,
} from '@ionic/react';
import { find, map } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AppLayout from '../layouts/AppLayout';
import FloatingBackButton from '../components/buttons/FloatingBackButton';
import useError from '../hooks/useError';

import { useLocale } from '../contexts/LocaleContext';
import { Tour } from '../interfaces/Interfaces';
import { useToursByTourIdsQuery } from '../graphql/dato/__generated__/dato-graphql.generated';
import {
  useGetUserToursCreatedByMeLazyQuery,
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useIonVisible from '../hooks/useIonVisible';
import useAuthStore from '../stores/useAuthStore';
import FullWidthTourCard from '../components/cards/FullWidthTourCard';
import { useTourCreationRequest } from '../contexts/TourCreationRequestContext';
import useToursRatingStatistics from '../hooks/useToursRatingStatistics';
import useUserToursAudioStatistics from '../hooks/useUserToursAudioStatistics';

const CreatedToursPage: React.FC = () => {
  const { handleBackendError } = useError();
  const { queryLocale } = useLocale();
  const { t } = useTranslation();
  const { isVisible } = useIonVisible();
  const { tourCreationRequestStatisticsByUser } = useTourCreationRequest();

  const [getUserToursCreatedByMeQuery] = useGetUserToursCreatedByMeLazyQuery();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [tourIds, setTourIds] = useState<string[]>([]);
  const [tours, setTours] = useState<Tour[]>([]);

  const { toursRatingStatistics} = useToursRatingStatistics(map(tours, ({ id }) => id), isVisible);
  const { userToursAudioStatistics } = useUserToursAudioStatistics(map(tours, ({ id }) => id), isAuthenticated, isVisible);

  const queryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      tourIds,
      first: 100,
      skip: 0,
    };
  }, [tourIds, queryLocale]);

  const [toursResult] = useToursByTourIdsQuery({
    variables: queryVariables(),
    pause: !tourIds?.length
  });

  useIonViewDidEnter(() => {
    const getUserToursCreatedByMe = async () => {
      await handleBackendError(async () => {
        const { data, error } = await getUserToursCreatedByMeQuery({
          fetchPolicy: 'no-cache',
        });

        if (error) return error;

        const tourIds = map(
          data?.userTour?.getUserToursCreatedByMe,
          (startedUserTour) => startedUserTour.datoTourId
        );

        setTourIds(tourIds);
      });
    };

    getUserToursCreatedByMe();
  });

  useEffect(() => {
    const tours = toursResult?.data?.tours as Tour[];
    setTours(tours);
  }, [toursResult]);

  return (
    <AppLayout>
      <IonContent style={{
        '--padding-top': 'var(--ion-safe-area-top)'
      }}>
        <div className="relative mx-auto max-w-md">
          <FloatingBackButton style={{ left: "6px" }}/>

          <h3 className="pl-3 pb-2 pt-[75px] font-semibold ml-1 text-[1.1rem] text-[#414254]">
            {t(tours?.length ? 'profile.createdTours.title' : 'profile.createdTours.doNotHaveCreatedTours')}
          </h3>

          {!!tourCreationRequestStatisticsByUser?.inProgress && <div
            className="px-4 py-2 text-[0.875rem] text-white font-medium bg-[#A4D5CD]"
          >{t('profile.createdTours.tourCreationInProgress')}</div>}

          {map(tours, (tour) => <div key={tour.id}>
            <FullWidthTourCard
              tour={tour}
              tourRatingStatistics={find(toursRatingStatistics, ['datoTourId', tour.id])}
              userTourAudioStatistics={find(userToursAudioStatistics, ['datoTourId', tour.id])}
            />
          </div>)}
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default CreatedToursPage;
