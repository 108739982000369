import { map } from 'lodash-es';

import { Tour } from '../interfaces/Interfaces';
import { CreateUserTourInput } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { getInputToCreateUserStory } from './user-story-helpers';

const getInputToCreateUserTour = (tour: Tour) => {
  return {
    datoTourId: tour.id,
    datoCreatorProfileId: tour?.creatorProfile?.id,
    userTourStops: map(tour.tourStops, (tourStop) => ({
      datoTourStopId: tourStop.id,
      userStories: map(tourStop.stories, (story) => getInputToCreateUserStory(story)),
    })),
  } as CreateUserTourInput;
};

export { getInputToCreateUserTour };
