import { filter, flatten, intersectionWith, map, slice } from 'lodash-es';
import { DeepPartial } from 'react-hook-form';

import i18n from '../config/i18n.config';
import { TourStop } from '../interfaces/Interfaces';
import {
  UserQuiz,
  UserStory,
  UserStorySlide,
  UserTour,
  UserTourStop
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';

const numberOfTourStop = (tourStop: TourStop) => {
  const tourStops = tourStop?.tour?.tourStops;
  if (!tourStops?.length) return;

  // ignore preview tour stop
  const tourStopPosition = tourStops.filter((tourStop) => !tourStop.isPreviewTourStop).indexOf(tourStop);
  const firstTourStop = tourStops[0];
  return firstTourStop.isIntroTourStop
    ? tourStopPosition
    : tourStopPosition + 1;
};

const tourStopNumberLabel = (tourStop: TourStop) => {
  const { t } = i18n;

  return tourStop.isIntroTourStop
    ? t("tourStop.introduction")
    : `${t("dictionary.tourStop")} ${numberOfTourStop(tourStop)}`;
};

const checkQuizzesCompletionOfPreviousTourStops = (tourStop: TourStop, userTour?: DeepPartial<UserTour> | null) => {
  const tourStopsWithoutPreviewOne = filter(tourStop?.tour?.tourStops, (tourStop) => !tourStop.isPreviewTourStop);
  const previousTourStops = slice(
    tourStopsWithoutPreviewOne,
    0,
    tourStopsWithoutPreviewOne?.findIndex((currTourStop) => currTourStop.id === tourStop?.id)
  );
  const previousStories = flatten(map(previousTourStops, (previousTourStop) => previousTourStop?.stories));
  const previousStorySlides = flatten(map(previousStories, (previousStory) => previousStory?.storySlides));
  const previousQuizzes = map(previousStorySlides, (storySlide) => storySlide?.quiz);
  const previousBlockingQuizzes = filter(previousQuizzes, (quiz) => quiz?.isBlocking);

  const previousUserTourStops = intersectionWith(userTour?.userTourStops || [], previousTourStops, (el1, el2) => {
    return el1?.datoTourStopId === el2?.id
  });
  const previousUserStories = flatten(map(previousUserTourStops, (previousUserTourStop: UserTourStop) => previousUserTourStop?.userStories));
  const previousUserStorySlides = flatten(map(previousUserStories, (previousUserStory: UserStory) => previousUserStory?.userStorySlides));
  const previousUserQuizzes = map(previousUserStorySlides, (userStorySlide: UserStorySlide) => userStorySlide?.userQuiz);
  const previousCompletedBlockingUserQuizzes = filter(previousUserQuizzes, (userQuiz: UserQuiz) => userQuiz?.isBlocking && userQuiz?.isCompleted);

  return !(previousBlockingQuizzes?.length === previousCompletedBlockingUserQuizzes?.length);
};

export { numberOfTourStop, tourStopNumberLabel, checkQuizzesCompletionOfPreviousTourStops };
