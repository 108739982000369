import { useCallback, useEffect, useState } from 'react';
import { useIonModal, useIonRouter } from '@ionic/react';
import { OverlayEventDetail } from '@ionic/core';
import { Device } from '@capacitor/device';
import { includes } from 'lodash-es';

import useAppState from './useAppState';
import { useLastAppVersionFeaturesQuery } from '../graphql/dato/__generated__/dato-graphql.generated';
import { useLocale } from '../contexts/LocaleContext';
import AppVersionFeaturesModal from '../components/modals/AppVersionFeaturesModal';
import { AppVersionFeature } from '../interfaces/Interfaces';
import { getItemFromStorage, setItemToStorage } from '../helpers/storage-helpers';
import { isIosVersion } from '../helpers/device-helpers';

const useAppVersionFeatures = () => {
  const { isAppActive } = useAppState();
  const { queryLocale, locale } = useLocale();
  const router = useIonRouter();

  const [devicePlatform, setDevicePlatform] = useState<'ios' | 'android' | 'web'>();
  const [lastAppVersionFeatures, setLastAppVersionFeatures] = useState<AppVersionFeature>();

  const [present, dismiss] = useIonModal(AppVersionFeaturesModal, {
    onDismiss: (data?: { pathWithoutLocale?: string | null }) => dismiss(data),
    appVersionFeatures: lastAppVersionFeatures,
    devicePlatform
  });

  const queryVariables = useCallback(() => {
    return {
      locale: queryLocale,
      filter: {
        ...(devicePlatform === 'ios' ? { ios: { eq: true } } : {}),
        ...(devicePlatform === 'android' ? { android: { eq: true } } : {}),
        ...(devicePlatform === 'web' ? { web: { eq: true } } : {}),
      }
    };
  }, [queryLocale, devicePlatform]);

  const [lastAppVersionFeaturesResult] = useLastAppVersionFeaturesQuery({
    variables: queryVariables(),
    pause: !devicePlatform || !isAppActive,
    requestPolicy: 'network-only'
  });

  useEffect(() => {
    const getDevicePlatform = async () => {
      const deviceInfo = await Device.getInfo();
      const devicePlatform = deviceInfo.platform;
      setDevicePlatform(devicePlatform);
    }
    getDevicePlatform();
  }, []);

  useEffect(() => {
    const checkIfLastAppVersionViewed = async () => {
      const lastAppVersionFeatures = lastAppVersionFeaturesResult?.data?.allAppVersionFeatures?.[0] as AppVersionFeature;

      if (lastAppVersionFeatures) {
        const viewedAppVersionFeaturesIds = await getItemFromStorage('viewedAppVersionFeaturesIds') || [];

        if (!includes(viewedAppVersionFeaturesIds, lastAppVersionFeatures.id)) {
          setLastAppVersionFeatures(lastAppVersionFeatures);
        }
      }
    }

    checkIfLastAppVersionViewed();
  }, [lastAppVersionFeaturesResult.data]);

  useEffect(() => {
    if (lastAppVersionFeatures) {
      present({
        // set animated false for ios 17,because animation breaks the popup
        // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
        animated: !isIosVersion(17),
        onWillDismiss: async (e: CustomEvent<OverlayEventDetail>) => {
          const viewedAppVersionFeaturesIds = await getItemFromStorage('viewedAppVersionFeaturesIds') || [];
          await setItemToStorage('viewedAppVersionFeaturesIds', [...viewedAppVersionFeaturesIds, lastAppVersionFeatures.id])

          if (e.detail?.data?.pathWithoutLocale) {
            // pathWithoutLocale can contain query params
            router.push(`/${locale}/${e.detail?.data?.pathWithoutLocale}`);
          }
        },
        id: 'app-version-features-modal'
      });
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastAppVersionFeatures]
  );
};

export default useAppVersionFeatures;
