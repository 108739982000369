import { IonPage, IonFooter, IonLoading } from '@ionic/react';

import MiniPlayer from '../components/players/MiniPlayer';

const AppLayout: React.FC<{ isLoaderShown?: boolean; }> = ({ isLoaderShown, children }) => {
  return (
    <IonPage>
      <IonLoading isOpen={!!isLoaderShown} spinner="circles"/>

      {children}

      <IonFooter>
        <MiniPlayer/>
      </IonFooter>
    </IonPage>
  );
};

export default AppLayout;
