import { find } from 'lodash-es';
import { DeepPartial } from 'react-hook-form';

import { UserStory, UserTour } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import { Story, StorySlide, Tour, TourStop } from '../interfaces/Interfaces';

const getUserStorySlideFromUserTour = (
  userTour: DeepPartial<UserTour>,
  tourStop: TourStop,
  story: Story,
  storySlide: StorySlide,
) => {
  const userTourStop = find(userTour?.userTourStops, ['datoTourStopId', tourStop.id]);
  const userStory = find(userTourStop?.userStories, ['datoStoryId', story.id]);
  return find(userStory?.userStorySlides, ['datoStorySlideId', storySlide.id]);
};

const getUserStorySlideFromUserStory = (
  userStory: DeepPartial<UserStory>,
  storySlide: StorySlide,
) => {
  return find(userStory?.userStorySlides, ['datoStorySlideId', storySlide.id]);
};

const getUserStorySlide = (
  userTour?: DeepPartial<UserTour> | null,
  userStory?: DeepPartial<UserStory> | null,
  tour?: Tour | null,
  tourStop?: TourStop | null,
  story?: Story | null,
  storySlide?: StorySlide | null,
) => {
  if (tour && userTour && tourStop && story && storySlide && userTour?.datoTourId === tour?.id) {
    return getUserStorySlideFromUserTour(userTour, tourStop, story, storySlide);
  } else if (!tour && storySlide && userStory && userStory?.datoStoryId === story?.id) {
    return getUserStorySlideFromUserStory(userStory, storySlide);
  }
  return null;
};

export { getUserStorySlide, getUserStorySlideFromUserTour, getUserStorySlideFromUserStory };
