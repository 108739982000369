import { useEffect, useState } from 'react';

import {
  TourRatingStatistics,
  useGetTourRatingStatisticsLazyQuery,
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';

const useTourRatingStatistics = ({ tourId }: { tourId?: string | null }) => {
  const [getTourRatingStatisticsQuery] = useGetTourRatingStatisticsLazyQuery();

  const [tourRatingStatistics, setTourRatingStatistics] = useState<TourRatingStatistics | null | undefined>(null);

  useEffect(() => {
    const getTourRatingStatistics = async (datoTourId: string) => {
      const { data } = await getTourRatingStatisticsQuery({ variables: { input: { datoTourId }}});
      setTourRatingStatistics(data?.tourReview?.getTourRatingStatistics);
    };

    if (tourId) {
      getTourRatingStatistics(tourId);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tourId]
  );

  return { tourRatingStatistics };
};

export default useTourRatingStatistics;
