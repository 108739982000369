import { IonButton, useIonViewWillLeave } from '@ionic/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AppInput from '../../components/form/AppInput';
import { getBackendValidationErrors } from '../../helpers/error-helpers';
import { BackendValidationError } from '../../interfaces/Interfaces';
import useToast from '../../hooks/useToast';
import { PASSWORD_PATTERN } from '../../constants';
import { useChangePasswordMutation } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';

interface ChangePasswordForm {
  password: string;
  newPassword: string;
}

const ChangePassword: React.FC = () => {
  const { t } = useTranslation();
  const { presentToast } = useToast();
  const [changePasswordMutation] = useChangePasswordMutation();

  const [backendErrors, setBackendErrors] = useState<BackendValidationError | null | undefined>(null);
  const { register, reset, handleSubmit, formState: { errors } } = useForm<ChangePasswordForm>();

  const changePassword = async (input: ChangePasswordForm) => {
    try {
      const { data } = await changePasswordMutation({ variables: { input }});
      const changePassword = data?.user?.changePassword;

      if (changePassword) {
        reset();
        presentToast('profile.updateProfile.toasts.changePassword.success');
      }
    } catch (e: any) {
      const backendErrors = getBackendValidationErrors(e);
      setBackendErrors(backendErrors);
    }
  };

  useIonViewWillLeave(() => {
    reset();
  });

  return (
    <div className="flex flex-col">
      <form onSubmit={handleSubmit(changePassword)} className="flex flex-col">
        <AppInput
          placeholder="profile.updateProfile.form.password.placeholder"
          name="password"
          type="password"
          register={register}
          validators={{
            required: t('profile.updateProfile.form.password.errors.required'),
            pattern: {
              value: PASSWORD_PATTERN,
              message: t('authFlow.form.password.errors.pattern')
            },
          }}
          frontendErrors={errors}
          backendErrors={backendErrors}
        />
        <AppInput
          placeholder="profile.updateProfile.form.newPassword.placeholder"
          name="newPassword"
          type="password"
          register={register}
          validators={{
            required: t('profile.updateProfile.form.newPassword.errors.required'),
            pattern: {
              value: PASSWORD_PATTERN,
              message: t('authFlow.form.newPassword.errors.pattern')
            },
          }}
          frontendErrors={errors}
          backendErrors={backendErrors}
        />

        <IonButton
          type="submit" size="large" shape="round" className="capitalize font-medium text-[1rem] mt-[20px]"
        >{t('profile.updateProfile.buttons.savePassword')}</IonButton>
      </form>
    </div>
  );
};

export default ChangePassword;
