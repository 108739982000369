import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router';
import { useIonRouter } from '@ionic/react';

import useAuthStore from '../stores/useAuthStore';
import useRoutes from '../hooks/useRoutes';
import AppLayout from '../layouts/AppLayout';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const router = useIonRouter();
  const { authFlowPath, currentPath } = useRoutes();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const isUserInitialised = useAuthStore(state => state.isUserInitialised);

  useEffect(() => {
    if (isUserInitialised && !isAuthenticated) router.push(authFlowPath(currentPath()));
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated, isUserInitialised]
  );

  if (!isUserInitialised) return null;

  return (
    <>
      {isAuthenticated ?
        <Route
          {...rest}
          render={(props) => <Component {...props} />}
        /> :
        // Display empty page if the user is not authenticated.
        // After that the user will be redirected to auth flow in useEffect
        <AppLayout></AppLayout>
      }
    </>
  );
};

export default PrivateRoute;
