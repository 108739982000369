import { isEmpty } from "lodash-es";

import { Category } from "../../interfaces/Interfaces";
import CategoryTag from "./CategoryTag";

const CategoryTags: React.FC<{
  categories: Category[] | undefined;
  wrapperClassName?: string;
}> = ({ categories, wrapperClassName }) => {
  if (!categories || isEmpty(categories)) {
    return null;
  }

  return (
    <div className={wrapperClassName}>
      {categories.map((category) => (
        <CategoryTag
          category={category}
          key={`category-${category.id}`}
        />
      ))}
    </div>
  );
};

export default CategoryTags;
