import { useIonModal } from '@ionic/react';
import { createContext, useContext, useEffect, useState } from 'react';

import useStoryPlayer from '../hooks/useStoryPlayer';
import useAuthStore from '../stores/useAuthStore';
import { useStoryCreationRequestUpdatedSubscription } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import CreateStorySuccessModal from '../components/modals/CreateStorySuccessModal';

const useValue = () => {
  const { navigateToStory } = useStoryPlayer();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const [createdStoryId, setCreatedStoryId] = useState<string>();

  const { data: storyCreationRequestUpdatedData } = useStoryCreationRequestUpdatedSubscription({
    // subscribe when user is authenticated
    skip: !isAuthenticated,
  });

  const [present, dismiss] = useIonModal(CreateStorySuccessModal, {
    storyId: createdStoryId,
    navigateToStory,
    onDismiss: () => dismiss(),
  });

  useEffect(() => {
    const createdStoryId = storyCreationRequestUpdatedData?.storyCreationRequestUpdated?.userStory?.datoStoryId;
    if (createdStoryId) {
      setCreatedStoryId(createdStoryId);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storyCreationRequestUpdatedData]
  );

  useEffect(() => {
    if (createdStoryId) {
      present();
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createdStoryId]
  );

  return {};
};

const StoryCreationRequestContext = createContext({} as ReturnType<typeof useValue>);

const StoryCreationRequestProvider: React.FC = ({ children }) => {
  return (
    <StoryCreationRequestContext.Provider value={useValue()}>
      {children}
    </StoryCreationRequestContext.Provider>
  );
};

const useStoryCreationRequest = () => {
  return useContext(StoryCreationRequestContext);
};

export { StoryCreationRequestProvider, useStoryCreationRequest };
