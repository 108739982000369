import { useState } from 'react';
import { useCustomCompareEffect } from 'react-use';
import { isEqual } from 'lodash-es';

import {
  useGetUserToursAudioStatisticsLazyQuery,
  UserTourAudioStatistics,
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useError from './useError';

const useUserToursAudioStatistics = (tourIds: string[], isAuthenticated: boolean | null, isVisible: boolean) => {
  const { handleBackendError } = useError();

  const [getUserToursAudioStatisticsQuery] = useGetUserToursAudioStatisticsLazyQuery();

  const [userToursAudioStatistics, setUserToursAudioStatistics] = useState<UserTourAudioStatistics[]>([]);

  useCustomCompareEffect(() => {
    const getUserToursAudioStatistics = async () => {
      if (isAuthenticated && isVisible && tourIds?.length) {
        await handleBackendError(async () => {
          const { data, error } = await getUserToursAudioStatisticsQuery({
            fetchPolicy: 'no-cache',
            variables: {
              input: {
                datoTourIds: tourIds,
              }
            }
          });

          if (error) return error;

          const statistics = data?.userTour?.getUserToursAudioStatistics;
          if (statistics) {
            setUserToursAudioStatistics(statistics);
          }
        });
      }
    };

    getUserToursAudioStatistics();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tourIds, isAuthenticated, isVisible],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  return { userToursAudioStatistics };
};

export default useUserToursAudioStatistics;
