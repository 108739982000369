import { IonContent, useIonRouter, useIonViewDidEnter } from '@ionic/react';
import AppLayout from "../layouts/AppLayout";
import StoryMap from "../components/map/StoryMap";
import useGeolocationPermissions from "../hooks/useGeolocationPermissions";
import AppHeader from "../components/AppHeader";
import { useMixpanel, MixpanelEvents } from "../contexts/MixpanelContext";
import useSearchParams from '../hooks/useSearchParams';
import { useEffect, useState } from 'react';
import { useCity } from '../contexts/CityContext';
import { useDebouncedEffect } from '@react-hookz/web';
import useRoutes from '../hooks/useRoutes';
import useAppState from '../hooks/useAppState';

const StoryMapPage: React.FC = () => {
  // Request geolocation permissions if not granted yet
  //  by triggering a Geolocation request
  useGeolocationPermissions();

  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const { setCityIdToReceive } = useCity();
  const router = useIonRouter();
  const { localePath } = useRoutes();
  const { isAppActive } = useAppState();
  const { cityIdToReceive }: { cityIdToReceive: string | null } = useSearchParams();

  const [citySearchAdditionalButton, setCitySearchAdditionalButton] = useState<JSX.Element>();

  useIonViewDidEnter(() => {
    // TODO: Track location that's being viewed, track moving story map around, track other story map interactions
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_STORY_MAP);
    }
  });

  // set city id to receive
  useEffect(() => {
    if (cityIdToReceive && isAppActive) setCityIdToReceive(cityIdToReceive);
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cityIdToReceive, isAppActive]
  );

  // delete city id query parameter from url
  useDebouncedEffect(() => {
    if (cityIdToReceive && isAppActive) router.push(localePath('stories'));
  }, [cityIdToReceive, isAppActive], 100);

  const renderAdditionalButtonInCitySearch = (button: JSX.Element) => {
    setCitySearchAdditionalButton(button);
  };

  return (
    <AppLayout>
      <AppHeader isAbsolutelyPositioned={true} citySearchAdditionalButton={citySearchAdditionalButton}/>

      <IonContent>
        <div className="h-full w-full">
          <StoryMap renderAdditionalButtonInCitySearch={renderAdditionalButtonInCitySearch}/>
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default StoryMapPage;
