import SlImageComparer from "@shoelace-style/shoelace/dist/react/image-comparer";
import { ImageComparerRecord } from "../../graphql/dato/__generated__/dato-graphql.generated";
import ImageCaption from "./ImageCaption";
import ImageCopyright from "./ImageCopyright";

interface ImageComparerProps {
  imageComparerRecord: ImageComparerRecord;
}

const ImageComparer: React.FC<ImageComparerProps> = ({
  imageComparerRecord,
}) => {
  // Note: The before and after slots are exchanged, since they're rendered
  // differntly then I would have expected in the current version of the Shoelace
  // Image Comparer component.
  //
  // Rendering now is:
  // Left => Before
  // Right => After
  const imageBefore = imageComparerRecord.imageAfter?.responsiveImage;
  const imageAfter = imageComparerRecord.imageBefore?.responsiveImage;

  return (
    <div className="flex flex-col items-center">
      <SlImageComparer position={50}>
        {imageBefore && (
          <img
            slot="before"
            src={imageBefore.src}
            alt={imageBefore.alt!}
            title={imageBefore.title!}
            className="max-h-[320px]"
          />
        )}
        {imageAfter && (
          <img
            slot="after"
            src={imageAfter.src}
            alt={imageAfter.alt!}
            title={imageAfter.title!}
            className="max-h-[320px]"
          />
        )}
      </SlImageComparer>
      <ImageCaption caption={imageComparerRecord.caption} />
      {imageComparerRecord.imageAfter && (
        <ImageCopyright fileField={imageComparerRecord.imageAfter} />
      )}
      {imageComparerRecord.imageBefore && (
        <ImageCopyright fileField={imageComparerRecord.imageBefore} />
      )}
    </div>
  );
};

export default ImageComparer;
