import { create } from "zustand";

interface UIStoreState {
  isNavigationMenuVisible: boolean;
  setIsNavigationMenuVisible: (isNavigationMenuVisible: boolean) => void;
}

const useUIStore = create<UIStoreState>((set, get) => ({
  isNavigationMenuVisible: true,
  setIsNavigationMenuVisible: async (isNavigationMenuVisible) => {
    set((state) => ({
      ...state,
      isNavigationMenuVisible,
    }));
  },
}));

export default useUIStore;
