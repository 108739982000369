import { useEffect } from 'react';
import { differenceWith, filter, isNumber, map } from 'lodash-es';
import { Capacitor } from '@capacitor/core';
import { useTranslation } from 'react-i18next';

import { getDistanceBetweenCoordinates } from '../helpers/turf-helpers';
import useLocalNotifications from './useLocalNotifications';
import useBackgroundGeolocation from './useBackgroundGeolocation';
import useStoryExplorationStore from '../stores/useStoryExplorationStore';
import useAnalyticsStore from '../stores/useAnalyticsStore';
import useBackgroundGeolocationStore from '../stores/useBackgroundGeolocationStore';

const defaultMaxDistanceToStory = 150; // in meters

const useNearStoryForExploreMode = () => {
  const { checkPermissions: checkLocalNotificationsPermissions, sendNotification } = useLocalNotifications();
  const {
    addWatcher: addBackgroundGeolocationWatcher,
    removeWatcher: removeBackgroundGeolocationWatcher
  } = useBackgroundGeolocation();
  const { t } = useTranslation();

  const isExploreModeEnabled = useStoryExplorationStore((state) => state.isExploreModeEnabled);
  const unexploredStories = useStoryExplorationStore((state) => state.unexploredStories);
  const setNearestStoriesToExplore = useStoryExplorationStore((state) => state.setNearestStoriesToExplore);
  const viewedStoryIds = useAnalyticsStore((state) => state.viewedStoryIds);
  const currentLocation = useBackgroundGeolocationStore((state) => state.currentLocation);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    checkLocalNotificationsPermissions();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    if (isExploreModeEnabled) {
      addBackgroundGeolocationWatcher('storiesForExploreMode');
      checkLocalNotificationsPermissions();
    } else {
      removeBackgroundGeolocationWatcher('storiesForExploreMode');
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isExploreModeEnabled]
  );

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    const trackNearestStories = async () => {
      if (!isExploreModeEnabled || !unexploredStories?.length || !currentLocation) return;

      // get unviewed stories
      const unviewedStories = differenceWith(
        unexploredStories,
        viewedStoryIds,
        (arrVal, othVal) => arrVal.id === othVal,
      );
      if (!unviewedStories?.length) return;

      const nearestStories = filter(unviewedStories, (story) => {
        const distance = getDistanceBetweenCoordinates(currentLocation, story?.location);
        return isNumber(distance) && distance < defaultMaxDistanceToStory;
      });
      if (!nearestStories?.length) return;

      // update unexplored stories
      setNearestStoriesToExplore(nearestStories);

      const localNotificationsPermission = await checkLocalNotificationsPermissions();
      if (localNotificationsPermission) {
        await sendNotification(
          {
            notifications: map(nearestStories, (story) => ({
              id: Math.floor(Math.random() * 2147483647),
              title: t('nearestStoryTracking.notification.title'),
              body: t('nearestStoryTracking.notification.text', { storyTitle: story.title }),
              sound: 'tour_stop_notification.wav'
            })),
          },
          {
            id: 'near-story',
            name: 'Nearest story tracking',
            description: 'Tracking the nearest story',
            importance: 4,
            visibility: 1,
            vibration: true,
            sound: 'tour_stop_notification.wav'
          }
        );
      }
    };

    trackNearestStories();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLocation, isExploreModeEnabled]
  );
};

export default useNearStoryForExploreMode;
