import { IonButton, IonContent, useIonModal, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AuthLayout from '../layouts/AuthLayout';
import AppInput from '../components/form/AppInput';
import { getBackendValidationErrors } from '../helpers/error-helpers';
import { BackendValidationError } from '../interfaces/Interfaces';
import { getItemFromStorage, removeItemFromStorage } from '../helpers/storage-helpers';
import useToast from '../hooks/useToast';
import SuccessSignUpModal from '../components/modals/SuccessSignUpModal';
import {
  useResendSignUpVerificationEmailMutation,
  useSignUpConfirmMutation
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useError from '../hooks/useError';
import { isIosVersion } from '../helpers/device-helpers';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';
import { checkReturnedRegistrationData } from '../helpers/auth-helpers';
import { useAuth } from '../contexts/AuthContext';
import AppButton from '../components/buttons/AppButton';

interface SignUpConfirmationForm {
  verificationCode: string;
}

const SignUpConfirmationPage: React.FC = () => {
  const { presentToast } = useToast();
  const { t } = useTranslation();
  const { mixpanel, mixpanelEnabled } = useMixpanel();
  const [signUpConfirmMutation] = useSignUpConfirmMutation();
  const [resendSignUpVerificationEmailMutation] = useResendSignUpVerificationEmailMutation();
  const { setCurrentUserWithRelatedData } = useAuth();
  const { handleBackendError } = useError();

  const [present, dismiss] = useIonModal(SuccessSignUpModal, {
    onDismiss: () => dismiss(),
  });

  const [backendErrors, setBackendErrors] = useState<BackendValidationError | null | undefined>(null);
  const { register, reset, handleSubmit, formState: { errors } } = useForm<SignUpConfirmationForm>();

  const signUpConfirm = async (input: SignUpConfirmationForm) => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.SIGN_UP_CONFIRM);
    }

    try {
      const registrationData = await getItemFromStorage('sentRegistrationData');
      const { data } = await signUpConfirmMutation({
        variables: {
          input: {
            ...input,
            email: registrationData?.email,
          }
        }
      });
      const me = data?.auth?.signUpConfirm;

      if (me) {
        await removeItemFromStorage('sentRegistrationData');
        present({
          // set animated false for ios 17,because animation breaks the popup
          // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
          animated: !isIosVersion(17)
        });

        checkReturnedRegistrationData(registrationData, me);
        if (mixpanelEnabled && me) {
          mixpanel.track(MixpanelEvents.SIGN_UP_SUCCESS, {
            sentEmail: registrationData?.email,
            returnedEmail: me.email
          });
        }
      }

      setCurrentUserWithRelatedData(me);
    } catch (e: any) {
      const backendErrors = getBackendValidationErrors(e);
      setBackendErrors(backendErrors);
    }
  };

  const resendSignUpVerificationEmail = async () => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.SIGN_UP_RESEND_VERIFICATION_CODE);
    }

    const registrationData = await getItemFromStorage('sentRegistrationData');

    await handleBackendError(async () => {
      const { data, errors } = await resendSignUpVerificationEmailMutation({
        variables: {
          input: {
            email: registrationData?.email,
          }
        }
      });

      if (errors) return errors;

      const resendSignUpVerificationEmail = data?.auth?.resendSignUpVerificationEmail;
      if (resendSignUpVerificationEmail) {
        presentToast('authFlow.signUpConfirmation.toasts.resendVerificationCode.success');
      }
    });
  };

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_SIGN_UP_CONFIRMATION);
    }
  });

  useIonViewWillLeave(() => {
    reset();
  });

  return (
    <AuthLayout titleTranslationPath="authFlow.signUpConfirmation.title">
      <IonContent>
        <div className="flex flex-col p-5 max-w-xl mx-auto">
          <h3 className="text-[1.5rem] text-[#414254] font-bold">{t('authFlow.signUpConfirmation.pageTitle')}</h3>
          <p className="text-[0.875rem] text-[#414254] pb-[30px]">{t('authFlow.signUpConfirmation.message')}</p>

          <form onSubmit={handleSubmit(signUpConfirm)} className="flex flex-col">
            <AppInput
              placeholder="authFlow.form.verificationCode.placeholder"
              name="verificationCode"
              register={register}
              validators={{ required: t('authFlow.form.verificationCode.errors.required') }}
              frontendErrors={errors}
              backendErrors={backendErrors}
            />

            <AppButton type="submit" className="mt-[80px]">
              {t('authFlow.signUpConfirmation.buttons.confirm')}
            </AppButton>
          </form>

          <div
            className="text-[0.75em] text-[#414254] text-center pt-[18px]"
          >
            <span>{t('authFlow.legalMessage.byContinuing')} </span>
            <a
              href="https://www.guidable.com/terms-of-service"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer text-primary"
            >{t('authFlow.legalMessage.termsOfUse')}</a>
            <span> {t('authFlow.legalMessage.and')} </span>
            <a
              href="https://guidable.com/datenschutz"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer text-primary"
            >{t('authFlow.legalMessage.privacyPolicy')}</a>
            <span> {t('authFlow.legalMessage.agreed')}</span>
          </div>

          <IonButton
            fill='clear'
            className="capitalize"
            onClick={resendSignUpVerificationEmail}
          >{t('authFlow.signUpConfirmation.buttons.resendVerificationCode')}</IonButton>
        </div>
      </IonContent>
    </AuthLayout>
  );
};

export default SignUpConfirmationPage;
