import { IonFooter, IonPage, useIonViewWillEnter, useIonViewWillLeave } from '@ionic/react';
import MiniPlayer from '../components/players/MiniPlayer';
import useSearchParams from '../hooks/useSearchParams';
import useAnalyticsStore from '../stores/useAnalyticsStore';
import SignUpOrLogInWall from './StoryPage/SignUpOrLoginWall';
import StoryPlayer from './StoryPage/StoryPlayer';
import useUIStore from '../stores/useUIStore';
import useAuthStore from '../stores/useAuthStore';

const StoryPage: React.FC = () => {
  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const viewedStoryIds = useAnalyticsStore((state) => state.viewedStoryIds);
  const { storyId }: { storyId: string | null } = useSearchParams();

  // A user can view a story if they're logged in, or if they viewed less than
  // a certain number of stories (which also allows a user to open a share link
  // and see the story that's been shared with them), or if it's a story that
  // they've already viewed (so that navigating back still works).
  const canViewStory =
    isAuthenticated ||
    viewedStoryIds.length <= 2 ||
    (storyId && viewedStoryIds.includes(storyId));

  const setIsNavigationMenuVisible = useUIStore((state) => state.setIsNavigationMenuVisible);

  useIonViewWillEnter(() => {
    setIsNavigationMenuVisible(false);
  });

  useIonViewWillLeave(() => {
    setIsNavigationMenuVisible(true);
  });

  return (
    <IonPage>
      {canViewStory ? (
        <StoryPlayer />
      ) : (
        <>
          <SignUpOrLogInWall />
          <IonFooter>
            <MiniPlayer />
          </IonFooter>
        </>
      )}
    </IonPage>
  );
};

export default StoryPage;
