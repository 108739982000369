import StarRatings from 'react-star-ratings';
import { round } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { TourRatingStatistics } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';

const StarRating: React.FC<{
  tourRatingStatistics: TourRatingStatistics;
  starDimension?: string;
  captionClassName?: string;
}> = ({
  tourRatingStatistics,
  starDimension = '12px',
  captionClassName = 'text-[#414254] text-[0.6rem] font-light ml-1.5'
}) => {
  const { t } = useTranslation();

  const roundedAverageRating = round(tourRatingStatistics?.averageRating || 5, 2);

  return (
    <div>
      <StarRatings
        rating={roundedAverageRating}
        name={tourRatingStatistics?.datoTourId}
        starRatedColor="var(--ion-color-primary)"
        starDimension={starDimension}
        starSpacing="0px"
      />

      <span className={captionClassName}>
        {roundedAverageRating} ({tourRatingStatistics?.totalReviewsAmount} {t('tour.reviews')})
      </span>
    </div>
  );
};

export default StarRating;
