import { useEffect } from 'react';
import {
  IonButtons,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonPage,
  IonIcon,
} from '@ionic/react';
import { close } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import confetti from 'canvas-confetti';

import guidableLogo from '../../assets/GU_Logo_RZ-RGB.svg';

const SuccessSignUpModal: React.FC<{
  onDismiss: () => void;
}> = ({ onDismiss }) => {
  const { t } = useTranslation();

  useEffect(() => {
    confetti({
      particleCount: 500,
      spread: 100,
      origin: { y: 0.6 },
    });
  }, []);

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={onDismiss}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="flex flex-col items-center">
          <h3
            className="text-[1.5rem] text-[#414254] text-center font-bold max-w-[275px] py-10"
          >{t('authFlow.signUpConfirmation.modals.signUpSuccess.title')} 🙌🏻</h3>
          <img
            src={guidableLogo}
            alt="guidable – stories to explore"
            className="max-w-[224px]"
          />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default SuccessSignUpModal;
