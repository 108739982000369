import { useIonPopover } from '@ionic/react';
import { FC, RefObject, useEffect } from 'react';

import { useOnboardingHints } from '../contexts/OnboardingHintsContext';

const useOnboardingHint = ({
  component,
  hintTargetRef,
  hintName,
  isVisible,
}: {
  component: FC<any>;
  hintTargetRef: RefObject<any>;
  hintName: 'storyMapPageExploreModeButton' | 'storyPlayerMapButton' | 'storyPlayerTextContentButton' |
    'storyPlayerStartGroupSharingButton' | 'storyPlayerCompleteGroupSharingButton' | 'storyMapSearchInSelectedPlaceButton';
  isVisible: boolean;
}) => {
  const { hintToDisplay, setHintToQueue, completeHint } = useOnboardingHints();

  const [present, dismiss] = useIonPopover(component, {
    onDismiss: (data: any, role: string) => {
      dismiss(data, role);
    },
  });

  useEffect(() => {
    if (isVisible) {
      setHintToQueue(hintName);
    }
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hintName, isVisible]
  );

  useEffect(() => {
    const displayPopover = async () => {
      if (hintTargetRef?.current && hintToDisplay === hintName && isVisible) {
        present({
          event: { target: hintTargetRef.current } as Event,
          arrow: true,
          backdropDismiss: true,
          translucent: false,
          mode: 'ios',
          cssClass: 'onboarding-hint',
          onDidDismiss: async () => {
            completeHint(hintName);
          },
        });
      }
    };

    displayPopover();
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hintTargetRef, hintToDisplay, isVisible]
  );
};

export default useOnboardingHint;
