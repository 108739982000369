import { memo } from 'react';

import { find, head } from 'lodash-es';
import { Marker as MapMarker } from 'react-map-gl';

import { SightseeingSpot, Story } from '../../interfaces/Interfaces';

const ClusterMarkers: React.FC<{
  clusters: Story[][];
  sightSeeingSpots: SightseeingSpot[];
  setCluster: (cluster: Story[]) => void;
  zoom: number;
}> = ({ clusters, setCluster, sightSeeingSpots, zoom }) => {
  const markerSize = Math.pow(zoom, 2) / 7;

  return (
    <>
      {clusters.map((cluster) => {
        const clusterStory = head(cluster);

        const sightSeeingSpot = find(sightSeeingSpots, (sightSeeingSpot) =>
          sightSeeingSpot?.location?.latitude === clusterStory?.location?.latitude &&
          sightSeeingSpot?.location?.longitude === clusterStory?.location?.longitude &&
          sightSeeingSpot?.isVisibleOnMap
        );
        // Not render cluster if stories belong to sightseeing spot that is visible on the map
        // In this case, sightseeing spot marker displays
        if (sightSeeingSpot) return null;

        return <MapMarker
          key={clusterStory?.id}
          latitude={clusterStory?.location?.latitude}
          longitude={clusterStory?.location?.longitude}
          onClick={() => setCluster(cluster)}
          style={{ zIndex: 1 }}
        >
          <div
            className="rounded-full bg-[#414254] text-center text-white font-semibold"
            style={{
              width: `${markerSize}px`,
              height: `${markerSize}px`,
              lineHeight: `${markerSize}px`,
              fontSize: `${markerSize / 2.5}px`,
            }}
          >
            {cluster.length}
          </div>
        </MapMarker>
      })}
    </>
  );
};

export default memo(ClusterMarkers);
