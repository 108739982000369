import { IonButton, IonIcon, useIonModal, useIonRouter } from '@ionic/react';
import { listCircle } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { differenceWith } from 'lodash-es';

import useStoryExplorationStore from '../../stores/useStoryExplorationStore';
import useAnalyticsStore from '../../stores/useAnalyticsStore';
import { Story } from '../../interfaces/Interfaces';
import StoriesListInExploreModeModal from '../modals/StoriesListInExploreModeModal';
import { isIosVersion } from '../../helpers/device-helpers';

const StoriesListInExploreModeButton: React.FC = () => {
  const router = useIonRouter();

  const nearestStoriesToExplore = useStoryExplorationStore((state) => state.nearestStoriesToExplore);
  const viewedStoryIds = useAnalyticsStore((state) => state.viewedStoryIds);

  const [unviewedNearestStories, setUnviewedNearestStories] = useState<Story[]>([]);

  const [present, dismiss] = useIonModal(StoriesListInExploreModeModal, {
    router,
    onDismiss: () => dismiss(),
  });

  useEffect(() => {
    // filter already viewed stories
    const filteredStories = differenceWith(
      nearestStoriesToExplore,
      viewedStoryIds,
      (arrVal, othVal) => arrVal.id === othVal,
    );

    setUnviewedNearestStories(filteredStories)
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nearestStoriesToExplore, viewedStoryIds]
  );

  if (!unviewedNearestStories?.length) return null;

  return (
    // set animated false for ios 17,because animation breaks the popup
    // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
    <IonButton onClick={() => present({ animated: !isIosVersion(17) })} style={{ '--padding-end': 0 }} className="relative">
      <div
        className="absolute z-[1] top-0 right-0 rounded-full w-[12px] h-[12px] bg-[#E38873] text-[0.5rem] text-white text-center leading-[12px] tracking-tighter"
      >{unviewedNearestStories?.length}</div>
      <IonIcon icon={listCircle} color="primary" className="text-[1.8rem]" />
    </IonButton>
  );
};

export default StoriesListInExploreModeButton;
