import { useEffect, useRef } from "react";
import { StorySlide } from "../../interfaces/Interfaces";
import { Viewer, ViewerOptions } from "photo-sphere-viewer";
import { MarkersPlugin } from "photo-sphere-viewer/dist/plugins/markers";
import { PanoramaImageRecord } from "../../graphql/dato/__generated__/dato-graphql.generated";
import guidableLogo from "../../assets/GU_Logo_RZ-RGB_Icon.png";

import "photo-sphere-viewer/dist/photo-sphere-viewer.css";
import "photo-sphere-viewer/dist/plugins/markers.css";
import "./PanoramaViewer.scss";

const preparePanoramaOptions = (panoramaImage: PanoramaImageRecord) => {
  const panoramaOptions: Partial<ViewerOptions> = {};

  const {
    defaultLongitude,
    defaultLatitude,
    defaultZoomLevel,
    sphereCorrectionPan,
    sphereCorrectionTilt,
    sphereCorrectionRoll,
  } = panoramaImage;

  // TODO: Coordinates could be defined in degrees in the CMS, then converted to radiant here. Should be easier to handle for CMS users.
  if (defaultLongitude) panoramaOptions["defaultLong"] = defaultLongitude;
  if (defaultLatitude) panoramaOptions["defaultLat"] = defaultLatitude;
  if (defaultZoomLevel) panoramaOptions["defaultZoomLvl"] = defaultZoomLevel;

  if (sphereCorrectionPan || sphereCorrectionTilt || sphereCorrectionRoll) {
    const sphereCorrection = {
      pan: sphereCorrectionPan ?? 0,
      tilt: sphereCorrectionTilt ?? 0,
      roll: sphereCorrectionRoll ?? 0,
    };

    panoramaOptions["sphereCorrection"] = sphereCorrection;
  }

  return panoramaOptions;
};

const PanoramaViewer: React.FC<{ storySlide: StorySlide }> = ({
  storySlide,
}) => {
  const panoramaViewerRef = useRef<any>();

  useEffect(() => {
    // The ref might not be present on the first execution of the hook
    if (!panoramaViewerRef.current) return;

    // There might be no panorama image defined
    if (!storySlide.panoramaImage?.length) return;

    const panoramaImage = storySlide.panoramaImage?.[0];

    const panoramaImageUrl =
      panoramaImage.panoramaImageFile?.responsiveImage?.src;

    const panoramaOptions = preparePanoramaOptions(panoramaImage);
    const panoramaPlugins = [];

    const panoramaMarkers = panoramaImage.markers.map((marker) => {
      const markerHtml = `
        <div class='flex items-center w-fit text-xl leading-5 font-medium px-4 py-2.5 bg-slate-600/[.65] text-white rounded-full'>
          <div class='w-4 h-4 mr-2 rounded-full bg-green-400'></div>
          <div>${marker.title}</div>
        </div>`;

      return {
        id: `panorama-marker-${marker.id}`,
        longitude: `${marker.longitude}deg`,
        latitude: `${marker.latitude}deg`,
        html: markerHtml,
        scale: [0.6, 1.1],
      };
    });

    if (panoramaMarkers) {
      panoramaPlugins.push([
        MarkersPlugin,
        {
          markers: panoramaMarkers,
        },
      ]);
    }

    const panoramaViewerInstance = new Viewer({
      container: panoramaViewerRef.current,
      panorama: panoramaImageUrl,
      loadingImg: guidableLogo,
      navbar: [], // Disable the navbar inside the panorama viewer
      fisheye: true, // Apply a fisheye effect, feels like a wide-angle shot
      ...panoramaOptions,
      plugins: panoramaPlugins as any,
    });

    // Note: This is the magic that makes sure the panorama viewer is always
    // properly sized right after initial render. Might be due to how React
    // and the Viewer interact.
    const timer1 = setTimeout(() => {
      panoramaViewerInstance.autoSize();
    }, 100);

    // Trigger a second autoSize() call after the first one just to make sure
    // the viewer gets correctly displayed
    const timer2 = setTimeout(() => {
      panoramaViewerInstance.autoSize();
    }, 300);

    return () => {
      // Clear resize timeouts
      clearTimeout(timer1);
      clearTimeout(timer2);

      // Destroy the panorama viewer instance
      panoramaViewerInstance.destroy();
    };
  }, [panoramaViewerRef, storySlide]);

  return (
    <div
      ref={panoramaViewerRef}
      className="pointer-events-auto absolute h-full w-full"
    ></div>
  );
};

export default PanoramaViewer;
