import { IonButton, IonContent, useIonRouter, useIonViewDidEnter, useIonViewWillLeave } from '@ionic/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import AuthLayout from '../layouts/AuthLayout';
import AppInput from '../components/form/AppInput';
import { getBackendValidationErrors } from '../helpers/error-helpers';
import { BackendValidationError } from '../interfaces/Interfaces';
import { getItemFromStorage, removeItemFromStorage } from '../helpers/storage-helpers';
import useToast from '../hooks/useToast';
import useRoutes from '../hooks/useRoutes';
import { PASSWORD_PATTERN } from '../constants';
import {
  useResendResetPasswordVerificationEmailMutation,
  useResetPasswordConfirmMutation
} from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useError from '../hooks/useError';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';
import AppButton from '../components/buttons/AppButton';

interface ResetPasswordConfirmationForm {
  password: string;
  verificationCode: string;
}

const ResetPasswordConfirmationPage: React.FC = () => {
  const router = useIonRouter();
  const { t } = useTranslation();
  const { presentToast } = useToast();
  const { localePath } = useRoutes();
  const { handleBackendError } = useError();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [resetPasswordConfirmMutation] = useResetPasswordConfirmMutation();
  const [resendResetPasswordVerificationEmailMutation] = useResendResetPasswordVerificationEmailMutation();

  const [backendErrors, setBackendErrors] = useState<BackendValidationError | null | undefined>(null);
  const { register, reset, handleSubmit, formState: { errors } } = useForm<ResetPasswordConfirmationForm>();

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_RESET_PASSWORD_CONFIRMATION);
    }
  });

  useIonViewWillLeave(() => {
    reset();
  });

  const resetPasswordConfirm = async (input: ResetPasswordConfirmationForm) => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.RESET_PASSWORD_CONFIRM);
    }

    try {
      const email = await getItemFromStorage('resetPasswordEmail');
      const { data } = await resetPasswordConfirmMutation({
        variables: {
          input: {
            ...input,
            email,
          }
        }
      });
      const resetPasswordConfirm = data?.auth?.resetPasswordConfirm;

      if (resetPasswordConfirm) {
        await removeItemFromStorage('resetPasswordEmail');
        router.push(localePath('login'));
      }
    } catch (e: any) {
      const backendErrors = getBackendValidationErrors(e);
      setBackendErrors(backendErrors);
    }
  };

  const resendResetPasswordVerificationEmail = async () => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.RESET_PASSWORD_RESEND_VERIFICATION_CODE);
    }

    const email = await getItemFromStorage('resetPasswordEmail');

    await handleBackendError(async () => {
      const { data, errors } = await resendResetPasswordVerificationEmailMutation({
        variables: {
          input: {
            email,
          }
        }
      });

      if (errors) return errors;

      const resendResetPasswordVerificationEmail = data?.auth?.resendResetPasswordVerificationEmail;
      if (resendResetPasswordVerificationEmail) {
        presentToast('authFlow.resetPasswordConfirmation.toasts.resendVerificationCode.success');
      }
    });
  };

  return (
    <AuthLayout titleTranslationPath="authFlow.resetPasswordConfirmation.title">
      <IonContent>
        <div className="max-w-xl mx-auto bg-[#A4D5CD] text-white text-[0.75rem] px-5 py-3">
          ✅ {t("authFlow.resetPasswordConfirmation.emailSentMessage")}
        </div>

        <div className="flex flex-col p-5 max-w-xl mx-auto">
          <h3 className="text-[1.5rem] text-[#414254] font-bold">{t('authFlow.resetPasswordConfirmation.pageTitle')}</h3>
          <p className="text-[0.875rem] text-[#414254] pb-[30px]">{t('authFlow.resetPasswordConfirmation.message')}</p>

          <form onSubmit={handleSubmit(resetPasswordConfirm)} className="flex flex-col">
            <AppInput
              placeholder="authFlow.form.verificationCode.placeholder"
              name="verificationCode"
              register={register}
              validators={{ required: t('authFlow.form.verificationCode.errors.required') }}
              frontendErrors={errors}
              backendErrors={backendErrors}
            />
            <AppInput
              placeholder="authFlow.form.newPassword.placeholder"
              name="password"
              type="password"
              register={register}
              validators={{
                required: t('authFlow.form.newPassword.errors.required'),
                pattern: {
                  value: PASSWORD_PATTERN,
                  message: t('authFlow.form.password.errors.pattern')
                },
              }}
              frontendErrors={errors}
              backendErrors={backendErrors}
            />

            <AppButton type="submit" className="mt-[80px]">
              {t('authFlow.signUpConfirmation.buttons.confirm')}
            </AppButton>
          </form>

          <IonButton
            fill='clear'
            className="capitalize"
            onClick={resendResetPasswordVerificationEmail}
          >{t('authFlow.signUpConfirmation.buttons.resendVerificationCode')}</IonButton>
        </div>
      </IonContent>
    </AuthLayout>
  );
};

export default ResetPasswordConfirmationPage;
