import { getFirstBackendValidationError } from '../helpers/error-helpers';
import useToast from './useToast';

const useError = () => {
  const { presentToast } = useToast();

  const handleBackendError = async (callback: () => any) => {
    try {
      const errors = await callback();

      if (errors) {
        const error = getFirstBackendValidationError(errors);
        if (error) {
          presentToast(error, 'danger');
        }
      }
    } catch (e) {
      const error = getFirstBackendValidationError(e);
      if (error) {
        presentToast(error, 'danger');
      }
    }
  };

  return {
    handleBackendError,
  }
};

export default useError;
