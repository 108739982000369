import { IonButton } from "@ionic/react";
import { StorySlide } from "../../interfaces/Interfaces";

const SlideActions: React.FC<{ storySlide: StorySlide }> = ({ storySlide }) => {
  const { slideActions } = storySlide;

  if (!slideActions?.length) {
    return null;
  }

  const slideActionButtons = slideActions.map((slideActionButton, index) => {
    if (slideActionButton.__typename === "SlideActionButtonRecord") {
      const { label, url, variant } = slideActionButton;

      // Note: Slide action button variants defined in DatoCMS are "light", "dark", "primary" and "secondary",
      //  which map 1:1 to Ionic's button color variants.
      const buttonColor = variant || "light";

      return (
        <IonButton
          key={index}
          color={buttonColor}
          href={url!}
          target="_blank"
          size="large"
          style={{
            fontSize: "18px",
            textTransform: "none", // Prevents button label from being capitalized
            "--border-radius": "8px",
          }}
        >
          {label}
        </IonButton>
      );
    } else {
      return null;
    }
  });

  return <div className="flex w-full justify-center">{slideActionButtons}</div>;
};

export default SlideActions;
