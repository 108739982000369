import { memo } from 'react';
import { DeepPartial } from 'react-hook-form';

import { Tour } from '../../interfaces/Interfaces';
import TourMap from './TourMap';
import TourStopMarkers from './TourStopMarkers';
import TourRoute from './TourRoute';
import { UserTour, UserTourAudioStatistics } from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';

const TourMapWithMarkers: React.FC<{
  tour: Tour,
  userTour?: DeepPartial<UserTour> | null;
  isVisible: boolean;
  userTourAudioStatistics?: UserTourAudioStatistics | null;
}> = ({ tour, userTour, isVisible, userTourAudioStatistics }) => {
  return (
    <TourMap tour={tour} isVisible={isVisible}>
      <TourStopMarkers tour={tour} userTour={userTour} userTourAudioStatistics={userTourAudioStatistics}/>
      <TourRoute tour={tour} lineColor="#e38873" isActive={true}/>
    </TourMap>
  );
};

export default memo(TourMapWithMarkers);
