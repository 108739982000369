import { IonContent } from "@ionic/react";
import React, { useEffect, useRef } from "react";
import { Helmet } from "react-helmet";

import { useMediaPlayer } from "../../contexts/MediaPlayerContext";
import { Story } from "../../interfaces/Interfaces";
import FloatingBackButton from "../buttons/FloatingBackButton";
import CloseStoryPlayerButton from '../buttons/CloseStoryPlayerButton';

import "./WebStoryPlayer.scss";

const StoryLink: React.FC<{ story: Story }> = ({ story }) => {
  return <a href={`${story.externalStoryUrl!}#embedMode=1`}>{story.title}</a>;
};

const StoryLinks: React.FC<{ stories: Story[] }> = ({ stories }) => {
  return (
    <>
      {stories.map((story) => (
        <StoryLink story={story} key={`story-link-${story.id}`} />
      ))}
    </>
  );
};

const StoryPlayer: React.FC<{ story: Story }> = ({ story }) => {
  const playerRef = useRef<any>(null);
  const stories = [story];

  const onPlayerInit = (player: any) => {
    // Pause the player
    player.addEventListener("amp-story-player-close", () => {
      player.pause();
    });

    // Rewind each story to the beginning
    // stories.forEach((story) => {
    //   if (story.externalStoryUrl) {
    //     player.rewind(story.externalStoryUrl);
    //   }
    // });

    // Play the first story
    // player.play();

    // Unmute the player
    // player.unmute();

    // Navigation between stories
    player.addEventListener("navigation", (event: any) => {
      const previousStoryIndex = event.detail.index - 1;
      const previousStory = stories[previousStoryIndex];

      if (previousStory) {
        // Pause the previous story
        player.pause();
      }

      const currentStoryIndex = event.detail.index;
      const currentStory = stories[currentStoryIndex];

      if (currentStory) {
        // Rewind the current story to the beginning
        // player.rewind(currentStory.externalStoryUrl!);
        // Play and unmute the current story
        // player.play();
        // player.unmute();
      }
    });

    // Navigation between story pages
    player.addEventListener("storyNavigation", (event: any) => {
      // Pause the audio from the previous slide, can otherwise continue to play§
      // if a video was still loading while navigating onwards
      // event.target.pause();
      // event.target.play();
    });

    const timer = setTimeout(() => {
      player.play();
      // player.unmute();
    }, 1000);

    return () => clearTimeout(timer);
  };

  useEffect(
    () => {
      if (!playerRef.current) return;

      const player = playerRef.current;

      if (player.isReady) {
        return onPlayerInit(player);
      } else {
        player.addEventListener("ready", () => {
          return onPlayerInit(player);
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [playerRef]
  );

  return (
    <amp-story-player
      ref={playerRef}
      layout="responsive"
      exit-control="close-button"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <StoryLinks stories={stories} />
    </amp-story-player>
  );
};

export const IncludeAmpStoryPlayerScript = () => {
  return (
    <Helmet>
      <script
        async
        src="https://cdn.ampproject.org/amp-story-player-v0.js"
      ></script>
      <link
        href="https://cdn.ampproject.org/amp-story-player-v0.css"
        rel="stylesheet"
        type="text/css"
      />
      <meta
        name="viewport"
        content="width=device-width,minimum-scale=1,initial-scale=1"
      ></meta>
    </Helmet>
  );
};

const WebStoryPlayer: React.FC = () => {
  const { currentStory } = useMediaPlayer();

  if (!currentStory) {
    return null;
  }

  return (
    <>
      <IncludeAmpStoryPlayerScript />
      <IonContent>
        <FloatingBackButton />

        {/* Close tour button */}
        <CloseStoryPlayerButton />

        {/* Web Story Player */}
        <div id="story-player-container" className="h-full w-full">
          <StoryPlayer story={currentStory} />
        </div>

        {/*
          Note: Keeping this for reference, a simple iframe with autoplay permissions
          can work too for playing the story in a muted state.
        */}
        {/* <iframe
          key={`story-${currentStory.id}`}
          title={currentStory.title}
          src={currentStory.externalStoryUrl}
          frameBorder="0"
          className="h-full w-full"
          allow="autoplay"
        ></iframe> */}
      </IonContent>
    </>
  );
};

export default WebStoryPlayer;
