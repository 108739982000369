import {
  IonButtons,
  IonButton,
  IonHeader,
  IonContent,
  IonToolbar,
  IonPage,
  IonIcon,
  UseIonRouterResult,
  IonLabel,
  IonItem,
} from '@ionic/react';
import { useEffect, useState } from 'react';
import { close, codeOutline, heart } from 'ionicons/icons';
import { generatePath } from 'react-router';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { differenceWith, reduce, round, sortBy } from 'lodash-es';

import useStoryExplorationStore from '../../stores/useStoryExplorationStore';
import {
  useGetStoriesLikeStatisticsLazyQuery,
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useError from '../../hooks/useError';
import useAnalyticsStore from '../../stores/useAnalyticsStore';
import { Story } from '../../interfaces/Interfaces';
import { useLocale } from '../../contexts/LocaleContext';
import { getDistanceBetweenCoordinates } from '../../helpers/turf-helpers';
import useBackgroundGeolocationStore from '../../stores/useBackgroundGeolocationStore';

const StoriesListInExploreModeModal: React.FC<{
  router?: UseIonRouterResult;
  onDismiss: () => void;
}> = ({ onDismiss, router }) => {
  const { t } = useTranslation();
  const { handleBackendError } = useError();
  const { locale } = useLocale();

  const [getStoriesLikeStatisticsQuery] = useGetStoriesLikeStatisticsLazyQuery();

  const [storiesLikesStatistics, setStoriesLikesStatistics] = useState<{ [key: string]: number; }>({});
  const [storyDistances, setStoryDistances] = useState<{ [key: string]: number; }>({});
  const [unviewedNearestStoriesToExplore, setUnviewedNearestStoriesToExplore] = useState<Story[]>([]);
  const [sortedUnviewedNearestStories, setSortedUnviewedNearestStories] = useState<Story[]>([]);

  const nearestStoriesToExplore = useStoryExplorationStore((state) => state.nearestStoriesToExplore);
  const viewedStoryIds = useAnalyticsStore((state) => state.viewedStoryIds);
  const currentLocation = useBackgroundGeolocationStore((state) => state.currentLocation);

  useEffect(() => {
    // get stories likes statistics
    handleBackendError(async () => {
      const { data, error } = await getStoriesLikeStatisticsQuery({
        fetchPolicy: 'no-cache',
      });

      if (error) return error;

      const storiesLikesStatisticsData = reduce(
        data?.storyEvaluation?.getStoriesLikeStatistics,
        (acc, storyLikeStatistics) => ({
          ...acc,
          [storyLikeStatistics.datoStoryId]: storyLikeStatistics.totalLikesAmount
        }),
      {});

      setStoriesLikesStatistics(storiesLikesStatisticsData);
    });
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    // filter already viewed stories
    const unviewedStories = differenceWith(
      nearestStoriesToExplore,
      viewedStoryIds,
      (arrVal, othVal) => arrVal.id === othVal,
    );

    setUnviewedNearestStoriesToExplore(unviewedStories);
  }, [nearestStoriesToExplore, viewedStoryIds]);

  useEffect(() => {
    // sort stories by amount of likes
    const sortedStories = sortBy(unviewedNearestStoriesToExplore, (story) => {
      const totalLikesAmount = storiesLikesStatistics?.[story?.id] || 0;
      return -totalLikesAmount;
    });

    setSortedUnviewedNearestStories(sortedStories)
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unviewedNearestStoriesToExplore, storiesLikesStatistics]
  );

  useEffect(() => {
    // count distance to stories
    const storyDistancesData = reduce(
      unviewedNearestStoriesToExplore,
      (acc, story) => {
        const distance = getDistanceBetweenCoordinates(currentLocation, story?.location);

        return {
          ...acc,
          ...distance ? { [story.id]: round(distance) } : {},
      }},
    {});

    setStoryDistances(storyDistancesData)
  },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unviewedNearestStoriesToExplore, currentLocation]
  );

  const navigateToStoryPage = (story: Story) => {
    router?.push(generatePath("/:locale(de|en|fr|es)/story?:queryParams", {
      locale,
      queryParams: queryString.stringify({ storyId: story.id })
    }));

    onDismiss();
  };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton onClick={() => onDismiss()}>
              <IonIcon icon={close} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div className="px-5 pb-5 h-full text-center">
          <h3 className="text-[1.25rem] text-[#414254] font-bold pb-5">
            {t('storiesListInExploreModeModal.title')}
          </h3>

          {sortedUnviewedNearestStories?.map((story) =>
            <IonItem
              key={story.id}
              lines="none"
              color="light"
              className="mb-2"
              style={{ '--padding-end': '5px', '--padding-start': '5px', '--inner-padding-end': 0 }}
              onClick={() => navigateToStoryPage(story)}
            >
              <IonLabel color="dark" className="mr-2 font-semibold">{story.title}</IonLabel>

              <div className="flex items-center text-[#414254]">
                <div className="flex items-center mr-2">
                  <IonIcon icon={codeOutline} slot="end" className="mr-1"/>
                  <div className="min-w-[40px] text-[0.75em] text-left">
                    {storyDistances?.[story?.id] || 0}{t('tour.metersInShortForm')}
                  </div>
                </div>

                <div className="flex items-center">
                  <IonIcon icon={heart} slot="end" className="text-rose-700 mr-1"/>
                  <div className="min-w-[25px] text-[0.75em] text-left">
                    {storiesLikesStatistics?.[story?.id] || 0}
                  </div>
                </div>
              </div>

            </IonItem>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default StoriesListInExploreModeModal;
