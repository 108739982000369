import { Glassfy, GlassfySku } from 'capacitor-plugin-glassfy';
import { Capacitor } from '@capacitor/core';

import useToast from './useToast';

const useInAppPurchase = () => {
  const { presentToast } = useToast();

  const initGlassfy = async () => {
    if (!Capacitor.isNativePlatform()) {
      return false;
    }

    if (!process.env.REACT_APP_GLASSFY_API_KEY) {
      presentToast('Glassfy. API key error', 'danger');
      return false;
    }

    try {
      await Glassfy.initialize({ apiKey: process.env.REACT_APP_GLASSFY_API_KEY, watcherMode: false });
      return true;
    } catch (e) {
      presentToast('Glassfy. Initialization error', 'danger');
      return false;
    }
  };

  const getOffering = async (offeringId: string) => {
    try {
      const offerings = await Glassfy.offerings();
      return offerings.all.find((o) => o.offeringId === offeringId);
    } catch (e) {
      presentToast('Glassfy. Fetching offerings error', 'danger');
      return null;
    }
  };

  const purchaseSku = async (sku: GlassfySku) => {
    try {
      await Glassfy.purchaseSku({ sku });
      return true;
    } catch (e) {
      presentToast('Glassfy. Purchase error', 'danger');
      return false;
    }
  };

  const setCustomSubscriber = async (userId: string) => {
    try {
      await Glassfy.connectCustomSubscriber({ subscriberId: userId });
      return true;
    } catch (e) {
      presentToast('Glassfy. Connecting custom subscriber error', 'danger');
      return false;
    }
  };

  return {
    initGlassfy,
    getOffering,
    purchaseSku,
    setCustomSubscriber,
  };
};

export default useInAppPurchase;
