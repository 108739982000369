import { BaseFileFieldsFragment } from "../../graphql/dato/__generated__/dato-graphql.generated";

const ImageCopyright: React.FC<{ fileField: BaseFileFieldsFragment }> = ({
  fileField,
}) => {
  const { copyright, author } = fileField;

  if (!copyright && !author) {
    return null;
  }

  return (
    <div className="mx-2 text-center text-[0.65rem] leading-[0.8rem] text-slate-100">
      &copy; {[author, copyright].filter((x) => x).join(" – ")}
    </div>
  );
};

export default ImageCopyright;
