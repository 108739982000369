import { point as turfPoint, Units as TurfUnits } from '@turf/helpers';
import turfDistance from '@turf/distance';

import { LatLonField } from '../graphql/dato/__generated__/dato-graphql.generated';

const getDistanceBetweenCoordinates = (location1?: LatLonField | null, location2?: LatLonField | null) => {
  let distance = null;
  if (
    location1?.longitude && location1?.latitude &&
    location2?.longitude && location2?.latitude
  ) {
    const from = turfPoint([location1?.longitude, location1?.latitude]);
    const to = turfPoint([location2?.longitude, location2?.latitude]);
    const options: { units?: TurfUnits; } = { units: 'meters' };
    distance = turfDistance(from, to, options);
  }

  return distance
};

export { getDistanceBetweenCoordinates };
