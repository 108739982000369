import { memo, useEffect, useState } from 'react';
import { IonIcon, useIonRouter } from '@ionic/react';
import clsx from 'clsx';

import { Story } from '../../interfaces/Interfaces';
import { MixpanelEvents, MixpanelPeopleProperties, useMixpanel, } from '../../contexts/MixpanelContext';
import useRoutes from '../../hooks/useRoutes';
import useAuthStore from '../../stores/useAuthStore';
import useError from '../../hooks/useError';
import {
  EvaluationType,
  StoryEvaluation, useEvaluateStoryMutation,
  useGetMyStoryEvaluationLazyQuery
} from '../../graphql/backend/__generated__/backend-graphql-sdk.generated';
import thumbDownIcon from '../../assets/thumbs/thumb-down.svg';
import thumbUpIcon from '../../assets/thumbs/thumb-up.svg';

const StoryEvaluationButtons: React.FC<{ story: Story }> = ({ story }) => {
  const router = useIonRouter();
  const { currentPath, loginPath } = useRoutes();
  const { handleBackendError } = useError();

  const [myStoryEvaluation, setMyStoryEvaluation] = useState<Partial<StoryEvaluation> | null | undefined>();
  const [isMyStoryEvaluationLoading, setIsMyStoryEvaluationLoading] = useState<boolean>(false);
  const [isEvaluateStoryLoading, setIsEvaluateStoryLoading] = useState<boolean>(false);

  const [getMyStoryEvaluationQuery] = useGetMyStoryEvaluationLazyQuery();
  const [evaluateStoryMutation] = useEvaluateStoryMutation();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);

  const { mixpanel, mixpanelEnabled } = useMixpanel();

  useEffect(
    () => {
      let isMounted = true;

      const fetchMyStoryEvaluation = async () => {
        await handleBackendError(async () => {
          if (isMounted) setIsMyStoryEvaluationLoading(true);

          const { data, error } = await getMyStoryEvaluationQuery({
            variables: {
              input: {
                datoStoryId: story.id
              }
            }
          });

          if (error) {
            if (isMounted) setIsMyStoryEvaluationLoading(false);
            return error;
          }

          if (isMounted) {
            setMyStoryEvaluation(data?.storyEvaluation?.getMyStoryEvaluation);
            setIsMyStoryEvaluationLoading(false);
          }
        });
      };

      if (isAuthenticated) fetchMyStoryEvaluation();

      return () => {
        isMounted = false;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAuthenticated]
  );

  const handleOnClick = async (evaluationType: EvaluationType) => {
    if (!isAuthenticated) {
      router.push(loginPath(currentPath()));
      return;
    }

    if (
      evaluationType !== myStoryEvaluation?.evaluationType &&
      !isMyStoryEvaluationLoading &&
      !isEvaluateStoryLoading
    ) {
      // TODO: add functionality to track dislikes
      if (evaluationType === EvaluationType.Dislike) {
        if (mixpanelEnabled) {
          mixpanel.track(MixpanelEvents.REMOVE_STORY_LIKE, {
            storyId: story.id,
            storyTitle: story.title,
          });

          // Decrement number of stories liked on the user profile in Mixpanel
          mixpanel.people.increment(MixpanelPeopleProperties.STORIES_LIKED, -1);
        }
      } else {
        if (mixpanelEnabled) {
          mixpanel.track(MixpanelEvents.ADD_STORY_LIKE, {
            storyId: story.id,
            storyTitle: story.title,
          });

          // Increment number of stories liked on the user profile in Mixpanel
          mixpanel.people.increment(MixpanelPeopleProperties.STORIES_LIKED);
        }
      }

      await handleBackendError(async () => {
        setIsEvaluateStoryLoading(true);

        const { data, errors } = await evaluateStoryMutation({
          variables: {
            input: {
              datoStoryId: story.id,
              evaluationType,
            }
          }
        });

        if (errors) {
          setIsEvaluateStoryLoading(false);
          return errors;
        }

        setMyStoryEvaluation(data?.storyEvaluation?.evaluateStory);
        setIsEvaluateStoryLoading(false);
      });
    }
  };

  return (
    <div className="whitespace-nowrap ml-2">
      <IonIcon
        icon={thumbUpIcon}
        onClick={() => handleOnClick(EvaluationType.Like)}
        className={clsx(
          "cursor-pointer text-[25px] mr-2",
          myStoryEvaluation?.evaluationType === EvaluationType.Like ? "text-rose-700" : "text-slate-100"
        )}
      />

      <IonIcon
        icon={thumbDownIcon}
        onClick={() => handleOnClick(EvaluationType.Dislike)}
        className={clsx(
          "cursor-pointer text-[25px]",
          myStoryEvaluation?.evaluationType === EvaluationType.Dislike ? "text-rose-700" : "text-slate-100"
        )}
      />
    </div>
  );
};

export default memo(StoryEvaluationButtons);
