import {
  createContext,
  useContext, useEffect,
  useState,
} from 'react';
import { difference, head, isArray, uniq } from 'lodash-es';

import { getItemFromStorage, setItemToStorage } from '../helpers/storage-helpers';

const useValue = () => {
  const [completedHints, setCompletedHints] = useState<string[]>();
  const [hintsQueue, setHintsQueue] = useState<string[]>([]);
  const [hintToDisplay, setHintToDisplay] = useState<string>();

  useEffect(() => {
    const checkCompletedHints = async () => {
      const completedOnboardingHints = await getItemFromStorage('completedOnboardingHints');
      setCompletedHints(completedOnboardingHints || []);
    };
    checkCompletedHints();
  }, []);

  useEffect(() => {
    if (isArray(completedHints)) {
      const uncompletedHints = difference(hintsQueue, completedHints);
      setHintToDisplay(head(uncompletedHints));
    }
  }, [hintsQueue, completedHints]);

  const setHintToQueue = async (hintName: string) => {
    setHintsQueue((prev) => (uniq([...prev, hintName])));
  };

  const completeHint = async (hintName: string) => {
    const updatedCompletedHints = [...completedHints || [], hintName];
    setItemToStorage('completedOnboardingHints', updatedCompletedHints);
    setCompletedHints(updatedCompletedHints);
  };

  return {
    hintToDisplay,
    setHintToQueue,
    completeHint,
  };
};

const OnboardingHintsContext = createContext({} as ReturnType<typeof useValue>);

const OnboardingHintsProvider: React.FC = ({ children }) => {
  return (
    <OnboardingHintsContext.Provider value={useValue()}>
      {children}
    </OnboardingHintsContext.Provider>
  );
};

const useOnboardingHints = () => {
  return useContext(OnboardingHintsContext);
};

export { OnboardingHintsProvider, useOnboardingHints };
