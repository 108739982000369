import {
  IonButton,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  useIonModal,
  useIonRouter,
  useIonViewDidEnter
} from '@ionic/react';
import { RouteComponentProps } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Image, ResponsiveImageType } from 'react-datocms';
import { carOutline, phonePortraitOutline } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';

import AppLayout from '../layouts/AppLayout';
import FloatingBackButton from '../components/buttons/FloatingBackButton';
import StartTourButton from './TourPage/StartTourButton';
import useTour from '../hooks/useTour';
import useAuthStore from '../stores/useAuthStore';
import useRoutes from '../hooks/useRoutes';
import { useCreateGroupSharingMutation } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useGroupSharingStore from '../stores/useGroupSharingStore';
import GroupSharingQrCodeModal from '../components/modals/GroupSharingQrCodeModal';
import useError from '../hooks/useError';
import DownloadTourButton from '../components/buttons/DownloadTourButton';
import walkIcon from '../assets/transportation-types/walk.svg';
import bikeIcon from '../assets/transportation-types/bike.svg';
import carIcon from '../assets/transportation-types/car.svg';
import busIcon from '../assets/transportation-types/bus.svg';
import trainIcon from '../assets/transportation-types/train.svg';
import boatIcon from '../assets/transportation-types/boat.svg';
import directionIcon from '../assets/tour/direction.svg';
import mapImage from '../assets/tour/map.png';
import { isIosVersion } from '../helpers/device-helpers';
import useDownload from '../hooks/useDownload';
import { MixpanelEvents, useMixpanel } from '../contexts/MixpanelContext';

interface StartTourPageProps extends RouteComponentProps<{ tourSlug: string }> {}

const StartTourPage: React.FC<StartTourPageProps> = ({ match }) => {
  const router = useIonRouter();
  const { tourSlug } = match.params;
  const { tour } = useTour({ tourSlug });
  const { t } = useTranslation();
  const { currentPath, loginPath } = useRoutes();
  const { handleBackendError } = useError();
  const { downloadTour } = useDownload();
  const { mixpanel, mixpanelEnabled } = useMixpanel();

  const [createGroupSharingMutation] = useCreateGroupSharingMutation();

  const isAuthenticated = useAuthStore((state) => state.isAuthenticated);
  const myGroupSharing = useGroupSharingStore((state) => state.myGroupSharing);
  const setMyGroupSharing = useGroupSharingStore((state) => state.setMyGroupSharing);

  const [present, dismiss] = useIonModal(GroupSharingQrCodeModal, {
    groupSharing: myGroupSharing,
    tour,
    router,
    onDismiss: () => dismiss(),
  });

  useIonViewDidEnter(() => {
    if (mixpanelEnabled) {
      mixpanel.track(MixpanelEvents.VIEW_TOUR_START, {
        tourId: tour?.id,
        tourTitle: tour?.title,
      });
    }
  });

  const tourHighlightImage = tour?.highlightImage
    ?.responsiveImage as ResponsiveImageType;

  const firstTourStopLocation = tour?.tourStops?.[0]?.location;
  const firstTourStopLocationUrl = firstTourStopLocation &&
    `https://www.google.com/maps/place/${firstTourStopLocation?.latitude},${firstTourStopLocation?.longitude}`;

  const startGroupSharing = async () => {
    if (!isAuthenticated) {
      router.push(loginPath(currentPath()));
      return;
    }

    if (myGroupSharing) {
      if (mixpanelEnabled) {
        mixpanel.track(MixpanelEvents.INVITE_TO_GROUP_SHARING, {
          source: 'Start tour page',
          tourId: tour?.id,
          tourTitle: tour?.title,
        });
      }

      present({
        // set animated false for ios 17,because animation breaks the popup
        // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
        animated: !isIosVersion(17)
      });
    } else {
      if (mixpanelEnabled) {
        mixpanel.track(MixpanelEvents.START_GROUP_SHARING, {
          source: 'Start tour page',
          tourId: tour?.id,
          tourTitle: tour?.title,
        });
      }

      await handleBackendError(async () => {
        const { data, errors } = await createGroupSharingMutation({ variables: { input: {}}});

        if (errors) return errors;

        const groupSharing = data?.groupSharing?.createGroupSharing;
        if (groupSharing) {
          setMyGroupSharing(groupSharing, true);
          present({
            // set animated false for ios 17,because animation breaks the popup
            // on this ios version (https://github.com/ionic-team/ionic-framework/issues/27620)
            animated: !isIosVersion(17)
          });
        }
      });
    }
  };

  return (
    <AppLayout>
      <IonContent>
        <div className="relative mx-auto h-full max-w-xl">
          <FloatingBackButton/>

          {tour && (
            <div
              className="flex flex-col justify-between h-full items-between py-5 px-7"
              style={{
                paddingTop: "calc(var(--ion-safe-area-top, 12px) + 58px)",
              }}
            >
              <div>
                {tourHighlightImage && <div className="max-w-[230px]">
                    <Image data={tourHighlightImage} layout="responsive" />
                </div>}

                <div className="flex justify-between items-center space-x-2 pb-7 pt-2">
                  <div>
                    <h3 className="text-[#414254] font-extrabold pb-1">{tour?.title}</h3>

                    <div className="text-[#414254] text-[0.6875rem]">
                      <span className="mr-0.5">{t('startTourPage.infoBlock.distance')}:</span>
                      <span className="font-bold">{tour.distanceInKilometers} {t('tour.kilometersInShortForm')}</span>
                    </div>

                    <div className="flex flex-wrap text-[#414254] text-[0.6875rem]">
                      <span className="mr-0.5">{t('startTourPage.infoBlock.totalDuration')}:</span>

                      {tour.durationInMinutes && <div className="flex items-center justify-end mr-1.5">
                          <IonIcon src={walkIcon} className="mr-0.5" />
                          <span className="font-bold">
                            {tour.durationInMinutes} {t('tour.minutesInShortForm')}
                          </span>
                      </div>}

                      {tour.durationByBikeInMinutes && <div className="flex items-center justify-end mr-1.5">
                          <IonIcon src={bikeIcon} className="mr-0.5" />
                          <span className="font-bold">
                            {tour.durationByBikeInMinutes} {t('tour.minutesInShortForm')}
                          </span>
                      </div>}

                      {tour.durationByCarInMinutes && <div className="flex items-center justify-end mr-1.5">
                          <IonIcon src={carIcon} className="mr-0.5" />
                          <span className="font-bold">
                            {tour.durationByCarInMinutes} {t('tour.minutesInShortForm')}
                          </span>
                      </div>}

                      {tour.durationByBusInMinutes && <div className="flex items-center justify-end mr-1.5">
                          <IonIcon src={busIcon} className="mr-0.5" />
                          <span className="font-bold">
                            {tour.durationByBusInMinutes} {t('tour.minutesInShortForm')}
                          </span>
                      </div>}

                      {tour.durationByTrainInMinutes && <div className="flex items-center justify-end mr-1.5">
                          <IonIcon src={trainIcon} className="mr-0.5" />
                          <span className="font-bold">
                            {tour.durationByTrainInMinutes} {t('tour.minutesInShortForm')}
                          </span>
                      </div>}

                      {tour.durationByBoatInMinutes && <div className="flex items-center justify-end mr-1.5">
                          <IonIcon src={boatIcon} className="mr-0.5" />
                          <span className="font-bold">
                            {tour.durationByBoatInMinutes} {t('tour.minutesInShortForm')}
                          </span>
                      </div>}
                    </div>
                  </div>
                </div>

                {!!firstTourStopLocationUrl && <>
                  <p className="text-[1rem] text-[#414254] font-medium pt-2 pb-3 underline">
                    {t('startTourPage.directionBlock.title')}
                  </p>

                  <div className="flex justify-between w-full pb-5">
                    <div className="flex items-center mr-2">
                        <a
                          href={firstTourStopLocationUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="flex items-center"
                        >
                            <IonIcon src={directionIcon} className="text-[2rem] mr-3" />
                        </a>

                        <a
                          href={firstTourStopLocationUrl}
                          target="_blank"
                          rel="noreferrer"
                          className="text-[0.75rem] leading-1 text-[#414254]"
                        >{t('startTourPage.infoBlock.buttons.showNavigation')}</a>
                    </div>

                    <img src={mapImage} className="w-[54px] h-[54px] shadow-[0_0_10px_1px_rgba(0,0,0,0.3)]"  alt=""/>
                  </div>
                </>}

                <p className="text-[1rem] text-[#414254] font-medium pt-2 underline">
                  {t('startTourPage.warningBlock.title')}
                </p>

                <IonItem
                  lines="none"
                  style={{ '--padding-end': 0, '--padding-start': 0, '--inner-padding-end': 0 }}
                >
                  <IonIcon src={phonePortraitOutline} slot="start" className="mr-5" />
                  <IonLabel color="dark" className="ion-text-wrap m-0">
                    <div className="text-[0.75rem] leading-1">
                      {t(`startTourPage.warningBlock.batteryMessage`)}
                    </div>
                  </IonLabel>
                </IonItem>

                <IonItem
                  lines="none"
                  style={{ '--padding-end': 0, '--padding-start': 0, '--inner-padding-end': 0 }}
                >
                  <IonIcon src={carOutline} slot="start" className="mr-5" />
                  <IonLabel color="dark" className="ion-text-wrap m-0">
                    <div className="text-[0.75rem] leading-1">
                      {t(`startTourPage.warningBlock.trafficMessage`)}
                    </div>
                  </IonLabel>
                </IonItem>

                {Capacitor.isNativePlatform() && <IonItem
                  lines="none"
                  style={{ '--padding-end': 0, '--padding-start': 0, '--inner-padding-end': 0 }}
                >
                  <div className="mr-5 text-[#727273]"><DownloadTourButton tour={tour}/></div>
                  <IonLabel color="dark" className="ion-text-wrap m-0">
                    <div className="text-[0.75rem] leading-1">
                      {t(`startTourPage.warningBlock.downloadTourMessage.partOne`)}
                      <span
                        className="font-bold underline"
                        onClick={() => {
                          isAuthenticated ? downloadTour(tour?.id) : router.push(loginPath(currentPath()));
                        }}
                      >{t(`startTourPage.warningBlock.downloadTourMessage.partTwo`)}</span>
                      {t(`startTourPage.warningBlock.downloadTourMessage.partThree`)}
                    </div>
                  </IonLabel>
                </IonItem>}
              </div>

              <div className="flex flex-col pb-5">
                <StartTourButton tour={tour} router={router} />
                <IonButton
                  size="large"
                  shape="round"
                  fill="outline"
                  className="normal-case font-medium text-[1rem] min-h-[56px]"
                  style={{
                    '--border-color': '#EA7358',
                    '--color': '#EA7358'
                  }}
                  onClick={startGroupSharing}
                ><span className="font-bold">{t('startTourPage.buttons.startGroupSharing')}</span></IonButton>
              </div>

              <div className="grow"/>
            </div>
          )}
        </div>
      </IonContent>
    </AppLayout>
  );
};

export default StartTourPage;
