import { useIonRouter } from "@ionic/react";
import { useRouteMatch } from "react-router";

import { useMediaPlayer } from "../contexts/MediaPlayerContext";
import { Story, StorySlide } from "../interfaces/Interfaces";
import useRoutes from "./useRoutes";
import { useChangeGroupSharingMutation } from '../graphql/backend/__generated__/backend-graphql-sdk.generated';
import useGroupSharingStore from '../stores/useGroupSharingStore';

const useStoryPlayer = () => {
  const router = useIonRouter();
  const { storyPath } = useRoutes();
  const { currentTour, currentTourStop, currentStory } = useMediaPlayer();
  const [changeGroupSharingMutation] = useChangeGroupSharingMutation();

  const myGroupSharing = useGroupSharingStore((state) => state.myGroupSharing);

  const routeMatch = useRouteMatch("/:locale(de|en|fr|es)/story");

  const navigateToStory = ({ story }: { story: Story }) => {
    if (story !== currentStory) {
      const { tourStop } = story;

      const storyPlayerPath = storyPath({
        story,
        tour: tourStop?.tour,
        tourStop,
      });

      // update Group Sharing if the user is in Group Sharing mode
      if (myGroupSharing) {
        // delete isGroupSharingStart query param from the route
        const updatedStoryPlayerPath = storyPlayerPath.replace('&isGroupSharingStart=true', '');

        changeGroupSharingMutation({
          variables: {
            input: {
              navigationUrl: updatedStoryPlayerPath,
              startAudioTime: new Date(),
              isPlaying: true,
            }
          }
        });
      }

      if (routeMatch) {
        // Replace current history entry using replaceState, and instruct the router
        // to not animate in the new page
        router.push(storyPlayerPath, "none", "replace");
      } else {
        // Play the story by opening the story player and pushing the URL to the history
        router.push(storyPlayerPath);
      }
    }
  };

  const navigateToCurrentStory = () => {
    if (currentStory) {
      const storyPlayerPath = storyPath({
        story: currentStory,
        tour: currentTour,
        tourStop: currentTourStop,
      });
      router.push(storyPlayerPath);
    }
  };

  const navigateToStorySlide = ({
    storySlide,
    skipRouteMatching,
  }: {
    storySlide: StorySlide,
    skipRouteMatching?: boolean
  }) => {
    const { story } = storySlide;

    // If the story player is open and the story or story slide belongs to a different story,
    // replace the URL state with the new story
    if ((routeMatch || skipRouteMatching) && story && story !== currentStory) {
      navigateToStory({ story });
    }
  };

  return {
    navigateToStory,
    navigateToCurrentStory,
    navigateToStorySlide,
  };
};

export default useStoryPlayer;
